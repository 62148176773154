<script>

import Element from "@/components/Element.vue";

export default {
    name: "Code",
    extends: Element
}

</script>

<template>

    <div class="generate-element">
        <div v-html="info.code"></div>
    </div>

</template>

<style scoped lang="scss">

</style>