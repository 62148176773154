<script>
import Element from "@/components/Element.vue";
import Title2 from "@/views/Base/Title/Title2.vue";

export default {
  name: "PortalTeamName",
  components: {Title2},
  extends: Element,
}
</script>

<template>
  <Title2 :info="obj" :title="obj.name"/>
</template>

<style scoped lang="scss">

</style>