<script>

import axios from "@/axios";

export default {
  name: 'Colors',

  components: {},

  data: () => ({
    colors: [{
      name: 'main_color',
      value: '#002e5a',
    }, {
      name: 'secondary_color',
      value: 'blue',
    }, {
      name: 'link',
      value: '#003399',
    }, {
      name: 'text',
      value: '#1B1B1B',
    }, {
      name: 'bg',
      value: 'white',
    }, {
      name: 'border',
      value: '#003399',
    }, {
      name: 'border_light',
      value: '#407DD8',
    }, {
      name: 'icon_bg',
      value: 'white',
    }, {
      name: 'icon',
      value: '#003399',
    }, {
      name: 'block_dark_bg',
      value: 'white',
    }, {
      name: 'block_dark_text',
      value: 'white',
    }, {
      name: 'block_dark_border',
      value: 'white',
    }],
  }),

  computed: {},

  methods: {

    async loadPack() {

      let url = 'domaincolor/packexec';

      this.colors = await axios.get(url, {}).then(result => result.data.data);

      let doms = this.colors.map(el => el.dom);

      let dom_uniq = [...new Set(doms)]

    },

    updateColors() {

      let colors = this.colors;

      let element = null;

      colors.forEach(el => {

        element = null;
        if (el.dom === null) {
          element = document.documentElement;
        }
        if (el.dom !== null) {
          element = document.getElementsByTagName(el.dom);
          element = element.length > 0 ? element[0] : null;
        }

        if (element) {
          // console.log(el.dom,el.code,el.color)
          element.style.setProperty("--" + el.code, el.color);
        }
      });


    },

  },

  async mounted() {

    await this.loadPack();

    this.updateColors();

    setTimeout((e)=>{
      this.updateColors();
    }, 2000)

  }
}
</script>

<style>

#test {
  color: var(--main);
}
</style>