<script>
import router from "@/router";
import ControllToast from "@/Library/Controll/Toast";

export default {
  name: "ControllError",
  mixins: [ControllToast],
  data: () => ({
    errors: [],
  }),
  methods: {

    errorLog(result) {
      let error_data = result.response.data;
      let error_status = result.response.status;
      let error_header = result.response.headers;
      let error_code = error_data.code;

      console.log(error_code);
      console.log(error_data);
      console.log(error_status);
      console.log(error_header);

    },

    /**
     * Rozpoznaje błędy i wyświetla informacje
     *
     * @param result obiekt odpowiedzi
     * @returns {*[]}
     */
    errorRecognize(result) {
      let ret = [];

      let error_data = result.response.data;
      let error_code = error_data.code;

      switch (error_code) {
        case 'no-required-data':

          ret.push({
            type: 'toast',
            data: 'Brak wymaganych danych'
          });

          break;
        case 'in-story':

          ret.push({
            type: 'toast',
            data: 'Hasło było w użyciu'
          });

          break;

        case 'no-login':
        case 'token-expired':

          ret.push({
            type: 'toast',
            data: 'Token wygasł'
          });
          ret.push({
            type: 'logout',
            data: {}
          });

          break;
      }

      return ret;


    },

    /**
     * Wykonuje akcje
     *
     * @param arr tablica akcji
     */
    errorExecute(arr) {

      let self = this;

      arr.forEach(element => {

        let type = element.type;
        let data = element.data;

        switch (type) {

            /**
             * Wysyła informacje
             */
          case 'toast':
            self.toastError(data);
            break;

            /**
             * Akcja wylogowania
             */
          case 'logout':
            router.push('/logout');
            break;

        }

      });

    },

    /**
     * Akcja uproszczona reagowania na błędy
     *
     * @param error obiekt błędu
     * @param config obiekt konfiguracji
     */
    errorCatch(error, config = {}) {


      console.log(error)

      /**
       * Czy pokazywać błąd w logach
       * @type {boolean} czy pokazywać błąd w logach
       */
      let show_log = config.show_log === undefined ? false : config.show_log;

      /**
       * Rozpoznaje
       */
      let errorActions = this.errorRecognize(error);

      if (show_log)
        this.errorLog(error);

      /**
       * Wykonuje akcje
       */
      this.errorExecute(errorActions);

    }

  }


}
</script>