<template>
  <div class="home">

    <div class="container">

      <Slider1/>

      <div class="line-info">
        <div class="row">
          <div class="col-12 col-md-6">
            <img src="https://us.edu.pl/wp-content/uploads/image/t4e/Transform4Europe_CVI-3_internet-1.png" alt="" style="width: 300px; max-width: 70%"/>
          </div>
          <div class="col-12 col-md-6"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <TabsContentLeft/>
        </div>
      </div>

      <BlockBlue/>

      <div class="row">
        <div class="col-12">
          <Title title="Aktualności"/>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-for="item in items">
              <ArticleBlock1 v-bind="item"/>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <Note1/>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Title title="Publikacje"/>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="item in items2">
              <ArticleBlockPublication v-bind="item"/>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Title title="UŚK w liczbach"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <StatLetterList/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Colors from "@/views/Colors.vue";
import SocialList from "@/views/Base/Social/List.vue";
import MenuIcon from "@/views/Base/Menu/Icon.vue";
import MenuInline from "@/views/Base/Menu/Inline.vue";
import StatIconList from "@/views/Base/Stat/IconList.vue";
import StatLetterList from "@/views/Base/Stat/LetterList.vue";
import Title from "@/views/Base/Title/Title.vue";
import ArticleBlockPublication from "@/views/Article/BlockPublication.vue";
import TestimonialBlock from "@/views/Base/Testimonial/Block.vue";
import ArticleBlockPublication2 from "@/views/Article/BlockPublication2.vue";
import LinkShowMore from "@/views/Base/Link/ShowMore.vue";
import Hello1 from "@/views/Base/Hello/Hello1.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import MenuBlock from "@/views/Base/Menu/Block.vue";
import ToggleBlock from "@/views/Base/Toggle/Block.vue";
import ToggleList from "@/views/Base/Toggle/List.vue";
import FileBlock from "@/views/Base/File/Block.vue";
import FileList from "@/views/Base/File/List.vue";
import Hr1 from "@/views/Base/Hr/Hr1.vue";
import Tabs1 from "@/views/Base/Tabs/Tabs1.vue";
import Note1 from "@/views/Base/Note/Note1.vue";
import Slider1 from "@/views/Base/Slider/Slider1.vue";
import Gallery1 from "@/views/Base/Gallery/Gallery1.vue";
import LineBig1 from "@/views/Base/Line/LineBig1.vue";
import LinkListBig from "@/views/Base/Link/ListBig.vue";
import ListIconCheck from "@/views/Base/List/IconCheck.vue";
import InfoListIcon1 from "@/views/Base/Info/ListIcon1.vue";
import ArticleBlock1 from "@/views/Article/Block1.vue";
import Row from "@/Library/Forms/Row.vue";
import TabsContentLeft from "@/views/Base/Tabs/TabsContentLeft.vue";
import Page from "@/Extends/Page.vue";
import BlockBlue from "@/views/Base/Block/Blue.vue";

export default {
  name: 'HomeView',

  extends: Page,
  components: {
    BlockBlue,
    TabsContentLeft,
    Row,
    ArticleBlock1,
    InfoListIcon1,
    ListIconCheck,
    LinkListBig,
    LineBig1,
    Gallery1,
    Slider1,
    Note1,
    Tabs1,
    Hr1,
    FileList,
    FileBlock,
    ToggleList,
    ToggleBlock,
    MenuBlock,
    MenuDropdown,
    Hello1,
    LinkShowMore,
    ArticleBlockPublication2,
    TestimonialBlock,
    ArticleBlockPublication,
    Title,
    StatLetterList,
    StatIconList,
    MenuInline,
    MenuIcon,
    SocialList,
    Colors,
    HelloWorld
  },
  data: () => ({
    items: [
      {
        name: "Dr hab. Ireneusz Kida, prof. UŚ na stażu w Dżakarcie",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/Bez-kategorii/20230203_113430-300x225.jpg',
        date: "2023.05.10",
        text: "W ramach projektu pt. „Jeden Uniwersytet – Wiele Możliwości. Program Zintegrowany” (JUWM), w okresie od 27.01.2023 do 26.04.2023 dr hab. Ireneusz Kida, prof. UŚ przebywał na stażu w Katolickim Uniwersytecie Atma Jaya w Dżakarcie, Indonezja, a ściślej na Wydziale Pedagogiki i Języków oferującym następujące programy: nauczanie języka angielskiego, katolicka edukacja religijna, poradnictwo i doradztwo, kształcenie...\n",
      },
      {
        name: "Warsztaty dla studentów i studentek ze specjalności nauczycielskich",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/OBRAZKI-WYR%C3%93%C5%BBNIAJ%C4%84CE/%C5%81api%C4%85ce-si%C4%99-r%C4%99ce-dzieci.jpg',
        date: "2023.05.10",
        text: "Wszystkich studentów i studentki ze specjalności nauczycielskich, chcących rozwijać swoje kompetencje oraz wiedzę w zakresie nauczania i wychowania uczniów, zapraszamy do udziału w projekcie Koła Naukowego „PAIDAGOGOS” Podczas cyklu czterech warsztatów możliwe będzie",
      },
      {
        name: "Ogólnopolski sukces Yeleny Poleshko, studentki Wydziału Humanistycznego UŚ",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/OBRAZKI-WYROZNIAJACE/win-g861e51c44_1280-300x200.jpg',
        date: "2023.05.10",
        text: "W dniach 12-13 maja 2023 roku we Wrocławiu odbyła się jubileuszowa XXV Ogólnopolska Olimpiada Języka Polskiego dla Cudzoziemców organizowana przez Politechnikę Wrocławską. Wzięli w niej udział zagraniczni studenci z uczelni z całej Polski. Uniwersytet Śląski reprezentowali zwycięzcy wewnętrznych eliminacji: Yeliena Poleshko (Ukraina) i Chang Hei (Chiny) – z międzynarodowych studiów polskich na Wydziale Humanistycznym oraz Orest Maksymiv (Ukraina) i Nguyen Khoa Tran (Wietnam ) z...\n",
      },
      {
        name: "Wystawa „Między modernizmem a surrealizmem” na Wydziale Humanistycznym w Sosnowcu",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/Bez-kategorii/obrazek-wyrozniajacy28-300x200.png',
        date: "2023.05.10",
        text: "Serdecznie zapraszamy na wystawę „Między modernizmem a surrealizmem”. Przedstawione prace inspirowane są dziełami Pabla Picassa, bowiem rok 2023 został w Hiszpanii ogłoszony rokiem tego wybitnego artysty. Wystawa prac „Między modernizmem a surrealizmem” zawiera witraże, wyroby ceramiczne oraz grafiki przygotowane przez młodych artystów z Pałacu Młodzieży w Katowicach i Szkoły Podstawowej nr 55 Specjalnej w Katowicach....",
      },
      {
        name: "„Uświadamiamy w różnorodnych nurtach wsparcia psychicznego” – publikacja Koła Studenckiego Superwizja",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/Bez-kategorii/obrazek-wyrozniajacy27-300x200.png',
        date: "2023.05.10",
        text: "Serdecznie zapraszamy do zapoznania się z publikacją stworzoną przez grupę studentów i studentek z Koła Studenckiego Superwizja. „Uświadamiamy w różnorodnych nurtach wsparcia psychicznego” ma na celu poszerzenie świadomości na temat psychologicznych i filozoficznych form pomocy i wsparcia. Poradnik umożliwia zapoznanie się z dostępnymi na rynku usługami rozwojowymi i psychologicznymi, pokazuje różnice pomiędzy nimi i pomaga...",
      },
      {
        name: "Warsztaty stylometryczne dla nauczycielek i nauczycieli akademickich",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/OBRAZKI-WYRÓŻNIAJĄCE/startup-g18d7b8750_640-300x200.jpg',
        date: "2023.05.10",
        text: "Członkinie i członkowie Centrum Dydaktyki oraz  Zespołu Humanistyki Cyfrowej Wydziału Humanistycznego UŚ zapraszają serdecznie nauczycielki i nauczycieli akademickich na warsztaty stylometryczne, które poprowadzi prof. dr. hab. Jan Rybicki z Uniwersytetu Jagiellońskiego. Warsztaty odbędą się 17.05.2023 r. w godz. 10:30-14:00 i zostaną poświęcone pakietowi stylo, służącemu do porównywania tekstów na podstawie częstości słów. Pakiet stylo znajduje zastosowanie w...",
      },
    ],
    items2: [
      {
        name: "Dr hab. Ireneusz Kida, prof. UŚ na stażu w Dżakarcie",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/Bez-kategorii/20230203_113430-300x225.jpg',
        date: "2023.05.10",
        text: "W ramach projektu pt. „Jeden Uniwersytet – Wiele Możliwości. Program Zintegrowany” (JUWM), w okresie od 27.01.2023 do 26.04.2023 dr hab. Ireneusz Kida, prof. UŚ przebywał na stażu w Katolickim Uniwersytecie Atma Jaya w Dżakarcie, Indonezja, a ściślej na Wydziale Pedagogiki i Języków oferującym następujące programy: nauczanie języka angielskiego, katolicka edukacja religijna, poradnictwo i doradztwo, kształcenie...\n",
      },
      {
        name: "Warsztaty dla studentów i studentek ze specjalności nauczycielskich",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/OBRAZKI-WYR%C3%93%C5%BBNIAJ%C4%84CE/%C5%81api%C4%85ce-si%C4%99-r%C4%99ce-dzieci.jpg',
        date: "2023.05.10",
        text: "Wszystkich studentów i studentki ze specjalności nauczycielskich, chcących rozwijać swoje kompetencje oraz wiedzę w zakresie nauczania i wychowania uczniów, zapraszamy do udziału w projekcie Koła Naukowego „PAIDAGOGOS” Podczas cyklu czterech warsztatów możliwe będzie",
      },
      {
        name: "Ogólnopolski sukces Yeleny Poleshko, studentki Wydziału Humanistycznego UŚ",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/OBRAZKI-WYROZNIAJACE/win-g861e51c44_1280-300x200.jpg',
        date: "2023.05.10",
        text: "W dniach 12-13 maja 2023 roku we Wrocławiu odbyła się jubileuszowa XXV Ogólnopolska Olimpiada Języka Polskiego dla Cudzoziemców organizowana przez Politechnikę Wrocławską. Wzięli w niej udział zagraniczni studenci z uczelni z całej Polski. Uniwersytet Śląski reprezentowali zwycięzcy wewnętrznych eliminacji: Yeliena Poleshko (Ukraina) i Chang Hei (Chiny) – z międzynarodowych studiów polskich na Wydziale Humanistycznym oraz Orest Maksymiv (Ukraina) i Nguyen Khoa Tran (Wietnam ) z...\n",
      },
      {
        name: "Wystawa „Między modernizmem a surrealizmem” na Wydziale Humanistycznym w Sosnowcu",
        image: 'https://us.edu.pl/wydzial/wh/wp-content/uploads/sites/15/Bez-kategorii/obrazek-wyrozniajacy28-300x200.png',
        date: "2023.05.10",
        text: "Serdecznie zapraszamy na wystawę „Między modernizmem a surrealizmem”. Przedstawione prace inspirowane są dziełami Pabla Picassa, bowiem rok 2023 został w Hiszpanii ogłoszony rokiem tego wybitnego artysty. Wystawa prac „Między modernizmem a surrealizmem” zawiera witraże, wyroby ceramiczne oraz grafiki przygotowane przez młodych artystów z Pałacu Młodzieży w Katowicach i Szkoły Podstawowej nr 55 Specjalnej w Katowicach....",
      },
    ],

  })
}
</script>

<style>
.home {
  background-color: #fafafa;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

.line-info{
  width: 100%;
  height: 100px;

  margin: 50px 0 15px;

  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('https://us.edu.pl/wp-content/uploads/image/t4e/pasek-t4e.jpg');
}
</style>