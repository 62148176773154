<template>
    <div class="row">

        <template v-for="item in this.info.items">
            <div v-if="to(item, 'text')" class="col-12 col-sm-6 col-md-4 stat-item">
                <div class="stat-item__text stat-letters">
                <span class="stat-letters__item letter"
                      :class="{'letter--numeric': is_numeric(letter)}"
                      v-for="letter in this.letters(item)">
                    {{ letter }}
                </span>
                </div>
                <span class="stat-item__name">{{ to(item, 'text') }}</span>
            </div>
        </template>
    </div>

</template>

<script>
import StatAbstract from "@/views/Base/Stat/Abstract.vue";

export default {
    name: "StatLetterList",
    extends: StatAbstract,
    props: {
        // info: [],
    },
    computed: {
        items() {
            return this.info.items;
        }
    },
    methods: {
        letters(item) {


            let ret = item.count ? item.count.toString() : "";

            return ret;

        },
        is_numeric(str) {
            return /^\d+$/.test(str);
        },
    }
}
</script>

<style scoped lang="scss">
.stat-letter-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;


    &__item {
        margin: 0;
        padding: 0;
        flex: 1 1 0;
    }
}

.stat-item {

    text-decoration: none;
    text-align: center;

    user-select: none;

    cursor: pointer;

    display: flex;
    flex-direction: column;

    //margin-right: 12px;
    margin-bottom: 12px;

    &__name,
    &__text {

    }


    &__name {
        color: var(--text);
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 2rem;
        /* or 162% */

        text-align: center;
    }

    &__text {
        color: var(--text);
        opacity: 0.6;

        font-weight: 400;
        font-size: 0.89rem;
        line-height: 21px;
        margin-bottom: 35px;
    }


}

.stat-letters {

    &__item {

        position: relative;

    }
}

.letter {
    height: 4rem;
    line-height: 4.35rem;
    margin-left: 2px;

    font-size: 2.5rem;

    font-weight: 700;
    color: var(--main);

    &--numeric {
        padding: 0 1rem;
        display: inline-block;
    }

    &--numeric:before,
    &--numeric:after {
        position: absolute;
        left: 0;
        right: 0;

        border-color: var(--border_light);
        border-width: 2px;
        border-style: solid;
        height: 50%;
        content: '';
        border-radius: 5px;
    }

    &--numeric:before {
        top: 0;
        border-bottom: none;
    }

    &--numeric:after {
        top: 50%;
        border-top: none;
    }
}


</style>