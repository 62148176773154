<script>
export default {
  name: "ComponentStateMachine",
  data() {
    return {
      state: 'wait',
      states: {

      },
      state_transitions: {

      },
    }
  },
  methods: {
    stateTransition: function (currentState, action) {

      let state_next = this.state_transitions[currentState][action]

      return state_next || currentState;

    },
    stateUpdate: function (action) {

      this.state = this.stateTransition(this.state, action);

    },
    stateCompare: function (state) {

      return this.state === state;

    }
  },
}
</script>