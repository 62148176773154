<script>

import ShowSvg from "@/components/ShowSvg.vue";
import Element from "@/components/Element.vue";

export default {
    name: "PageModelBlockSocialMediaNEW",
    components: {
        ShowSvg
    },
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    facebook_has: true,
                    facebook: "/",
                    twitter_has: true,
                    twitter: "/",
                    instagram_has: true,
                    instagram: "/",
                }
            }
        }
    },
    methods: {
        isLink(text) {
            return text && text.length > 0
        },

        imgStyle(item) {
            let ret = {}

            ret.width = item.width ? item.width+'px' : 100
            ret.height = item.height ? item.height+'px' : 100
            ret.objectFit = item.fit_content ? item.fit_content : "unset"

            return ret
        }
    }
}
</script>

<template>


    <ul class="list">
        <li class="list__item" v-if="info.facebook_has && isLink(info.facebook)">
            <a :href="info.facebook" title="Facebook link" target="_blank">
                <ShowSvg icon="socials-facebook"/>
            </a>
        </li>

        <li class="socials__item" v-if="info.linkedin_has && isLink(info.linkedin)">
            <a :href="info.linkedin" title="Linkedin link" target="_blank">
                <ShowSvg icon="socials-linkedin"/>
            </a>
        </li>

        <li class="socials__item" v-if="info.tiktok_has && isLink(info.tiktok)">
            <a :href="info.tiktok" title="Tiktok link" target="_blank">
                <ShowSvg icon="socials-tiktok"/>
            </a>
        </li>

        <li class="list__item" v-if="info.twitter_has && isLink(info.twitter)">
            <a :href="info.twitter" title="X link" target="_blank">
                <ShowSvg icon="socials-x"/>
            </a>
        </li>

        <li class="list__item" v-if="info.instagram_has && isLink(info.instagram)">
            <a :href="info.instagram" title="Instagram link" target="_blank">
                <ShowSvg icon="socials-instagram"/>
            </a>
        </li>

        <li v-for="item in info.items" class="list__item" v-if="info.items && info.items.length > 0">
            <a :href="item.link" title="Social link" target="_blank">
                <img :src="item.image.url" alt="Social img" :style="imgStyle(item)">
            </a>
        </li>
    </ul>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: unset;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    width: 100%;

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 767px) {

    }

    &__item {
        margin-left: 0;

        & > a {
            display: block;
        }
    }
}

.item {

}

.content--l, .content--sm {

}

.content--xs {

}

</style>
