<template>
    <ToggleBlock
            v-for="( item, idx) in items"
            @open="open(idx)"
            @close="close(idx)"
            :ref="'toggle-'+idx"
    ></ToggleBlock>
</template>

<script>
import ToggleBlock from "@/views/Base/Toggle/Block.vue";

export default {
    name: "ToggleList",
    components: {ToggleBlock},
    data: () => ({
        items: [{
            name: 'Audyt',
            text: 'Ogół działań, poprzez które uzyskuje się niezależną ocenę funkcjonowania instytucji, legalności, gospodarności, celowości, rzetelności. Audyt jest zazwyczaj wykonywany przez odrębną komórkę, podporządkowaną bezpośrednio kierownikowi instytucji (audyt wewnętrzny) lub przez podmiot zewnętrzny (audyt zewnętrzny).'
        }, {
            name: 'Aukcja',
            text: 'Ogół działań, poprzez które uzyskuje się niezależną ocenę funkcjonowania instytucji, legalności, gospodarności, celowości, rzetelności. Audyt jest zazwyczaj wykonywany przez odrębną komórkę, podporządkowaną bezpośrednio kierownikowi instytucji (audyt wewnętrzny) lub przez podmiot zewnętrzny (audyt zewnętrzny).'
        }]
    }),

    methods: {
        open(idx) {

            this.items.forEach((item, item_idx) => {

                if (item_idx !== idx) {
                    let el = this.$refs['toggle-' + idx][0];
                    console.log(el)
                    if (el.state === 'open') {
                        el.close();

                        console.log('Close ', item_idx)
                    }
                }
            })

        },
        close(idx) {

        },
    }
}
</script>

<style scoped lang="scss">

.toggle-block {
  margin-bottom: 17px;
  background-color: var(--bg);
  padding: 12px;

  position: relative;

  border-radius: 12px;

  &__name {
    color: var(--main);
    font-size: 1.15rem;
    line-height: 1.3rem;
    font-weight: 400;
    cursor: pointer;


    &:before {
      position: absolute;

      right: 20px;
      top: 10px;

      content: '';

      width: 10px;
      height: 10px;

      border: 2px solid var(--main);
      border-top: 0;
      border-right: 0;

      transform: rotate(-45deg);
      transition: all .3s;
    }

  }

  &__text {
    font-size: 1.15rem;
    line-height: 1.85rem;
    font-weight: 300;
    display: none;
    margin-top: 22px;
    padding-right: 4rem;
  }

  &--open &__name:before {
    transform: rotate(135deg);
    top: 19px;
  }

  &--open &__text {
    display: block;
  }

}

</style>