<script>

export default {
    name: "Hr1",
    props: {
        info: {
            default() {
                return {}
            }
        }
    },
    computed: {
        style() {
            let ret = {}

            ret.background = this.info.color
            ret.height = this.info.weight + "px"

            return ret
        }
    }
}
</script>

<template>

    <div class="line width-100-percent" :style="style"></div>
</template>

<style scoped lang="scss">

.line {
    width: 100%;
}

</style>