<script>
import {defineComponent} from 'vue'
import TermAndConditions1 from "@/views/Base/Testimonial/TermAndConditions1.vue";
import TermAndConditions2 from "@/views/Base/Testimonial/TermAndConditions2.vue";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "TermAndConditions",
  extends: Element,
  components: {TermAndConditions2, TermAndConditions1},
  props: {
    info: {},
  }
})
</script>

<template>
  <term-and-conditions1 :info="info" v-if="info.type === 'type_1'"/>
  <term-and-conditions2 :info="info" v-if="info.type === 'type_2'"/>
</template>

<style scoped lang="scss">

</style>