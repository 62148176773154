<template>
  <span class="date-time" :title="date">
    <ShowDate :date="date" :show_null="show_null" :show_year_always="show_year_always" :null_text="null_text"/>
    <ShowTime :date="date" :class="time_class" v-if="show_time"/>
  </span>
</template>

<script>

import ShowDate from "./Date";
import ShowTime from "./Time";

export default {
  name: "ShowDateTime",
  components: {ShowTime, ShowDate},
  props: {
    date: {},
    icon: {
      default() {
        return false;
      }
    },
    show_null: {
      default() {
        return true;
      }
    },
    show_year_always: {
      default() {
        return false;
      }
    },
    show_time: {
      default() {
        return false;
      }
    },
    time_class: {
      default() {
        return 'grey-text';
      }
    },
    null_text: {
      default() {
        return "Brak";
      }
    }
  },
  computed: {

    icon_show() {
      let ret = true;
      if (!this.date)
        ret = false;

      if (!this.icon)
        ret = false;

      return ret;

    },

  }

}
</script>

<style scoped lang="scss">

.date {

}

</style>