<template>

    <div class="mix" v-if="gallery_style === 'mix'">
        <div class="mix__row mix-row" :class="{'mix-row--reverse': index % 2 !== 0, 'mix-row--single': value[0] && !value[1] && !value[2], 'mix-row--single-sub': value[0] && value[1] && !value[2]}" v-for="(value, name, index) in mixRows">
            <div class="mix-row__main">
                <div class="mix-row__image" v-if="value[0]" @click="showGallery(value[0])">
                    <img :src="value[0].url" :style="imgStyle" alt="Obrazek Galerii" title="Obrazek Galerii"/>
                    <p class="image-text" v-if="to(value[0], 'text')">{{ to(value[0], 'text') }}</p>
                </div>
            </div>
            <div class="mix-row__sub" v-if="value[1] || value[2]">
                <div class="mix-row__image" v-if="value[1]" @click="showGallery(value[1])">
                    <img :src="value[1].url" :style="imgStyle" alt="Obrazek Galerii" title="Obrazek Galerii"/>
                    <p class="image-text" v-if="to(value[1], 'text')">{{ to(value[1], 'text') }}</p>
                </div>
                <div class="mix-row__image" v-if="value[2]" @click="showGallery(value[2])">
                    <img :src="value[2].url" :style="imgStyle" alt="Obrazek Galerii" title="Obrazek Galerii"/>
                    <p class="image-text" v-if="to(value[2], 'text')">{{ to(value[2], 'text') }}</p>
                </div>
            </div>
        </div>
    </div>

    <template v-if="gallery_style === 'masonry'">
        <masonry-wall :items="gallery" :column-width="300" :gap="16">
            <template #default="{ item, index }">
                <div class="masonry-image" @click="showGallery(item)">
                    <img style="max-width: 100%" :src="item.url" alt="Obrazek Galerii" title="Obrazek Galerii"/>
                    <p class="image-text">{{ item.text }}</p>
                </div>
            </template>
        </masonry-wall>
    </template>

    <template v-if="gallery_style === 'block' || gallery_style === 'row'">
        <draggable
            tag="ul"
            :list="gallery"
            :group="{ name: 'g1' }"
            item-key="filename"
            :disabled="!move"
            @end="$emit('actionUpdateLocation', gallery)"
            class="gallery width-100-percent" :class="{'gallery--row': gallery_style === 'row', 'gallery--block': gallery_style === 'block'}">
            <template #item="{ element }">
                <div>
                    <li class="gallery__item image"
                        @click.stop="showGallery(element.thumb)">
                        <img :src="element.url" class="image__thumb" :style="imgStyle" alt="Obrazek Galerii" title="Obrazek Galerii"/>
                        <p class="image-text">{{ element.text }}</p>

                        <div class="image__buttons" v-if="!element.progress || element.progress == 100.00">

                            <button
                                class="image__buttons-item image__buttons-delete"
                                @click.prevent.stop="this.$emit('imageDelete', element)"
                                v-if="can_delete"
                            >
                                <img src="@/assets/img/trash.svg" alt="Usuń" title="Usuń"/>
                            </button>
                            <button
                                class="image__buttons-item image__buttons-edit"
                                @click.prevent.stop="this.$emit('imageEdit', element)"
                                v-if="can_edit_info"
                            >
                                <img src="@/assets/img/pencil.png" alt="Edytuj" title="Edytuj"/>
                            </button>
                            <button
                                class="image__buttons-item image__buttons-show"
                                @click="showGallery(element.thumb)"
                                v-if="can_zooming"
                            >
                                <img src="@/assets/img/search.svg" alt="Powiększ" title="Powiększ"/>
                            </button>
                        </div>
                        <div class="image__progress" v-else>
                            <div class="progress" v-if="element.progress">
                                <span class="progress__load" :style="{ width: `${element.progress}%` }"></span>
                            </div>
                        </div>

                    </li>
<!--                    <p class="image__text">{{ element.text }}</p>-->
                </div>
            </template>
        </draggable>


        <a v-if="download_all_gallery_url && gallery.length" @click="downloadAll" ref="downloadAllBtn"
           class="gallery__download">
            Pobierz wszystkie pliki
        </a>


<!--        <vue-easy-lightbox-->
<!--            :visible="gallery_visible"-->
<!--            :imgs="gallery_imgs"-->
<!--            :index="gallery_index"-->
<!--            @hide="hideGallery"-->
<!--        >-->

<!--        </vue-easy-lightbox>-->
    </template>

    <vue-easy-lightbox
        :visible="gallery_visible"
        :imgs="gallery_imgs"
        :index="gallery_index"
        @hide="hideGallery"
    >

    </vue-easy-lightbox>

    <template v-if="gallery_style === 'slider'">
        <div class="">
            <swiper
                :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
                :loop="false"
                :spaceBetween="10"
                :navigation="true"
                :thumbs="{ swiper: swiper }"
                :modules="modules"
                class="mySwiper2"
                @swiper="setSwiperMain"
            >
                <swiper-slide v-for="element in gallery">
                    <li class="gallery__item image" @click="showGallery(element)">
                        <span class="image__thumb" :style="{ backgroundImage: `url(${element.thumb})`, ...imgStyle}"></span>
                        <p class="image-text">{{ element.text }}</p>


                    </li>
<!--                    <p class="image__text">{{ to(element, "text") }}</p>-->
                </swiper-slide>
            </swiper>


            <swiper
                @swiper="setThumbsSwiper"
                :loop="false"
                :spaceBetween="10"
                :slidesPerView="4"
                :freeMode="true"
                :watchSlidesProgress="true"
                :modules="modules"
                class="mySwiper"
            >
                <swiper-slide v-for="element in gallery">
                    <li class="gallery__item image">
                        <span class="image__thumb" :style="{ backgroundImage: `url(${element.thumb})`, ...imgStyle}"></span>
                    </li>
                </swiper-slide>
            </swiper>
        </div>
    </template>


</template>

<script>
import {useToast} from "vue-toastification";
import CoreDate from "@/Library/Helpers/date";
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from "@/axios";
import draggable from 'vuedraggable';

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Element from "@/components/Element.vue";

export default {
    name: "ShowGallery",
    extends: Element,
    emits: ['imageDelete', 'imageEdit'],
    components: {
        VueEasyLightbox,
        draggable,

        Swiper,
        SwiperSlide,
    },
    props: {
        gallery_style: {
            default() {
                return "block"
            }
        },
        class: {},
        style: {},
        gallery: {},
        can_delete: {},
        can_edit_info: {},
        can_zooming: {},
        download_all_gallery_url: {},
        move: {},
        object_fit: {
            default() {
                return null
            }
        }
    },
    data() {
        return {
            gallery_imgs: '', // Img Url , string or Array of string
            gallery_visible: false,
            gallery_index: 0, // default: 0

            swiper: null,
            swiper_main: null,
            modules: [FreeMode, Navigation, Thumbs],

            items: [
                {
                    title: 'First',
                    description: 'The first item.',
                },
                {
                    title: 'Second',
                    description: 'The second item.',
                },
            ]
        }
    },
    methods: {
        setThumbsSwiper(swiper) {
            this.swiper = swiper
        },

        setSwiperMain(swiper) {
            this.swiper_main = swiper
        },

        async downloadAll() {

            let toast = useToast();

            let btn = this.$refs.downloadAllBtn;

            btn.style.opacity = .3;

            await axios
                .get(this.download_all_gallery_url)
                .then(await function (response) {

                    toast.success("Pobieranie rozpoczęte", {
                        timeout: 2000
                    });
                    let ret = response.data.data.url;

                    btn.style.opacity = 1;

                    window.location = ret;
                });


        },

        imageSizeFormat(size) {

            let ret = size;
            if (Number.isInteger(size)) {

                let i = Math.floor(Math.log(size) / Math.log(1024));
                ret = (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            }

            return ret;

        },

        showDate(date) {
            return CoreDate.show(date);
        },

        showGallery(img) {

            let index = this.gallery.findIndex(image => image.thumb === img.thumb)

            if (this.can_zooming) {
                this.gallery_imgs = this.showImages;
                this.gallery_index = index;
                this.gallery_visible = true
            }

            return false;
        },
        hideGallery() {
            this.gallery_visible = false
        }
    },
    computed: {

        showImages() {

            let ret = this.gallery.map(img => ({src: img.thumb, title: img.text}))

            return ret;

        },

        imgStyle() {
            let ret = {}

            // ret.objectFit = this.object_fit ? this.object_fit : "contain"

            return ret
        },

        mixRows() {
            let mix_rows = {}

            let index = 0

            if (this.gallery && Array.isArray(this.gallery)) {
                this.gallery.map(image => {
                    if (!mix_rows[`row_${index}`])
                        mix_rows[`row_${index}`] = []

                    mix_rows[`row_${index}`].push(image)

                    if (mix_rows[`row_${index}`].length === 3)
                        index++
                })
            }

            return mix_rows
        }
    }
}
</script>


<style scoped lang="scss">

.mix {

    &__row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.mix-row {
    display: flex;
    height: 500px;
    column-gap: 30px;

    &__main {
        width: calc(33.3% + 33.3%);
    }

    &__sub {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        width: 33.4%;

        & .mix-row__image {
            height: calc(50% - 15px);
        }
    }

    &__image {
        display: flex;
        height: 100%;

        position: relative;

        & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 992px) {
        flex-direction: column!important;
        row-gap: 30px;
        height: calc(500px + 250px + 30px);

        &__main {
            width: 100%;
            height: 500px;
        }

        &__sub {
            flex-direction: row;
            column-gap: 30px;
            height: 250px;
            width: 100%;

            & .mix-row__image {
                height: 100%;
            }
        }

        &--single {
            max-height: 250px;
        }

        &--single-sub {

            & .mix-row__sub > .mix-row__image {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 767px) {
        height: calc(250px + 500px + 30px + 15px);

        &__main {
            height: 250px;
        }

        &__sub {
            flex-direction: column;
            height: 500px;

            & .mix-row__image {
                height: calc(50% - 7.5px);
            }
        }

        &__image {
            max-height: 250px;
        }

        &--single-sub {
            height: 250px;
        }
    }

    &--reverse {
        flex-direction: row-reverse;
    }

    &--single {

        & .mix-row {

            &__main {
                width: 100%;
            }
        }
    }

    &--single-sub {

        & .mix-row {

            &__sub {

                & .mix-row__image {
                    height: 100%;
                }
            }
        }
    }
}

.image-text {
    font-family: var(--text_font);
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 5px;
    background: var(--block_dark_bg);
    color: var(--block_dark_text);
    margin: 0;
    width: 100%;
    text-align: left;
    font-size: 0.67rem;
}

.content--l {

}

.content--sm, .content--xs{

    & .mix-row {
        flex-direction: column;
        row-gap: 30px;
        height: calc(500px + 250px + 30px);

        &__main {
            width: 100%;
            height: 500px;
        }

        &__sub {
            flex-direction: row;
            column-gap: 30px;
            height: 250px;
            width: 100%;

            & .mix-row__image {
                height: 100%;
            }
        }
    }
}

.content--xs {

    & .mix-row {
        height: calc(250px + 500px + 30px + 15px);

        &__main {
            height: 250px;
        }

        &__sub {
            flex-direction: column;
            height: 500px;
        }
    }
}

</style>

<style scoped lang="scss">


.progress {

    position: absolute;

    left: 0;
    right: 0;
    height: 4px;
    top: 0;
    overflow: hidden;
    box-sizing: border-box;

    &__load {
        //height: 4px;
        background-color: #3ea5fe;
        position: absolute;


        top: 0px;
        left: 0;
        bottom: 0;

        will-change: width;

        transition: .5s;

    }

}


.gallery {

    margin: 0;
    padding: 0;

    list-style: none;

    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(300px, 1fr) );
    column-gap: 15px;

    width: 100%;

    &__item {
        width: 100%;
        height: 0;
        padding-bottom: 67%;
        margin-bottom: 12px;

        position: relative;

        & .image-text {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border: 1px solid #e6e7ea;
            border-top: none;
        }
    }

    &__download {
        cursor: pointer;
    }

    &--row {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        & .image {

            &__thumb {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    &--block {

        & .image {

            &__thumb {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

}

.image {
    //display: flex;
    //flex-direction: row;
    //width: 100%;
    //margin: 0 0 12px;
    //padding: 0;
    //
    //position: absolute;
    //
    //left: 0;
    //top: 0;
    //right: 0;
    //bottom: 0;
    //overflow: hidden;

    &__thumb {
        overflow: hidden;

        position: absolute;

        display: block;

        width: 100%;
        //height: 500px;

        border-radius: 6px;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-size: cover;
        background-position: center;
        border: 1px solid #e6e7ea;
    }

    &__info {
        padding: 2px 0 0 12px;

        display: flex;
        flex-direction: column;

        text-align: left;

        flex: 1 0;
        overflow: hidden;

    }

    &__buttons {

        top: 0;
        right: 12px;

        position: absolute;
        width: 120px;
        min-width: 120px;

        text-align: center;

        margin-top: 12px;

        &-item {
            border-color: transparent;

            display: inline-block;

            cursor: pointer;

            //margin-left: 12px;

            float: right;

            width: 30px;
            height: 30px;

            border-radius: 50%;

            padding: 0;


            margin-right: 6px;


            background-color: rgba(255, 255, 255, 1);
            user-select: none;

            & img {
                //margin-top: 4px;

            }

            &:first-of-type {
                margin-left: 0;
            }
        }

        &-delete {
            color: red;
        }

        &-edit {
            //color: red;

            & img {
                width: 20px;

            }

        }

        &-show {
            //color: red;

            & img {
                width: 20px;

            }

        }
    }

    &__progress {
        width: 100%;

        .progress {
            top: 0;

            //border-radius: 4px;
            height: 8px;
            overflow: hidden;
            background: #e6e7ea;
            margin: 0;
            padding: 0;

            &__load {
                float: left;
                top: -4px;
                height: 12px;
                margin: 0;
                padding: 0;
            }
        }
    }

    &__name {
        color: #3f424a;
        height: 21px;
        overflow: hidden;
    }

    &__size {
        color: #898f99;

    }

    &__date {
        color: #898f99;

    }

    &__text {
        font-family: var(--text_font);
        color: var(--text);
        margin: 10px;
        text-align: center;
        word-break: break-word;
    }
}

.gallery {
    &__item {
    }

}

.masonry-image {
    position: relative;
}

</style>