<script>
import Element from "@/components/Element.vue";

export default {
    name: "TabsContentLeft",
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            icon: "school",
                            name: "Excepturi a vel exercitationem quisquam?",
                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos quod tempore consectetur, voluptates nemo, esse id, voluptatem aliquam aspernatur dolorum quaerat voluptatum dolore dolorem repudiandae earum veritatis nobis. Alias ab veniam perferendis autem possimus dolore architecto mollitia quas soluta dolores?",
                            number: 0
                        },
                        {
                            icon: "business_center",
                            name: "Mollitia, voluptate.",
                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore quis tempora sapiente facere ex asperiores dolorum neque officia quas dolores maiores, nobis sint, consequuntur animi eos adipisci corrupti! Porro labore at corrupti rem, veniam reprehenderit autem similique ea illo impedit!",
                            number: 1
                        },
                        {
                            icon: "group",
                            name: "Harum itaque repudiandae excepturi veniam consequatur",
                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa numquam est harum praesentium animi quos quo, obcaecati dolore odio voluptas laboriosam nisi quidem corrupti necessitatibus eius aliquam modi natus alias labore, culpa voluptate vero dicta! Porro sequi saepe sunt doloremque?",
                            number: 2
                        },
                        {
                            number: 3,
                            name: "Alias quia qui, minima, optio quod.",
                            text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam reprehenderit rerum voluptates, omnis necessitatibus beatae amet veritatis quisquam animi, recusandae quis dicta nulla aliquam dolor doloribus ipsa? Ipsa magnam adipisci, unde soluta impedit voluptas maiores molestias temporibus? Pariatur, corporis fugiat.",
                            icon: "chat"
                        }
                    ]
                }
            }
        }
    },
    extends: Element,
    methods: {
        clickItem(item) {
            let header = this.$refs.header
            let body = this.$refs.body

            header.style.opacity = "0"
            header.style.visibility = "hidden"
            header.style.transform = "scale(0.9)"

            body.style.opacity = "0"
            body.style.visibility = "hidden"
            body.style.transform = "scale(0.9)"

            setTimeout(() => {
                this.info.items.map(el => el.active = false)
                item.active = true

                header.style.opacity = "1"
                header.style.visibility = "visible"
                header.style.transform = "scale(1)"

                body.style.opacity = "1"
                body.style.visibility = "visible"
                body.style.transform = "scale(1)"
            }, 250)
        }

    },
    computed: {
        activeItem() {
            return this.info.items.find(item => item.active) || {}
        }
    },
    mounted() {
        if (this.info.items && this.info.items[0])
            this.info.items[0]["active"] = true
    }
}
</script>

<template>

    <div class="block block-tabs-anim">
        <div class="block__left">
            <ul class="tabs">
                <li class="tabs__item item" :class="{'item--active': item.active}" @click="clickItem(item)" v-for="item in info.items">
                    <span v-if="item.icon && item.icon !== ''" class="material-symbols-rounded">{{ item.icon }}</span>
                    <p class="">{{ to(item, "name") }}</p>
                </li>
            </ul>
        </div>

        <div class="block__right">
            <div class="content">
                <div class="content__header" ref="header">
                    <span v-if="activeItem.icon && activeItem.icon !== ''" class="material-symbols-rounded">{{ activeItem.icon }}</span>
                    <h1>{{ to(activeItem, "name") }}</h1>
                </div>

                <p class="content__body" ref="body" v-html="parseText(activeItem, 'text')"></p>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">

.block {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    height: 500px;

    &__left {
        padding: 16px;
        background: var(--block_dark_bg);
        min-width: 250px;
        width: 250px;
        overflow: auto;
    }

    &__right {
        padding: 30px;
        border-radius: 12px;
        border: 1px solid var(--border);
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        overflow: auto;
    }

    @media screen and (max-width: 1440px) {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            border: 1px solid var(--border);
            border-radius: 12px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            border: 1px solid var(--border);
            border-radius: 12px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.tabs {
    padding: 0;
    margin: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &_item {

    }
}

.item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    border-radius: 16px;

    padding: 12px 12px 14px 12px;
    cursor: pointer;
    transition: all 0.25s ease 0s;

    &:hover {
        background: var(--block_light_bg);

        & > .material-symbols-rounded, & > p {
            color: var(--text);
        }

        & > .material-symbols-rounded {
            transform: scale(0.90);
        }
    }

    & > .material-symbols-rounded, & > p {
        transition: all 0.25s ease 0s;
        color: var(--block_dark_text);
    }

    & > .material-symbols-rounded {
        font-size: 2rem;
    }

    & > p {
        font-family: var(--title_font);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.101px;
        margin-bottom: 0;
        margin-top: 0;
        text-align: left;
    }

    &--active {
        background: var(--block_light_bg);

        & > .material-symbols-rounded, & > p {
            color: var(--text);
        }
    }
}

.content {

    &__header, &__body {
        transition: all 0.25s ease 0s;
    }

    &__header > h1, &__body {
        font-style: normal;
        margin-top: 0;
    }

    &__header {
        font-family: var(--title_font);
        display: flex;
        column-gap: 24px;
        margin-bottom: 50px;

        & > .material-symbols-rounded, & > h1 {
            color: var(--title)
        }

        & > .material-symbols-rounded {
            font-size: 4rem;
        }

        & > h1 {
            font-size: 1.56rem;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.18px;
        }
    }

    &__body {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 1.33rem;
        color: var(--text);
    }
}

.content--l, .content--sm {

    & .block {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            border: 1px solid var(--border);
            border-radius: 12px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.content--xs {

    & .block {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            border: 1px solid var(--border);
            border-radius: 12px;
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

</style>