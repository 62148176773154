<script>
import {defineComponent} from 'vue'
import MenuInline from "@/views/Base/Menu/Inline.vue";
import Dropdown from "@/Library/Components/Dropdown.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";

export default defineComponent({
  name: "LayoutHeader",
  components: {MenuDropdown, Dropdown, MenuInline},
  data: () => ({
    items_left: [{
      name: 'Pusta strona',
      url: '/blank',
    }, {
      name: 'FAQ',
      url: '/faq',
    }, {
      name: 'Bład strony',
      url: '/error',
    }, {
      name: 'Strona tekstowa',
      url: '/text',
    }],
    items_right: [
      {
        name: 'Kandydat',
        url: 'https://facebook.com',
      },
    ],
  })
})
</script>

<template>
  <header class="header">

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <MenuInline space="20px" :items="items_left" :border="true"/>
        </div>
        <div class="col-12 col-md-6 list">

          <div class="list__item header_search search ">
            <span class="material-symbols-outlined search__icon d-none">search</span>
            <span class="search__name d-none">Szukaj w serwisie</span>
          </div>
          <div class="list__item search__lang lang"></div>
          <MenuDropdown class="list__item header__dropdown" name="Wydziały"/>
          <MenuDropdown class="list__item header__dropdown" name="Instytuty"/>
          <i class="material-symbols-outlined header__wcag wcag">accessibility_new</i>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
.header {

  position: relative;
  padding: 16px 0;

  &:before {

    position: absolute;
    content: '';

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;


    background: #003399;
    opacity: 0.05;

  }

  &__dropdown {
    float: right;
    padding: 0 !important;
    margin-left: 24px;
    margin-top: 0;
  }

  &__search {
    float: right !important;
    margin-left: 12px;
  }

  &__lang {
    float: right !important;
    margin-left: 12px;
  }

  &__wcag {
    float: right !important;
    margin-left: 24px;
  }
}

.search {
  display: flex;

  &__icon {
    font-weight: 200 !important;
    font-size: 18px !important;
    margin-right: 6px;
  }

  &__name {
  }
}

.lang {
  width: 20px;
  height: 20px;

  display: block;

  background-image: url('@/assets/img/flags/us.svg');
  background-color: white;
  background-position: center center;
  background-size: cover;

  overflow: hidden;
  border-radius: 50%;

  //margin-top: -2px;

}

.list {
  display: flex;
  float: right;

  &__item {
    margin-left: 24px;

    &:first-child {
      margin-left: auto;
    }
  }
}

.wcag{

  width: 26px;
  height: 26px;
  background: var(--main_color);
  color: white;
  text-align: center;
  border-radius: 50%;
  font-size: 20px !important;
  line-height: 26px !important;
  margin-top: -4px;
  margin-bottom: -5px;
}

</style>