<script>
import Element from "@/components/Element.vue";
import ShowDateTime from "@/Library/Show/DateTime.vue";

export default {
    name: "ArticleMetric",
    components: {ShowDateTime},
    extends: Element,
    props: {
        info: {
            default() {
                return {}
            }
        }
    }
}
</script>

<template>

    <ul class="metric">
        <li class="metric__item item user">
            <div v-if="obj.author_avatar" class="user__avatar" :style="{backgroundImage: `url(${obj.author_avatar})`}"></div>
            <p class="user__name">{{ obj.author_name }}</p>
        </li>

        <li class="metric__item item date">
            <span class="material-symbols-rounded">calendar_month</span>
            <ShowDateTime :show_time="true" :date="obj.date"/>
        </li>

        <li v-if="obj.category_name" class="metric__item item category">
            <p>{{ obj.category_name }}</p>
        </li>
    </ul>

</template>

<style scoped lang="scss">

.metric {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    column-gap: 24px;
    row-gap: 12px;
    flex-wrap: wrap;

    &__item {

    }
}

.item {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.user {

    &__avatar {
        min-width: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__name {
        font-family: var(--text_font);
        margin: 0;
        color: var(--text);
    }
}

.date {

    & > span:last-child {
        font-family: var(--text_font);
        color: var(--text);
    }
}

.category {

    & > p {
        font-family: var(--text_font);
        margin: 0;
        background: var(--block_dark_bg);
        color: var(--block_dark_text);
        padding: 2px 6px;
        border-radius: 2px;
    }
}

</style>