<script>
import {defineComponent} from 'vue'
import Input from "@/Library/Forms/Input.vue";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "BlockImageLinks",
  components: {Input},
  extends: Element,
  props: {
    info: {
      default() {
        return {
          buttonName: "BUTTON",
          buttonLink: "",
          image: "https://picsum.photos/200/300",
          items: [{
            link: '',
            name: 'Sections 1',
          }, {
            link: '',
            name: 'Sections 2',
          }, {
            link: '',
            name: 'Sections 3',
          }, {
            link: '',
            name: 'Sections 4',
          }, {
            link: '',
            name: 'Sections 5',
          }]
        }
      }
    },
  }
})
</script>

<template>
<!--  <div class="list__main-wrapper">-->
    <div class="list__container">
      <div class="list-container__image">
        <img v-if="info.image" :src="info.image.url" alt="List main image" />
      </div>

      <div class="list-container__info">
        <ul class="info__list list">
          <li class="list__item-link" v-for="item in info.items" :key="item.name">
            <a :href="item.link">{{to(item, 'name')}}</a>
          </li>

        </ul>

        <router-link :to="info.buttonLink" class="info__link-container">
          <div class="info__button button">
            <p class="button__text">{{to(this.info, 'buttonName')}}</p>
            <span class="material-symbols-outlined">arrow_forward</span>
          </div>
        </router-link>
      </div>
    </div>
<!--  </div>-->
</template>

<style scoped lang="scss">
  .list__container{
    max-width: 100%;
    top: 952px;
    left: 100px;
    padding: 48px;
    border-radius: 6px;
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;

    .list-container{
      &__image{
        flex-basis: 50%;
        background-color: lightgray;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info{
        flex-basis: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .list{
          flex-basis: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          list-style: none;
          padding: 0;
          margin: 0;

          .list__item-link{
            padding: 1rem 1rem 1rem 1.5rem;

            a{
              text-decoration: none;
              color: var(--link);
            }
          }
        }

        .button{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          align-content: center;
          width: 157px;
          height: 40px;
          border-radius: 4px;
          gap: 4px;
          border: 1px solid var(--border);
          padding: 0.5rem 1.75rem 0.5rem 2rem;

          &__text{
            width: 69px;
            height: 18px;
            margin: 0;
            font-weight: bold;
            line-height: 1.3;
            color: var(--text);
          }

          &__icon{
            width: 24px;
            height: 24px;
            color: var(--icon);
          }
        }
      }
    }
  }

  .info__link-container{
    text-decoration: none;
  }

  @media only screen and (max-width: 991px) {
    .list__container{
      flex-direction: column;
      padding: 5%;
    }

    .list{
      flex-direction: row!important;
      flex-wrap: wrap;


      .list__item-link{
        flex-basis: 49%;
        text-align: center;
      }
    }

    .button{
      margin: 0 auto;
    }
  }
</style>