<script>
import {defineComponent} from 'vue'
import Image3V2 from "@/views/Base/Image/Image3V2.vue";
import Image3 from "@/views/Base/Image/Image3.vue";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "Image3V2",
  components: {Image3, Image3V2},
  extends: Element,
  props: {}
})
</script>

<template>
  <div class="gallery__item item">
    <div class="item__image">
      <img :src="info.image.url" alt="Item image" />
    </div>

    <div class="item__content item-content">
      <h2 class="item-content__header">{{to(info, 'header')}}</h2>
      <p class="item-content__description">{{to(info, 'description')}}</p>

      <div class="item-content__icons">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item{
  position: relative;
  max-width: 360px;
  border-radius: 5px;
  border: 1px solid var(--block_light_border);
  margin: 0 auto;

  &__image{
    max-width: 360px;
    max-height: 340px;
    overflow: hidden;
    border-radius: 5px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__content{
    display: none!important;
  }

  &:hover > &__content{
    display: flex!important;
  }
}

.item-content{
  max-width: 360px;
  max-height: 340px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--block_light_bg);
  opacity: 0.8;
  z-index: 1;
  border-radius: 5px;

  &__header{
    font-size: 1.22rem;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.07920006662607193px;
    text-align: center;
    margin: 0;
    color: var(--block_text_color)
  }

  &__description{
    font-size: 0.89rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    margin: 0 3%;
    color: var(--block_text);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 1% 0;

    svg{
      fill: var(--block_text);
      margin: 0 3%;
    }
  }
}
</style>