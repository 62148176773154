//Mylib.js
let exports = {};

/**
 * Zwraca kolor przypisany do litery
 *
 * @param letter sprawdzana litera
 *
 * @returns {string} przypisany kolor
 */
exports.letterColor = function (letter) {

    letter = letter.toLowerCase();

    let colors = {

        a: "#14C6FF",
        b: "#424242",
        c: "#82B1FF",
        d: "#FF5252",
        e: "#2196F3",
        f: "#4CAF50",
        g: "#FFC107",
        h: "#14c6FF",
        i: "#FFCF00",
        j: "#FF1976",
        k: "#FF8657",
        l: "#C33AFC",
        m: "#1E2D56",
        n: "#909090",
        o: "#9BA6C1",
        p: "#2ED47A",
        q: "#FF5c4E",
        r: "#308DC2",
        s: "#14C6FF",
        t: "#424242",
        u: "#82B1FF",
        v: "#FF5252",
        w: "#2196F3",
        x: "#4CAF50",
        y: "#FFC107",
        z: "#14c6FF",
    };


    return colors[letter];

}

/**
 * Funkcja zamienia znak \n\r na br
 *
 * @param str sprawdzany string
 * @param is_xhtml
 *
 * @returns {string} string poprawiony
 */
exports.nl2br = function (str, is_xhtml) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Philip Peterson
    // +   improved by: Onno Marsman
    // +   improved by: Atli Þór
    // +   bugfixed by: Onno Marsman
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Maximusya
    // *     example 1: nl2br('Kevin\nvan\nZonneveld');
    // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
    // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
    // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

exports.urlify = function (str) {


    let regex = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim

    return str.replace(regex, function (url, b, c) {
        let url2 = (c === 'www.') ? 'http://' + url : url;
        return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    })


}

exports.strtr = function (str, arr) {
    const makeToken = (inx) => `{{###~${inx}~###}}`,

        tokens = Object.keys(arr)
            .map((key, inx) => ({
                key,
                val: arr[key],
                token: makeToken(inx)
            })),

        tokenizedStr = tokens.reduce((carry, entry) =>
            carry.replace(new RegExp(entry.key, "g"), entry.token), str);

    return tokens.reduce((carry, entry) =>
        carry.replace(new RegExp(entry.token, "g"), entry.val), tokenizedStr);
};

exports.acronym = function (str) {

    let words = str.split(' ');
    let acronym = "";
    let index = 0
    while (index < words.length) {
        let nextWord = words[index];
        acronym = acronym + nextWord.charAt(0);
        index = index + 1;
    }

    return acronym;
};

exports.declime = function (count, str_1,str_2_4,str_5_plus ) {

    let lastDigit2Str = String(count).slice(-1);
    let lastDigit2Num = Number(lastDigit2Str);

    let ret = "";
    if(count === 0)
        ret = str_5_plus;
    if(count === 1)
        ret = str_1;
    if(count >= 2 && count <= 4)
        ret = str_2_4;
    if(count >=5 && count <= 21)
        ret = str_5_plus;

    if(count >= 22 && lastDigit2Num >= 2  && lastDigit2Num <= 4)
        ret = str_2_4;
    if(count >= 22 && (lastDigit2Num === 0  || lastDigit2Num >= 5))
        ret = str_5_plus;

    return ret;
};
exports.gender = function (gender, male, female ) {

    if(gender === 'male' || gender === 'men')
        return male;
    if(gender === 'female' || gender === 'woman')
        return female;

}
exports.similarity = function (first, second ) {

    first = first.replace(/\s+/g, '')
    second = second.replace(/\s+/g, '')

    if (first === second) return 1; // identical or empty
    if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.substring(i, i + 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram) + 1
            : 1;

        firstBigrams.set(bigram, count);
    };

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.substring(i, i + 2);
        const count = firstBigrams.has(bigram)
            ? firstBigrams.get(bigram)
            : 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

export default exports;