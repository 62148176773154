<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default {
    name: "Calendar",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    info: {
                        name: "Button",
                        //status: filled, bordered, text, text_underline
                        status: "filled",
                        icon: "play_circle",
                        icon_position: "left",
                        icon_fill: "tes",
                        size: "big"
                    }
                }
            }
        },
    }
}
</script>

<template>

    <button
        @click="$router.push($root.routerPrefix + info.link.url)"
        type="button"
        class="button"
        :class="`button--${info.status}`,
               {'button--icon': info.icon, 'button--reverse': info.icon_position === 'right', 'button--small': info.size === 'small'}">
            <span
                v-if="info.icon_has === 'yes'"
                class="button__icon material-symbols-rounded"
                :class="{'material-symbols-rounded--fill': info.icon_fill === 'yes'}">
                    {{ info.icon }}
            </span>

        <span class="button__text">{{ to(info, "name") }}</span>
    </button>

</template>

<style scoped lang="scss">

.button {
    margin: 0;
    border-radius: 4px;
    border: 1px solid var(--block_dark_bg);
    background: var(--block_dark_bg);

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 14px 32px 15px 31px!important;
    min-width: fit-content;
    cursor: pointer;

    &__icon, &__text {
        color: var(--block_dark_text);
    }

    &__icon {
        margin: -4px 0;
    }

    &__text {
        font-family: var(--title_font);
        font-style: normal;
        line-height: normal;
        font-size: 0.78rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &--small {
        padding-top: 10px!important;
        padding-bottom: 11px!important;
    }

    &--icon {
        padding-left: 27px!important;
    }

    &--reverse {
        flex-direction: row-reverse;
        column-gap: 5px;
        padding-left: 31px!important;
        padding-right: 27px!important;
    }

    &--bordered, &--text, &--text_underline {
        background: var(--bg);

        & .button {

            &__icon, &__text {
                color: var(--block_light_text);
            }
        }
    }

    &--text, &--text_underline {
        border-color: transparent;
        padding-left: 23px!important;
        padding-right: 24px!important;

        &.button--small {
            padding-top: 7px!important;
            padding-bottom: 7px!important;
        }

        &.button--icon {
            padding-left: 27px!important;
            padding-right: 31px!important;

            &.button--reverse {
                padding-left: 31px!important;
                padding-right: 27px!important;
            }
        }
    }

    &--text_underline {

        & .button {

            &__text {
                text-decoration: underline;
            }
        }
    }
}

.content--l, .content--sm {

}

.content--xs {

}

.material-symbols-rounded {

    &--fill {
        font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
    }
}

</style>