<script>
import RenderStructureBase from "@/views/RenderStructureBase.vue";
import RenderStructure from "@/views/RenderStructure.vue";
import RenderStructureContent from "@/views/RenderStructureContent.vue";

export default {
  name: "RenderStructure",
  components: {RenderStructureContent, RenderStructure},
  extends: RenderStructureBase,
  computed: {}

}
</script>

<template>
  <template v-if="addElement">
    <div class="row" v-if="addElementRow">

      <template v-if="sectionIsBoxed || sectionIsFullContentIsBoxed">
        <div class="col-12">
          <div class="full" :style="sectionStylesRender">
          <div class="container">
            <div class="row">
              <RenderStructureContent
                  parent_direction="row"
                  :elements="elements"
                  :sections="sections"
                  :obj="obj"
                  :section="section"/>
            </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="sectionIsFullContentIsFull" class="col-12">
        <div class="full" :style="sectionStylesRender">
          <RenderStructureContent
              :parent_direction="section.direction"
              :elements="elements"
              :sections="sections"
              :obj="obj"
              :section="section"
          />
        </div>
      </div>
      <div v-if="sectionIsColumn" :style="sectionStylesRender">
        <RenderStructureContent
            :parent_direction="section.direction"
            :elements="elements"
            :sections="sections"
            :obj="obj"
            :section="section"
        />
      </div>
    </div>

    <div class="col" v-if="addElementColumn">
      <template v-if="sectionIsBoxed || sectionIsFullContentIsBoxed">
        <div class="full" :style="sectionStylesRender">
          <div class="container">
            <div class="row">

              <RenderStructureContent
                  parent_direction="row"
                  :elements="elements"
                  :sections="sections"
                  :obj="obj"
                  :section="section"/>

            </div>
          </div>
        </div>
      </template>
      <div
          v-if="sectionIsFullContentIsFull"
          :style="sectionStylesRender">

        <RenderStructureContent
            :parent_direction="section.direction"
            :elements="elements"
            :sections="sections"
            :obj="obj"
            :section="section"/>

      </div>
      <div
          v-if="sectionIsColumn"
          :style="sectionStylesRender">

        <RenderStructureContent
            :parent_direction="section.direction"
            :elements="elements"
            :sections="sections"
            :obj="obj"
            :section="section"/>

      </div>
    </div>

  </template>

  <component
      v-if="!addElement"
      :class="cls"
      :data-kids_count="sectionKidsLength"
      :is="domElement"
      :style="(sectionIsBoxed || sectionIsFullContentIsBoxed) ? {} : sectionStylesRender"
  >

    <template v-if="sectionIsBoxed || sectionIsFullContentIsBoxed">

      <div class="full">
      <div class="container">
        <div class="row" :style="sectionStylesRender">

          <RenderStructureContent
              parent_direction="row"
              :elements="elements"
              :sections="sections"
              :obj="obj"
              :section="section"/>

        </div>
      </div>
      </div>
    </template>

    <template v-if="sectionIsFullContentIsFull || sectionIsColumn">

      <RenderStructureContent
          :parent_direction="'column'"
          :elements="elements"
          :sections="sections"
          :obj="obj"
          :section="section"
      />

    </template>

  </component>


</template>

<style lang="scss">

.content {
  display: flex;

  &--horizontal:not(&:has(.no-width-100-percent)) {
    flex-direction: row !important;

    /**
     * COLUMNS
     * [-][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--top {
      align-items: start !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [-][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--middle {
      align-items: center !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [-][ ][ ]
     */

    &.content--left.content--bottom {
      align-items: end !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][-][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--top {
      align-items: start !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][-][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--middle {
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][-][ ]
     */

    &.content--center.content--bottom {
      align-items: end !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][-]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--right.content--top {
      align-items: start !important;
      justify-content: end !important;
      text-align: right;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][-]
     * [ ][ ][ ]
     */

    &.content--right.content--middle {
      align-items: center !important;
      justify-content: end !important;
      text-align: right;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][-]
     */

    &.content--right.content--bottom {
      align-items: end !important;
      justify-content: end !important;
      text-align: right;
    }
  }

  &--vertical:not(&:has(.no-width-100-percent)) {
    flex-direction: column !important;

    & > .element, & > .content--vertical {
      width: 100%;
    }


    /**
     * ROWS
     * [-][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--top {
      align-items: start !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [-][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--middle {
      align-items: start !important;
      justify-content: center !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [-][ ][ ]
     */

    &.content--left.content--bottom {
      align-items: start !important;
      justify-content: end !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][-][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--top {
      align-items: center !important;
      justify-content: start !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][-][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--middle {
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][-][ ]
     */

    &.content--center.content--bottom {
      align-items: center !important;
      justify-content: end !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][-]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--right.content--top {
      align-items: end !important;
      justify-content: start !important;
      text-align: right;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][-]
     * [ ][ ][ ]
     */

    &.content--right.content--middle {
      align-items: end !important;
      justify-content: center !important;
      text-align: right;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][-]
     */

    &.content--right.content--bottom {
      align-items: end !important;
      justify-content: end !important;
      text-align: right;
    }
  }
}

//.content {
//    display: flex;
//
//
//    &--horizontal {
//        flex-direction: row !important;
//    }
//
//    &--vertical {
//        flex-direction: column;
//    }
//
//    &--left {
//        & > :last-child {
//            margin-right: auto;
//        }
//    }
//
//    &--right {
//        align-items: end;
//
//        & > :first-child {
//            margin-left: auto;
//        }
//    }
//
//
//    &--top {
//        justify-content: start;
//    }
//
//    &--middle {
//        justify-content: center;
//    }
//
//    &--bottom {
//       justify-content: end;
//    }
//
//}
//
//
//.content--horizontal.content--center {
//
//    & > :last-child {
//        margin-right: auto;
//    }
//
//    & > :first-child {
//        margin-left: auto;
//    }
//}
//
//.content--vertical.content--center {
//    & > * {
//        align-self: center;
//        text-align: center;
//    }
//}
//
//.content--vertical > * {
//    width: 100%;
//}

.full {
  width: 100vw!important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}

</style>