<script>

export default {
    name: "Temp",
    data: () => ({
        internet_connection: true
    }),
    methods: {
        setTempToStorage(data) {
            localStorage.setItem("temp", JSON.stringify(data))
        },

        /**
         * Metoda zwraca element w obiekcie "temp" w localStorage
         * @param code nazwa systemowa elementu
         * @param def domyślny typ elementu jeśli element o takim kodzie nie istnieje
         */
        getTempElement(code, def = undefined) {
            let temp = JSON.parse(localStorage.getItem("temp")) ? JSON.parse(localStorage.getItem("temp")) : {}

            return temp[code] ? temp[code] : def
        },

        /**
         * Zapisuje element pod określoną nazwą systemową
         * @param code nazwa systemowa elementu
         * @param data dane do elementu
         */
        setTempElement(code, data) {
            let temp = JSON.parse(localStorage.getItem("temp")) ? JSON.parse(localStorage.getItem("temp")) : {}

            temp[code] = data

            this.setTempToStorage(temp)
        },

        /**
         * Dodaje dane do konkretnego elementu w obiekcie "temp" w localStorage
         */
        addToTempElement(code, data) {
            /**
             * Pobrany element w obiekcie "temp" z localStorage
             */
            let temp_element = this.getTempElement(code, []);


            data.map(data_obj => {
                /**
                 * Usuwam identyczne elementy (jeśli są takie)
                 */
                temp_element = temp_element.filter(o => (o.id && data_obj.id && o.id !== data_obj.id) || JSON.stringify(o) !== JSON.stringify(data_obj))

                /**
                 * Szukamy w elemencie pod nazwą systemową "code" z objektu "temp" "index" obiektu do zaktualizowania/dodania nowego
                 */
                let temp_element_obj_index = temp_element.findIndex(obj => (obj.id && data_obj.id && obj.id === data_obj.id) || JSON.stringify(obj) === JSON.stringify(data_obj))

                /**
                 * Jeśli "index" szukanego obiektu === -1 (czyli taki obiekt nie istnieje) to do temp["code"] dodajemu nowy obiekt ("data_obj")
                 */
                if (temp_element_obj_index === -1)
                    temp_element.push(data_obj)
            })

            this.setTempElement(code, temp_element)

        },

        /**
         * Usuwanie całego elementu w obiekcie "temp"
         * @param code nazwa systemowa elementu
         */
        deleteTempElement(code) {
            let temp = JSON.parse(localStorage.getItem("temp")) ? JSON.parse(localStorage.getItem("temp")) : {}

            delete temp[code]

            this.setTempToStorage(temp)
        },

        /**
         * Usuwanie wybranych obiektów (czyli filtrowanie) z konkretnego elementu w obiekcie "temp"
         * @param code nazwa systemowa elementu z obiekcie "temp"
         * @param key nazwa pola obiektu
         * @param value wartość pola obiektu
         */
        filterTempElementByKeyValue(code, key, value) {
            let temp = JSON.parse(localStorage.getItem("temp")) ? JSON.parse(localStorage.getItem("temp")) : {}

            if (temp[code])
                temp[code] = temp[code].filter(obj => obj[key] !== value)

            this.setTempToStorage(temp)
        }
    },
    mounted() {
        window.addEventListener("offline", (e) => {
            this.internet_connection = false
        })

        window.addEventListener("online", (e) => {
            this.internet_connection = true
        })
    }
}

</script>