<template>

</template>

<script>
import Validate from "@/Library/Forms/Validate";

export default {
  name: "InputBase",
  mixins: [Validate],
  emits: ['updateValue'],
  methods: {
    focus() {

    }
  },

}
</script>

<style scoped>

</style>