<template>

</template>

<script>
import Element from "@/components/Element.vue";

export default {
    name: "StatAbstract",
    extends: Element,
}
</script>

<style scoped>

</style>