<script>
export default {
  name: "ShowTags",
  props: {
    data: {
      default() {
        return [];
      }
    },
  },
  computed: {
    tagsArr() {

      let ret = this.data;

      let data_string = typeof this.data === 'string' || this.data instanceof String;

      if (data_string ){
        ret = ret.split(',');
      }

      if (!Array.isArray(ret))
        ret = [];

      return ret;
    }
  }
}
</script>

<template>

  <ul class="list-tags">
    <li class="list-tags__item" v-for="item in this.tagsArr">
      {{ item }}
    </li>
  </ul>
</template>

<style scoped lang="scss">

.list-tags {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style: none;

  &__item {
    position: relative;

    min-height: 25px;
    line-height: 1.6rem;
    margin-bottom: .5rem;

    padding: 3px 12px;
    margin-right: 12px;

    color: var(--main_color);

    cursor: pointer;

    &:after,
    &:before {
      position: absolute;

      content: '';

      left: 0;
      top: 0;

    }

    &:after {

      right: 0;
      bottom: 0;

      border: 1px solid var(--main_color);

      border-radius: 6px;
      opacity: .3;

    }

  }
}

</style>