<script>
import {defineComponent} from 'vue'
import Hr2 from "@/views/Base/Hr/Hr2.vue";
import Breadcrumb from "@/views/Base/Breadcrumb/Breadcrumb.vue";
import Title2 from "@/views/Base/Title/Title2.vue";

export default defineComponent({
  name: "TemplatePage",
  components: {Title2, Breadcrumb, Hr2},
  props: {
    title: {
      default() {
        return "";
      }
    },
    print: {
      default() {
        return false;
      }
    },
    breadcrumb: {
      default() {
        return [
          {title: 'Strona główna', link: '/', icon: 'home'},
          {title: 'Pusta strona', link: '/blank'},
        ];
      }
    },
  }
})
</script>

<template>


  <div class="container">
    <div class="row">
      <div class="col-12">
        <Hr2 style="margin: 0 0 23px;"/>
        <span class="material-symbols-outlined print" v-if="print">
print
</span>
        <Title2 :title="title" style="margin: 0;"/>

        <Breadcrumb :breadcrumb="breadcrumb"/>
        <br>
        <slot name="default"></slot>

      </div>
    </div>


  </div>

</template>

<style scoped lang="scss">
.print{
  float: right;color: var(--main_color);
}
</style>