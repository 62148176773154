<script>
import ShowVideo from "@/Library/Show/Video.vue";

export default {
  name: "MovieYouTube",
  components: {ShowVideo},
  props: {
    info: {
      default() {
        return {};
      }
    }
  },
  computed: {
    url(){
      return this.info ? this.info.url : null;
    }
  }
}
</script>

<template>
  <ShowVideo :video="url"/>
</template>

<style scoped lang="scss">

</style>