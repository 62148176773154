<template>

  <div class="home w-100">
    <ContentHeader :name="name" :breadcrumb="breadcrumb">
      <template #header_right>
        <slot name="header_right"/>
      </template>
    </ContentHeader>
    <div class="content-header-right buttons">
      <slot name="button"></slot>
    </div>

    <div class="w-100">

      <slot name="default"/>

    </div>

  </div>
</template>

<script>
import ControllTemplate from "@/Library/Controll/Template";
import ContentHeader from "@/Library/Components/ContentHeader";

export default {
  name: "TemplatePage",
  components: {ContentHeader},
  mixins: [ControllTemplate],
  props: {
    name: {
      default() {
        return "";
      }
    },
    breadcrumb: {

      default() {
        return [];
      }
    },
  },
  computed: {

    showTitle() {
      return this.showElement('title');
    },
    showBody() {
      return this.showElement('body');
    },

  }
}
</script>

<style scoped lang="scss">

.card-header {
  overflow: hidden;
}

.card-title {
  width: 100%;
}

.buttons {
  position: absolute;
  right: 10px;
  top: 53px;
  z-index: 10;
  @media screen and (max-width: 575px) {
    top: 80px;
  }
}
</style>