<script>
import {defineComponent} from 'vue'
import MenuInline from "@/views/Base/Menu/Inline.vue";
import LabelSolid from "@/views/Base/Label/Solid.vue";
import LabelSoft from "@/views/Base/Label/Soft.vue";
import MenuMobileWrapper from "@/views/Base/Menu/MobileWrapper.vue";

export default defineComponent({
  name: "LayoutNav",
  components: {MenuMobileWrapper, LabelSoft, LabelSolid, MenuInline},
  data: () => ({
    items: [{
      name: 'Aktualności',
      url: '/article',
      id_top_position: null,
    }, {
      name: 'Kadra',
      url: '/user',
      id_top_position: null,

    }, {
      name: 'Galerie',
      url: '/galerie',
      id_top_position: null,

    }, {
      name: 'Raporty',
      url: '/raport',
      id_top_position: null,

    }],
    mobile_menu_show: false,
  }),
  methods: {

    /**
     * Obsługa kliknięć otrzymanych z komponentu "MenuMobileWrapper"
     */
    menuMobileWrapperHandler(func) {

      let scrollbarWidth = window.innerWidth - document.querySelector("body").offsetWidth + "px"

      if (func === "open") {
        document.querySelector(".header").style.transition = "none"
        document.querySelector(".header").style.paddingRight = scrollbarWidth
        document.querySelector("body").style.paddingRight = scrollbarWidth
        document.body.style.overflow = "hidden"
        this.mobile_menu_show = true
      }
      if (func === "close") {
        document.querySelector(".header").style.paddingRight = "0px"
        document.querySelector("body").style.paddingRight = "0px"
        setTimeout(() => {
          document.querySelector(".header").style.transition = "all 0.25s ease 0s"
        }, 250)
        document.body.style.overflow = "auto"
        this.mobile_menu_show = false
      }
    },
  }
})
</script>

<template>
  <nav class="nav">

    <div class="container">
      <div class="row">
        <div class="col-9 col-lg-3">
          <router-link to="/">
            <img src="@/assets/img/logo.png" class="nav__logo"/>

          </router-link>
        </div>
        <div class="col-3 col-lg-9">

          <img src="../assets/img/eu.svg" style="float:right; margin: 6px 0 0 36px;" class="d-none d-xl-block"/>
          <img src="../assets/img/hamburger.svg" style="float:right; margin: 16px 0 0 36px;"
               @click="menuMobileWrapperHandler('open')" class="d-sm-block d-lg-none"/>
          <MenuInline :items="items"
                      color="black"
                      style="padding: 12px 0;"
                      :size="'1.2rem'"
                      :space="'20px'"
                      :weight="500"
                      :place="'right'"
                      class="d-none d-lg-flex"
          />
          <LabelSoft :text="'Nabór trwa'" style="float:right; margin: 8px 36px 8px 12px;" class="d-none d-lg-block"/>
        </div>
      </div>
    </div>


    <MenuMobileWrapper
        :show="mobile_menu_show"
        :menu_items="items"
        @emitMenuMobileWrapper="menuMobileWrapperHandler"/>
  </nav>
</template>

<style scoped lang="scss">
.nav {

  position: relative;
  padding: 24px 0;

  //background: #fff;

  &__logo {
    width: 300px;
    max-width: 100%;
  }
}
</style>