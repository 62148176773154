<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Page",
  mounted() {
    scroll(0,0)

  }
})
</script>

<template>

</template>

<style scoped lang="scss">

</style>