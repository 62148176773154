<script>
import ShowFiles from "@/Library/Show/Files.vue";

export default {
    name: "Files",
  components: {ShowFiles},
    props: {
        info: {
            default() {
                return {};
            }
        }
    },
    computed: {
        files() {
            return this.info && this.info.files_objs ? this.info.files_objs : [];
        },
    }
}
</script>

<template>
  <ShowFiles :files="files"/>
</template>

<style scoped lang="scss">

.link {
    cursor: pointer;
}

.image {
    max-width: 100%;
}
</style>