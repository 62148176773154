<script>

export default {
    name: "ControlAnimate",
    data: () => ({
        animItems: [],
        animNumbers: [],

        animNumbersSections: [],

        observer: null,
        observerOptions: {
            threshold: 0.5
        },

        animStartDelay: 0
    }),
    methods: {
        animOnScroll() {
            this.animItems.forEach(animItem => {
                let animItemHeight = animItem.offsetHeight
                let animItemOffset = this.offset(animItem).top
                let animStart = 4

                let animItemPoint = window.innerHeight - animItemHeight / animStart
                if (animItemHeight > window.innerHeight)
                    animItemPoint = window.innerHeight - window.innerHeight / animStart

                if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight))
                    animItem.classList.add("_active")
                // else
                //     animItem.classList.remove("_active")
            })
        },

        offset(el) {
            let rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop

            return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
        },

        clearAnimItems() {
            if (this.animItems.length > 0) {
                this.animItems.forEach(animItem => {
                    animItem.classList.remove("_active")
                })
            }
        },

        numbersInit(numbers) {
            this.animNumbers = numbers ? numbers : document.querySelectorAll("[data-anim-number]")

            if (this.animNumbers) {
                this.animNumbers.forEach(animNumber => {
                    this.numbersAnimate(animNumber)
                })
            }
        },

        numbersAnimate(animNumber) {
            let startTimeStamp = null
            let duration = parseInt(animNumber.dataset.animNumber) ? parseInt(animNumber.dataset.animNumber) : 2000
            let startValue = parseInt(animNumber.innerHTML)
            let startPosition = 0
            let step = (timestamp) => {
                if (!startTimeStamp)
                    startTimeStamp = timestamp

                let progress = Math.min((timestamp - startTimeStamp) / duration, 1)
                animNumber.innerHTML = Math.floor(progress * (startPosition + startValue))

                if (progress < 1)
                    window.requestAnimationFrame(step)
            }

            window.requestAnimationFrame(step)
        },

        observerInit() {
            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        let targetElement = entry.target
                        let animNumbers = document.querySelectorAll("[data-anim-number]")
                        if (animNumbers.length)
                            this.numbersInit(animNumbers)

                        observer.unobserve(targetElement)
                    }
                })
            })

            this.animNumbersSections = document.querySelectorAll(".anim-numbers-section")

            if (this.animNumbersSections.length) {
                this.animNumbersSections.forEach(animNumbersSection => {
                    this.observer.observe(animNumbersSection)
                })
            }
        },

        afterWindowLoad() {
            this.animItems = document.querySelectorAll("._anim-item")

            if (this.animItems.length > 0)
                window.addEventListener("scroll", this.animOnScroll)

            setTimeout(() => {
                this.animOnScroll()
            }, this.animStartDelay)

            this.observerInit()
        }
    },
    mounted() {
        if (document.readyState === "complete" || document.readyState === "interactive")
            this.afterWindowLoad()
        else
            window.addEventListener("load", this.afterWindowLoad)
    }
}

</script>