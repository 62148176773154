<script>
import Element from "@/components/Element.vue";

export default {
  name: "PortalTeamDescription",
  extends: Element,
}
</script>

<template>
  <p v-html="obj.description"></p>
</template>

<style scoped lang="scss">

</style>