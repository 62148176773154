<script>
import {mapState} from "vuex";

export default {
  name: "Cookie",
  props: {},

  data: () => ({
    show: false
  }),

  methods: {
    close() {
      localStorage.setItem('cookie-' + this.domain_id, 'show');
      this.show = false;
    },

    updateShow() {


      this.show = false;
      if (this.domain_id && localStorage.getItem('cookie-' + this.domain_id) !== 'show')
        this.show = true;

    }
  },

  computed: {

    ...mapState('app', ['domain_id']),

  },

  mounted() {
    this.updateShow();
  },

  watch: {
    domain_id() {
      this.updateShow();
    }
  }
}
</script>

<template>
  <div class="cookie" v-if="show">
    <div class="cookie__text">
      Uniwersytet Śląski w Katowicach przywiązuje dużą wagę do poszanowania prywatności użytkowników odwiedzających
      serwisy internetowe Uczelni.

      W celu umożliwienia prawidłowego funkcjonowania i zapisywania ustawień poszczególnych użytkowników, strony
      internetowe Uniwersytetu Śląskiego w Katowicach wykorzystują tzw. cookies (z ang. ciasteczka). Cookies to małe
      pliki tekstowe wysyłane przez serwis do przeglądarki internetowej użytkownika na urządzeniu końcowym (komputer,
      smartfon, tablet, itp.).

      W związku z powyższym informujemy, że strona którą odwiedzasz wykorzystuje wyłącznie niezbędne, techniczne pliki
      cookies.
    </div>
    <div class="cookie__close" @click="close"></div>
  </div>
</template>

<style scoped lang="scss">
.cookie {
  padding: 12px 70px 12px 16px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, .12), 0px 0px 2px 0px rgba(0, 0, 0, .14);
  border-radius: 4px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  width: 700px;
  max-width: 33.3333%;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  z-index: 1998;

  &__text {
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 0;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: .7;

    &:before,
    &:after {
      position: absolute;
      width: 24px;
      height: 2px;
      top: 14px;
      left: 2px;
      background-color: #666;
      content: '';
      border-radius: 2px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  @media screen and (max-width: 1024px){
    width: 100%;
    max-width: 100%;
    left: 0;bottom: 0;

  }
}
</style>