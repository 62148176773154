<template>
    <ul class="info-list">
        <li class="info-list__item info-item" v-for="item in info.items">
            <div class="info-item__icon material-symbols-outlined">{{ item.icon }}</div>
            <div class="info-item__info info">
                <span class="info__name">{{ item.name }}</span>
                <span class="info__desc">{{ item.desc }}</span>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: "InfoListIcon1",
    data: () => ({
        info: {
            items: [{
                icon: 'content_copy',
                name: 'Wyślij wiadomość',
                desc: 'otrzymasz wiadomość  w ciągu paru dni roboczych'
            }, {
                icon: 'email',
                name: 'Zacznij czat',
                desc: '1 konsultant online'
            }, {
                icon: 'content_copy',
                name: 'Skontaktuj się z punktem informacyjnym w swojej okolicy',
                desc: 'otrzymasz wiadomość  w ciągu paru dni roboczych'
            }],
        }
    })
}
</script>

<style scoped lang="scss">
.info-list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;

  &__item {
    margin: 0;
    padding: 0;
    flex: 1 1 0px;

  }
}

.info-item {
  display: flex;
  flex-direction: row;

  &__icon {
    width: 34px;
    height: 34px;
      color: var(--main);
  }

  &__info {
    padding-left: 12px;
  }
}

.info {
  display: flex;
  flex-direction: column;

  &__name {

      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;

      /* UE */

      color: var(--main);

      margin-bottom: .3rem;
  }

  &__desc {
      opacity: .7;
      max-width: 200px;
  }
}
</style>