<template>
  <router-link :to="'/'+role+'/'+user.id" v-if="user && link">
    <div class="user" :class="this.class">
      <div class="user__avatar"
           :style="{ backgroundImage: `url(${user.avatar_url})` }"/>
      <div class="user__name">
        {{ user.name_all }}
      </div>
    </div>
  </router-link>

  <div class="user" :class="this.class" v-if="user && !link">
    <div class="user__avatar"
         :style="{ backgroundImage: `url(${user.avatar_url})` }"/>
    <div class="user__name">
      {{ user.name_all }}
    </div>
  </div>

  <div class="user grey-text" :class="this.class" v-if="!user">
    Brak
  </div>
</template>

<script>
export default {
  name: "ShowUser",
  props: {
    user: {
      type: Object
    },
    class: {},
    config: {},
    role: {
      type: String,
      default() {
        return 'seller';
      }
    },
    link: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },

  methods: {
    nameToName() {
      if (this.user.name_all === undefined || this.user.name_all === '' || this.user.name_all === "Brak nazwy")
        this.user.name_all = this.user.name;
    }
  },

  mounted() {
    if (this.config && this.config['class'] !== undefined)
      this.class = this.config['class'];
  },
  watch: {
    user:{
      handler()
      {
        // this.nameToName();
      }
    }
  }
}
</script>

<style scoped lang="scss">

.user {

  display: flex;

  &__avatar {
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin: 0 6px 0 0;

    background-position: center;
    background-size: cover;
  }

  &__name {

    font-size: 14px;
    line-height: 30px;
    /* identical to box height */
    text-align: left;
    //padding-left: 6px;

    /* Heading */

    color: #3F434A;

    user-select: none;
  }

  &.user--small{

    .user__name {
      line-height: 22px;
    }
    .user__avatar {
      width: 22px;
      min-width: 22px;
      height: 22px;
    }
  }

  &__avatar-20 &__avatar {
    width: 20px;
    min-width: 20px;
    height: 20px;

    margin-top: 5px;
  }

  [data-layout-mode=dark] &__name,
  .dark-layout &__name {
    color: rgba(255, 255, 255, .7);

  }

  &--rotate &__name {

    padding-left: 4px;
    padding-top: 8px;
  }

}
</style>