<template>

  <div :src="image" :class="this.class" class="show-image mb-1"
       v-if="imageHas || this.show_if_null"
       :style="{ backgroundImage: `url(${imageUrl})` }">
  </div>

</template>

<script>

import axios from "@/axios";

export default {
  props: {
    image: {},
    class: {},
    show_if_null: {},
  },
  components: {},
  data() {
    return {}
  },
  name: "ShowImage",
  methods: {},
  computed: {
    imageHas() {

      let ret = false;

      if (!ret && this.image !== null)
        ret = true;

      if (!ret)
        ret = false;

      return ret;

    },
    baseUrl() {
      return axios.defaults.baseURL;
    },

    appUrl() {


      let link = axios.defaults.baseURL;

      let liinkArr = link.split('/');
      liinkArr.pop();
      liinkArr.pop();
      link = liinkArr.join('/')+'/';

      return link;
    },

    imageUrl(){

      let ret = null;

      if(!ret && this.image !== null){
        ret = this.image;
      }
      if(!ret){
        ret = this.appUrl+'/data/base/mark/no-image.png';
      }

      console.log(ret)

      return ret;

    }
  }

}
</script>

<style scoped lang="scss">
.show-image {
  width: 100%;
  height: 0;
  padding-bottom: 67%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

.mw-600{
  background-color: var(--sp-body-bg) !important;
  max-width: 600px;
  padding-bottom: 400px;
  border-radius: 6px;
}

</style>