<script>
import Alert from "@/Library/Components/Alert.vue";
import axios from "@/axios";

export default {
  name: "AppNotAccess",
  components: {Alert},
  props: {
    domain: {
      default(){
        return null;
      }
    }
  },
  data: () => ({
    notaccess_label: 'info',
    notaccess_text: 'Brak dostępu',
  }),
  methods: {
  }
}
</script>

<template>

  <div class="not-access">
    <div class="not-access__content content">
      <img class="content__image" src="@/assets/img/logo.png" alt="Logo Uniwersytetu"/>
      <div class="content__name">
        <Alert :type="notaccess_label">{{ notaccess_text }}</Alert>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

.not-access {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: 1050;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

</style>