<script>

import ControlAnimate from "@/Library/Controll/Animate.vue"

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Parallax, Pagination, Navigation } from 'swiper/modules';

export default {
    name: "HeroSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            image: {
                                url: "https://via.placeholder.com/1920x1080",
                            },
                            subtitle: "Subtitle",
                            title: "Title",
                            description: "Description",
                            site_link: "/",
                            site_link_name: "Link",
                            site_link_two: "/",
                            site_link_two_name: "Link"
                        }
                    ]
                }
            }
        }
    },
    mixins: [ControlAnimate],
    setup() {
        return {
            modules: [Parallax, Pagination, Navigation]
        }
    }
}

</script>

<template>

    <div class="component anim-6 _anim-item">

        <swiper
            :speed="1000"
            :parallax="true"
            :modules="modules"
            :auto-height="true"
            :pagination="{ clickable: true }"
            :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
            class="mySwiper">
            <swiper-slide v-for="(item, index) in info.items">
                <div class="content">
                    <div class="content__text text">
                        <h1 class="text__subtitle" data-swiper-parallax="-1000">
                            <span :class="{'anim-1 _anim-item': index === 0}">{{ item.subtitle }}</span>
                        </h1>
                        <h1 class="text__title" data-swiper-parallax="-800">
                            <span :class="{'anim-2 _anim-item': index === 0}">{{ item.title }}</span>
                        </h1>
                        <p class="text__text" data-swiper-parallax="-600">
                            <span :class="{'anim-3 _anim-item': index === 0}" v-html="item.description"></span>
                        </p>

                        <div class="text__links" data-swiper-parallax="-400">
                            <router-link v-if="item.site_link" :to="item.site_link" class="link td-no" :class="{'anim-4 _anim-item': index === 0}">{{ item.site_link_name }}</router-link>
                            <router-link v-if="item.site_link_two" :to="item.site_link_two" class="link td-no" :class="{'anim-4 _anim-item': index === 0}">{{ item.site_link_two_name }}</router-link>
                        </div>
                    </div>

                    <div class="content__image" data-swiper-parallax="-800">
                        <img :src="item.image?.url" alt="" :class="{'anim-5 _anim-item': index === 0}">
                    </div>
                </div>
            </swiper-slide>

            <button type="button" class="swiper-button-prev">
                <span class="material-symbols-rounded">chevron_left</span>
            </button>
            <button type="button" class="swiper-button-next">
                <span class="material-symbols-rounded">chevron_right</span>
            </button>
        </swiper>

    </div>

</template>

<style scoped lang="scss">

.component {
    background: var(--block_light_bg);
    overflow-x: hidden;

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-width: 1000px) {

    }

    @media screen and (max-width: 444px) {

    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    padding: 70px calc(150px + 15px) 120px calc(150px + 15px);

    @media screen and (max-width: 1440px) {
        padding: 70px calc(100px + 15px) 120px calc(100px + 15px);
    }

    @media screen and (max-width: 1200px) {
        padding: 70px calc(50px + 15px) 120px calc(50px + 15px);
    }

    @media screen and (max-width: 1000px) {
    }

    @media screen and (max-width: 444px) {

    }
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 30px;

    height: 100%;

    &__text {
        width: 58%;
        padding-right: 135px;
    }

    &__image {
        width: 42%;

        & img {
            width: 100%;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 1440px) {

        & .text {
            padding-right: 0;

            &__title {
                font-size: 48px;
                line-height: 60px;
            }
        }
    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        row-gap: 30px;

        & .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &__title {
                font-size: 35px;
                line-height: 42px;
            }

            &__text {
                font-size: 15px;
                line-height: 22px;
            }

            &__subtitle, &__title, &__text {
                text-align: center;
            }

            &__links {
                flex-direction: column;
                row-gap: 10px;

                & .link {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 444px) {

    }
}

.text {

    &__subtitle, &__title, &__text {

        & > span {
            display: block;
        }
    }

    &__subtitle, &__title {
        font-family: var(--title_font);
    }

    &__subtitle {
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 5px;
        text-transform: uppercase;
        color: var(--title_sub);
        margin-top: 0;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 65px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0.5px;
        color: var(--title);
        margin-top: 0;
        margin-bottom: 15px;
    }

    &__text {
        font-family: var(--text_font);
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color: var(--text);
        margin-top: 0;
        margin-bottom: 30px;
    }

    &__links {
        display: flex;
        align-items: center;
        column-gap: 30px;
    }
}

.link {
    //border-radius: 8px;
    border: 2px solid #fff;
    color: var(--block_dark_link);

    display: block;
    width: fit-content;

    padding: 10px 25px;

    font-family: var(--title_font);
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;

    cursor: pointer;
    user-select: none;

    &:nth-child(odd) {
        background: var(--block_dark_bg);
        border-color: var(--block_dark_bg);

        &:hover {
            color: var(--block_dark_bg);
            background: #fff;
        }
    }

    &:nth-child(even) {
        background: var(--block_dark_bg);
        border-color: var(--block_dark_bg);

        &:hover {
            color: var(--block_dark_bg);
            background: #fff;
        }
    }
}

.content--xl {

    & .swiper-slide {
        padding: 70px calc(100px + 15px) 120px calc(100px + 15px);
    }

    & .content {

        & .text {
            padding-right: 0;

            &__title {
                font-size: 48px;
                line-height: 60px;
            }
        }
    }
}

.content--l {

    & .swiper-slide {
        padding: 70px calc(50px + 15px) 120px calc(50px + 15px);
    }

    & .content {

        & .text {
            padding-right: 0;
        }
    }
}

.content--sm, .content--xs {

    & .swiper-slide {
        padding: 70px calc(50px + 15px) 120px calc(50px + 15px);
    }

    & .content {
        flex-direction: column;
        row-gap: 30px;

        & .text {
            padding-right: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &__title {
                font-size: 35px;
                line-height: 42px;
            }

            &__text {
                font-size: 15px;
                line-height: 22px;
            }

            &__subtitle, &__title, &__text {
                text-align: center;
            }

            &__links {
                flex-direction: column;
                row-gap: 10px;

                & .link {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.content--xs {

}

.anim {
    &-1, &-2, &-3, &-4 {
        transition: transform 1s ease 0s;
        transform: translateY(100px);

        &._active {
            transform: translateY(0);
        }
    }

    &-1 {
        transition: all 1s ease 0s;
    }

    &-2 {
        transition: all 1s ease 0.2s;
    }

    &-3 {
        transition: all 1s ease 0.4s;
    }

    &-4 {
        transition: transform 1s ease 0.6s, opacity 1s ease 0.6s, visibility 1s ease 0.6s, background 0.25s ease 0s;
    }

    &-5 {
        transition: all 1s ease 0.2s;
        transform: translateY(-100px);

        &._active {
            transform: translateY(0);
        }
    }

    &-6 {
        transition: all 1s ease 0s;
    }
}

</style>

<style lang="scss">

.swiper {

    &-pagination {
        bottom: 30px!important;

        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        column-gap: 8px!important;

        &-bullet {
            margin: 0!important;

            min-width: 16px!important;
            width: 16px!important;
            height: 16px!important;
            border: 2px solid rgba(0, 0, 0, 0.8)!important;
            background: transparent!important;

            &-active {
                border-color: var(--block_dark_bg)!important;

                position: relative!important;

                &::before {
                    position: absolute!important;
                    content: ""!important;
                    top: 50%!important;
                    left: 50%!important;
                    transform: translate(-50%, -50%)!important;
                    width: 8px!important;
                    height: 8px!important;
                    background: var(--block_dark_bg)!important;
                    border-radius: 50%!important;
                }
            }
        }
    }

    &-button {

        &-prev, &-next {
            background: transparent;

            transition: all 0.25s ease 0s;
            opacity: 0;
            visibility: hidden;

            border: 1px solid var(--title_sub);
            border-radius: 50%;

            min-width: 50px!important;
            width: 50px!important;;
            height: 50px!important;;

            display: flex;
            align-items: center;
            justify-content: center;

            backdrop-filter: blur(1px);

            &::after {
                display: none;
            }

            & .material-symbols-rounded {
                font-size: 20px;
                color: var(--title);
            }

            &:hover {
                backdrop-filter: blur(2px);
            }

            @media screen and (max-width: 1440px) {

            }

            @media screen and (max-width: 1200px) {
                border: none;
            }

            @media screen and (max-width: 1000px) {
            }

            @media screen and (max-width: 444px) {

            }
        }

        &-prev {

        }

        &-next {

        }

        &-disabled {
            display: none;
        }
    }

    &:hover {

        & .swiper-button {

            &-prev, &-next {
                opacity: 1;
                visibility: visible;
            }

            &-prev {
                transform: translateX(25px);
            }

            &-next {
                transform: translateX(-25px);
            }

            @media screen and (max-width: 1440px) {

            }

            @media screen and (max-width: 1200px) {

                &-prev {
                    transform: translateX(-3px);
                }

                &-next {
                    transform: translateX(3px);
                }
            }

            @media screen and (max-width: 1000px) {
            }

            @media screen and (max-width: 444px) {

            }
        }
    }
}


</style>