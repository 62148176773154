<template>

  <ul class="menu-list">
    <li class="menu-list__item" v-for="item in this.itemsAll">
      <router-link :to="item.url" class="menu-item">
        <i class="menu-item__icon material-symbols-outlined">{{ item.icon }}</i>
        <span class="menu-item__name">
                  {{ to(item,'name') }}

        </span>
      </router-link>
    </li>
  </ul>

</template>

<script>
import MenuAbstract from "@/views/Base/Menu/Abstract.vue";

export default {
  name: "MenuIcon",
  extends: MenuAbstract,
  props: {
    // info: [],
  },
}
</script>

<style scoped lang="scss">

.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;


  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
  gap: 12px;

  &__item {
    //width: 100px;
    margin: 0;
  }
}

.menu-item {

  display: flex;

  flex-direction: column;
  text-decoration: none;
  text-align: center;

  color: var(--icon);

  border: 1px solid transparent;
  position: relative;
  cursor: pointer;

  background-color: white;

  border-radius: 10px;

  & > * {
    z-index: 2;
  }

  &:before {
    position: absolute;
    content: '';
    z-index: 1;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--main);
    opacity: .1;
    display: none;
  }

  &:hover {
    border: 1px solid var(--main);

  }

  &:hover &__name {
    font-weight: bold;

  }

  &:hover:before {
    display: block;
  }

  &__icon {
    font-size: 1.78rem;
    padding: 15px 0;
    font-weight: 200;
  }

  &__name {
    font-size: 0.67rem;
    line-height: 1.5em;
    font-weight: 200;
    margin-bottom: 10px;
  }

}

</style>