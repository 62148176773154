<template>

    <div class="file-block">
        <div class="file-block__download">
            <img src="@/assets/images/download.svg" alt="Download">
        </div>
        <div class="file-block__info file-info">
            <div class="file-info__name">
                {{info.name}}
            </div>
            <ul class="file-info__metric file-metric">
                <li class="file-metric__item">
                    {{info.format}}
                </li>
                <li class="file-metric__item">
                    {{info.info}}
                </li>
                <li class="file-metric__item">
                    {{info.size}}
                </li>
                <li class="file-metric__item">Aktualny na dzień {{info.date}}</li>
            </ul>
        </div>
    </div>

</template>

<script>
import FileAbstract from "@/views/Base/File/Abstract.vue";

export default {
    name: "FileBlock",
    extends: FileAbstract,
    data: () => ({
        info: {
            name: 'Regulamin praktyk studenckich dla Wydziału Nauk Ścisłych na roko 2023',
            format: 'PDF',
            info: '232 strony',
            size: '105 kb',
            date: '12.05.2023',
        }
    })
}
</script>

<style scoped lang="scss">

.file-block {
  display: flex;
  flex-direction: row;
    margin-bottom: 2rem;


  &__download {
    padding-right: 12px;
  }

  &__info {
  }
}

.file-info {
  &__name {
    color: var(--main);
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }

  &__metric {
  }
}

.file-metric {
  margin: 0;
  padding: 0;
  display: inline-block;

  &__item {
    display: inline-block;
    opacity: 0.8;

    padding: 0 12px 0 0;
    margin: 0 12px 0 0;

    position: relative;

    &:before {

      content: '';

      position: absolute;
      right: 0;
      top: 50%;

      width: 2px;
      height: 2px;

      border-radius: 50%;

      transform: translateY(-50%);

      display: block;

      background-color: var(--main);

    }

    &:last-child {
      margin: 0;
      padding: 0;
    }

    &:last-child:before {
      display: none;
    }
  }
}

</style>