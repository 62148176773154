<script>
import CalendarAbstract from "@/Library/Components/Calendar/Abstract.vue";
import CalendarDay from "@/Library/Components/Calendar/Day.vue";

export default {
  name: 'calendarMonth',
  extends: CalendarAbstract,
  emits: ['selectDay'],
  components: {
    CalendarDay
  },
  computed: {
    getWeeks() {
      let firstDay = new Date(this.year, this.month, 1);
      let lastDay = new Date(this.year, this.month + 1, 0);
      if (firstDay.getDay() !== 0) {
        firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1);
      } else if (firstDay.getDay() === 0) {
        firstDay.setDate(firstDay.getDate() - firstDay.getDay() - 6);
      }
      let weeks = [];
      while (firstDay <= lastDay) {
        let week = [];
        for (let i = 0; i < 7; i++) {
          let day = {
            date: firstDay.getDate(),
            fullDate: new Date(firstDay),
          }
          week.push(day);
          firstDay.setDate(firstDay.getDate() + 1);
        }
        weeks.push(week);
      }
      return weeks;
    }
  },
  methods: {
    nextMonth() {
      let date
      if (this.month === 12) {
        date = new Date(this.year + 1, 1, 1)
      } else if (this.month !== 12) {
        date = new Date(this.year, this.month + 1, 1)
      }
      this.dateMain = date
      this.$emit('selectRange', {start: new Date(this.year, this.month, 1), end: new Date(this.year, this.month + 1, 0)})
    },
    prevMonth() {
      let date
      if (this.month !== 1) {
        date = new Date(this.year, this.month - 1, 1)
      } else if (this.month === 1) {
        date = new Date(this.year - 1, 12, 1)
      }
      this.dateMain = date
      this.$emit('selectRange', {start: new Date(this.year, this.month, 1), end: new Date(this.year, this.month + 1, 0)})
    },
  },
}
</script>

<template>
  <div class="calendar">
    <div class="calendar__header head">
      <div class="head__btn head__btn--left" @click="this.prevMonth()"></div>
      <div class="head__header">
        <p>{{ this.monthName }}</p>
        <p>{{ this.year }}</p>
      </div>
      <div class="head__btn head__btn--right" @click="this.nextMonth()"></div>
    </div>

    <table class="calendar__table table">
      <thead class="table__head head-table">
      <tr>
        <th class="head-table__title" v-for="day in this.daysNamesTab "><p>{{ day }}</p></th>
      </tr>
      </thead>

      <tbody class="table__body body">
      <tr v-for="week in getWeeks" class="body__week week">
        <td v-for="day in week">
          <CalendarDay :calendarType="'month'" :firstDayOfRange="new Date(this.year, this.month, 1)" :day="day"
                       :events="eventInDay(day)"
                       @selectDay="selectDay"
          />
        </td>
      </tr>
      </tbody>

    </table>
  </div>

</template>

<style scoped lang="scss">
.calendar {
    width: 100%;
  //width: max-content;
  justify-content: center;

  &__header {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 90%;
  }
}

.head {
  &__header {
    display: flex;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    align-items: center;
    font-weight: 500;
    line-height: normal;
    color: var(--heading, #3F434A);
    font-size: 20px;

    & p {

      margin-top: 0;
      margin-bottom: 0;
    }
    & p:last-child {
      color: var(--body-light, #8A9099);
      font-size: 18px;
      margin-left: 7px;
    }
  }

  &__btn {

    position: relative;
    margin: auto 0 auto 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    cursor: pointer;

    &--right {
      border-left: 5px solid #000000;
    }

    &--left {
      border-right: 5px solid #000000;
    }

    &::before {
      position: absolute;
      content: '';
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-top: -20px;
      margin-left: -35px;

    }
  }
}


.table {
  font-family: Poppins;
  text-align: center;
  margin-top: 5%;
    width: 100%;

  &__head {
    color: var(--body-light, #8A9099);
    font-size: 14px;
  }


}

.head-table {
  &__title {
    font-weight: unset;

    & p {
    }
  }
}

</style>