<template>
	<div class="row">
		<div class="col-lg-7">
			<apexchart :series="obj.answers"
								 width="100%"
								 height="300%"
								 :options="options"/>
		</div>
		<div class="col-5 radio--other">
			<div v-if="obj.other_texts">
				<p class="list" v-for="text in obj.other_texts">
					{{ text }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Wykres kołowy
 */
export default {
	name: "PieChart",
	props: {obj: {}},
	computed: {
		options() {
			let self = this;
			return {
				chart: {
					toolbar: {
						show: false
					},
					animations: {
						enabled: false
					},
					type: 'pie',
					offsetX: 0
				},
				dataLabels: {
					enabled: true,
					style: {
						textAnchor: 'end',
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: ['#000000'],
					},
					dropShadow: {
						enabled: false,
					}
				},
				grid: {
					borderColor: '#e7e7e7',
					row: {
						colors: ['#f3f3f3', 'transparent'],
						opacity: 0.5
					}
				},
				xaxis: {
					categories: this.obj.options,
				},
				labels: this.obj.options,
				legend: {
					position: 'right',
					horizontalAlign: 'left',
					floating: false,
					offsetX: 0,
					offsetY: 0,
					labels: {
						colors: ['#FFFFFF', '#000000']
					},
					formatter(val, opts) {
						let ind = opts.seriesIndex;
						let percentage = (opts.w.config.series[ind] / self.obj.sum * 100).toFixed(2);
						percentage = isNaN(percentage) ? 0 : percentage;
						return `${val} (${opts.w.config.series[ind]} - ${percentage}%)`;
					}
				},
				responsive: [{
					breakpoint: 1024,
					options: {
						legend: {
							position: 'bottom'
						}
					},
				}]
			}
		}
	}
}
</script>

<style scoped lang="scss">
.checkbox--other {
	margin-left: -43px;
	margin-top: 200px;
	@media screen and (max-width: 1024px) {
		margin: 0;
	}
}

.list {
	padding: 12px;
	margin-bottom: 10px;
	border-radius: 6px;
	background-color: #ddd;

	[data-layout-mode="dark"] &,
	.dark-layout & {
		background-color: var(--sp-gray-300);
	}
}
</style>