<script>
import Title2 from "@/views/Base/Title/Title2.vue";

export default {
  name: "UserName",
  components: {Title2},
  props: {
    obj: {
      default() {
        return {}
      }
    }
  }
}
</script>

<template>
  <Title2 :title="obj.name_all"/>
</template>

<style scoped lang="scss">

</style>