<script>
import {defineComponent} from 'vue'
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";

export default defineComponent({
  name: "UserBlock1",
  components: {Thumb1},
  props: {
    name: {
      default() {
        return 'Dr. Irena Eris'
      }
    },
    position: {
      default() {
        return 'Podsekretarz Stanu w Ministerstwie Wyglądu'
      }
    },
    image: {
      default() {
        return 'https://picsum.photos/200/300?random=2'
      }
    },
  }

})
</script>

<template>
  <router-link class="user-block" to="/user/show">
    <Thumb1 format="11" :image="image" class="user-block__image" radius="yes"/>
    <div class="info user-block__info">
      <h4 class="info__name">{{name}}</h4>
      <span class="info__position">{{position}}</span>
    </div>
  </router-link>
</template>

<style scoped lang="scss">

.user-block{
  position: relative;
  margin-bottom: 50px;
  color: inherit;
  display: block;

  &__info {

    position: absolute;
    background-color: var(--block_light_bg);
    width: 90%;
    bottom: -10%;
    left: 0;

    padding: 18px;

    border-radius: 0 12px 12px 12px;

    transition: all .3s;
    height: 125px;
    //overflow: hidden;

    &:after {

      position: absolute;
      width: 0;
      height: 20px;

      content: '';

      bottom: 0;

      top: -35px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      clear: both;


    }

  }

}

.info{

  display: flex;
  flex-direction: column;

  &__name {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: black;
    margin: 0 0 12px 0;
    padding: 0;
  }

  &__positio  {
    margin: 0;
    padding: 0;
    color: black;
    opacity: .7;
    font-weight: 300;

  }
}

</style>