<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "TermAndConditionsV2",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          footer: "© 2020 - yourBrand. All right reserved",
          termsLink: "",
          termsName: "",
          privacyPolicyLink: "",
          privacyPolicyName: "",
          SettingsLink: "",
          SettingsName: ""
        }
      }
    },
  }
})
</script>

<template>
  <div class="term-and-conditions__container term">
    <div class="term__links">
      <a :href="info.termsLink">{{to(this.info, 'termsName')}}</a>
      <a :href="info.privacyPolicyLink">{{to(this.info, 'privacyPolicyName')}}</a>
      <a :href="info.SettingsLink">{{to(info, 'SettingsName')}}</a>
    </div>

    <div class="term__footer">
      <p class="term-footer__text">{{to(info, 'footer')}}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.term{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &__links{
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    a{
      text-decoration: none;
      color: var(--link);
    }
  }

  .term-footer__text{
    color: var(--text);
  }
}

@media screen and (max-width: 991px) {
  .term__links{
    flex-basis: 70%;
  }
}
</style>