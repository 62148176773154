<template>
	<div class="row">
		<div :class="obj.other_texts?'col-7':'col-12'">
			<apexchart :series="obj.answers"
								 width="100%"
								 height="300%"
								 :options="options"/>
		</div>
		<div class="col-5 checkbox--other" v-if="obj.other_texts">
			<p class="list" v-for="text in obj.other_texts">
				{{ text }}
			</p>
		</div>
	</div>
</template>

<script>
/**
 * Wykres warstwowy
 */
export default {
	name: "AreaChart",
	props: {obj: {}},
	computed: {
		options() {
			return {
				chart: {
					toolbar: {
						show: false
					},
					animations: {
						enabled: false
					},
					type: 'area',
				},
				grid: {
					borderColor: '#e7e7e7',
					row: {
						colors: ['#f3f3f3', 'transparent'],
						opacity: 0.5
					}
				},
				plotOptions: {
					bar: {
						columnWidth: '100%',
						dataLabels: {
							position: 'top',
							orientation: 'vertical'
						},
					}
				},
				dataLabels: {
					enabled: false,
					offsetX: 8,
					position: 'end',
					style: {
						textAnchor: 'end',
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
						colors: ['#000000']
					},
				},
				stroke: {
					show: true,
					width: 20,
					colors: ['transparent']
				},
				xaxis: {
					type: 'category',
					categories: this.obj.options,
					labels: {
						show: true
					},
				},
				yaxis: {
					labels: {
						show: true,
						style: {
							colors: '#000000'
						}
					},
					title: {
						rotate: 0,
						offsetX: 30,
						offsetY: -100,
						style: {
							color: '#000000',
							fontSize: '12px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 600,
							cssClass: 'apexcharts-yaxis-title',
						},
					},
				},
				legend: {
					position: 'right',
					horizontalAlign: 'right',
					offsetX: -10,
					offsetY: 25,
					labels: {
						colors: ['#FFFFFF', '#000000']
					},
					show: true,
					showForSingleSeries: true,
				},
				responsive: [{
					breakpoint: 1024,
					options: {
						legend: {
							position: 'bottom'
						}
					},
				}]
			}
		}
	}
}
</script>

<style scoped lang="scss">
.checkbox--other {
	margin-left: -43px;
	margin-top: 200px;
	@media screen and (max-width: 1024px) {
		margin: 0;
	}
}

.list {
	padding: 12px;
	margin-bottom: 10px;
	border-radius: 6px;
	background-color: #ddd;

	[data-layout-mode="dark"] &,
	.dark-layout & {
		background-color: var(--sp-gray-300);
	}
}
</style>