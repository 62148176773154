<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "BlockStep3",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    type: "mixed", // one_side, mixed
                    items: [
                        {
                            icon: "photo_camera",
                            title: "Step One",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur adipisicing.",
                            position: "right"
                        },
                        {
                            icon: "mode_comment",
                            title: "Step Two",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur adipisicing.",
                            position: "left"
                        },
                        {
                            icon: "lock",
                            title: "Step Three",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore consectetur adipisicing.",
                            position: "right"
                        }
                    ]
                }
            }
        }
    }
})
</script>

<template>

    <ul class="list" :class="{'list--one-side': info.type === 'one_side'}">
        <template v-for="(item, index) in info.items">
            <li
                v-if="to(item, 'title') ||  parseText(item, 'description')"
                class="list__item item"
                :class="{'item--left': item.position === 'left'}">
                <div class="item__icon">
                    <span class="material-symbols-rounded material-symbols-rounded--fill">{{ item.icon }}</span>
                </div>

                <div class="item__circle">
                    <span>{{ index + 1 }}</span>
                </div>

                <div class="item__text text">
                    <h1 class="text__title">{{ to(item, "title") }}</h1>
                    <p class="text__description" v-html="parseText(item, 'description')"></p>
                </div>
            </li>
        </template>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    row-gap: 64px;

    width: 100%;

    &__item {

    }

    &--one-side {

        & .item {
            flex-direction: row;
            margin: 0;

            &__text {

                &::before, &::after {
                    width: 1px;
                    right: unset;
                    left: calc(-56px - (1px / 2))!important;
                }
            }
        }

        & .text {

            &__title, &__description {
                text-align: left!important;
            }
        }
    }

    @media screen and (max-width: 1440px) {

        & .item {
            flex-direction: row;
            margin: 0;

            &__text {

                &::before, &::after {
                    width: 1px;
                    right: unset;
                    left: calc(-56px - (1px / 2));
                }
            }
        }

        & .text {

            &__title, &__description {
                text-align: left;
            }
        }
    }

    @media screen and (max-width: 767px) {

        & .list {

        }

        & .item {
            flex-direction: column;
            align-items: center;
            margin: 0;

            &__icon {
                margin-top: 0;
                margin-bottom: 10px;
            }

            &__circle {
                margin-top: 0;
                margin-bottom: 9px;

                min-width: 37px;
                width: 37px;
                height: 37px;

                & > span {
                    display: block;
                }
            }

            &__text {

                &::before, &::after {
                    display: none;
                }
            }
        }

        & .text {

            &__title, &__description {
                text-align: center;
            }

            &__description {
                font-size: 0.89rem;
            }
        }
    }
}

.item {
    display: flex;
    column-gap: 32px;

    margin-left: calc(50% - (96px + 32px + (48px / 2)));

    &__icon {
        min-width: 96px;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background: var(--block_light_bg);

        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;

        & .material-symbols-rounded {

        }
    }

    &__circle {
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: var(--block_dark_bg);

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 30px;

        & > span {
            display: none;
            font-size: 0.89rem;
            font-weight: 700;
            line-height: 24px;
            color: var(--block_dark_text);
        }
    }

    &__text {

        position: relative;

        &::before, &::after {
            position: absolute;
            content: "";
            width: 3px;
            left: calc(-56px - (3px / 2));
            background: var(--block_dark_border);
        }

        &::before {
            top: -64px;
            height: calc(64px + 30px);
        }

        &::after {
            top: calc(30px + 48px);
            height: calc(100% - (30px + 48px));
        }
    }

    &:first-child {

        & .item__text {

            &::before {
                display: none;
            }
        }
    }

    &:last-child {

        & .item__text {

            &::after {
                display: none;
            }
        }
    }

    &--left {
        flex-direction: row-reverse;
        margin-left: 0;
        margin-right: calc(50% - (96px + 32px + (48px / 2)));

        @media screen and (max-width: 1440px) {

        }

        & .item {

            &__text {

                &::before, &::after {
                    left: unset;
                    right: calc(-56px - (3px / 2));

                    @media screen and (max-width: 1440px) {
                        right: unset;
                        left: calc(-56px - (1px / 2));
                    }
                }
            }
        }

        & .text {

            &__title, &__description {
                text-align: right;

                @media screen and (max-width: 1440px) {
                    text-align: left;
                }

                @media screen and (max-width: 767px) {
                    text-align: center;
                }
            }
        }
    }
}

.text {

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        text-align: left;
        margin-top: 0;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.22rem;
        font-weight: 700;
        margin-bottom: 8px;
        color: var(--title);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.78rem;
        font-weight: 400;
        line-height: 24px;
        color: var(--text);
        margin-bottom: 0;
    }
}

.content--l, .content--sm {

    & .list {

    }

    & .item {
        flex-direction: row;
        margin: 0;

        &__text {

            &::before, &::after {
                width: 1px;
                right: unset;
                left: calc(-56px - (1px / 2));
            }
        }
    }

    & .text {

        &__title, &__description {
            text-align: left;
        }
    }
}

.content--xs {

    & .list {

    }

    & .item {
        flex-direction: column;
        align-items: center;
        margin: 0;

        &__icon {
            margin-top: 0;
            margin-bottom: 10px;
        }

        &__circle {
            margin-top: 0;
            margin-bottom: 9px;

            min-width: 37px;
            width: 37px;
            height: 37px;

            & > span {
                display: block;
            }
        }

        &__text {

            &::before, &::after {
                display: none;
            }
        }
    }

    & .text {

        &__title, &__description {
            text-align: center;
        }

        &__description {
            font-size: 0.89rem;
        }
    }
}

.material-symbols-rounded {

    &--fill {
        font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
    }
}

</style>