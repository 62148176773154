import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './assets/css/style.css'
import './assets/css/bootstrap-grid.min.css'

import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/solarized-light.css'

import RenderStructure from "./views/RenderStructure.vue";

import VueApexCharts from "vue3-apexcharts";

import MasonryWall from '@yeger/vue-masonry-wall'

let app = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(VueHighlightJS)
    .use(MasonryWall)

app.component("RenderStructure", RenderStructure);

app.mount('#app')