<template>

    <div class="publication">
        <div class="publication__row publication-head">
            <span class="publication-head__label">Wydawnictwo</span>
            <span class="publication-head__date">
                    01.01.2022
                </span>
        </div>
        <div class="publication__row publication-name">
            Stan wdrażania Funduszy Europejskich w Polsce w latach 2014-2020:
            Lista umów z miejscami realizacji
        </div>
        <ul class="publication__row publication-footer">

            <a class="publication-footer__show-more">
                Zobacz szczegóły
            </a>

        </ul>
    </div>

</template>

<script>

import ArticleAbstract from '@/views/Article/Abstract.vue';

export default {
    name: "ArticleBlockPublication2",
    extends: ArticleAbstract,
}
</script>

<style scoped lang="scss">


.publication {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  text-align: left;
  justify-content: space-between;


  padding: 18px 12px 12px;

  border-radius: 12px 12px 12px 12px;

  max-width: 300px;
  height: 300px;

  &__row {

    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

  }

}

.publication-head {
  &__label {
    background-color: #F2F5FA;
    color: var(--main);

    border-radius: 6px;
    padding: 6px;

    margin-right: 12px;
    text-transform: uppercase;
  }

  &__date {
    opacity: 0.8;
  }
}

.publication-name {
  font-size: 1.2rem;
  font-weight: 600;
  //height: 75px;
  color: var(--main);

  line-height: 1.575rem;
  -webkit-line-clamp: 7;

  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.publication-footer {
  display: flex;
  flex-direction: row;
  padding: 0;

  list-style: none;
  font-size: 0.78rem;

  margin: auto 0 0;


  &__show-more {


    font-weight: 700;
    font-size: 0.85rem;
    line-height: 0.85rem;
    /* identical to box height, or 100% */

    letter-spacing: 0.12em;
    text-transform: uppercase;
    position: relative;

    color: var(--main);

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:before {
      position: absolute;
      content: '';
      right: -10px;
      height: 7px;
      width: 7px;

      border: 1.5px solid var(--main);
      transform: rotate(45deg);

      border-bottom: none;
      border-left: none;
    }
  }
}

</style>