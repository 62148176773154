<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "Alert",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    // Statuses: success, info, warring, error
                    // ----- version 1 -----
                    // status: "success",
                    // message: "Success! The action has been completed satisfactorily."
                    // ----- version 2 -----
                    // status: "info",
                    // message: "New update available. A new version for wireland has been released."
                    // ----- version 3 -----
                    // status: "warring",
                    // message: "New update available. A new version for wireland has been released."
                    // ----- version 4 -----
                    status: "error",
                    message: "Error! Something failed when trying to download the file, try again."
                }
            }
        }
    }
})
</script>

<template>
    <div class="message width-100-percent" :class="info.status">
        <div class="message__icon-container">
            <span class="material-symbols-outlined message__icon-status"
                  v-if="info.status === 'success'">check_circle</span>
            <span class="material-symbols-outlined message__icon-status" v-if="info.status === 'info'">info</span>
            <span class="material-symbols-outlined message__icon-status" v-if="info.status === 'warring'">report</span>
            <span class="material-symbols-outlined message__icon-status" v-if="info.status === 'error'">error</span>
        </div>
        <p class="message__text">{{ to(this.info, 'message') }}</p>
        <span class="material-symbols-outlined message__icon-close" style="display: none">close</span>
    </div>
</template>

<style scoped lang="scss">
.message {
    //width: 946px;
    max-height: 88px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    align-content: center;
    align-items: center;
    column-gap: 16px;
    border-radius: 5px;
    padding: 10px;

    &__icon-container {
        min-width: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background: #fff;
    }

    &__icon-close {
        margin-left: auto;
    }

    &__icon-status {
        color: white;
    }

    &__text {
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin: 0;
    }
}

.success {
    //border: 1px solid #00AE7D;
    color: rgba(0, 32, 67, 1);
    background-color: rgba(148, 193, 31, 1);

    .message__icon-container {
        background: rgba(0, 32, 67, 1);

        & .material-symbols-outlined {
            color: rgba(148, 193, 31, 1);
        }
    }
}

.info {
    //border: 1px solid #238FCD;
    color: rgba(0, 32, 67, 1);
    background-color: rgba(0, 149, 219, 1);

    .message__icon-container {
        background: rgba(0, 32, 67, 1);

        & .material-symbols-outlined {
            color: rgba(0, 149, 219, 1);
        }
    }
}

.warring {
    //border: 1px solid #FFB30F;
    color: rgba(0, 32, 67, 1);
    background-color: rgba(251, 185, 0, 1);

    .message__icon-container {
        background: rgba(0, 32, 67, 1);

        & .material-symbols-outlined {
            color: rgba(251, 185, 0, 1);
        }
    }
}

.error {
    //border: 1px solid #E60651;
    color: #fff;
    background-color: rgba(155, 19, 42, 1);

    .message__icon-container {
        background: #fff;

        & .material-symbols-outlined {
            color: rgba(155, 19, 42, 1);
        }
    }
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
</style>