<script>
import ShowImage from "@/Library/Show/Image.vue";
import ShowFiles from "@/Library/Show/Files.vue";
import ShowAudio from "@/Library/Show/Audio.vue";
import ShowVideo from "@/Library/Show/Video.vue";
import ShowGallery from "@/Library/Show/Gallery.vue";
import Element from "@/components/Element.vue";

export default {
  name: "ArticleContentPart",
  components: {ShowGallery, ShowVideo, ShowAudio, ShowFiles, ShowImage},
  extends: Element,
  props: {
    part: {
      default() {
        return {};
      }
    }
  }
}
</script>

<template>


  <template v-if="part.type === 'text'">
    <span v-html="to(part, 'text')"/>
  </template>

  <template v-if="part.type === 'code'">
    <code v-html="to(part, 'text')"/>
  </template>

  <template v-if="part.type === 'image'">
    <a :href="part.thumb_link">
      <ShowImage :image="part.thumb"/>
    </a>
  </template>

  <template v-if="part.type === 'audio'">
    <ShowAudio :audio="part.audio_url"/>
  </template>

  <template v-if="part.type === 'video'">
    <ShowVideo :video="part.video_show_url"/>
  </template>

  <template v-if="part.type === 'gallery'">
    <ShowGallery :gallery="part.gallery_show" :can_zooming="true"/>
  </template>

  <template v-if="part.type === 'files'">
    <ShowFiles :files="part.files"/>
  </template>

</template>

<style scoped lang="scss">

</style>