<template>
    <div class="testimonial">
        <div class="testimonial__text">
            Inwestujemy Fundusze Europejskie w rozwój Polski. Ustawa wdrożeniowa pozwoli efektywnie wykorzystywać
            przyznane Polsce fundusze europejskie. Wprowadza mechanizmy koordynacji programów współfinansowanych z
            funduszy strukturalnych, Funduszu na rzecz Sprawiedliwej Transformacji i Funduszu Spójności. Zawiera także
            szczegółowe przepisy dotyczące Krajowego Planu Odbudowy
        </div>
        <div class="testimonial__user testimonial-user">
            <div class="testimonial-user__thumb">

            </div>
            <div class="testimonial-user__name testimonial-name">
                <span class="testimonial-name__name">mówi Jan Nowak</span>
                <span class="testimonial-name__function">
Minister Funduszy i Polityki Regionalnej
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TestimonialBlock"
}
</script>

<style scoped lang="scss">

.testimonial {
  position: relative;
  padding: 50px 0 0 50px;

  display: flex;
  flex-direction: column;

  text-align: left;

  &:before {
    position: absolute;
    content: '';
    background-image: url('data:image/svg+xml, <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.23493 20.449C12.7947 15.5057 19.6944 13 28.7392 13H31.9892V22.1617L29.3762 22.685C24.9237 23.5755 21.8264 25.3272 20.1689 27.898C19.3041 29.2829 18.8136 30.8686 18.7454 32.5H28.7392C29.6011 32.5 30.4278 32.8424 31.0373 33.4519C31.6468 34.0614 31.9892 34.888 31.9892 35.75V58.5C31.9892 62.0848 29.0739 65 25.4892 65H5.98919C5.12723 65 4.30058 64.6576 3.69109 64.0481C3.0816 63.4386 2.73919 62.612 2.73919 61.75V45.5L2.74894 36.0133C2.71969 35.6525 2.10219 27.105 8.23493 20.449ZM61.2392 65H41.7392C40.8772 65 40.0506 64.6576 39.4411 64.0481C38.8316 63.4386 38.4892 62.612 38.4892 61.75V45.5L38.4989 36.0133C38.4697 35.6525 37.8522 27.105 43.9849 20.449C48.5447 15.5057 55.4444 13 64.4892 13H67.7392V22.1617L65.1262 22.685C60.6737 23.5755 57.5764 25.3272 55.9189 27.898C55.0541 29.2829 54.5636 30.8686 54.4954 32.5H64.4892C65.3511 32.5 66.1778 32.8424 66.7873 33.4519C67.3968 34.0614 67.7392 34.888 67.7392 35.75V58.5C67.7392 62.0848 64.8239 65 61.2392 65Z" fill="white"/></svg>');
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
    font-size: 10rem;

    left: 0;
    top: 0;

    color: red;
  }


  &__user {
    padding-top: 1.4rem;
  }

  &__text {

    position: relative;
    z-index: 2;

    font-weight: 400;

    font-size: 1.35rem;
    line-height: 2rem;

    padding-bottom: 2rem;
  }


}

.testimonial-user {
  display: flex;
  flex-direction: row;

  &__thumb {
    margin-right: 1.2rem;
    width: 60px;
    height: 60px;
    background-color: #eee;
  }

  &__name {
  }
}

.testimonial-name {

  display: flex;
  flex-direction: column;

  &__name {
    margin-top: 1rem;
  }

  &__function {
  }
}

</style>