<script>
import {Carousel, Navigation, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Element from "@/components/Element.vue";

export default {
    name: "Testimonial2",
    extends: Element,
    components: {
        Carousel,
        Slide,
        Navigation
    },
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            image: {
                                url: "https://picsum.photos/500/250"
                            },
                            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni doloremque esse enim accusamus rerum dolorum, delectus rem odit aliquid animi ipsam excepturi quam beatae, quisquam corporis fugit facilis suscipit laboriosam natus molestiae earum ea voluptate!"
                        },
                        {
                            image: {
                                url: "https://picsum.photos/500/255"
                            },
                            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore id quo odit esse, et cupiditate fuga odio vel eos neque inventore numquam nesciunt ad saepe magni autem? Id dolore totam deleniti assumenda atque, adipisci laudantium!"
                        },
                        {
                            image: {
                                url: "https://picsum.photos/500/251"
                            },
                            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas quas commodi, dolore corrupti iure eaque optio excepturi repellat. Pariatur mollitia alias impedit blanditiis dolorum autem vitae. Iusto, reiciendis? Fugit, nesciunt exercitationem consequatur quod aperiam ullam."
                        },
                        {
                            image: {
                                url: "https://picsum.photos/500/256"
                            },
                            description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste reiciendis omnis itaque quas nemo aut culpa, facilis minima, sequi assumenda dolor dolore quis architecto amet incidunt. Magnam ut, voluptatem delectus autem iure beatae atque eveniet."
                        },
                        {
                            image: {
                                url: "https://picsum.photos/500/252"
                            },
                            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias vero ipsa eum fugiat laborum nostrum a quis aut iste saepe in fugit veritatis dicta recusandae laudantium, officiis nesciunt culpa tenetur quas consequatur labore eius nemo."
                        },
                        {
                            image: {
                                url: "https://picsum.photos/500/257"
                            },
                            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui officiis magni quis commodi vero suscipit quo amet iusto molestiae ducimus natus fugit, voluptate quisquam eos explicabo accusamus adipisci ab asperiores. Saepe aliquam quae deleniti a?"
                        },
                    ]
                }
            }
        }
    },
    data: () => ({
        current_index: 0,

        items_to_show: 3
    }),
    methods: {
        handleSlideStart(data) {
            this.current_index = data.currentSlideIndex
        },

        imgStyle(item) {
            let ret = {}

            ret.width = item.width ? item.width+'px' : 100
            ret.height = item.height ? item.height+'px' : 100
            ret.objectFit = item.fit_content ? item.fit_content : "unset"

            return ret
        },

        updateItemsToShow() {
            switch (this.screenWidthStr) {
                case 'xs':
                    this.items_to_show = 1
                    break;
                case 'sm':
                    this.items_to_show = 2
                    break;
                case 'l':
                    this.items_to_show = 2
                    break;
                case 'xl':
                    this.items_to_show = 3
                    break;
            }
        }
    },
    computed: {
        currentDescription() {
            let current_item = this.info.items[this.current_index]

            return this.parseText(current_item, "description")
        }
    },
    watch: {
        'screenWidthStr': {
            handler(newValue, oldValue) {
                this.updateItemsToShow()
            },
            deep: true
        }
    }
}
</script>

<template>

    <div class="logos no-width-100-percent">
        <div class="logos__carousel">
            <Carousel :items-to-show="items_to_show" :wrap-around="true" @slide-start="handleSlideStart" ref="carousel">
                <Slide v-for="(item, index) in info.items" :key="index" style="width: auto!important;">
                    <a class="carousel__item" :href="item.site_link" target="_blank" title="Logo link">
                        <img :src="item.image?.url" alt="logo" :style="imgStyle(item)"/>
                    </a>
                </Slide>

                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>

        <p class="logos__description" v-html="currentDescription"></p>
    </div>

</template>

<style lang="scss">

.carousel {

    &__prev, &__next {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 6px;

        & > svg {
            transition: all 0.25s ease 0s;
            fill: #fff;
        }
    }

    &__prev {

        &:hover {

            & > svg {
                animation: animPrev 0.5s;
            }
        }
    }

    &__next {

        &:hover {

            & > svg {
                animation: animNext 0.5s;
            }
        }
    }
}

@keyframes animPrev {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-4px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes animNext {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(4px);
    }
    100% {
        transform: translateX(0);
    }
}

</style>

<style scoped lang="scss">

.carousel__item {
    display: block;
    text-decoration: none;
    margin: 10px 15px;

    & > img {
        width: 100%;
    }
}

.logos {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 767px) {

    }

    &__carousel {

    }

    &__description {
        font-family: var(--text_font);
        font-style: normal;
        font-size: 1.11rem;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: var(--text);

        margin: 0 50px;

        @media screen and (max-width: 1440px) {

        }

        @media screen and (max-width: 767px) {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .logos {
        row-gap: 40px;

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

</style>