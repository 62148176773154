<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "News3",
    extends: Element,
    computed: {
        image() {
            return this.obj.thumb;
        },
        lead() {
            return this.obj.lead;
        },
        category_name() {
            return this.obj.category_name && this.obj.category_name !== "" ? this.obj.category_name : null;
        },
        lang() {
            return localStorage.getItem('lang')
        },
    },
    props: {
        obj: {
            default() {
                return {}
            }
        },
        slugs: {
            default() {
                return []
            }
        },
    }
})
</script>

<template>

    <router-link :to="slugs[lang] ?? './'" class="item">
        <div class="item__image" :style="{backgroundImage: `url(${image})`}"></div>

        <div class="item__content content">
            <h1 class="content__title three-dots three-dots--2">{{ to(obj, 'title') }}</h1>
            <p class="content__category" v-if="category_name">{{ to(obj, "category_name") }}</p>
        </div>
    </router-link>

</template>

<style scoped lang="scss">

.item {
    height: 100%;
    text-decoration: none;
    display: block;

    &__image {
        height: 236px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__content {

    }
}

.content {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    height: calc(100% - 236px);

    &__title, &__category {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 14px;
        color: var(--link);
    }

    &__category {
        font-family: var(--text_font);
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.7px;
        padding: 6px;
        border-radius: 2px;
        color: var(--block_dark_text);
        background: var(--block_dark_bg);
        width: fit-content;
        margin-bottom: 0;

        margin-top: auto;
    }
}

</style>