<script>
import {defineComponent} from 'vue'
import Hr1 from "@/views/Base/Hr/Hr1.vue";
import MenuInline from "@/views/Base/Menu/Inline.vue";
import SocialList from "@/views/Base/Social/List.vue";

export default defineComponent({
  name: "LayoutFooterBelow",
  components: {SocialList, MenuInline, Hr1}
})
</script>

<template>

  <div class="footer-bellow">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <img src="../assets/img/eu-footer.svg" style="max-width: 100%">
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          Portal współfinansowany przez Unię Europejską ze środków Europejskiego Funduszu Rozwoju Regionalnego oraz Funduszu Spójności w ramach Programu Pomoc Techniczna.
          <br>
          © Uniwersytet Śląski w Katowicach
        </div>
        <div class="col-12 col-lg-6">
          <img src="@/assets/img/logo.png"
               style="float:right; max-width: 100%; width: 250px;"/>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped lang="scss">

.footer-bellow{
  padding: 20px 0 55px;
}

</style>