<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Sections",
  props: {
    info: {
      default() {
        return {
          sections: [{
            title: "Photo camera",
            options: [{
              name: "Set 1"
            }, {
              name: "Set 2"
            }, {
              name: "Set 3"
            }, {
              name: "Set 4"
            }]
          }, {
            title: "Computer",
            options: [{
              name: "PC 1"
            }, {
              name: "PC 2"
            }, {
              name: "PC 3"
            }, {
              name: "PC 4"
            }]
          }, {
            title: "Item",
            options: [{
              name: "Sub item 1"
            }, {
              name: "Sub item 2"
            }, {
              name: "Sub item 3"
            }, {
              name: "Sub item 4"
            }]
          }, {
            title: "Section",
            options: [{
              name: "Option 1"
            }, {
              name: "Option 2"
            }, {
              name: "Option 3"
            }, {
              name: "Option 4"
            }]
          }]
        }
      }
    }
  },
  data: () => ({
    sections: [],
  }),
  methods: {
    optionClick(section, sections) {
      let state = section.open;

      sections.map((item) => {
        item.open = false;
      });

      if (state === true){
        section.open = false;
      }
      if (state !== true){
        section.open = true;
      }
    }
  },
  mounted() {
    this.sections = [...this.info.sections];
  }
})

</script>

<template>
  <div class="sections-container">
    <div class="sections-container__item-container">
      <div class="sections-container__item item" v-for="section in sections" :key="section.title" @click="optionClick(section, sections)">
        <h2 class="item__header">{{section.title}}</h2>
        <span class="material-symbols-outlined item__icon" style="user-select: none" v-if="section.open !== true">arrow_forward_ios</span>
        <span class="material-symbols-outlined item__icon" style="user-select: none" v-if="section.open === true">arrow_back_ios</span>
      </div>
    </div>

    <div class="sections-container__options options" :class="{ 'options__option-open': section.open === true }" v-for="section in sections" :key="section.title">
      <div class="options__option-item" v-for="option in section.options" :key="option.name">
        <h2 class="option-item__header">{{option.name}}</h2>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sections-container{
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 15px;

    &__item-container{
      min-width: 270px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;

      .item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding: 0 3%;

        &__header{
          font-size: 0.78rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: var(--title);
        }

        &__icon{
          color: var(--icon);
        }
      }
    }

    .options{
      display: none;
      background-color: var(--block_light_bg);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      &__option-open{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__option-item{
        padding: 0 3%;
      }

      .option-item__header{
        font-size: 0.78rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--title);
      }
    }
  }
</style>