<script>
import Element from "@/components/Element.vue";

export default {
    name: "Image",
    props: {
        info: {
            default() {
                return {};
            }
        }
    },
    extends: Element,
    computed: {
        url() {
            return this.info && this.info.image ? this.info.image.url : null;
        },

        link() {

            return this.info.site_link?.url;
        },

        linkHas() {

            let url = this.link;

            return url !== undefined && url !== null;

        },

        imgStyle() {
            let ret = {}

            ret.width = this.info.width ? this.info.width + 'px' : 100
            ret.height = this.info.height ? this.info.height + 'px' : 100
            ret.objectFit = this.info.fit_content ? this.info.fit_content : "unset"

            if (this.info.style !== "free")
                ret.height = ret.width

            if (this.info.style === "circle")
                ret.borderRadius = "50%"

            if (!this.info.width)
                ret.maxWidth = "100%"

            return ret
        }
    }
}
</script>

<template>

    <router-link class="link" v-if="linkHas" :to="$root.routerPrefix + link">
        <img :src="url" :style="imgStyle" class="image" :alt="info.alt"/>
        <span class="screen-reader-only">(otwiera w nowym oknie)</span>
    </router-link>

    <img v-if="!linkHas" :src="url" :style="imgStyle" class="image" :alt="info.alt"/>

    <p class="image-text" v-if="to(info, 'sub_text')">{{ to(info, 'sub_text') }}</p>
</template>

<style scoped lang="scss">

.screen-reader-only {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
}

.link {
    cursor: pointer;
}

.image {
    max-width: 100%;
}

.image-text {
    font-family: var(--text_font);
    //position: absolute;
    //left: 0;
    //bottom: 0;
    padding: 0 5px;
    //background: var(--block_dark_bg);
    color: var(--text);
    margin: 0;
    width: 100%;
    //text-align: left;
    font-size: 0.67rem;
}
</style>