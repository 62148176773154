import axios from "@/axios";

export const app = {
    state: () => ({
        temp: JSON.parse(localStorage.getItem("temp")) ? JSON.parse(localStorage.getItem("temp")) : {},
        pass_tab: JSON.parse(localStorage.getItem("pass_tab")) ? JSON.parse(localStorage.getItem("pass_tab")) : {},
        lang: localStorage.getItem("lang") || "pl",
        config: JSON.parse(localStorage.getItem('config')) || {},
        domain_id: localStorage.getItem('domain_id') || null,
        domain_info: JSON.parse(localStorage.getItem('domain_info')) || {},
        domain_obj: JSON.parse(localStorage.getItem('domain_obj')) || {},

        initial: JSON.parse(localStorage.getItem('initial')) || {},
        view: JSON.parse(localStorage.getItem('view')) || {},
        push: localStorage.getItem('push') || 'yes',
        cookie: localStorage.getItem('cookie') || 'waiting',
        stat_user_id: localStorage.getItem('stat_user_id') || null,
        application_server_public_key: localStorage.getItem('application_server_public_key') || null,

        lang_arr: JSON.parse(localStorage.getItem('lang_arr')) || {},
    }),
    actions: {

        domainInfoSet({commit}, {data}) {

            localStorage.setItem('domain_info', JSON.stringify(data));
            commit('setDomainInfo', data);

        },

        passTabAdd({commit}, {data}) {

            let key = 'pass_tab';

            let pass_tab = JSON.parse(localStorage.getItem(key)) || {};
            pass_tab[data.path] = data.pass;

            localStorage.setItem(key, JSON.stringify(pass_tab));
            commit('setPassTab', pass_tab);

        },

        domainObjSet({commit}, {data}) {

            localStorage.setItem('domain_obj', JSON.stringify(data));
            commit('setDomainObj', data);

        },

        viewSet({commit}, {data}) {

            console.log(data)

            commit('setView', data);

        },

        async domainIdSet({commit}, {data}) {

            /**
             * Aktualizacja tokena w domyślnych ustawieniach axios'a
             */
            axios.defaults.headers['Domain-Id'] = data;
            axios.defaults.headers.get['Domain-Id'] = data;
            axios.defaults.headers.common['Domain-Id'] = data;

            commit("setDomainId", data);

        },

        async getApplicationServerPublicKey({commit}) {


            await axios
                .get('base/pushpublickkey')
                .then(response => {

                    let data = response.data.data;
                    let application_server_public_key = data.key;

                    localStorage.setItem('application_server_public_key', application_server_public_key);
                    commit('application_server_public_key', application_server_public_key);

                });
        },

        async pushSet({commit, push}) {

            localStorage.setItem('push', push);
            commit('push', push);

        },

        async init({commit, dispatch}) {

            await axios
                .get('base/init')
                .then(response => {

                    let data = response.data;


                    let config = data.config;
                    let initial = data.initial;
                    // let modules = data.modules;
                    // let menu = data.menu;

                    localStorage.setItem('config', JSON.stringify(config));
                    commit('config', config);

                    localStorage.setItem('initial', JSON.stringify(initial));
                    commit('initial', initial);
                    //
                    // localStorage.setItem('modules', JSON.stringify(modules));
                    // commit('modules', modules);
                    //
                    // localStorage.setItem('menu', JSON.stringify(menu));
                    // commit('menu', menu);

                })
                .catch(error => {

                    let error_data = error.response.data;
                    let error_status = error.response.status;
                    let error_header = error.response.headers;
                    let error_code = error_data.code;

                    console.log(error_data);
                    console.log(error_status);
                    console.log(error_header);

                    switch (error_code) {
                        case 'no-login':
                        case 'token-expired':
                            dispatch('user/logout', null, {root: true})
                            break;
                    }


                });

            dispatch('langArrLoad')
        },

        cookieAccept({commit}, data) {

            localStorage.setItem("cookie", "yes");

            commit("setCookie", "yes");

        },

        cookieReject({commit}, data) {

            localStorage.setItem("cookie", "no");

            commit("setCookie", "no");

        },

        statUserIdSet({commit}, data) {


            localStorage.setItem("statUserId", data);

            commit("setStatUserId", data);

        },

        /**
         * Aktualizowanie obiektu "temp" w localStorage
         * @param commit pozwala zmieniać zmienne w "state"
         * @param data dane do zaktualizowania
         */
        setTempToStorage({commit}, data) {

            localStorage.setItem("temp", JSON.stringify(data));

            commit("setTemp", data);
        },

        /**
         * Aktualizowanie obiektu "lang" w localStorage
         * @param commit pozwala zmieniać zmienne w "state"
         * @param lang język do zaktualizowania
         */
        setLangToStorage({commit}, lang) {

            localStorage.setItem("lang", lang);

            commit("setLang", lang);
        },

        /**
         * Pobieranie i zapisywanie do localStorage nazw systemowych w różnych językach
         * @param commit pozwala zmieniać zmienne w "state"
         */
        langArrLoad({commit}) {


            let url = "base/lang";

            let params = {
                scope: 'code,lang,text'
            };

            axios
                .get(url, {params: params})
                .then((response) => {

                    let data = response.data.data;

                    let data_transformed = {}

                    data.map(element => {
                        if (data_transformed[element.lang] === undefined)
                            data_transformed[element.lang] = {}

                        data_transformed[element.lang][element.code] = element.text
                    })


                    localStorage.setItem('lang_arr', JSON.stringify(data_transformed));
                    commit('setLangArr', data_transformed);
                })
        },
    },
    mutations: {

        setView(state, data) {
            state.view = data;
        },

        setPassTab(state, data) {
            state.pass_tab = data;
        },

        setDomainInfo(state, data) {
            state.domain_info = data;
        },

        setDomainObj(state, data) {
            state.domain_obj = data;
        },

        setDomainId(state, data) {
            state.domain_id = data;
        },

        setLangArr(state, data) {
            state.lang_arr = data;
        },

        setCookie(state, cookie) {
            state.cookie = cookie;
        },

        setStatUserId(state, stat_user_id) {
            state.stat_user_id = stat_user_id;
        },

        setLang(state, lang) {
            state.lang = lang;
        },

        setTemp(state, data) {
            state.temp = data;
        },

        config(state, config) {
            state.config = config;
        },

        initial(state, initial) {
            state.initial = initial;
        },

        application_server_public_key(state, application_server_public_key) {
            state.application_server_public_key = application_server_public_key;
        },

        push(state, push) {
            state.push = push;
        }
    },
    getters: {
        getLangName(state) {
            if (state.lang === 'pl')
                return "Polski"
            if (state.lang === 'en')
                return "Angielski"
            if (state.lang === 'de')
                return "Niemiecki"
        }
    },
    namespaced: true
}