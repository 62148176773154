<script>

import Element from "@/components/Element.vue";
export default {
  name: "Text",
  extends: Element,
  props: {
    info: {
      default() {
        return {};
      }
    }
  },
    computed: {
        text() {

            let ret = "";

            if (this.info){
                ret = this.to(this.info, 'text');
            }

            return ret;
        }
    }
}
</script>

<template>
    <div class="inner-text" :style="{color: info.text_color}" v-html="text"></div>
</template>

<style lang="scss">
.inner-text {
    & img {
        max-width: 100%;
    }
}
</style>