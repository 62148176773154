<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "Image3",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          image: "https://picsum.photos/200/300",
          header: "Lorem ipsum dolor sit amet consectetur",
          description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation."
        }
      }
    },
  }
})
</script>

<template>
  <div class="events-v2__container">
    <div class="events-v2-container__events events">
      <img class="events__image" :src="info.image.url" alt="Main image for event" />

      <h2 class="events__header">{{to(this.info, 'header')}}</h2>
      <p class="events__description">{{to(this.info, 'description')}}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.events-v2__container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .events {
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    margin: 2% 0;

    &__image {
      width: 650px;
      max-width: 100%;
      height: 367px;
      object-fit: cover;
    }

    &__header {
      font-size: 1.56rem;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.10080009698867798px;
      text-align: center;
      color: var(--title);
      margin: 1.5rem 0 0.5rem 0;
    }

    &__description {
      font-size: 1.33rem;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0;
      text-align: center;
      color: var(--text);
      margin: 0;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 839px) {
  .events{
    &__image {
      max-width: 90%;
      max-height: 300px;
    }

    &__description {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 20px!important;
    }
  }
}
</style>