<template>
    <h2 class="title" v-html="to(this.info, 'title')"></h2>
</template>

<script>

import TitleAbstract from "@/views/Base/Title/Abstract.vue";

export default {
    name: "Title1",
    extends: TitleAbstract,
    props: {

      info: {

        default() {
          return {};
        }
      },
      title: {

        default() {
          return "";
        }
      },
      color: {

        default() {
          return "var(--text)";
        }
      },
      color_border: {

        default() {
          return "var(--main_color)";
        }
      }
    }
}
</script>

<style scoped lang="scss">

.title {
  margin-bottom: 2rem;
  line-height: 2rem;
  font-size: 1.6rem;
  font-weight: 600;

  text-align: left;

  color: v-bind(color);

  position: relative;

  padding-bottom: 0.7rem;
    margin-top: 2rem;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 2px;
    width: 144px;
    background-color: v-bind(color_border);

  }
}

</style>