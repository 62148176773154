<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "BlockNewsV2",
  extends: Element,
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    info: {
      default() {
        return {}
      }
    }
  }
})
</script>

<template>
  <div class="news__content content">
    <div class="content__item item">
      <img class="item__image" v-if="info.image" :src="info.image.url" alt="News image" />
      <p class="item__description">{{to(info, 'description')}}</p>

      <div class="item__category-container">
        <p class="item__category-first">{{to(info, 'categoryFirst')}}</p>
        <p class="item__category-second">{{to(info, 'categorySecond')}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__image{
      max-height: 400px;
      max-width: 100%;
      margin: 0 auto;
    }

    &__description,
    &__category-first,
    &__category-second{
      font-size: 0.89rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin: 3%;
      color: var(--text);
    }

    &__category-container{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
    }
  }
}
</style>