<template>

    <div class="menu-mobile" :class="{'menu-mobile--show': show}" @click="$emit('emitMenuMobileWrapper', 'close')">
        <div class="menu-mobile__container" @click.stop>
            <div class="menu-mobile__head">
                <div class="menu-mobile-close" @click="$emit('emitMenuMobileWrapper', 'close')">
                    <span class="material-symbols-outlined">close</span>
                </div>
            </div>
            <div class="menu-mobile__content">
                <div class="menu-mobile__menu">
                    <SiteMenuStyleMobile
                        :items="menu_items"
                        @emitMenuMobile="menuMobileHandler"
                        @updateSimulate="(slug, url) => $emit('updateSimulate', slug, url)"/>
                </div>
                <div class="menu-mobile__plugins">

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SiteMenuStyleMobile from "@/views/Base/Menu/SiteMenuStyleMobile.vue";

export default {
    name: "SiteMenuStyleMobileWrapper",
    components: {
        SiteMenuStyleMobile
    },
    emits: ["emitMenuMobileWrapper", "updateSimulate"],
    props: {
        show: {
            default() {
                return false
            }
        },
        menu_items: {
            default() {
                return null
            }
        },
    },
    methods: {
        /**
         * Obsługa kliknięć otrzymanych z komponentu "MenuMobile"
         */
        menuMobileHandler(item, func) {
            if (func === "toggle")
                this.menu_items.find(obj => obj.id === item.id).open = !this.menu_items.find(obj => obj.id === item.id).open

            if (func === "close")
                this.$emit("emitMenuMobileWrapper", "close")
        },
    }
}
</script>

<style scoped lang="scss">

.menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease 0s;
    z-index: 99999;

    display: flex;
    justify-content: end;

    &--show {
        opacity: 1;
        visibility: visible;

        & > .menu-mobile__container {
            transform: translateX(0);
        }
    }

    &__container {
        box-shadow: 0 10px 15px rgba(25, 25, 25, .1);

        width: 300px;
        height: 100%;
        background-color: var(--bg);
        transition: all 0.25s ease 0s;
        transform: translateX(100%);
    }

    &__head {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 15px 28px 0 15px;
    }

    &__content {
        padding: 15px;
        height: calc(100% - 54px);
        overflow: auto;
    }
}

.menu-mobile-close {

    display: flex;
    cursor: pointer;

    & .material-symbols-outlined {
        color: var(--link);
    }
}

</style>