<script>

import axios from "@/axios";
import AbstractList from "@/views/Abstract/List.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";
import ControllError from "@/Library/Controll/Error.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import Alert from "@/Library/Components/Alert.vue";
import Calendar from "@/Library/Components/Calendar.vue";
import ArticleEventBlock from "@/views/Article/EventBlock.vue";

export default {
    name: "ArticleEventFull",
    components: {Calendar, Alert, MenuDropdown, ArticleEventBlock},
    extends: AbstractList,
    mixins: [ComponentFormStateMachine, ControllError],
    data: () => ({
        items: [],

        pars: {},

        sort: 'date ASC',
        sort_types: [
            {name: 'Od najnowszych', value: 'date DESC'},
            {name: 'Od najstarszych', value: 'date ASC'},
            {name: 'Alfabetycznie', value: 'title ASC'}
        ],

        get_url: 'article/get',
        get_params: {
            event: "yes",
            version: 'no',
            scope: 'title,event_date_start,event_date_end,slug,slugs,thumb,langs,lead,urls'
        }
    }),
    methods: {
        async refresh(config = {}) {

            if (!config.set_range) {
                this.get_params["event_date_start_from"] = this.getFirstAndLastDayOfMonth().firstDay
                this.get_params["event_date_start_to"] = this.getFirstAndLastDayOfMonth().lastDay
            }

            this.stateUpdate("sendInfo")

            let params = {...this.get_params, ...this.config};
            params['sort'] = this.sort;

            let response = await axios
                .get(this.get_url, {params: params})
                .then(r => r.data);
            this.items = response.data;
            this.count = response.count;

            this.stateUpdate("sendSuccess")
        },

        getFirstAndLastDayOfMonth() {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth();

            // Ustalenie pierwszego dnia aktualnego miesiąca
            const firstDay = new Date(year, month, 1);
            const formattedFirstDay = this.formatDate(firstDay);

            // Ustalenie ostatniego dnia aktualnego miesiąca
            const lastDay = new Date(year, month + 1, 0);
            const formattedLastDay = this.formatDate(lastDay);

            return {firstDay: formattedFirstDay, lastDay: formattedLastDay};
        },

        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        getCalendarDay(day) {
            let date = this.formatDate(day.fullDate)

            this.get_params["event_date_start_from"] = date
            this.get_params["event_date_start_to"] = date

            this.refresh({set_range: true})
        },

        getCalendarRange(range) {
            let date_start = this.formatDate(range.start)
            let date_end = this.formatDate(range.end)

            this.get_params["event_date_start_from"] = date_start
            this.get_params["event_date_start_to"] = date_end

            this.refresh({set_range: true})
        }
    },
    watch: {
        page: {
            handler() {
                this.refresh();
            }
        }
    },
    async mounted() {
        // this.get_params["event_date_start_from"] = this.getFirstAndLastDayOfMonth().firstDay
        // this.get_params["event_date_start_to"] = this.getFirstAndLastDayOfMonth().lastDay

        // await this.refresh();
    },
    beforeRouteUpdate(to, from, next) {
        if (to.path === this.$route.path) {
            this.loadUri(to.query);
        }
        next();
    },
}
</script>

<template>

    <div class="list">
        <div class="list__head head">
            <div class="head__sort">
                <MenuDropdown :name="t('sort')" :items="sort_types" :selected="sort" @click="updateSort"/>
            </div>
        </div>

        <div class="list__body body" :class="{'loading': stateLoading()}">
            <div class="body__calendar">
                <Calendar calendar-type="month" @selectDay="getCalendarDay" @selectRange="getCalendarRange"/>
            </div>
            <div class="body__content content">
                <div class="content__objs objs">
                    <ArticleEventBlock v-for="item in items" :item="item"/>
                </div>
                <div class="content__alert">
                    <Alert :text="t('none')" label="" type="info" v-if="!stateLoading() && items.length === 0"/>
                </div>
            </div>
        </div>
    </div>


</template>

<style scoped lang="scss">

.list {
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    width: 100%;

    &__head {
        margin-bottom: 16px;
    }

    &__body {

    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: end;

    &__sort {

    }
}

.body {
    display: flex;
    column-gap: 16px;

    &__calendar {
        width: 400px;
    }

    &__content {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        &__calendar {
            width: 100%;
        }
    }
}

.content {

    &__objs {

    }

    &__alert {

    }
}

.objs {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.content--xs {

    & .body {
        flex-direction: column;

        &__calendar {
            width: 100%;
        }
    }
}

</style>