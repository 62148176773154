<script>
import {defineComponent} from 'vue'
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "Space",
  extends: Element,
  props: {
    height: {
      default() {
        return '0';
      }
    },
  },

  computed: {
    getStyle(){

      let params = {};
      params['height'] = this.height+'px';

      return params;

    },
  }

})
</script>

<template>
  <div class="space" :style="getStyle"></div>
</template>

<style lang="scss">

.space{
  width: 100%;
  clear: both;
}

</style>