<script>
import Dropdown from "@/Library/Components/Dropdown.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import {mapActions, mapState} from "vuex";
import Element from "@/components/Element.vue";

export default {
    name: "LangSelect",
    components: {
        MenuDropdown,
        Dropdown
    },
    extends: Element,
    data: () => ({}),
    computed: {

        ...mapState('app', ['lang', 'domain_info', 'view']),
        ...mapState({
            app_config: state => state.app.config
        }),

        langName() {

            let langObj = this.items.find(item => item.value === this.lang);

            return langObj ? langObj.name : '';

        },

        items() {
            let ret = []

            if (this.app_config.base_lang_public_options)
                ret = this.app_config.base_lang_public_options

            return ret
        },

        urls() {

            return this.view.urls !== undefined ? this.view.urls : {};

        },
    },

    methods: {

        ...mapActions('app', ['setLangToStorage']),

        langClick(val) {

            let domain_info = this.domain_info;
            let url_base = domain_info.url_base;

            let url = url_base + val + '/';

            if (this.urls[val])
                url += this.urls[val];

            this.setLangToStorage(val);

            window.location.href = url;


        },

    }
}
</script>

<template>

    <MenuDropdown v-if="items.length > 1" :name="t('language')" :items="items" :selected="lang" @click="langClick"/>

    <!--  <MenuDropdown :name="langName" :items="items" v-if="items.length > 1" :selected="'pl'" @click="langClick"/>-->
</template>

<style scoped lang="scss">

</style>