<template>
  <h2 class="title" :class="{...sizeClass}">
    {{ to(info,'title') }}
  </h2>

</template>

<script>

import TitleAbstract from "@/views/Base/Title/Abstract.vue";

export default {
  name: "Title2",
  extends: TitleAbstract,
  props: {
    info: {
      default() {
        return {};
      }
    },
    title: {
      default() {
        return "";
      }
    },
    size: {
      default() {
        return "l";
      }
    },
  },
  computed: {
    sizeClass() {

      let ret = '';

      if (this.size === 's')
        ret = {'title--s': true};

      if (this.size === 'm')
        ret = {'title--m': true};

      if (this.size === 'l')
        ret = {'title--l': true};

      if (this.size === 'xl')
        ret = {'title--xl': true};

      return ret;

    },
  }

}
</script>

<style scoped lang="scss">

.title {
  font-weight: 700;
  &--s{

    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  &--m{}
  &--l{
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  &--xl{}
}

</style>