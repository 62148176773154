<template>
    <span v-if="noData" class="grey-text">Brak</span>
    <a :href="'https://www.google.com/maps/place/'+show_city+' '+show_street+' '+show_building_number" target="_blank" v-if="!noData">
        ul. {{ show_street }} {{ show_building_number }}<span
            v-if="show_apartment_number">/{{ show_apartment_number }}</span><span v-if="!two_lines">,</span>
        <br v-if="two_lines">
        {{ show_zip_code }} {{ show_city }}
        <br v-if="show_country || show_region ">
        <span v-if="show_country  || show_region ">{{ show_region }}, {{ show_country }}</span>
    </a>
</template>

<script>
export default {
    name: "ShowLocation",
    props: {
        data: {
            default() {
                return undefined;
            }
        },
        street: {
            type: String,
            default() {
                return ""
            }
        },
        building_number: {
            type: String,
            default() {
                return ""
            }
        },
        apartment_number: {
            type: String,
            default() {
                return ""
            }
        },
        zip_code: {
            type: String,
            default() {
                return ""
            }
        },
        city: {
            type: String,
            default() {
                return ""
            }
        },
        region: {
            type: String,
            default() {
                return null
            }
        },
        country: {
            type: String,
            default() {
                return null
            }
        },
        two_lines: {
            type: Boolean,
            default() {
                return true
            }
        },
    },

    data: () => ({
        show_street: "",
        show_building_number: "",
        show_apartment_number: "",
        show_zip_code: "",
        show_city: "",
        show_region: "",
        show_country: "",

    }),

    computed: {

        noData() {
            return !this.show_building_number && !this.show_street && !this.show_zip_code && !this.show_city;
        }

    },


    methods: {

        loadData() {
            let data = this.$props.data;

            this.show_street = this.street;
            this.show_building_number = this.building_number;
            this.show_apartment_number = this.apartment_number;
            this.show_zip_code = this.zip_code;
            this.show_city = this.city;
            this.show_region = this.region;
            this.show_country = this.country;

            if (data !== undefined) {

                this.show_street = data.street;
                this.show_building_number = data.building_number;
                this.show_apartment_number = data.apartment_number;
                this.show_zip_code = data.zip_code;
                this.show_city = data.city;
                this.show_region = data.region;
                this.show_country = data.country;


            }


        }

    },

    mounted() {
        this.loadData();
    },

    watch: {

        $props: {
            handler(newValue, oldValue) {
                this.loadData();
            },
            deep: true
        }

    },


}
</script>

<style scoped>

</style>