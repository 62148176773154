<script>
// Multijęz -> article_list, article_new
// Dodać do logos zamiast nazw Logo, dodać obrazki
// przycisk -> bez icony nie działa, icona z prawej strony nie działa

import ListIconCheck from "@/views/Base/List/IconCheck.vue";
import Files from "@/views/Base/Files/Files.vue";
import Image from "@/views/Base/Image/Image.vue";
import Router from "@/views/Base/Router/Router.vue";
import Text from "@/views/Base/Text/Text.vue";
import Slider1 from "@/views/Base/Slider/Slider1.vue";
import UserName from "@/views/User/Name.vue";
import UserAvatar from "@/views/User/Avatar.vue";
import UserDescription from "@/views/User/Description.vue";
import UserTags from "@/views/User/Tags.vue";
import Hr1 from "@/views/Base/Hr/Hr1.vue";
import MovieYouTube from "@/views/Base/Movie/MovieYouTube.vue";
import Title from "@/views/Base/Title/Title.vue";
import Title2 from "@/views/Base/Title/Title2.vue";
import PageModelAudioFile from "@/views/Base/Audio/AudioFile.vue";
import AudioFile from "@/views/Base/Audio/AudioFile.vue";
import MovieFile from "@/views/Base/Movie/MovieFile.vue";
import MovieVimeo from "@/views/Base/Movie/MovieVimeo.vue";
import ArticleNews from "@/views/Article/News.vue";
import ArticleTitle from "@/views/Article/Title.vue";
import LangSelect from "@/views/Base/Lang/Lang.vue";
import Search from "@/views/Base/Search/Search.vue";
import ArticleList from "@/views/Article/List.vue";
import MenuInline from "@/views/Base/Menu/Inline.vue";
import MenuInline3 from "@/views/Base/Menu/Inline3.vue";
import MenuInlineBig from "@/views/Base/Menu/InlineBig.vue";
import LabelSoft from "@/views/Base/Label/Soft.vue";
import Base from "@/Library/Extends/Base.vue";
import Hello1 from "@/views/Base/Hello/Hello1.vue";
import MenuIcon from "@/views/Base/Menu/Icon.vue";
import Note1 from "@/views/Base/Note/Note1.vue";
import StatLetterList from "@/views/Base/Stat/LetterList.vue";
import PortalTeamName from "@/views/Portal/Team/Name.vue";
import PortalTeamDescription from "@/views/Portal/Team/Description.vue";
import PortalTeamImage from "@/views/Portal/Team/Image.vue";
import PortalTeamStand from "@/views/Portal/Team/Stand.vue";
import PortalTeamTags from "@/views/Portal/Team/Tags.vue";
import ArticleThumb from "@/views/Article/Thumb.vue";
import ArticleContent from "@/views/Article/Content.vue";
import ArticleTags from "@/views/Article/Tags.vue";
import ArticleMetric from "@/views/Article/Metric.vue";
import PortalTeamList from "@/views/Portal/Team/List.vue";
import Tabs1 from "@/views/Base/Tabs/Tabs1.vue";
import TabsContentLeft from "@/views/Base/Tabs/TabsContentLeft.vue";
import MenuMobileWrapper from "@/views/Base/Menu/MobileWrapper.vue";
import Title1 from "@/views/Base/Title/Title1.vue";
import Alert from "@/views/Base/Alert/Alert.vue";
import Contact from "@/views/Base/Contact/Contact.vue";
import BlockStep from "@/views/Base/Block/BlockStep.vue";
import BlockBenefits from "@/views/Base/Block/BlockBenefits.vue";
import BlockFaQ from "@/views/Base/Block/BlockFaQ.vue";
import BlockStep2 from "@/views/Base/Block/BlockStep2.vue";
import BlockStep3 from "@/views/Base/Block/BlockStep3.vue";
import BlockIcon from "@/views/Base/Block/BlockIcon.vue";
import BlockImageLinks from "@/views/Base/Block/BlockImageLinks.vue";
import BlockNews from "@/views/Base/Block/BlockNews.vue";
import BlockNews2 from "@/views/Article/News2.vue";
import Image2 from "@/views/Base/Image/Image2.vue";
import Image3 from "@/views/Base/Image/Image3.vue";
import InfoV2 from "@/views/Base/Info/InfoV2.vue";
import Slider from "@/views/Base/Slider/Slider.vue";
import Quote from "@/views/Base/Info/Quote.vue";
import ImageDescriptionV1 from "@/views/Base/Block/ImageDescriptionV1.vue";
import ImageAll from "@/views/Base/Image/ImageAll.vue";
import InfoV3 from "@/views/Base/Info/InfoV3.vue";
import TeamMember2 from "@/views/Portal/Team/TeamMember2.vue";
import TeamMember3 from "@/views/Portal/Team/TeamMember3.vue";
import File from "@/views/Base/File/File.vue";
import Testimonial2 from "@/views/Base/Testimonial/Testimonial2.vue";
import BlogPosts from "@/views/Base/Block/BlogPosts.vue";
import Calendar from "@/views/Base/Block/Calendar.vue";
import CustomButton from "@/views/Base/Block/CustomButton.vue";
import GalleryV2 from "@/views/Base/Block/GalleryV2.vue";
import GalleryV3 from "@/views/LK/GalleryV3.vue";
import ListWithLinks from "@/views/Base/Block/ListWithLinks.vue";
import ListLinks from "@/views/Base/Block/ListLinks.vue";
import SocialMedia from "@/views/Base/Social/SocialMedia.vue";
import Sections from "@/views/LK/Sections.vue";
import TermAndConditions from "@/views/Base/Testimonial/TermAndConditions.vue";
import TermAndConditionsV2 from "@/views/Base/Testimonial/TermAndConditionsV2.vue";
import Logo from "@/views/LK/Logo.vue";
import Gallery from "@/views/Base/Block/Gallery.vue";
import Persons from "@/views/LK/Persons.vue";
import BlockFaQV2 from "@/views/Base/Block/BlockFaQV2.vue";
import BlockNewsV2 from "@/views/Base/Block/BlockNewsV2.vue";
import Info from "@/views/Base/Info/Info.vue";
import DynamicChartShow from "@/views/Base/DynamicChart/ChartShow.vue";
import DynamicTableShow from "@/views/Base/DynamicChart/TableShow.vue";
import Space from "@/views/Base/Mixed/Space.vue";
import SiteMap from "@/views/Base/Mixed/SiteMap.vue";
import SiteMenuUniversal from "@/views/Base/Menu/SiteMenuUniversal.vue";
import ArticleEventFull from "@/views/Article/EventFull.vue";
import ArticleEventShort from "@/views/Article/EventShort.vue";
import SiteMenuStyle from "@/views/Base/Menu/SiteMenuStyle.vue";
import Code from "@/views/Base/Text/Code.vue";
import ArticleEventLink from "@/views/Article/EventLink.vue";
import BlockMap from "@/views/Base/Block/Map.vue";
import ArticleLead from "@/views/Article/Lead.vue";
import ArticleEventMetric from "@/views/Article/EventMetric.vue";
import DateCountDown from "@/views/Base/Block/DateCountdown.vue";
import Alert2 from "@/views/Base/Alert/Alert2.vue";
import HeroSlider from "@/views/Base/Slider/HeroSlider.vue";
import Block3Image from "@/views/Base/Block/Block3Image.vue";
import ArticleNews2List from "@/views/Article/News2List.vue";
import BlocksIcon from "@/views/Base/Block/BlocksIcon.vue";

export default {
    name: "RenderElement",
    extends: Base,
    components: {
        BlocksIcon,
        ArticleNews2List,
        Block3Image,
        HeroSlider,
        Alert2,
        DateCountDown,
        ArticleEventMetric,
        Files,
        ArticleLead,
        BlockMap,
        ArticleEventLink,
        ArticleEventFull,
        ArticleEventShort,
        SiteMenuStyle,
        SiteMenuUniversal,
        Code,
        MenuInline3,
        SiteMap,
        Space,
        DynamicTableShow,
        DynamicChartShow,
        Info,
        BlockNewsV2,
        BlockFaQV2,
        Persons,
        Logo,
        TermAndConditionsV2,
        TermAndConditions,
        Sections,
        SocialMedia,
        ListWithLinks,
        ListLinks,
        Gallery,
        GalleryV3,
        GalleryV2,
        CustomButton,
        Calendar,
        BlogPosts,
        Testimonial2,
        File,
        TeamMember3,
        TeamMember2,
        InfoV3,
        ImageAll,
        ImageDescriptionV1,
        Quote,
        Slider,
        InfoV2,
        Image3,
        Image2,
        BlockNews2,
        BlockNews,
        BlockImageLinks,
        BlockIcon,
        BlockStep3,
        BlockStep2,
        BlockFaQ,
        BlockBenefits,
        BlockStep,
        Contact,
        Alert,
        Title1,
        MenuMobileWrapper,
        TabsContentLeft,
        Tabs1,
        PortalTeamList,
        ArticleMetric,
        ArticleTags,
        ArticleContent,
        ArticleThumb,
        PortalTeamTags,
        PortalTeamStand,
        PortalTeamImage,
        PortalTeamDescription,
        PortalTeamName,
        StatLetterList,
        Note1,
        MenuIcon,
        Hello1,
        LabelSoft,
        MenuInlineBig,
        MenuInline,
        ArticleList,
        LangSelect,
        Search,
        ArticleTitle,
        ArticleNews,
        MovieVimeo,
        MovieFile,
        AudioFile,
        PageModelAudioFile,
        Title2,
        Title,
        MovieYouTube,
        Hr1, UserTags, UserDescription, UserAvatar, UserName, Slider1, Text, Router, Image, ListIconCheck
    },
    props: {
        item: {
            default() {
                return {};
            }
        },
        obj: {
            default() {
                return {};
            }
        },
    },
    data: () => ({
        text_types: ['text', 'textarea', 'editor']
    }),
    computed: {

        cls() {

            let ret = {};
            // ret[this.elementShowClass('xs')] = true;
            // ret[this.elementShowClass('sm')] = true;
            // ret[this.elementShowClass('l')] = true;
            // ret[this.elementShowClass('xl')] = true;

            return ret;

        },

        backgroundColor() {

            let color = this.getStyleValue('background-color');
            let opacity = this.getStyleValue('background-color-opacity') ?? 0;

            let colorFinal = color ? 'rgba(' + parseInt(color.slice(-6, -4), 16) + ',' + parseInt(color.slice(-4, -2), 16) + ',' + parseInt(color.slice(-2), 16) + ',' + (opacity / 100) + ')' : null;

            return colorFinal;

        },

        getStyle() {

            /**
             * Tablica styli
             * @type {{}}
             */
            let ret = {};

            /**
             * Marginesy
             * @type {string[]}
             */
            let margins = ['left', 'right', 'top', 'bottom'];
            let margins_names = margins.map(margin => 'margin-' + margin);
            margins_names.forEach(margins_name => {
                ret[margins_name] = this.getStyleValue(margins_name, 'px');
            })

            /**
             * Dopełnienia
             * @type {string[]}
             */
            let paddings = ['left', 'right', 'top', 'bottom'];
            let paddings_names = paddings.map(padding => 'padding-' + padding);
            paddings_names.forEach(padding_name => {
                ret[padding_name] = this.getStyleValue(padding_name, 'px');
            })

            /**
             * RAMKA
             */
            ret['border-width'] = this.getStyleValue('border-width', 'px');
            ret['border-color'] = this.getStyleValue('border-color');
            ret['border-style'] = this.getStyleValue('border-style');

            /**
             * Przeźroczystośc
             */
            ret['opacity'] = this.getStyleValue('opacity');

            /**
             * Kolor tła
             */
            ret['background-color'] = this.backgroundColor;

            if (this.showType === 'router-view')
                ret['width'] = '100%';


            let css = this.getStyleValue("css")?.replaceAll(/\s/g, '').replace(/(\r\n|\n|\r)/gm, "")

            let css_arr = css?.split(";")
            css_arr = css_arr?.filter(el => el !== "")

            if (css_arr && Array.isArray(css_arr)) {
                css_arr.map(el => {
                    let el_arr = el.split(":")
                    ret[el_arr[0]] = el_arr[1]
                })
            }

            return ret
        },

        showType() {

            let ret = this.item.widget;

            if (this.text_types.includes(this.item.widget))
                ret = 'text';

            return ret;

        },

        widgetData() {

            return this.item.widget_data;

        },

        widgetConfig() {

            return this.item.widget_config;

        },

    },

    methods: {

        elementShowClass(sizeScreen) {

            let element = this.item;

            let show = element['show_' + sizeScreen];

            let cls = '';
            if (show === 'no') {
                cls = 'd-' + (sizeScreen !== 'xs' ? sizeScreen + '-' : '') + 'none';
            }
            if (show === 'yes') {
                cls = 'd-' + (sizeScreen !== 'xs' ? sizeScreen + '-' : '') + 'inline-block';
            }

            return cls;

        },
        getStyleValueDefault(name) {

            let data_default = this.item['style'];
            data_default = data_default ?? {};

            return data_default[name] !== undefined ? data_default[name] : null;

        },

        getStyleValuePreview(name) {

            let data = this.item['style_' + this.screenWidthStr];
            data = data ?? {};

            return data[name] !== undefined ? data[name] : null;

        },

        getStyleValue(name, add = "") {

            let dataDefault = this.getStyleValueDefault(name);

            let dataPreview = this.getStyleValuePreview(name);

            let ret = null;
            if (!ret && dataPreview)
                ret = dataPreview + add;
            if (!ret && dataDefault)
                ret = dataDefault + add;

            return ret;

        },
    },
    mounted() {
        // this.load();
    }
}
</script>

<template>

    <div class="element"
         :class="elementShowClass"
         :data-show_type="showType"
         :data-screen_size="this.screenWidthStr"
         v-if="this.item['show_' + this.screenWidthStr] === 'yes'"
         v-bind:style="getStyle"
    >


        <Code v-if="showType === 'code'" :info="this.widgetData"/>

        <ListIconCheck v-if="showType === 'list_check'" :info="this.widgetData"></ListIconCheck>

        <Image v-if="showType === 'image'" :info="this.widgetData"/>
        <Files v-if="showType === 'files-own'" :info="this.widgetData"/>
        <AudioFile v-if="showType === 'audio_file'" :info="this.widgetData"/>
        <MovieFile v-if="showType === 'movie'" :info="this.widgetData"/>
        <MovieVimeo v-if="showType === 'movie_vimeo'" :info="this.widgetData"/>
        <MovieYouTube v-if="showType === 'movie_yt'" :info="this.widgetData"/>

        <LabelSoft v-if="showType === 'label'" :info="this.widgetData"/>
        <Text v-if="showType === 'text'" :info="this.widgetData"/>
        <Note1 v-if="showType === 'note-1'" :info="this.widgetData"/>

        <Title v-if="showType === 'title'" :info="this.widgetData"/>
        <Title1 v-if="showType === 'title-1'" :info="this.widgetData"/>
        <Title2 v-if="showType === 'title-2'" :info="this.widgetData"/>

        <LangSelect v-if="showType === 'lang-select'" :info="this.widgetData"/>
        <Search v-if="showType === 'search'" :info="this.widgetData"/>
        <Router v-if="showType === 'router-view'" :info="this.widgetData"/>
        <Space v-if="showType === 'space'" :info="this.widgetData"/>
        <SiteMap v-if="showType === 'site-map'" :info="this.widgetData"/>


        <StatLetterList v-if="showType === 'count-1'" :info="this.widgetData"/>
        <Slider1 v-if="showType === 'block-1'" :info="this.widgetData" :item="item"/>
        <Hr1 v-if="showType === 'hr'" :info="this.widgetData"/>

        <Hello1 v-if="showType === 'block-hello'" :info="this.widgetData"/>
        <TabsContentLeft v-if="showType === 'tabs-1'" :info="this.widgetData"/>
        <block-fa-q v-if="showType === 'faq-1'" :info="this.widgetData"/>
        <block-fa-q-v2 v-if="showType === 'faq-2'" :info="this.widgetData"/>
        <alert v-if="showType === 'alert-1'" :info="this.widgetData"/>
        <alert2 v-if="showType === 'alert-2'" :info="this.widgetData"/>
        <block-benefits v-if="showType === 'block-benefit-1'" :info="this.widgetData"/>
        <block-icon v-if="showType === 'block-benefit-2'" :info="this.widgetData"/>
        <image-description-v1 v-if="showType === 'image-description-1'" :info="this.widgetData"/>
        <date-count-down v-if="showType === 'date-countdown'" :info="this.widgetData"/>
        <image2 v-if="showType === 'image-description-2'" :info="this.widgetData"/>
        <image-all v-if="showType === 'image-description-3'" :info="this.widgetData"/>
        <contact v-if="showType === 'contact-with-map-1'" :info="this.widgetData"/>
        <BlockMap v-if="showType === 'map'" :info="this.widgetData"/>
        <BlockStep v-if="showType === 'steps-1'" :info="this.widgetData"/>
        <BlockStep2 v-if="showType === 'steps-2'" :info="this.widgetData"/>
        <BlockStep3 v-if="showType === 'steps-3'" :info="this.widgetData"/>
        <TeamMember2 v-if="showType === 'team-members-1'" :info="this.widgetData"/>
        <TeamMember3 v-if="showType === 'team-members-2'" :info="this.widgetData"/>
        <file v-if="showType === 'file-1'" :info="this.widgetData"/>
        <testimonial2 v-if="showType === 'logo-1'" :info="this.widgetData"/>
        <BlogPosts v-if="showType === 'posts-1'" :info="this.widgetData"/>
        <Calendar v-if="showType === 'calendar-1'" :info="this.widgetData"/>
        <CustomButton v-if="showType === 'button-1'" :info="this.widgetData"/>
        <GalleryV2 v-if="showType === 'gallery-2'" :info="this.widgetData"/>
        <list-with-links v-if="showType === 'list-with-links-1'" :info="this.widgetData"/>
        <list-links v-if="showType === 'list-links-1'" :info="this.widgetData"/>
        <social-media v-if="showType === 'social-media-1'" :info="this.widgetData"/>
        <term-and-conditions v-if="showType === 'term-and-conditions-1'" :info="this.widgetData"/>
        <term-and-conditions-v2 v-if="showType === 'term-and-conditions-2'" :info="this.widgetData"/>
        <gallery v-if="showType === 'gallery-own'" :info="this.widgetData"/>

        <info v-if="showType === 'info-1'" :info="this.widgetData"/>
        <info-v2 v-if="showType === 'info-2'" :info="this.widgetData"/>
        <info-v3 v-if="showType === 'info-3'" :info="this.widgetData"/>
        <block-image-links v-if="showType === 'image-with-links-1'" :info="this.widgetData"/>
        <BlockNews v-if="showType === 'news-1'" :info="this.widgetData"/>
        <block-news-v2 v-if="showType === 'news-2'" :info="this.widgetData"/>
        <Slider v-if="showType === 'slider-1'" :info="this.widgetData"/>

        <HeroSlider v-if="showType === 'hero-slider'" :info="this.widgetData"/>
        <Block3Image v-if="showType === 'block-3-image'" :info="this.widgetData"/>

        <quote v-if="showType === 'quote-1'" :info="this.widgetData"/>

        <UserName v-if="showType === 'user_name'" :info="this.widgetData" :obj="obj"/>
        <UserAvatar v-if="showType === 'user_avatar'" :info="this.widgetData" :obj="obj"/>
        <UserDescription v-if="showType === 'user_description'" :info="this.widgetData" :obj="obj"/>
        <UserTags v-if="showType === 'user_tags'" :info="this.widgetData" :obj="obj"/>

        <ArticleList v-if="showType === 'article_list'" :info="widgetData" :config="widgetConfig"/>
        <ArticleNews v-if="showType === 'article_news'" :info="widgetData" :config="widgetConfig"/>
        <ArticleNews2List v-if="showType === 'article_news_2'" :info="widgetData" :config="widgetConfig"/>
        <BlocksIcon v-if="showType === 'blocks-icon'" :info="widgetData" :config="widgetConfig"/>

        <ArticleEventFull v-if="showType === 'article_event_full'" :info="widgetData" :config="widgetConfig"/>
        <ArticleEventShort v-if="showType === 'article_event_short'" :info="widgetData" :config="widgetConfig"/>

        <ArticleTitle v-if="showType === 'article_title'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleLead v-if="showType === 'article_lead'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleEventLink v-if="showType === 'article_event_link'" :info="widgetData" :config="widgetConfig"
                          :obj="obj"/>
        <ArticleThumb v-if="showType === 'article_thumb'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleContent v-if="showType === 'article_content'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleTags v-if="showType === 'article_tags'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleMetric v-if="showType === 'article_metric'" :info="widgetData" :config="widgetConfig" :obj="obj"/>
        <ArticleEventMetric v-if="showType === 'article_event_metric'" :info="widgetData" :config="widgetConfig"
                            :obj="obj"/>

        <PortalTeamList v-if="showType === 'portalteam_list'" :info="widgetData" :obj="obj"/>
        <PortalTeamName v-if="showType === 'portalteam_name'" :info="widgetData" :obj="obj"/>
        <PortalTeamDescription v-if="showType === 'portalteam_description'" :info="widgetData" :obj="obj"/>
        <PortalTeamImage v-if="showType === 'portalteam_image'" :info="widgetData" :obj="obj"/>
        <PortalTeamStand v-if="showType === 'portalteam_stand'" :info="widgetData" :obj="obj"/>
        <PortalTeamTags v-if="showType === 'portalteam_tags'" :info="widgetData" :obj="obj"/>

        <MenuInline v-if="showType === 'site_menu'" space="20px" :info="widgetData"/>
        <MenuInline3 v-if="showType === 'site_menu_3'" space="20px" :info="widgetData" :border="true"/>
        <MenuInlineBig v-if="showType === 'site_menu_4'" :info="widgetData"/>
        <MenuIcon v-if="showType === 'site_menu_5'" :info="widgetData"/>
        <MenuMobileWrapper v-if="showType === 'site_menu_6'" :info="widgetData"/>
        <SiteMenuUniversal v-if="showType === 'site_menu_universal'" :info="widgetData"/>
        <SiteMenuStyle v-if="showType === 'site_menu_style'" :info="widgetData"/>

        <DynamicChartShow v-if="showType === 'data_chart'" :info="widgetData" :source="item" ref="element"/>
        <DynamicTableShow v-if="showType === 'data_table'" :source="item" ref="element"/>
    </div>

</template>

<style lang="scss" scoped>
.element {

    &:has(.width-100-percent) {
        width: 100%;
    }
}
</style>
