<script>
export default {
  name: "ControllTemplate",
  data: () => ({
  }),
  methods: {
    showElement(name){

      let ret = true;
      if(ret && typeof this.$slots[name] === undefined)
        ret = false;

      if(ret && typeof this.$slots[name] !== 'function')
        ret = false;

      if(ret && this.$slots[name]().length === 0)
        ret = false;

      return ret;

    }


  },
}
</script>
