<template>

  <a class="skip-main" href="#main">Przejdź do zawartości</a>

  <AppLoading v-if="state === 'loading' || state ==='loaded'"/>

  <RenderLayout v-if="state === 'loaded' || state === 'done'"/>

  <AppError v-if="state === 'error'"/>

  <AppNoLangs v-if="state === 'no-langs'"/>

  <AppNeedPass v-if="state === 'need-pass'" :domain="domain_obj" @passCorrect="passCorrectSet"/>

  <AppNotAccess v-if="state === 'not-access'"/>

  <Cookie v-if="state === 'loaded' || state === 'done'"/>

</template>


<script>

import LayoutHeader from "@/layout/Header.vue";
import LayoutNav from "@/layout/Nav.vue";
import Title from "@/views/Base/Title/Title.vue";
import LayoutFooter from "@/layout/Footer.vue";
import LayoutFooterBelow from "@/layout/FooterBelow.vue";
import Colors from "@/views/Colors.vue";
import Hello1 from "@/views/Base/Hello/Hello1.vue";
import Hr1 from "@/views/Base/Hr/Hr1.vue";
import MenuIcon from "@/views/Base/Menu/Icon.vue";
import RenderLayout from "@/views/RenderLayout.vue";
import {mapActions, mapState} from "vuex";
import axios from "@/axios";
import Element from "@/components/Element.vue";
import Alert from "@/Library/Components/Alert.vue";
import Row from "@/Library/Forms/Row.vue";
import AppLoading from "@/views/AppLoading.vue";
import AppError from "@/views/AppError.vue";
import AppNeedPass from "@/views/AppNeedPass.vue";
import AppNotAccess from "@/views/AppNotAccess.vue";
import Cookie from "@/components/Cookie.vue";
import AppNoLangs from "@/views/AppNoLangs.vue";

export default {
  name: 'App',

  extends: Element,

  components: {
    AppNoLangs,
    Cookie,
    AppNotAccess,
    AppNeedPass,
    AppError,
    AppLoading,
    Row,
    Alert,
    RenderLayout,
    MenuIcon, Hr1, Hello1, Colors, LayoutFooterBelow, LayoutFooter, Title, LayoutNav, LayoutHeader
  },

  data: () => ({
    state: 'loading',
    colors: [],
    fonts: [],
    domain_hosts: ['usk.strefaportali.pl', 'localhost', 'generator.us.edu.pl'],
    domain_get_url: 'domain/find',
    domain_get_params: {status: 'live', 'ip-test': true, scope_extra: 'langs,langs_public'},
    domain_data: null,
    domain_obj: null,
    domain_id: null,
    storage_prefix: 'app',
    opacity: 0,
  }),

  computed: {

    ...mapState('app', ['lang', 'lang_arr', 'initial', 'config', 'domain_info']),

    indexTag() {

    },

    protocol() {


      let host = document.baseURI;

      if (host.substring(0, 5) === 'http:') {
        host = 'http:';
      }
      if (host.substring(0, 6) === 'https:') {
        host = 'https:';
      }

      return host;

    },

    host() {

      let host = document.baseURI;

      if (host.substring(0, 7) === 'http://') {
        host = host.substring(7);
      }
      if (host.substring(0, 8) === 'https://') {
        host = host.substring(8);
      }
      if (host.indexOf('/') > 0) {
        host = host.substring(0, host.indexOf('/'))
      }

      return host;
    },

    hostFirst() {


      let host = this.host;

      let host_part = host.split('.');

      let host_first = host_part[0]

      host_first = host.split(':')[0];

      return host_first;

    },

    domainSource() {

      let host_first = this.hostFirst;

      let ret = null;
      if (this.domain_hosts.includes(host_first))
        ret = 'url';
      if (!ret)
        ret = 'domain';

      return ret;

    },

    routerPrefixDomain() {
      let prefix = '/';
      if (this.domainSource === 'url') {
        prefix += this.domainFind.folder + '/';
      }

      return prefix;

    },

    routerPrefix() {
      let prefix = this.routerPrefixDomain;

      // if (this.lang !== 'pl') {
      prefix += this.lang;
      // }

      return prefix;

    },

    domainFind() {

      let location = document.baseURI;

      let host = this.host

      let host_part = host.split(':')[0];

      let domain = null;
      let folder = null;
      if (this.domainSource === 'domain') {
        domain = this.hostFirst;
      }
      if (this.domainSource === 'url') {
        folder = this.pathFirst;
      }

      let ret = {
        domain: domain,
        folder: folder,
      };

      console.log(ret)

      return ret;

    },

    path() {

      return this.pathArr.join('/');

    },

    pathName() {
      return document.baseURI.substring(this.host.length - 1)
    },

    pathArr() {

      // let location = window.location;

      // let path_name = location.pathname;

      let path_name = this.pathName

      let ret = "";
      if (this.domainSource === 'domain') {
        ret = path_name.split('/');
      }
      if (this.domainSource === 'url') {
        ret = path_name.split('/');
        ret = ret.slice(1);
      }
      if (this.domainSource === 'none') {
        ret = path_name.split('/');
        ret = ret.slice(1);
      }


      return ret;

    },

    pathFirst() {

      return this.pathArr[0]

    },

    pathLang() {

      return this.pathFirst + '/' + this.lang

    },

    pathInDomain() {

      return this.pathInDomainArr.join('/')

    },

    pathInDomainArr() {
      let source = this.domainSource;
      let pathArr = [...this.pathArr]

      if (source === 'domain') {
        return pathArr;
      }

      if (source === 'url') {
        return pathArr.slice(1);
      }

      if (source === 'none') {
        return pathArr;
      }

    },

    pathInLang() {

      return this.pathInLangArr.join("/");

    },

    pathInLangArr() {

      let pathInDomainArr = [...this.pathInDomainArr];

      let first = pathInDomainArr.length > 0 ? pathInDomainArr[0] : null;

      let lang = 'pl';

      let langArr = [];

      if (first && first.length === 2) {
        lang = first;
        langArr = pathInDomainArr.splice(1);
      }

      return langArr;
    },

    slug() {

      let path = this.path;

      if (!path) return null;

      let path_arr = this.pathArr;

      let last_arr = path_arr.slice(-1);

      return last_arr[0];

    },

    langInUrl() {

      let pathInDomainArr = this.pathInDomainArr;

      let first = pathInDomainArr.length > 0 ? pathInDomainArr[0] : null;

      let lang = null;

      if (first && first.length === 2) {
        lang = first;
      }

      return lang;

    },


    urlBase() {

      let ret = this.protocol + '//' + this.host + '/';

      let domainFind = this.domainFind;

      if (domainFind.folder !== null)
        ret += domainFind.folder + '/';

      // ret += this.lang+'/';

      return ret;

    },

    urlLang() {

      return this.urlBase + this.lang + '/';

    },
  },

  methods: {

    ...mapActions('app', ['init', 'domainIdSet', "domainInfoSet", "domainObjSet", 'setLangToStorage']),

    async passCorrectSet(pass) {

      this.domainPassStorageSet(pass);

      await this.domainLoad();

      await this.init();

      await this.colorsLoad();

      await this.colorsUpdate();

      await this.loadDomainData();

    },

    async domainGetBase(params) {

      return await axios.get(this.domain_get_url, {params: params}).then(r => r.data.data).catch(r => r.response);

    },

    async domainGetByDomain(domain) {

      let params = {...this.domain_get_params};
      params.domain = domain;

      return await this.domainGetBase(params);

    },

    async domainGetByFolder(folder) {

      let params = {...this.domain_get_params};
      params.folder = folder;

      return await this.domainGetBase(params);

    },

    async domainGet(domainArr) {

      let domainData = null;
      if (domainArr.domain)
        domainData = await this.domainGetByDomain(domainArr.domain);

      if (domainArr.folder)
        domainData = await this.domainGetByFolder(domainArr.folder);

      return domainData;

    },

    async domainLoad() {

      let domainArr = this.domainFind;

      this.domain_data = domainArr;
      this.domain_obj = await this.domainGet(domainArr);
      this.domain_id = this.domain_obj ? this.domain_obj.id : null;

      await this.domainIdSet({data: this.domain_id});


      if (this.domain_obj && this.domain_obj.status === 401) {
        this.state = 'not-access';
        return null;
      }

      if (this.domain_obj && this.domain_obj.langs_public.length === 0) {
        this.state = 'no-langs';
        return null;
      }

      if (this.domain_id === null) {
        this.state = 'error';
        return null;
      }

      if (this.domain_obj.pass_lock === 'yes' && !this.domainPassCorrect()) {
        this.state = 'need-pass';
        return null;
      }
    },

    domainPassStorageName() {
      let id = this.domain_obj.id;

      return id + '_' + 'pass';
    },

    domainPassStorageGet() {

      let storage_name = this.domainPassStorageName();

      return this.storageGet(storage_name);

    },

    domainPassStorageSet(pass) {

      let storage_name = this.domainPassStorageName();

      return this.storageSet(storage_name, pass);

    },

    domainPass() {

      return this.domain_obj.pass;

    },

    domainPassCorrect() {

      return this.domainPass() === this.domainPassStorageGet();

    },

    async colorsLoad() {

      let url = 'domaincolor/packexec';

      this.colors = await axios.get(url, {}).then(result => result.data.data);

    },

    async fontsLoad() {

      let url = 'domainfont/get';

      let params = {status: 'live'}

      this.fonts = await axios.get(url, {params: params}).then(result => result.data.data);

      this.fonts.forEach(el => {

        if (el.source === 'url') {
          let link = document.createElement('link')
          link.rel = 'stylesheet';
          link.href = el.url;
          document.head.appendChild(link);
        }
        if (el.source === 'file') {
          let style = document.createElement('style');
          style.innerText =
              '@font-face {\n' +
              '  font-family: ' + el.name + ';\n' +
              '  src: url(' + el.url + ');\n' +
              '}';
          document.head.appendChild(style);
        }

      });


      // let newStyle = document.createElement('style');
      //
      // this.fonts.forEach(el => {
      //
      //   newStyle.appendChild(document.createTextNode("\
      // @font-face {\
      //     font-family: "+el.family+";\
      //     src: url('"+el.url+"');\
      // }\
      // "));
      // })
      //
      // document.head.appendChild(newStyle);


    },

    cssLoad() {

      if (!this.domain_obj)
        return null;

      let css = this.domain_obj.css;

      let style = document.createElement('style');
      style.innerText = css;
      document.head.appendChild(style);
    },

    async colorsUpdate() {

      let colors = this.colors;

      let element = null;
      let elementArr = null;

      await colors.forEach(el => {

        element = null;
        elementArr = null;
        if (el.dom === null) {
          element = document.documentElement;
        }

        if (el.dom !== null) {
          elementArr = document.getElementsByTagName(el.dom);
          // element = element.length > 0 ? element[0] : null;
        }

        if (element) {
          // console.log(el.dom, el.code, el.color)
          element.style.setProperty("--" + el.code, el.color);
        }
        if (elementArr) {
          for (let i = 0; i < elementArr.length; i++) {
            elementArr[i].style.setProperty("--" + el.code, el.color);
          }
        }
      });


    },

    async langUpdate() {

      let src = this.domainSource;

      if (src === 'domain') {
      }
      if (src === 'url') {
      }

    },

    domainInfo() {

      let ret = {
        'domain': this.domainFind,
        'lang': this.lang,
        'lang_in_url': this.langInUrl,
        'location_protocol': this.protocol,
        'location_host': this.host,
        'location_pathname': this.pathName,
        'location_search': location.search,
        'host': this.host,
        'host_first': this.hostFirst,
        'slug': this.slug,
        'source': this.domainSource,
        'url_base': this.urlBase,
        'url_lang': this.urlLang,
        'path': this.path,
        'path_arr': this.pathArr,
        'path_first': this.pathFirst,
        'path_lang': this.pathLang,
        'path_in_domain': this.pathInDomain,
        'path_in_domain_arr': this.pathInDomainArr,
        'path_in_lang': this.pathInLang,
        'path_in_lang_arr': this.pathInLangArr,
      };

      setTimeout(() => {

        this.domainInfoSet({data: ret});

      }, 10)

    },

    async load() {

      await this.domainInfo();

      await this.domainLoad();

      await this.init();

      await this.fontsLoad().then();

      await this.cssLoad();

      await this.colorsLoad().then();

    },

    loadDomainData() {


      if (this.langInUrl !== null) {

        this.setLangToStorage(this.langInUrl);

      }

      if (this.lang !== this.langInUrl) {

        let lng = this.langInUrl !== null ? this.langInUrl : 'pl';

        this.setLangToStorage(lng);

        this.$router.push(this.routerPrefix + '/' + this.pathInLang)

      }

      this.state = 'loaded';

      setTimeout(async () => {

        await this.colorsUpdate();
        this.state = 'done'

      }, 350)

      this.appendDomainCss()
    },

    appendDomainCss() {
      this.domainObjSet({data: this.domain_obj})

      let css = this.domain_obj.css?.replaceAll("\n", ""),
          head = document.head || document.getElementsByTagName('head')[0],
          style = document.createElement('style');

      head.appendChild(style);

      style.type = 'text/css';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  },

  async mounted() {

    await this.load();

    if (this.state === 'error')
      return;

    if (this.state === 'no-langs')
      return;

    if (this.state === 'need-pass')
      return;

    if (this.state === 'not-access')
      return;

    await this.loadDomainData();

    setTimeout(() => {
      this.opacity = 1;
    }, 3000)


  }
}
</script>

<style lang="scss">
:root {
  --text_font: 'Open Sans';
  --title_front_font: 'PT Sans Narrow';
  --title_font: 'PT Sans Caption';

  font-size: 18px;
  line-height: 1.5em !important;
}

a.td-no {
    text-decoration: none;
}

._anim-item {
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0s, visibility 0.5s ease 0s;
    opacity: 0;

    &._active {
        opacity: 1;
    }
}

#app {
  font-family: var(--text_font), Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

html {
  font-size: 18px !important;
}


p {
  font-family: var(--text_font);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title_font);
  margin: 0;
  padding: 0;
  line-height: 1.5em;

}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}


header {

  position: relative;

  padding: 16px 0;

  &:before {

    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--main_color);
    opacity: .05;
  }
}

footer {
  background-color: var(--bg);
}

nav {
  //padding: 30px;

  a {
    font-weight: bold;
    color: var(--main-color);

    &.router-link-exact-active {
      color: var(--secondary-color);
    }
  }
}

html {
  position: relative;

  & > * {
    position: relative;
    z-index: 2;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    content: '';
    z-index: 1;

    background: linear-gradient(white 0, white 50px, #004b9308 400px);
  }
}

body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;

  &__image {
    margin-bottom: 24px;
  }

  &__text {
  }
}

.btn-send {
  width: 100%;

  border: none;

  background-color: #002a5e;
  color: #fff;

  cursor: pointer;

  padding: 12px;

  border-radius: 6px;
}

footer {
  background-color: var(--bg);
}

.three-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &--2 {
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &--3 {
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &--4 {
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &--5 {
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.map-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 128, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 0);
  z-index: 999;
}

.map .leaflet-div-icon {
  width: 0;
  height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;

  & .custom-marker {
    border-radius: 50% 50% 50% 0;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    transform: rotate(-45deg);

    margin-top: -40px;
    margin-left: -15px;

    display: flex;
    align-items: center;
    justify-content: center;

    & > .material-symbols-rounded {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
      transform: rotate(45deg);
    }
  }
}

.leaflet-container {
  border-radius: inherit;

  &:after {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px !important;
    z-index: 1001 !important;
  }
}

.loading {
  position: relative;
  min-height: 100px;
}

.loading > * {
  opacity: 0.1;
  -webkit-transition: all 0.30s ease-in-out;
  transition: all 0.30s ease-in-out;
}

.loading:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 40px;
  margin-left: -30px;
  margin-top: -30px;
  border: 3px solid var(--border_light);
  border-radius: 50%;
  border-top: 3px solid var(--border);
  border-bottom: 3px solid var(--border);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  opacity: 1 !important;
  -webkit-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  z-index: 2;
}

.loading::after {
  font-family: var(--title_front_font);
  position: absolute;
  content: "Trwa ładowanie danych";
  left: 0;
  top: 60px;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  opacity: 1 !important;


}


/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a, button {
  //opacity: .6;
  transition: all .3s ease-in-out;
  //transform: scale(1.1);
}

a:hover, button:hover {
  opacity: .7;
  transition: all .3s ease-in-out;
  //transform: scale(1.1);
}

.text-panel img {
  max-width: 100% !important;
  height: auto !important;
}

a.skip-main {
  top: -100%;
  left: auto;
  position: fixed;
  width: 30%;
  height: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
  color: #fff;
  background-color: #000;
}

a.skip-main:focus, a.skip-main:active {
  top: 0;

}

.container .container {
  //padding-left: 0!important;
  //padding-right: 0!important;
}

.material-symbols-rounded {
  opacity: v-bind(opacity);
}


.row-nice {
  position: relative;

  //margin: 5px auto 10px;
  display: inline-block;
  width: 100%;
  float: none;

  &__help {
    //position: absolute;
    bottom: -18px;
    left: 0;
    font-size: 10px;
    line-height: 10px;
    color: #777;
  }


  &__input,
  &__textarea {
    padding: 10px 16px 10px 14px !important;
    font-size: 18px !important;
    font-weight: bold;
    border-radius: 6px !important;
    border: 1px solid rgb(0, 0, 0, .3);
    width: 100%;

    max-width: 100%;

    box-sizing: border-box !important;


    transition: all .2s !important;
    will-change: border-color;

    //background-color: #fff !important;

    text-indent: 0 !important;

    margin-bottom: 0 !important;
  }

  &__textarea {
    min-height: 150px;
  }

  &__input {
  }

  &__select {
    padding: 10px 16px 0 10px !important;
    height: 48px !important;
    border: 1px solid rgb(206, 208, 212);
    //background-color: #fff !important;
    font-weight: 700;
    font-size: 18px;
    width: 100%;

    border-radius: 6px !important;
  }

  &__tags {
    padding: 6px 16px 0 10px !important;
    height: 48px !important;
    border: 1px solid rgb(206, 208, 212);
    background-color: #fff;
    font-weight: 700;
    font-size: 12px !important;
    width: 100%;

    border-radius: 6px !important;

  }


  &__label {
    position: absolute;

    left: 14px;
    top: 10px;

    font-size: 16px;

    color: #333;

    transition: all .2s;
    will-change: translatex, translatey, scale, color;

    line-height: 1.8em;

    cursor: text;
  }

  &__input:focus,
  &__textarea:focus {
    border: 1px solid rgb(0, 0, 0, .3);
  }

  &-error &__input,
  &-error &__textarea,
  &-error &__input:focus,
  &-error &__textarea:focus,
  {
    border: 1px solid red !important;
  }


  [data-layout-mode="dark"] &__input,
  [data-layout-mode="dark"] &__select,
  [data-layout-mode="dark"] &__select:focus,
  [data-layout-mode="dark"] &__textarea,
  .dark-layout &__input,
  .dark-layout &__select,
  .dark-layout &__select:focus,
  .dark-layout &__textarea {
    color: #fff;
    background-color: var(--bs-card-bg) !important;
    border: 1px solid rgb(255, 255, 255, .3);

  }

  [data-layout-mode="dark"] &__input,
  [data-layout-mode="dark"] &__textarea,
  [data-layout-mode="dark"] &__input:focus,
  [data-layout-mode="dark"] &__textarea:focus,
  [data-layout-mode="dark"] &__input:not(:placeholder-shown) ~ &__label,
  [data-layout-mode="dark"] &__textarea:not(:placeholder-shown) ~ &__label,
  .dark-layout &__input,
  .dark-layout &__textarea,
  .dark-layout &__input:focus,
  .dark-layout &__textarea:focus,
  .dark-layout &__input:not(:placeholder-shown) ~ &__label,
  .dark-layout &__textarea:not(:placeholder-shown) ~ &__label,
  {
    background-color: var(--bs-card-bg) !important;

  }

  [data-layout-mode="dark"] &__select:focus,
  [data-layout-mode="dark"] &__input:focus,
  [data-layout-mode="dark"] &__textarea:focus {
    border: 1px solid rgb(255, 255, 255, .5);
  }

  .dark-layout &__select:focus,
  .dark-layout &__input:focus,
  .dark-layout &__textarea:focus {
    border: 1px solid rgb(255, 255, 255, .5);
  }


  [data-layout-mode="dark"] &__select ~ &__label,
  [data-layout-mode="dark"] &__input:focus ~ &__label,
  [data-layout-mode="dark"] &__input:not(:placeholder-shown) ~ &__label,
  [data-layout-mode="dark"] &__textarea:not(:placeholder-shown) ~ &__label,
  .dark-layout &__select ~ &__label,
  .dark-layout &__input:focus ~ &__label,
  .dark-layout &__input:not(:placeholder-shown) ~ &__label,
  .dark-layout &__textarea:not(:placeholder-shown) ~ &__label,
  {
    background-color: var(--sp-body-bg);
    color: rgb(255, 255, 255, .5);

  }

  [data-layout-mode="dark"] &__label,
  .dark-layout &__label {
    background-color: var(--bs-card-bg);
    padding: 0 6px;
  }


}

.row-nice__buttons {
  position: absolute;
  right: 0;
  top: 0;

  & > * {
    margin: 14px;
    cursor: pointer;
  }
}

.row-nice.row-nice--error .row-nice__help {
  color: red;
}


.row-nice.row-nice-password:before {
  position: absolute;
  top: 20px;
  right: 20px;

  content: '';

  width: 16px;
  height: 8px;

  display: block;

  //background-image: url('/img/eye.svg');
}

.row-nice__input:not(:placeholder-shown),
.row-nice__textarea:not(:placeholder-shown),
.row-nice__input:focus,
.row-nice__textarea:focus {
  outline: none;
  box-shadow: none !important;
}


.row-nice__input:-webkit-autofill {
  background: transparent;
}


.input-autocomplete-active ~ label.row-nice__label,
.input-icon-active ~ label.row-nice__label,
.input-color-active ~ label.row-nice__label,
.row-nice__select ~ label.row-nice__label,
.row-nice__tags ~ label.row-nice__label,
.row-nice__label ~ label.row-nice__label:not(.radio-label),
.row-nice__input:not(:placeholder-shown) ~ label.row-nice__label,
.row-nice__input:focus ~ label.row-nice__label,
.row-nice__textarea:not(:placeholder-shown) ~ label.row-nice__label,
.row-nice__textarea:focus ~ label.row-nice__label {
  color: #333;

  /*font-size: 11px;*/
  background-color: #fff;
  padding: 0 35px 0 5px;
  transform: translatey(-22px) scale(0.8) translateX(calc(-13% - 3px));
}

.row-nice__image label.row-nice__label {
  left: 0 !important;
  top: 0 !important;
  color: #333;
  font-size: 13px;
}

@media screen and (max-width: 1024px) {
  .row-nice .input_editor ~ label.row-nice__label {
    top: 5px;
  }
}


</style>
