<template>

    <div class="row">
        <div class="col-12">
            <div class="page-title-box align-items-center">
                <div class="d-flex justify-content-between">


                    <router-link class="page-back" v-if="backHas && backUrl" :to="backUrl">
                    </router-link>
                    <div class="page-title-right" style="margin-right: auto">

                        <h4 class="mb-sm-0">{{ name }}</h4>
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item" v-for="breadcrumb_item in breadcrumb">
                                <span v-if="!breadcrumb_item.url" :href="breadcrumb_item.url">{{
                                    breadcrumb_item.name
                                    }}</span>
                                <router-link class="main-menu__item-link" v-if="breadcrumb_item.url"
                                             :to="breadcrumb_item.url">
                                    {{ breadcrumb_item.name }}
                                </router-link>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <slot name="header_right"/>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "ContentHeader",
    props: [
        'name',
        'breadcrumb',
    ],
    computed: {
        back() {
            return this.breadcrumb[this.breadcrumb.length - 2]
        },

        backHas() {

            return this.breadcrumb.length > 1;

        },

        backUrl() {

            return this.back.url ?? null

        },
    }
}
</script>

<style scoped lang="scss">

.page-back {
  width: 38px;
  height: 38px;
  border-radius: 8px;
  margin-right: 12px;

  background-color: var(--secondary-color);

  display: block;

  box-sizing: border-box;

  position: relative;

  &:before {
    content: '';

    width: 14px;
    height: 14px;


    border: 2px solid white;
    border-right: 0;
    border-bottom: 0;
    left: 14px;
    top: 12px;
    position: absolute;
    transform: rotate(315deg);
  }
}

</style>