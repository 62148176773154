<template>

</template>

<script>
import {mapActions, mapState} from "vuex";
import ControllStorage from '@/Library/Controll/Storage';

export default {
    name: "Base",
    mixins: [ControllStorage],
    computed: {
        ...mapState('app', ['config', 'modules', 'initial', 'perspective']),
        ...mapState('app', {appConfig: 'config'}),
        ...mapState('user', ['info']),


        screenWidth() {
            return this.windowSize;
        },

        screenWidthStr() {
            let screenWidth = this.windowSize;
            let ret = 'xs';
            if (screenWidth > 767 && screenWidth <= 992)
                ret = 'sm';
            if (screenWidth > 992 && screenWidth <= 1400)
                ret = 'l';
            if (screenWidth > 1400)
                ret = 'xl';

            return ret;
        },
    },
    data: () => ({
        debug_on: true,
        windowSize: null,
    }),


    methods: {
        ...mapActions('app', ['perspectiveIn']),


        debug(...args) {

            if (this.debug_on) {
                console.log(...args)
            }

        },

        app() {
            return this.$root.$refs.app;
        },


        initGet(name, def = 'no') {

            let val = this.initial[name] ?? def;

            this.debug('Pobranie informacji z ustawień zainicjowanych', name, val, def);

            return val;
        },

        initIs(name, value_test = 'yes', def = 'no') {

            let val = this.initGet(name, def);

            let test = val === value_test;

            this.debug('Sprawdzenie czy wartość inicjująca ' + name + ' jest równa ' + value_test + ' wynik ' + test)

            return test;
        },

        initIsYes(name, def = 'no') {

            return this.initIs(name, 'yes', def);

        },

        configGet(name, def = 'no') {

            let val = this.config[name] ?? def;

            this.debug('Pobranie informacji z ustawień aplikacji', name, val, def);

            return val;

        },
        configIs(name, value_test = 'yes', def = 'no') {


            let val = this.configGet(name, def);

            let test = val === value_test;

            this.debug('Sprawdzenie czy wartość konfiguracji ' + name + ' jest równa ' + value_test + ' wynik ' + test)

            return test;


        },
        configIsYes(name, def = 'no') {
            return this.configIs(name, 'yes', def);

        },

        modulesConnected(name) {

            return !!this.modules.find(el => el === name);

        },


        perspectiveIn(data) {

            let user_roles = this.info.role;
            let perspective = this.perspective;

            let needs = data;
            if (typeof data === 'string' || data instanceof String) {
                needs = data.split(",");
            }

            let ret = needs.includes(perspective);

            return ret;

        },

        windowResize() {
            this.windowSize = window.innerWidth;
        },


    },

    created() {

        window.addEventListener("resize", this.windowResize);

        this.windowResize()

    },
    destroyed() {
        window.removeEventListener("resize", this.windowResize);
    },
}
</script>

<style scoped>

</style>