<script>
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";

export default {
  name: "UserAvatar",
  components: {Thumb1},
  props: {
    obj: {
      default() {
        return {}
      }
    }
  }
}
</script>

<template>

  <Thumb1 :image="obj.avatar_url" format="11" radius="big"/>
</template>

<style scoped lang="scss">

</style>