<script>
import Element from "@/components/Element.vue";
import ShowSvg from "@/components/ShowSvg.vue";
import Map from "@/components/Map/Map.vue";

export default {
    name: "BlockMap",
    components: {
        Map,
        ShowSvg
    },
    extends: Element,
    props: {
        info: {
            default() {
                return {}
            }
        }
    },
    data: () => ({
        markers: [],
        showMap: false,
    }),
    methods: {
        isLink(text) {
            return text && text.length > 0
        },

        updateLocation(lat, lng) {
            this.markers = []

            let marker = {
                id: 0,
                id_category: null,
                name: "Marker",
                lat: lat,
                lng: lng
            }

            this.markers.push(marker)

            this.updateCenter();
        },

        updateCenter() {
            let lat = this.info.latitude;
            let lng = this.info.longitude;

            let lat_transform = 0;
            let lng_transform = 0;
            // switch (this.screenWidthStr) {
            //     case 'xs':
            //         lat_transform = .0015
            //         break;
            //     case 'sm':
            //         lat_transform = .002
            //         break;
            //     case 'l':
            //         lng_transform = -.003
            //         break;
            //     case 'xl':
            //         lng_transform = -.003
            //         break;
            // }

            if (this.$refs.map)
                this.$refs.map.setCenter(lat + lat_transform, lng + lng_transform)
        },

        refreshMap() {
            this.showMap = false;
            setTimeout(()=> this.showMap = true,10)
            setTimeout(()=> this.updateLocation(this.info.latitude, this.info.longitude),10)
        }
    },
    computed: {
        hasMapData(){
            return this.info.latitude && this.info.longitude
        },

        mapContainerStyle() {
            let ret = {}

            ret.height = this.info.height + "px"

            return ret
        }
    },
    watch: {
        'screenWidthStr': {
            handler(newValue, oldValue) {
                this.refreshMap()
            },
            deep: true
        }
    },
    mounted() {
        this.updateLocation(this.info.latitude, this.info.longitude)
    }
}
</script>

<template>

    <div class="map-container" :style="mapContainerStyle">
        <Map
            ref="map"
            v-if="showMap && hasMapData"
            :id="String(Math.random())"
            :center="{
                            lat: this.info.latitude ? this.info.latitude : 50.259807,
                            lng: this.info.longitude ? this.info.longitude:  19.013125
                           }"
            :markers="markers"/>
    </div>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: unset;
}

.map-container {
    height: 500px;
}

.content--l, .content--sm {

    & .map-container {

    }
}

.content--xs {

    & .map-container {

    }
}

</style>