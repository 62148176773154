<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "File",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          name: "Akademicki Kodeks Wartości",
          size: "3,33 kB"
        }
      }
    }
  }
})
</script>

<template>
  <div class="files__items-container">
    <div class="files__item item">
      <span class="material-symbols-outlined item__icon">picture_as_pdf</span>
      <h2 class="item__name">{{to(this.info, 'name')}}</h2>
      <p class="item__size">({{info.size}})</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;

  &__icon{
    color: var(--icon)
  }

  &__name,
  &__size{
    font-size: 0.78rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    margin: 1% 2%;
    color: var(--text)
  }
}
</style>