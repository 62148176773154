<template>

  <div class="template-page-full" :class="{'template-page-full--show': template_page_full_show}">
    <div class="template-page-full__content">

      <div class="template-page-full__default">
        <slot name="default"></slot>
      </div>

    </div>

    <div class="template-page-full__close-btn">
      <span class="material-symbols-outlined" @click="hide">close</span>
    </div>
  </div>

</template>

<script>

export default {
  name: "TemplateModalFull",
  data: () => ({
    template_page_full_show: false,
  }),
  methods: {
    /**
     * Pokazywanie
     */
    show() {
      let scrollbarWidth = window.innerWidth - document.querySelector("body").offsetWidth + "px"

      document.querySelector(".header").style.transition = "none"
      document.querySelector(".header").style.paddingRight = scrollbarWidth
      document.querySelector("body").style.paddingRight = scrollbarWidth
      document.body.style.overflow = "hidden"
      this.template_page_full_show = true
    },

    /**
     * Ukrywanie
     */
    hide() {
      document.querySelector(".header").style.paddingRight = "0px"
      document.querySelector("body").style.paddingRight = "0px"
      setTimeout(() => {
        document.querySelector(".header").style.transition = "all 0.25s ease 0s"
      }, 250)
      document.body.style.overflow = "auto"
      this.template_page_full_show = false
    }
  }
}
</script>

<style scoped lang="scss">

.template-page-full {
  background-color: rgba(255, 255, 255, 0.99);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  padding: 30px 0;
  visibility: hidden;
  transition: all 0.25s ease 0s;
  z-index: 9;

  &__content {
    height: 100%;
    border-radius: 6px;
  }

  &__default {

  }

  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {

      & .material-symbols-outlined {
        opacity: 0.5;
      }
    }

    & .material-symbols-outlined {
      display: flex;
    }
  }
}

html.contrast {

  & .template-page-full {
    background-color: rgba(0, 0, 0, 0.99);

    &__close-btn {

      & .material-symbols-outlined {
        color: #d2d2d2;
      }
    }
  }
}

</style>