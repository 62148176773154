<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default {
    name: "InfoV2",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    subTitle: "Contact",
                    header: "Get in Touch",
                    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat."
                }
            }
        },
    }
}
</script>

<template>

    <div class="item">
        <h1 class="item__subtitle">{{ to(info, "subTitle") }}</h1>
        <h1 class="item__title">{{ to(info, "title") }}</h1>
        <p class="item__description" v-html="parseText(info, 'description')"></p>
    </div>

</template>

<style scoped lang="scss">

.item {
    display: flex;
    flex-direction: column;
    //align-items: center;

    &__subtitle, &__title, &__description {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
    }

    &__subtitle, &__title {
        font-family: var(--title_font);
        font-weight: 700;
        color: var(--title);
    }

    &__subtitle {
        font-size: 1.56rem;
        margin-bottom: 16px;
    }

    &__title {
        font-size: 2.78rem;
        line-height: 60px;
        margin-bottom: 25px;
    }

    &__description {
        font-family: var(--text_font);
        font-size: 1.11rem;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 24px;
        color: var(--text);
    }

    @media screen and (max-width: 1440px) {

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__description {
            margin-bottom: 32px;
        }
    }

    @media screen and (max-width: 767px) {

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__subtitle {
            font-size: 1.22rem;
        }

        &__title {
            font-size: 2.11rem;
            line-height: normal;
            margin-bottom: 33px;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
            margin-bottom: 32px;
        }
    }
}

.content--l, .content--sm {

    & .item {

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__description {
            margin-bottom: 32px;
        }
    }
}

.content--xs {

    & .item {

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__subtitle {
            font-size: 1.22rem;
        }

        &__title {
            font-size: 2.11rem;
            line-height: normal;
            margin-bottom: 33px;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
            margin-bottom: 32px;
        }
    }
}

</style>