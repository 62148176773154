<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "BlockNews2",
  extends: Element,
  computed: {
    image() {
      return this.info.thumb;
    },
    title() {
      return this.info.title;
    },
    lead() {
      return this.info.lead;
    },
    category_name() {
      return this.info.category_name;
    },
    lang(){
      return localStorage.getItem('lang')
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    info: {
      default() {
        return {}
      }
    }
  }
})
</script>

<template>
  <div class="messages__top-part top-part">
    <div class="top-part__arrows-container arrows-container">
      <div class="arrows-container__left" @click="this.$refs.carousel.prev()">
        <span class="material-symbols-outlined">keyboard_arrow_left</span>
      </div>

      <div class="arrows-container__right" @click="this.$refs.carousel.next()">
        <span class="material-symbols-outlined">keyboard_arrow_right</span>
      </div>
    </div>
  </div>

  <carousel class="slider-v2-container__carousel carousel" ref="carousel" :items-to-show="1">
    <slide class="carousel__item" v-for="item in info.items" :key="item.name">
      <div class="carousel__category category">
        <div class="category__category-item">{{to(item, 'category')}}</div>
        <div class="category__date">{{to(item, 'date')}}</div>
      </div>

      <div class="carousel__content content">
        <h2 class="content__header">{{to(item, 'name')}}</h2>
        <h2 class="content__description">{{to(item, 'description')}}</h2>
      </div>

      <router-link v-if="item.link" :to="item.link" class="content__link-container">
        <div class="carousel__link link">
          <p class="link__description">{{to(item, 'linkName')}}</p>
          <span class="material-symbols-outlined link__icon">arrow_right_alt</span>
        </div>
      </router-link>
    </slide>
  </carousel>
</template>

<style scoped lang="scss">
.carousel__item {
  max-width: 98%;
  background-color: white;
  font-size: 1.11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1%;
  margin: 0;
  border: 1px solid var(--block_light_border);
}

.category {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-content: center;
  align-items: center;

  &__category-item {
    padding: 2% 2%;
    background-color: var(--secondary);
    color: var(--block_text);
    font-size: 0.89rem;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.007em;
    text-align: center;
  }

  &__date {
    font-size: 1rem;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
    margin-left: 5%;
    color: var(--text);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    font-size: 1.33rem;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.001em;
    text-align: left;
    color: var(--link);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: inline-block;
    margin: 1% 0;
  }

  &__description {
    font-size: 0.89rem;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.link {
  max-width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 5% 0;

  &__description {
    font-size: 0.78rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.0005em;
    text-align: left;
    color: var(--link);
    margin: 0;
  }

  &__icon {
    color: var(--icon);
  }
}

.content__link-container{
  text-decoration: none;
}

.top-part{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-content: center;
  align-items: center;
  margin-bottom: 1%;

  &__title-container{
    flex-basis: 80%;
    display: flex;
    flex-direction: column;

    .top-part__header{
      font-size: 1.44rem;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: -0.0008em;
      text-align: left;
      color: var(--link);
      margin: 0 0 0 0;
    }

    .top-part__sub-title{
      font-size: 1.11rem;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.10080009698867798px;
      text-align: left;
      color: var(--secondary);
      margin: 2% 0 1% 0;
    }
  }

  .arrows-container{
    flex-basis: 7%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    &__left,
    &__right{
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border: 1px solid var(--block_light_border);
      color: var(--secondary);
      cursor: pointer;
    }
  }
}

.carousel{
  max-width: 100%;
  margin: 0;
  padding: 0;

  &__slide{
    margin: 0 1%
  }
}

@media only screen and (max-width: 991px) {
  .carousel-container {
    flex-direction: column;
  }
  .content__header{
    margin: 2% 0;
  }
}
</style>