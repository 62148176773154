//Mylib.js
let exports = {};

/**
 * Porównuje dwie daty ale tylko rok, miesiąc i dzień
 *
 * @param d1 pierwsza data
 * @param d2 druga data
 *
 * @returns {boolean} tru jeśli datysą równe
 */
exports.dateEqual = function (d1, d2) {

    if (typeof d1 === 'string' || d1 instanceof String){
        d1 = new Date(d1)
    }
    if (typeof d2 === 'string' || d2 instanceof String){
        d2 = new Date(d2)
    }
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();

}
/**
 * Odejmuje od daty ilość dni
 *
 * @returns {Date} odejmuje ilość dni od daty
 */
exports.dayMinus = function (date, day = 1) {

    if (typeof date === 'string' || date instanceof String){
        date = new Date(date)
    }
    if(date === undefined){
        date = this.now();
    }

    let ret = new Date(date.valueOf());
    ret.setDate(ret.getDate() - day);


    // let ret = Date(new Date(date).setDate(new Date(date).getDate() + day));

    return this.yyyymmdd(ret, false);

}
/**
 * Dodaje do daty ilość dni
 *
 * @returns {Date} dodaje ilość dni od daty
 */
exports.dayPlus = function (date, day = 1) {

    if (typeof date === 'string' || date instanceof String){
        date = new Date(date)
    }
    if(date === undefined){
        date = this.now();
    }

    let ret = new Date(date.valueOf());
    ret.setDate(ret.getDate() + day);


    // let ret = Date(new Date(date).setDate(new Date(date).getDate() + day));

    return this.yyyymmdd(ret, false);


}


exports.dateRange = function (date_start, date_end, value = null){

    // let date_end = CoreTime.now();
    // let date_start = CoreTime.dayMinus(undefined, 7);

    let ret = {}
    while (!this.dateEqual(date_start, date_end)) {

        date_start = this.dayPlus(date_start);

        ret[date_start] = value;
    }

    return ret
}

/**
 * Zwraca wczorajszą datę
 *
 * @returns {Date} wczorajsza data
 */
exports.yesterday = function () {

    return new Date(new Date().setDate(new Date().getDate() - 1));

}

/**
 * Zwraca datę jaka była przed wczoraj
 *
 * @returns {Date} data przedwczoraj
 */
exports.beforeYesterday = function () {

    return new Date(new Date().setDate(new Date().getDate() - 2));

}

/**
 * Zwraca jutrzejszą datę
 *
 * @returns {Date} data jutrzejsza
 */
exports.tomorow = function () {

    return new Date(new Date().setDate(new Date().getDate() + 1));

}

/**
 * Zwraca datę jaka będzie po jutrze
 *
 * @returns {Date} data po jutrze
 */
exports.afterTomorow = function () {

    return new Date(new Date().setDate(new Date().getDate() + 1));

}

/**
 * Sprawdza czy data to dzisiaj
 *
 * @param date sprawdzana data
 */
exports.isToday = function (date) {
    let d = new Date();
    let td = new Date(date);

    return exports.dateEqual(d, td);

}

/**
 * Sprawdza czy podana data jest datą wczorajszą
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data wczorajsza
 */
exports.isYesterday = function (date) {

    let d = exports.yesterday();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}

/**
 * Sprawdza czy podana data jest datą z przed wczoraj
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data z przed wczoraj
 */
exports.isBeforeYesterday = function (date) {

    let d = exports.beforeYesterday();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}


/**
 * Sprawdza czy podana data jest datą jutrzejszą
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data jutrzejsza
 */
exports.isTomorow = function (date) {

    let d = exports.tomorow();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}


/**
 * Sprawdza czy podana data jest datą z po jutrza
 *
 * @param date sprawdzana data
 *
 * @returns {boolean} true jeśli jest to data z po jutrza
 */
exports.isAfterTomorow = function (date) {

    let d = exports.afterTomorow();
    let td = new Date(date);

    return exports.dateEqual(d, td);
}

/**
 * Zwraca datę w formie do wyświetlenia
 *
 * @param date obiekt daty
 *
 * @returns {string} data do wyświetlenia
 */
exports.show = (date, type = 'smart') => {

    let ret = null;

    switch (type) {
        case "smart":
            if (exports.isBeforeYesterday(date)) {
                ret = 'Przed Wczoraj';
            } else if (exports.isYesterday(date)) {
                ret = 'Wczoraj';
            } else if (exports.isToday(date)) {
                ret = 'Dzisiaj';
            } else if (exports.isTomorow(date)) {
                ret = 'Jutro';
            } else if (exports.isAfterTomorow(date)) {
                ret = 'Pojutrze';
            } else {
                let date_obj = new Date(date);  // 2009-11-10

                let month = date_obj.toLocaleString('default', {month: 'short'});
                let day = date_obj.getDate();


                ret = day + ' ' + month;

            }
            break;
        case "date":
            let date_obj = new Date(date);  // 2009-11-10

            let month = date_obj.toLocaleString('default', {month: 'short'});
            let day = date_obj.getDate();


            ret = day + ' ' + month;
            break;
    }

    return ret;

};

exports.showWeekDay = (date) => {

    let date_obj = new Date(date);  // 2009-11-10

    return date_obj.toLocaleDateString('default', {weekday: 'short'});
}

exports.yyyymmdd = (date = null, time = true) => {

    date = date ? date : new Date();
    if (typeof date === 'string' || date instanceof String){
        date = new Date(date)
    }

    let yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString();
    let dd = date.getDate().toString();

    let H = date.getHours();
    let M = date.getMinutes();
    let S = date.getSeconds();

    let mmChars = mm.split('');
    let ddChars = dd.split('');

    let ret = yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);

    if (time) {
        ret = ret + (H < 10 ? '0' : '') + H + ':' + (M < 10 ? '0' : '') + M + ':' + (S < 10 ? '0' : '') + S;
    }

    return ret;

}

exports.now = (time = false) => {

    return exports.yyyymmdd(null, time);

}

export default exports;