<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Logo",
  props: {
    info: {
      default() {
        return {
          header: "They are Talking About Us",
          subTitle: "Add subtitle",
          logos: [{
            title: "videohive",
            icon: "crop_square"
          }, {
            title: "themeforest",
            icon: "crop_square"
          }, {
            title: "photodune",
            icon: "crop_square"
          }, {
            title: "graphicriver",
            icon: "crop_square"
          }, {
            title: "codecanyon",
            icon: "crop_square"
          }, {
            title: "audijungle",
            icon: "crop_square"
          }]
        }
      }
    },
  }
})
</script>

<template>
  <div class="logo__container logo">
    <h2 class="logo__header">{{info.header}}</h2>
    <div class="logo__line"></div>
    <h2 class="logo__subtitle">{{info.subTitle}}</h2>

    <div class="logo__item-wrapper">
      <div class="logo-container__item item" v-for="logo in info.logos" :key="logo.name">
        <span class="material-symbols-outlined item__icon">{{logo.icon}}</span>
        <h2 class="item__header">{{logo.title}}</h2>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .logo{
    width: 1140px;
    max-width: 100%;

    &__header{
      font-size: 1.56rem;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.10080009698867798px;
      text-align: center;
      color: var(--title);
    }

    &__line{
      width: 100%;
      height: 1px;
      background-color: #CBE6F6;
    }

    &__subtitle{
      font-size: 1.11rem;
      font-weight: 500;
      letter-spacing: -0.10080009698867798px;
      text-align: center;
      color: var(--secondary);
    }

    &__item-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        flex-basis: 16%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;

        &__icon{
          color: var(--icon);
        }

        &__header {
          font-size: 1rem;
          font-weight: 700;
          letter-spacing: -0.10080009698867798px;
          text-align: center;
          color: var(--text);
        }
      }
    }
  }

  .material-symbols-outlined {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
  }
</style>