<script>
export default {
  name: 'calendarAbstract',
  emits: ['selectDay', 'selectRange'],
  data: () => ({
    rangeOfDate: null,
    eventsData: [],
    dateMain: new Date(),
    daysNames: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Ndz'],
    monthsNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Pazdziernik', 'Listopad', 'Grudzień'],
  }),
  props: {
    events: {
      type: Array,
      required: true
    },
    date: {
      type: Date,
      default: new Date()
    },
    calendarType: {
      type: String,
      required: true
    },
  },
  computed: {
    today() {
      return new Date()
    },
    year() {
      return this.dateMain.getFullYear()
    },
    month() {
      return this.dateMain.getMonth()
    },
      /**
       * @TODO sprawdzić czy na pewno może być zakomentowane
       */
    // day() {
    //   return this.dateMain.getDate()
    // },
    monthName() {
      return this.monthsNames[this.month];
    },
    daysNamesTab() {
      return this.daysNames;
    },
  },
  methods: {
    sortEvents(day) {
      let startData = new Date(day).toDateString()

      return this.events.filter(event => {
        return new Date(event.date_start).toDateString() === startData
      })
    },
    sortEventsInRange(start, end) {

      let startData = new Date(start)
      let endData = new Date(end)
      endData.setDate(endData.getDate() + 1)

      if (startData < endData) {
        return this.events.filter(event => {
          return new Date(event.date_start) >= startData && new Date(event.date_start) <= endData
        })
      }
    },
    selectDay(day) {
      this.$emit('selectDay', day)
    },
    selectRange(range) {
      this.$emit('selectRange', range)
    },
    eventInDay(day) {
      return this.sortEvents(day.fullDate)
    },
    dayName(day) {
      if (day !== 0) {
        day--
      } else if (day === 0) {
        day = 6
      }
      return this.daysNames[day];
    },

  },
  mounted() {
    this.dateMain = new Date(this.date)
  }
}

</script>

<template>

</template>

<style scoped lang="scss">

</style>