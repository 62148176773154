<script>
import ShowVideo from "@/Library/Show/Video.vue";

export default {
  name: "MovieFile",
  components: {ShowVideo},
  props: {
    info: {
      default() {
        return {};
      }
    }
  },
  computed: {
    url(){
      return this.info && this.info.video ? this.info.video.url : null;
    }
  }
}
</script>

<template>
  <ShowVideo :video="this.url"/>
</template>

<style scoped lang="scss">
.image{
  max-width: 100%;
}

</style>