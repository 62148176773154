<script>
import RenderElement from "@/views/RenderElement.vue";

export default {
  name: "RenderStructureBase",
  components: {RenderElement},
  data: () => ({
    styles: {},
  }),
  props: {
    parent_direction: {
      default() {
        return 'row';
      }
    },
    view: {
      default() {
        return {};
      }
    },
    obj: {
      default() {
        return {};
      }
    },
    section: {
      default() {
        return {};
      }
    },
    sections: {
      default() {
        return [];
      }
    },
    elements: {
      default() {
        return [];
      }
    },
  },
  computed: {
    domElement() {

      let ret = 'div';

      if (this.section.dom !== null)
        ret = this.section.dom;

      return ret;

    },

    sectionContentFill(){

      let section_content_fill_types = ['full','boxed'];

      let section_content_fill_default = 'boxed'

      return section_content_fill_types.includes(this.section.content_fill) ? this.section.content_fill : section_content_fill_default;

    },

    sectionIsFull() {
      return this.section.direction === 'row' && this.sectionContentFill === 'full'
    },

    sectionIsBoxed() {
      return this.section.direction === 'row' && this.sectionContentFill === 'boxed'
    },


    parentDirectionIsRow() {
      return this.parent_direction === 'row';
    },
    parentDirectionIsColumn() {
      return this.parent_direction === 'column';
    },

    addElement() {
      return this.parent_direction === this.section.direction;
    },

    addElementRow() {
      return this.addElement && this.parentDirectionIsRow
    },

    addElementColumn() {
      return this.addElement && this.parentDirectionIsColumn
    },

    sectionIsColumn() {
      return this.section.direction === 'column'
    },

    sectionIsFullContentIsFull() {
      return this.sectionIsFull && this.section.content_fill_content === 'full'
    },

    sectionIsFullContentIsBoxed() {
      return this.sectionIsFull && this.section.content_fill_content === 'boxed'
    },


    isColumnWithElements() {

      let isColumn = this.directionIsColumn;

      let hasElements = this.elementsKidsHas;

      return isColumn && hasElements;

    },

    isColumnWithRows() {

      let isColumn = this.directionIsColumn;

      let hasRows = this.sectionKidsHas;

      return isColumn && hasRows;

    },

    isRowWithWithElements() {

      let isRow = this.directionIsRow;

      let hasElements = this.elementsKidsHas;

      return isRow && hasElements;


    },

    isRowWithWithColumns() {

      let isRow = this.directionIsRow;

      let hasColumns = this.sectionKidsHas;

      return isRow && hasColumns;

    },


    cls() {

      let ret = {};

      if (this.sectionIsFull) {
        ret['full'] = true;
      }

      // if (this.isRowWithWithColumns) {
      //   ret['row'] = true;
      // }

      if (this.isRowWithWithElements) {
        Object.assign(ret, this.elemenetCls)
      }

      if (this.isColumnWithRows) {
        ret[this.sectionSizeClass('xs')] = true;
        ret[this.sectionSizeClass('sm')] = true;
        ret[this.sectionSizeClass('l')] = true;
        ret[this.sectionSizeClass('xl')] = true;
      }

      if (this.isColumnWithElements) {
        ret[this.sectionSizeClass('xs')] = true;
        ret[this.sectionSizeClass('sm')] = true;
        ret[this.sectionSizeClass('l')] = true;
        ret[this.sectionSizeClass('xl')] = true;

        Object.assign(ret, this.elemenetCls)
      }


      return ret;

    },

    elemenetCls() {

      let section = this.section;

      return {
        'content': true,
        'content--horizontal': section.content_direction === 'row',
        'content--vertical': section.content_direction === 'column',
        'content--left': section.content_align === 'left',
        'content--center': section.content_align === 'center',
        'content--right': section.content_align === 'right',
        'content--top': section.content_vertical_align === 'top',
        'content--middle': section.content_vertical_align === 'middle',
        'content--bottom': section.content_vertical_align === 'bottom',
      }

    },

    elementsKids() {
      return this.elements.filter(element => element.id_base_template_section === this.section.id)
    },

    elementsKidsLength() {
      return this.elementsKids.length;
    },

    elementsKidsHas() {
      return this.elementsKidsLength > 0;
    },

    sectionKids() {
      return this.sections.filter(section => section.id_top === this.section.id)
    },

    sectionKidsLength() {
      return this.sectionKids.length;
    },

    sectionKidsHas() {
      return this.sectionKidsLength > 0;
    },


    localElements() {
      return this.elements.filter(el => el.id_base_template_section === this.section.id)
    },

    siblings() {

      return this.sections.filter(section => section.id_top === this.section.id_top)

    },

    siblingsCount() {

      return this.siblings.length;

    },

    direction() {
      return this.section.direction;
    },

    directionIsColumn() {
      return this.direction === 'column';
    },

    directionIsRow() {
      return this.direction === 'row';

    },


    sectionStyle() {

      let style_default = {...this.section['style']};
      let style_preview = {...this.section['style_' + this.screenWidthStr]};


      let fields_default = style_default ? Object.keys(style_default) : [];
      let fields_preview = style_preview ? Object.keys(style_preview) : [];

      let ret = {};
      fields_default.forEach(field => {
        ret[field] = style_default[field];
      })
      fields_preview.forEach(field => {
        ret[field] = style_preview[field];
      })

      return ret;
    },

    sectionStylesRender() {

      let section_style = this.sectionStyle;

      let fields = Object.keys(section_style);
      let ret = {};
      fields.forEach(field => {

        ret[field] = {};
        let val = section_style[field];


        if (field === 'font-size')
          val += 'px'
        if (field === 'border-radius')
          val += 'px'
        if (field === 'border-width')
          val += 'px'
        if (field.includes('margin'))
          val += 'px'
        if (field.includes('padding'))
          val += 'px'

        ret[field] = val;

      });

      return ret;
    }


  },

  methods: {

    translateScreenSize(screen) {

      switch (screen) {
        case 'xs':
          return 'xs';
        case 'sm':
          return 'xs';
        case 'md':
          return 'sm';
        case 'l':
        case 'lg':
          return 'sm';
        case 'xl':
          return 'lg';
        case 'xxl':
          return 'xl';

      }

    },

    sectionSizeClass(sizeScreen) {

      let section = this.section;

      let parent = this.sections.find(parent => parent.id === section.id_top)

      let screenSizeForClass = this.translateScreenSize(sizeScreen);


      let size = section['show_' + sizeScreen + '_size'];
      let show = section['show_' + sizeScreen];
      let direction = parent ? parent['show_' + sizeScreen + '_direction'] : 'column';

      let cls = '';
      if (show === 'no') {
        cls = 'd-' + (screenSizeForClass !== 'xs' ? screenSizeForClass + '-' : '') + 'none';
        return cls;
      }
      if (direction === 'column') {
        cls = 'col-' + (screenSizeForClass !== 'xs' ? screenSizeForClass + '-' : '') + '12';
        return cls;
      }

      cls = 'col';
      if (sizeScreen === 'xs' && size !== 'auto') {
        cls = 'col-' + screenSizeForClass;
      }
      if (sizeScreen !== 'xs' && size !== 'auto') {
        cls = 'col-' + screenSizeForClass + '-' + size;
      }

      return cls;

    },


    stylesUpdate() {

      this.styles = this.sectionStylesRender;

    }

  },

  mounted() {

    this.stylesUpdate();

  },
}
</script>
