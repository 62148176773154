<template>
  <div class="note">
    <img class="note__img" src="@/assets/images/umbrella.svg">
    <p class="note__text" v-html="to(info, 'text')"/>
  </div>
</template>

<script>
import NoteAbstract from "@/views/Base/Note/Abstract.vue";

export default {
  name: "Note1",
  extends: NoteAbstract,

}
</script>

<style scoped lang="scss">

.note {

  max-width: 100%;

  background: #FFF4CE;
  opacity: 0.8;
  border-radius: 6px;
  padding: 18px;

  &__img {
    margin-bottom: 8px;
  }

  &__text {
    line-height: 1.5rem;
    margin-bottom: 0;
  }
  &__text p:last-child{
    margin-bottom: 0;
  }
}

</style>