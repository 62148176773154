<script>

import axios from "@/axios";
import RenderStructure from "@/views/RenderStructure.vue";
import LangSelect from "@/views/Base/Lang/Lang.vue";

export default {
  name: "RenderLayout",
  components: {LangSelect, RenderStructure},
  props: {},
  data: () => ({

    direction: 'row',

    get_section_url: 'basetemplate/sectionget',
    get_section_params: {
      template_code: 'layout',
      template_status: 'actual',
      sort: 'number',
    },
    get_element_url: 'basetemplate/elementget',
    get_element_params: {
      template_code: 'layout',
      template_status: 'actual',
      sort: 'number',
    },
    sections: [],
    elements: [],
  }),
  computed: {

    sectionsMain() {

      return this.sections.filter(item => item.id_top === null)

    }

  },
  methods: {
    async loadSections() {

      this.sections = await axios
          .get(this.get_section_url, {params: this.get_section_params})
          .then(result => result.data.data)

    },
    async loadElements() {

      this.elements = await axios
          .get(this.get_element_url, {params: this.get_element_params})
          .then(result => result.data.data)

    },
  },
  async mounted() {
    await this.loadSections();
    await this.loadElements();
  }
}
</script>

<template>
  <RenderStructure
      v-for="item in sectionsMain"
      :parent_direction="'row'"
      :section="item"
      :sections="sections"
      :elements="elements"
  />
</template>