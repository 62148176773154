<template>
  <span class="date" :title="date">
    <span class="material-symbols-outlined" v-if="icon_show">calendar</span>
    {{ date_show }}
  </span>
</template>

<script>

export default {
  name: "ShowDate",
  props: {
    date: {},
    icon: {
      default() {
        return false;
      }
    },
    show_null: {
      default() {
        return true;
      }
    },
    show_year_always: {
      default() {
        return false;
      }
    },
    null_text: {
      default() {
        return "Brak";
      }
    }
  },
  computed: {

    icon_show() {
      let ret = true;
      if (!this.date)
        ret = false;

      if (!this.icon)
        ret = false;

      return ret;

    },
    date_show() {

      let ret = null;
      if (this.show_null)
        ret = this.null_text;


      if (this.date) {
        let date = new Date(this.date);  // 2009-11-10

        let month = date.toLocaleString('default', {month: 'short'});
        let day = date.getDate();
        let year = date.getFullYear();
        let year_now = (new Date()).getFullYear();

        ret = day + ' ' + month;

        if (year !== year_now || this.show_year_always)
          ret += " " + year;

      }

      return ret;

    }

  }

}
</script>

<style scoped lang="scss">

.date {

}

</style>