<template>

</template>

<script>
import Element from "@/components/Element.vue";

export default {
  name: "LinkAbstract",
  extends: Element,
  emits: ['click']
}
</script>

<style scoped>

</style>