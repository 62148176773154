<script>
import {defineComponent} from 'vue'
import Title from "@/views/Base/Title/Title.vue";
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";
import MenuBlock from "@/views/Base/Menu/Block.vue";
import LinkShowMore from "@/views/Base/Link/ShowMore.vue";

export default defineComponent({
  name: "BlockBlue",
  components: {LinkShowMore, MenuBlock, Thumb1, Title},
  data: () => ({
    start: "</div></div>",
    end: "<div class='home'><div class='container'>",
  }),
  computed: {
    topBg() {

      let el = undefined;
      let top = 400;
      if (this.$refs.block)
        el = this.$refs.block;
      if (el)
        top = el.getBoundingClientRect().top;

      return top+'px';
    }
  }

})
</script>

<template>
  <template v-html="start"></template>
  <div class="block" ref="block">
    <div class="row">
      <div class="col-12 col-lg-5">
        <Title title="Najbardziej <br/>oblegane kierunki" color="white" color_border="#011535" style="margin: 0;padding: 0;"/>
      </div>
      <div class="col-12 col-lg-6">
        <Thumb1 radius="big" image="https://www.otouczelnie.pl/assets/uploads/news_rekrutacja/88d0a-prawo-us2.jpg"/>
      </div>
    </div>
    <div class="row" style="margin-top: -100px;">
      <div class="col-12">
        <MenuBlock/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <br>
        <LinkShowMore color="white"/>
      </div>
    </div>
  </div>
  <template v-html="end"></template>
</template>

<style scoped lang="scss">

.block {
  background: var(--main);
  padding: 80px 20px;
  margin: 50px 0;
& > *{
  position: relative;
  z-index: 2;
}

  &__bg-full{
    position: absolute;
    left: 0;
    right: 0;
    top: 1000px;
    height: 645px;
    content: '';
    background-color: var(--main);
    width: 100%;
    //opacity: .2;
    //z-index: 1;
  }
}

</style>