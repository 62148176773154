<template>

    <div class="item" :class="{'item--reverse': info.position === 'right'}">
        <img class="item__image" :src="info.image.url" alt="image"/>

        <div class="item__text text">
            <h1 class="text__title">{{ to(info, "title") }}</h1>
            <p class="text__description" v-html="parseText(info, 'description')"></p>
        </div>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "ImageDescriptionV1",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    position: "left",
                    image: {
                        url: "https://picsum.photos/606/456/?blur=10"
                    },
                    title: "Lorem ipsum dolor sit amet, consectetur adipisicing",
                    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
                }
            }
        }
    }
})
</script>

<style scoped lang="scss">

.item {
    display: flex;

    &__image {
        min-width: 100px;
        max-width: 608px;
        width: 100%;

        border-radius: 0 10px 10px 0;

        object-fit: cover;
    }

    &__text {
        width: 100%;
        margin: 126px 150px 126px 127px;
        text-align: left;
    }

    &--reverse {
        flex-direction: row-reverse;

        & .item {

            &__image {
                border-radius: 10px 0 0 10px;
            }

            &__text {
                margin: 126px 127px 126px 150px;
            }
        }
    }

    @media screen and (max-width: 1440px) {

        &__image {
            max-width: 372px;
        }

        &__text {
            margin: 47px 24px 45px 24px!important;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;

        &__image {
            max-width: unset;
            height: 206px;
            border-radius: 10px!important;
        }

        &__text {
            margin: 24px 0 0 0!important;
            text-align: center;
        }
    }
}

.text {

    &__title, &__description {
        font-style: normal;
        line-height: normal;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.56rem;
        font-weight: 700;

        margin-bottom: 16px;
        color: var(--title);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 1.11rem;
        font-weight: 400;
        line-height: 30px;

        color: var(--text);
    }

    @media screen and (max-width: 1440px) {

        &__title {
            margin-bottom: 8px;
            font-size: 1.22rem;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }

    @media screen and (max-width: 767px) {

        &__title {
            font-size: 1.22rem;
            margin-bottom: 8px;
        }

        &__description {
            font-size: 0.78rem;
            line-height: 24px;
        }
    }
}

.content--l, .content--sm {

    & .item {

        &__image {
            max-width: 372px;
        }

        &__text {
            margin: 47px 24px 45px 24px;
        }
    }

    & .text {

        &__title {
            margin-bottom: 8px;
            font-size: 1.22rem;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

.content--xs {

    & .item {
        flex-direction: column;

        &__image {
            max-width: unset;
            height: 206px;
            border-radius: 10px;
        }

        &__text {
            margin: 24px 0 0 0;
            text-align: center;
        }
    }

    & .text {

        &__title {
            font-size: 1.22rem;
            margin-bottom: 8px;
        }

        &__description {
            font-size: 0.78rem;
            line-height: 24px;
        }
    }
}

</style>