<script>

import axios from "@/axios";
import SearchItem from "@/views/Base/Search/SearchItem.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import Element from "@/components/Element.vue";
import Row from "@/Library/Forms/Row.vue";

export default {
    name: "Search",
    extends: Element,
    components: {
        Row,
        SearchItem
    },
    mixins: [ComponentFormStateMachine],
    props: {},
    data: () => ({
        show_search: false,
        search_text: null,

        timeout_handler: null,
        timeout_ms: 500,

        objs: []
    }),
    methods: {
        updateValue(name, value) {
            this.$data[name] = value

            clearTimeout(this.timeout_handler);
            this.timeout_handler = setTimeout(async () => {
                this.load()
            }, this.timeout_ms)
        },

        load() {
            let params = {
                search_text: this.search_text,
                sort: "create_date DESC",
                scope: "title,text,thumb,slug,slugs,langs,urls",
                version: "no"
            }

            let url = "article/get"

            this.stateUpdate("sendInfo")

            axios
                .get(url, {params: params})
                .then(response => {
                    this.objs = response.data.data

                    this.stateUpdate("sendSuccess")
                })
                .catch(error => {

                })
        },

        itemClick(link) {
            this.show_search = false;

            setTimeout(() => {
                this.linkClick(link)
            }, 250)
        }
    }
}

</script>

<template>

    <div class="button" @click="show_search = true">
        <span class="material-symbols-rounded">search</span>
    </div>

    <div class="search" :class="{'search--show': show_search}">
        <div class="search__input global-search-input">
            <Row type="text" name="search_text" label="Wyszukiwanie" :value="search_text" @updateValue="updateValue"/>
            <span class="material-symbols-rounded">search</span>
        </div>
        <div class="search__content content" :class="{'loading': stateLoading()}">
            <div class="content__objs">
                <SearchItem v-for="obj in objs" :item="obj" :simulate="simulate" @itemClick="itemClick"/>
            </div>
        </div>

        <div class="search__close">
            <div class="button" @click="show_search = false">
                <span class="material-symbols-rounded">close</span>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

.global-search-input {
    position: relative;

    & .row-nice {
        margin: 0!important;
    }

    & input, & label {
        background: var(--block_dark_bg)!important;
        color: var(--block_dark_text)!important;
    }

    & input {
        border-radius: 25px!important;
        padding-right: calc(24px + 24px + 12px)!important;
    }

    & label {
        border-radius: 14px;
    }

    & > .material-symbols-rounded {
        position: absolute;
        top: calc(50% - (24px / 2));
        right: 24px;
        color: var(--block_dark_text);

        cursor: pointer;
        transition: all 0.25s ease 0s;

        &:hover {
            transform: scale(1.1);
        }
    }
}

</style>

<style scoped lang="scss">

.button {
    margin: 0;
    min-width: 48px;
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--border);
    transition: all 0.25s ease 0s;
    cursor: pointer;

    &:hover {
        transform: scale(0.95);
    }

    &:active {
        transform: scale(1);
    }

    & > .material-symbols-rounded {

    }
}

.search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh + 50px);
    z-index: 99999;
    background: rgba(0, 0, 0, 0.9);

    transition: all 0.25s ease 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50px);

    display: flex;
    flex-direction: column;

    &__input {
        width: 100%;
        max-width: 500px;
        margin: 100px auto 50px auto;
    }

    &__content {
        height: 100%;
        overflow-y: auto;
    }

    &__close {
        margin-top: auto;
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        & .material-symbols-rounded {
            color: var(--block_dark_text);
        }
    }

    &--show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        height: 100vh;
    }
}

.content {

    min-height: 200px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    &__objs {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        gap: 30px;
    }
}


</style>