<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "BlockFaQ",
    components: {},
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    type: "type_1",
                    items: [
                        {
                            question: "Is Wireland for Web the best wireframe template for my design project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does Wireland for Web have more than 300 different UI components?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Can I Create e-commerce using this wireframe kit?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Will this wireframe library save me more than 30 hour of work per week?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does this template includes video documentation?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "How will Wireland for Web help me become more efficient?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Is Wireland for Web the best wireframe template for my design project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        }
                    ]
                }
            }
        }
    },
    methods: {
        expand(event, item) {
            item.expanded = !item.expanded

            let item_html = event.target

            if (!item_html.classList.contains("item"))
                item_html = item_html.closest(".item")

            let item_body = item_html.querySelector(".item__body")
            let body_description = item_body.querySelector(".body__description")

            if (item.expanded) {
                item_body.style.height = body_description.offsetHeight + "px"
            }

            if (!item.expanded) {
                item_body.style.height = 0
            }
        }
    }
})
</script>

<template>

    <ul class="list" :class="{'list--type-2': info.type === 'type_2'}">
        <template v-for="item in info.items">
            <li
                v-if="to(item, 'question')"
                class="list__item item"
                :class="{'item--expanded': item.expanded}">
                <div class="item__head head" @click="expand($event, item)">
                    <h1 class="head__title">{{ to(item, "question") }}</h1>
                    <div class="head__arrow">
                        <span class="material-symbols-rounded">expand_more</span>
                    </div>
                </div>
                <div class="item__body body">
                    <p class="body__description" v-html="parseText(item, 'option')"></p>
                </div>
            </li>
        </template>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    row-gap: 8px;

    width: 100%;

    &__item {
        margin-left: 0;
    }

    &--type-2 {
        row-gap: unset;
        border-radius: 9px;
        border: 1px solid var(--border);

        & .item {
            border-radius: unset;
            border: none;

            border-bottom: 1px solid var(--border);

            &:last-child {
                border-bottom: none;

                & .body {
                    border-bottom-left-radius: 9px;
                    border-bottom-right-radius: 9px;
                }
            }

            &--expanded {

                & .head__arrow {
                    border-radius: 4px;
                    box-shadow: var(--block_light_bg) 0px 0px 0px 3px;
                }
            }
        }

        & .head {

        }

        & .body {
            background: var(--block_light_bg);

            &__description {
                border-top: 1px solid var(--border);
            }
        }

        @media screen and (max-width: 767px) {
            border-left: none;
            border-right: none;
            border-radius: unset;

            & .body__description {
                border-top: none;
            }
        }
    }
}

.item {
    border-radius: 6px;
    border: 1px solid var(--border);

    &__head {

    }

    &__body {

    }

    &--expanded {

        & .head__arrow {

            & > .material-symbols-rounded {
                transform: rotate(180deg);
            }
        }
    }
}

.head__title, .body__description {
    font-style: normal;
    line-height: normal;
    text-align: start;
    margin-top: 0;
}

.head {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    padding: 24px;

    cursor: pointer;

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        color: var(--title);
        margin-bottom: 0;
    }

    &__arrow {
        display: flex;
        height: fit-content;

        & > .material-symbols-rounded {
            color: var(--icon);
        }
    }
}

.body {
    height: 0;
    overflow: hidden;

    transition: all 0.25s linear;

    &__description {
        font-family: var(--text_font);
        padding: 24px;
        color: var(--text);

        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .list {

        &--type-2 {
            border-left: none;
            border-right: none;
            border-radius: unset;

            & .body__description {
                border-top: none;
            }
        }
    }
}

</style>