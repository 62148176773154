<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";

export default defineComponent({
  name: "Persons",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    info: {
      default() {
        return {
          header: "Najnowsze informacje",
          subTitle: "Ciekawostki",
          sliders: [{
            image: "https://us.edu.pl/wp-content/uploads/imagefront/IMG_9136_foto-Julia-Agnieszka-Szymala.jpg",
            description: "We wtorek 3 października 2023 roku Uniwersytet Śląski w Katowicach podpisał umowę dotyczącą dofinansowania przez Górnośląsko-Zagłębiowską Metropolię projektu „Gra kryminalna «Zbrodnia nad Rawą – pomóż nam rozwiązać zagadkę kryminalną w centrum Katowic»”. Celem przedsięwzięcia jest poszerzanie wiedzy uczniów szkół ponadpodstawowych z zakresu kryminologii, kryminalistyki, prawa i psychologii. Projekt ma również na celu przedstawienie potencjału naukowego uczelni, jej różnorodnej oferty edukacyjnej oraz infrastruktury badawczej, zachęcając młodzież do podjęcia studiów w Górnośląsko-Zagłębiowskiej Metropolii.",
            name: "Projekt „Gra kryminalna «Zbrodnia nad Rawą…»” dofinansowany przez GZM",
          }, {
            image: "https://us.edu.pl/wp-content/uploads/obrazek-wyr%C3%B3%C5%BCniaj%C4%85cy/helloquence-OQMZwNd3ThU-unsplash__-600x401.jpg",
            description: "22 września 2023 roku prezydium Konferencji Rektorów Akademickich Szkół Polskich (KRASP) przyjęło stanowisko, w którym w pełni poparło Apel Rady Głównej Nauki i Szkolnictwa Wyższego do członków społeczności akademickiej w Polsce z 13 września 2023 roku dotyczy udziału i oddania ważnego głosu w wyborach parlamentarnych.",
            name: "Stanowisko prezydium KRASP ws. apelu Rady Głównej Nauki i Szkolnictwa Wyższego z 13 września 2023 r.\n",
          }, {
            image: "https://us.edu.pl/wp-content/uploads/obrazek-wyrozniajacy/profilowe.jpg",
            description: "Z okazji setnej rocznicy urodzin Wisławy Szymborskiej, od 16 do 20 października 2023 roku w Centrum Informacji Naukowej i Bibliotece Akademickiej w Katowicach (CINiBA) będzie się odbywał cykl wydarzeń poświęcony osobie noblistki.",
            name: "„Wolę… Szymborską!” – 100. rocznica urodzin noblistki",
          }, {
            image: "https://us.edu.pl/wp-content/uploads/fotografie/Biblioteka_Slaska_01-900x600.jpg",
            description: "W październiku 2023 roku stanowisko dyrektora Biblioteki Śląskiej w Katowicach objął dr Artur Madaliński – filolog, menedżer kultury i wykładowca akademicki związany z Uniwersytetem Śląskim. Zgodnie z uchwałą zarządu województwa śląskiego obowiązki będzie pełnić przez trzy lata – do 30 września 2026 roku.",
            name: "Dr Artur Madaliński dyrektorem Biblioteki Śląskiej",
          }, {
            image: "https://us.edu.pl/wp-content/uploads/obrazek-wyr%C3%B3%C5%BCniaj%C4%85cy/AJS2220-600x401.jpg",
            description: "Jedność świata, o której mowa, nie opiera się na podobieństwie jednostek, społeczeństw, kultur czy historii, ale na wspólnocie problemów, z jakimi – pomimo tych różnic – zmagamy się jako ludzie. Zagrożenia klimatyczne, przyszłość edukacji, nierówności i wykluczenia, migracje, opieka zdrowotna, stan środowiska naturalnego to problemy, o których wiemy, że nigdy nie zostaną na dobre rozwiązane. Ta świadomość w połączeniu z globalną skalą wyzwań sprawia, że łatwo ulec poczuciu bezradności, poddać się rezygnacji lub melancholii. Zrozumiała jest też skłonność jednostek i społeczeństw do wypychania części problemów poza granice naszej realnej odpowiedzialności.",
            name: "Powaga uniwersytetu | List rektora UŚ na początek roku akademickiego 2023/2024",
          }, {
            image: "https://us.edu.pl/wp-content/uploads/fotografie/IMG_8993_fot-Julia-Agnieszka-Szymala.jpg",
            description: "Wyniki naboru do tego funduszu zostały ogłoszone we wtorek 26 września 2023 roku. O dotacje ubiegały się jednostki samorządu terytorialnego. Środki są przeznaczane na prace konserwatorskie i restauratorskie oraz roboty budowlane w zabytkowych obiektach. Na remont elewacji uniwersyteckiego budynku Cieszyn otrzymał dofinansowanie w wysokości 1 300 000 zł. Celem jest przywrócenie walorów architektonicznych obiektu, poprawa stanu technicznego oraz estetyki i atrakcyjności przestrzeni publicznej obszaru rewitalizowanego. Planowane jest przeprowadzenie robót budowlanych oraz prac konserwatorskich, m.in ",
            name: "Dofinansowanie renowacji elewacji budynku Wydziału Sztuki i Nauki o Edukacji UŚ",
          }]
        }
      }
    }
  }
})
</script>

<template>
  <div class="messages__container">
    <div class="messages__top-part top-part">
      <div class="top-part__title-container">
        <h2 class="top-part__sub-title">{{info.subTitle}}</h2>
        <h2 class="top-part__header">{{info.header}}</h2>
      </div>

      <div class="top-part__arrows-container arrows-container">
        <div class="arrows-container__left" @click="this.$refs.carousel.prev()">
          <span class="material-symbols-outlined">keyboard_arrow_left</span>
        </div>

        <div class="arrows-container__right" @click="this.$refs.carousel.next()">
          <span class="material-symbols-outlined">keyboard_arrow_right</span>
        </div>
      </div>
    </div>

    <carousel class="slider-v2-container__carousel carousel" ref="carousel" :items-to-show="3.5">
      <slide class="carousel__slide" v-for="item in info.sliders" :key="item.name">
        <div class="carousel__item">
          <div class="carousel__category category">
            <img :src="item.image" alt="Person image" />
          </div>

          <div class="carousel__content content">
            <h2 class="content__header">{{item.name}}</h2>
            <h2 class="content__description">{{item.description}}</h2>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<style scoped lang="scss">
.messages__container{
  width: 1170px;
  max-width: 100%;

  overflow: hidden;

  margin: 0 auto;
  .top-part{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    &__title-container{
      flex-basis: 80%;
      display: flex;
      flex-direction: column;

      .top-part__header{
        font-size: 1.44rem;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: -0.0008em;
        text-align: left;
        color: var(--title);
        margin: 0 0 0 0;
      }

      .top-part__sub-title{
        font-size: 1.11rem;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.10080009698867798px;
        text-align: left;
        color: var(--secondary);
        margin: 2% 0 1% 0;
      }
    }

    .arrows-container{
      flex-basis: 7%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      &__left,
      &__right{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border: 1px solid var(--block_light_border);
        color: var(--secondary);
        cursor: pointer;
      }
    }
  }

  .carousel{
    position: relative;

    &__item {
      width: 100%;
      background-color: white;
      font-size: 1.11rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //border-radius: 10px;
      padding: 1.5rem;
      border: 1px solid var(--block_light_border);

    }

    &__slide {
      padding: 0;
      padding-right: 12px;


      &:last-of-type{
        padding-right: 0;
      }
    }

    .category{
      max-width: 315px;
      height: 215px;
      //border-radius: 7px;
      overflow: hidden;

      display: flex;
      flex: 1 1 auto;

      img{
        width: 100%;
        height: 100%;
        //object-fit: contain;
        object-fit: cover;
      }
    }

    .content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 3% 0;

      &__header{
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--title);
        height: 50px;
        overflow: hidden;
      }

      &__description{
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--text);
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &__prev,
    &__next {
      box-sizing: content-box;
      border: 5px solid white;
    }
  }
}
</style>