<script>

import Element from "@/components/Element.vue";

export default {
    name: "ArticleEventBlock",
    props: {
        item: {
            default() {
                return null
            }
        },
        slugs: {
            default() {
                return []
            }
        }
    },
    extends: Element,
    computed: {
        lang() {
            return localStorage.getItem('lang')
        },

        month() {
            let ret = null

            if (this.item) {
                let date_arr = this.item.event_date_start?.split(" ")[0].split("-")

                let date = null
                let month = null

                if (date_arr && date_arr.length === 3) {
                    date = new Date(date_arr[0], date_arr[1] - 1, date_arr[2])
                    month = date.toLocaleString('default', { month: 'long' });
                }

                ret = month ? month : "Brak"
            }

            return ret
        },

        day() {
            let ret = null

            if (this.item) {
                let date = this.item.event_date_start?.split(" ")[0]

                ret = date ? date.split("-")[2] : "Brak"
            }

            return ret
        }
    }
}

</script>

<template>

    <router-link :to="item.slugs[lang] ?? './'" class="item">
        <div class="item__date date">
            <div class="date__month">{{ month }}</div>
            <div class="date__day">{{ day }}</div>
        </div>

        <h1 class="item__text">{{ item.title }}</h1>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: var(--link);
}

.item {
    display: flex;
    column-gap: 16px;

    &:hover {

        & .date {

            &__month {
                transform: scale(0.90);
            }

            &__day {
                transform: scale(1.2);
            }
        }
    }

    &__date {

    }

    &__text {
        margin-top: 5px;
        font-size: 0.89rem;
    }
}

.date {
    min-width: 100px;
    width: 100px;
    height: 100px;
    background: #222256;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__day, &__month {
        color: white;
        transition: all 0.25s ease 0s;
    }

    &__month {

    }

    &__day {
        font-size: 1.78rem;
    }
}

</style>