<script>
import Element from "@/components/Element.vue";

export default {
  name: "ArticleLead",
  extends: Element,
}
</script>

<template>
  <b>{{ to(obj, 'lead')}}</b>

</template>

<style scoped lang="scss">

</style>