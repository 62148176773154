<template>
  <div class="toggle-block" :class="{'toggle-block--open': state === 'open'}">
    <div class="toggle-block__name" @click="toggle">
      {{ info.name }}
    </div>
    <div class="toggle-block__text">
      {{ info.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleBlock",
  emits: ['open', 'close'],
  data: () => ({
    state: 'close',
    info: {
      name: 'Audyt',
      text: 'Ogół działań, poprzez które uzyskuje się niezależną ocenę funkcjonowania instytucji, legalności, gospodarności, celowości, rzetelności. Audyt jest zazwyczaj wykonywany przez odrębną komórkę, podporządkowaną bezpośrednio kierownikowi instytucji (audyt wewnętrzny) lub przez podmiot zewnętrzny (audyt zewnętrzny).'
    }
  }),
  methods: {

    open() {

      this.$emit('open');
      this.state = 'open';

    },
    close() {
      this.$emit('close');
      this.state = 'close';

    },
    toggle() {

      let action = null;
      if (this.state === 'open')
        action = 'close';
      if (this.state === 'close')
        action = 'open';

      if (action === 'open')
        this.open();
      if (action === 'close')
        this.close();

    }
  }
}
</script>

<style scoped lang="scss">

.toggle-block {
  margin-bottom: 17px;
  background-color: var(--bg);
  padding: 12px;

  position: relative;

  border-radius: 12px;
  overflow: hidden;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    //background-color: var(--main);
    content: '';

    opacity: .03;
  }

  &__name {
    color: var(--main);
    font-size: 1.15rem;
    line-height: 1.3rem;
    font-weight: 400;
    cursor: pointer;
    margin: -12px;
    padding: 12px;

    &:before {
      position: absolute;

      right: 20px;
      top: 10px;

      content: '';

      width: 10px;
      height: 10px;

      border: 2px solid var(--main);
      border-top: 0;
      border-right: 0;

      transform: rotate(-45deg);
      transition: all .3s;
    }

  }

  &__text {
    font-size: 1.15rem;
    line-height: 1.85rem;
    font-weight: 300;
    display: none;
    margin-top: 22px;
    padding-right: 4rem;
  }

  &--open &__name:before {
    transform: rotate(135deg);
    top: 19px;
  }

  &--open &__text {
    display: block;
  }

}

</style>