<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";
import ShowDate from "@/Library/Show/Date.vue";

export default defineComponent({
    name: "News4",
    components: {ShowDate},
    extends: Element,
    computed: {
        image() {
            return this.obj.thumb;
        },
        lead() {
            return this.obj.lead;
        },
        category_name() {
            return this.obj.category_name && this.obj.category_name !== "" ? this.obj.category_name : null;
        },
        date() {
            return this.obj.date;
        },
        title() {
            return this.obj.title;
        },
        lang() {
            return localStorage.getItem('lang')
        },
        link() {
            let urls = this.obj.urls;
            let lang = this.langGet();
            return urls[lang]
        },
    },
    props: {
        obj: {
            default() {
                return {}
            }
        },
        slugs: {
            default() {
                return []
            }
        }
    }
})
</script>

<template>

    <router-link :to="slugs[lang] ?? './'" class="item">
        <div class="item__badges badges">
            <p class="badges__category" v-if="category_name">{{ to(obj, "category_name") }}</p>
            <ShowDate class="badges__date" :date="date" :show_year_always="true"/>
        </div>
        <router-link :to="slugs[lang] ?? './'">
            <h1 class="item__title three-dots three-dots--3">{{ to(obj, 'title') }}</h1>
        </router-link>
        <p class="item__description three-dots three-dots--4">{{ to(obj, 'lead') }}</p>
        <router-link :to="slugs[lang] ?? './'" class="item__link">
            <p>{{ t("read_more") }}</p>
            <span class="material-symbols-rounded">arrow_forward</span>
        </router-link>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.item {
    //display: block;
    border-radius: 9px;
    border: 1px solid var(--border);
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 34px 99px 47px 29px;

    margin: 0!important;

    & .badges__category, & .badges__date, &__title, &__description, &__link > p {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    & .badges__category, & .badges__date, &__description, &__link > p {
        font-family: var(--text_font)
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.56rem;
        font-weight: 700;
        margin-bottom: 24px;
        color: var(--link);
        text-align: start;
    }

    &__description {
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 33px;
        color: var(--text);
        text-align: start;
    }

    &__link {
        margin-top: auto;

        display: flex;
        align-items: center;
        column-gap: 12px;

        & > p, & > .material-symbols-rounded {
            color: var(--text);
        }

        & > p {
            font-size: 0.78rem;
            font-weight: 500;
            text-decoration-line: underline;
            margin-bottom: 0;
        }

        & > .material-symbols-rounded {

        }
    }

    @media screen and (max-width: 767px) {
        padding-right: 29px;
    }
}

.badges {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 16px;

    &__category {
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.7px;
        padding: 6px 9px;
        border-radius: 2px;
        color: var(--block_dark_text);
        background: var(--block_dark_bg);
        width: fit-content;
        margin-bottom: 0;
    }

    &__date {
        font-size: 0.67rem;
        font-weight: 400;
        line-height: 20px;
        color: var(--text);
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .item {
        padding-right: 29px;
    }
}

</style>