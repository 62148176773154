<script>
import {defineComponent} from 'vue';
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// If you are using scss you can skip the css imports below and use scss instead
import styles from 'lightgallery/scss/lightgallery.scss';

export default defineComponent({
  name: "GalleryV3",
  components: {
    Lightgallery,
  },
  props: {
    info: {
      default() {
        return {
          header: "Tytuł galerii",
          description: "Staw Kozubek po rewitalizacji - część 1",
          images: [{
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 1"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 2"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 3"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 4"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 5"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 6"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 7"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 8"
          }, {
            link: "https://images.unsplash.com/photo-1695713978690-f37c3e224530?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
            description: "Image 9"
          }]
        }
      }
    }
  },
  data: () => ({
    plugins: [lgThumbnail, lgZoom],
  }),
  methods: {
    onInit: () => {
      console.log('lightGallery has been initialized');
    },
    onBeforeSlide: () => {
      console.log('calling before slide');
    },
  },
})
</script>

<template>
  <div class="gallery-v3__container">
    <div class="gallery-v3__line"></div>

    <div class="gallery-v3__header header">
      <div class="header__count-part">
        <h2 class="header__count">{{info.images.length}}</h2>
      </div>

      <div class="header__text-part">
        <h2 class="header__text">{{info.header}}</h2>
        <h2 class="header__description">{{info.description}}</h2>
      </div>
    </div>

    <div class="gallery-v3">
      <div class="gallery-v3__images images" v-for="image in info.images" :key="image.name">
        <img :src="image.link" alt="Image" />
      </div>
    </div>

<!--    <div class="gallery-v3">-->
<!--      <lightgallery-->
<!--          :settings="{ speed: 500, plugins: plugins }"-->
<!--          :onInit="onInit"-->
<!--          :onBeforeSlide="onBeforeSlide"-->
<!--          class="gallery-v3__images images"-->
<!--          v-for="image in info.images" :key="image.name">-->
<!--              <a :href="image.link">-->
<!--                <img :alt="image.description" :src="image.link" />-->
<!--              </a>-->
<!--      </lightgallery>-->
<!--    </div>-->


  </div>
</template>

<style scoped lang="scss">
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-zoom.css';

.gallery-v3__container {
  width: 1140px;
  max-width: 100%;
  position: relative;

  .gallery-v3__line{
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: var(--block_light_border);
    z-index: -1;
  }

  .header{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    align-content: center;

    &__count-part{
      flex-basis: 10%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: var(--text_color)
    }

    &__text-part{
      flex-basis: 85%;
    }

    &__text{
      font-size: 1.22rem;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: -0.0008em;
      text-align: left;
      margin: 0 3%;
      color: var(--title_color)
    }

    &__description{
      font-size: 1.22rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin: 1% 3%;
      color: var(--secondary_color)
    }
  }

  .gallery-v3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3px;
    margin: 0 0 0 .7%;

    .images {
      flex: 1 1 auto;
      flex-basis: 18%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>