<template>
    <div class="breadcrumb" v-if="!viewIsMainPage">
        <router-link :to="item.url+'/'" v-for="(item, index) in breadcrumb" class="breadcrumb__item"
                     :class="{'breadcrumb__item--active': index === breadcrumb.length - 1}">
            <div class="breadcrumb__item-title">{{ item.name }}</div>
        </router-link>
    </div>

</template>

<script>

import Element from "@/components/Element.vue";
import {mapState} from "vuex";

export default {
    name: "Breadcrumb",
    computed: {

        ...mapState('app', ['config', 'lang', 'domain_info', 'view']),

        breadcrumb() {

            let lang = this.lang;

            let url_base = this.urlBase;

            let src = this.view.breadcrumb ? this.view.breadcrumb : [];

            let ret = [];
            src.forEach(item => {

                let item_lang = item.langs ? item.langs.find(item_lang => item_lang.lang === lang) : []

                ret.push({
                    name: item_lang.name,
                    url: '/' + url_base + item_lang.url,
                })

            })

            return ret;

        },

        viewIsMainPage() {
            let view = this.view;

            let type = view.obj_module;

            let obj = view.obj;

            let code = obj ? obj.code : null;

            return type === 'page' && code === 'main';

        },

        urlBase() {

            let domain_info = this.domain_info;

            return domain_info.path_lang;

        }
    }
}
</script>

<style scoped lang="scss">

.breadcrumb {
    padding: 0;
    margin: 30px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 6px;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgb(100, 100, 100);
        font-size: 1rem;
        text-decoration: none;

        &-title {
            font-family: var(--text_font);
        }

        &-arrow {
        }

        &::after {
            content: "/";
            margin: 0 10px;
        }

        &--active {
            color: var(--link);

            & a {
                cursor: text;
            }

            &::after {
                display: none;
            }
        }
    }
}

html.contrast {

    & .breadcrumb {

        &__item {
            color: #fff;

            &--active {
                color: gold;
            }
        }
    }
}

</style>
