<template>
<FileBlock v-for="item in info.items"/>
</template>

<script>
import FileAbstract from "@/views/Base/File/Abstract.vue";
import FileBlock from "@/views/Base/File/Block.vue";
export default {
    name: "FileList",
    components: {FileBlock},
    extends: FileAbstract,
    data: () => ({
        info: {
            items: [{
                name: 'Załącznik nr 1 – Tabela transpozycji PI na działania/poddziałania  w poszczególnych osiach priorytetowych',
                format: 'PDF',
                info: '232 strony',
                size: '105 kb',
                date: '12.05.2023',
            },{
                name: 'Załącznik nr 1 – Tabela transpozycji PI na działania/poddziałania  w poszczególnych osiach priorytetowych',
                format: 'PDF',
                info: '232 strony',
                size: '105 kb',
                date: '12.05.2023',
            },{
                name: 'Załącznik nr 1 – Tabela transpozycji PI na działania/poddziałania  w poszczególnych osiach priorytetowych',
                format: 'PDF',
                info: '232 strony',
                size: '105 kb',
                date: '12.05.2023',
            }],
        }
    })
}
</script>

<style scoped>

</style>