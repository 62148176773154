<script>
import Row from "@/Library/Forms/Row.vue";
import Alert from "@/Library/Components/Alert.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import axios from "@/axios";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";

export default {
  name: "AppNeedPass",
  components: {Alert, Row},
  mixins: [ControllForm, ComponentFormStateMachine],
  emits: ['passCorrect'],
  props: {
    domain: {
      default() {
        return null;
      }
    },

  },
  data: () => ({
    needpass_label: 'info',
    needpass_text: 'Domena wymaga podania hasła, wprowadź hasło',
    obj: {
      pass: null
    }
  }),
  methods: {


    async domainPassSend() {

      let url = 'domain/passtest';
      console.log(this.$data)
      let params = {
        pass: this.obj.pass,
        id: this.domain.id,
      }

      this.sendInfo();

      let result = await axios.get(url, {params: params}).catch(r=>r);

      if (result.status === 200) {
        this.sendSuccess();
        this.$emit("passCorrect", this.obj.pass);
      }

      if (result.status !== 300) {
        this.sendError();

        this.needpass_label = 'error';
        this.needpass_text = 'Błędna hasło';
      }

    },
  }
}
</script>

<template>

  <div class="need-pass" :class="{
    loading: stateLoading(),
  }">
    <div class="need-pass__content content">
      <img class="content__image" src="@/assets/img/logo.png" alt="Logo Uniwersytetu"/>
      <div class="content__name">
        <Alert :type="needpass_label">{{ needpass_text }}</Alert>
        <br>
        <Row type="text" label="Wpisz hasło" name="pass" :value="obj.pass" placeholder="Wpisz tutaj hasło"
             @updateValue="updateValue"/>
        <br>
        <button class="btn-send" @click="domainPassSend" :disabled="stateLoading()">Ok</button>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

.need-pass {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: 1050;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

</style>