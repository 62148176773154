<script>
import Element from "@/components/Element.vue";

export default {
  name: "PortalTeamStand",
  extends: Element,
}
</script>

<template>
  <span>{{obj.stand}}</span>
</template>

<style scoped lang="scss">

</style>