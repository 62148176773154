<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "TermAndConditions1",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          footer: "© 2020 - yourBrand. All right reserved"
        }
      }
    },
  }
})
</script>

<template>
  <div class="term-and-conditions__container term">
    <div class="term__footer">
      <p class="term-footer__text">{{to(this.info, 'footer')}}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.term{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .term-footer__text{
    color: var(--text);
    margin: 0.63rem 0;
  }
}
</style>