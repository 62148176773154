<template>

  <div class="publication">
    <div class="publication__bg" :style="{backgroundImage: 'url('+image+')'}"></div>
    <div class="publication__info publication-info">
      <div class="publication-info__row publication-head">
        <span class="publication-head__label">Wydawnictwo</span>
        <span class="publication-head__date">
              {{date}}
                </span>
      </div>
      <div class="publication-info__row publication-name">
        {{name}}
      </div>
      <ul class="publication-info__row publication-footer">

        <li class="publication-footer__item publication-footer__item--icon">
          <img src="@/assets/images/download.svg"/>
        </li>
        <li class="publication-footer__item publication-footer__item--info">PDF</li>
        <li class="publication-footer__item publication-footer__item--info">232 strony</li>
        <li class="publication-footer__item publication-footer__item--info">545 KB</li>
      </ul>
    </div>
  </div>

</template>

<script>

import ArticleAbstract from '@/views/Article/Abstract.vue';

export default {
  name: "ArticleBlockPublication",
  extends: ArticleAbstract,
  props: {
    date: {
      default() {
        return '01.01.2023'
      }
    },
    name: {
      default() {
        return 'Kolejny maluch w stajni ośrodka akademickiego. Na świat przyszedł kasztanowaty ogierek'
      }
    },
    text: {
      default() {
        return 'Na wiele rzeczy nie mamy bezpośrednio wpływu, jak na aurę za oknem. Mżący śnieg z deszczem jest wyzwaniem'
      }
    },
    image: {
      default() {
        return 'https://picsum.photos/200/300?random=2'
      }
    },
  }

}
</script>

<style scoped lang="scss">

.publication {

  position: relative;
  text-align: left;
  width: 100%;
  height: 400px;

  &__bg,
  &__info {
    position: absolute;
  }

  &__bg {
    border-radius: 12px;
    top: 20%;
    height: 80%;
    left: 0;
    right: 0;
    background-color: #eee;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__info {
    background-color: var(--block_light_bg);
    width: 90%;
    top: 0;
    left: 0;

    padding: 18px 12px 12px;

    border-radius: 12px 12px 12px 0;

    &:after {

      position: absolute;
      width: 0;
      height: 20px;

      content: '';

      bottom: 0;

      top: 100%;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid white;
      clear: both;


    }
  }

}

.publication-info {
  display: flex;
  flex-direction: column;

  &__row {

    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

  }

}

.publication-head {
  &__label {
    background-color: #F2F5FA;
    color: var(--main);

    border-radius: 6px;
    padding: 6px;

    margin-right: 12px;
    text-transform: uppercase;
  }

  &__date {
    opacity: 0.8;
  }
}

.publication-name {
  font-size: 1.2rem;
  font-weight: 600;
  height: 65px;
  color: var(--main);

  line-height: 1.575rem;
  -webkit-line-clamp: 3;

  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.publication-footer {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  list-style: none;
  font-size: 0.78rem;

  &__item {

    position: relative;

    line-height: 2em;

    margin-right: 12px;

    &--icon {
      user-select: none;
      cursor: pointer;
    }

    &--info {
      padding: 0 12px 0 0;
      margin: 0 12px 0 0;


      &:before {

        content: '';

        position: absolute;
        right: 0;
        top: 40%;

        width: 2px;
        height: 2px;

        border-radius: 50%;

        transform: translateY(-50%);

        display: block;

        background-color: var(--main);
        opacity: .7;

      }

      &:last-child {
        margin: 0;
        padding: 0;
      }

      &:last-child:before {
        display: none;
      }
    }
  }
}

</style>