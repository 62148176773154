<script>
import Element from "@/components/Element.vue";
import ArticleContentPart from "@/views/Article/ContentPart.vue";
import ShowGallery from "@/Library/Show/Gallery.vue";
import ShowAudio from "@/Library/Show/Audio.vue";
import ShowVideo from "@/Library/Show/Video.vue";
import ShowFiles from "@/Library/Show/Files.vue";

export default {
  name: "ArticleContent",
  components: {ShowFiles, ShowVideo, ShowAudio, ShowGallery, ArticleContentPart},
  extends: Element,
}
</script>

<template>

  <template v-for="part in obj.parts">
    <ArticleContentPart :part="part"/>
  </template>

  <div v-html="to(obj, 'text')"></div>

  <template v-if="obj.gallery.length > 0">
    <ShowGallery :gallery="obj.gallery" :can_zooming="true"/>
  </template>

  <template v-if="obj.audio_url">
    <ShowAudio :audio="obj.audio_url"/>
  </template>

  <template v-if="obj.video">
    <ShowVideo :video="obj.video_own_url"/>
  </template>

  <template v-if="obj.files.length > 0">
    <ShowFiles :files="obj.files"/>
  </template>

</template>

<style scoped lang="scss">

</style>