<template>
  <div class="quote__container">
    <div class="quote-container__quote quote">
      <span class="material-symbols-outlined quote_icon">format_quote</span>
      <p class="quote__description" v-html="parseText(info, 'description')"></p>
      <h2 class="quote__author-name">{{to(info, 'authorName')}}</h2>
    </div>
  </div>
</template>

<script>
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import Element from "@/components/Element.vue";

export default {
  name: "Quote",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  extends: Element,
  computed: {
    boderColor(){
      let ret = 'var(--border)';
      if(this.info.colorBorder)
        ret = this.info.colorBorder;
      return ret;
    },
    bgColor(){
      let ret = null;
      if(this.info.colorBg)
        ret = this.info.colorBg;
      return ret;
    },
    color(){
      let ret = 'var(--text)';
      if(this.info.color)
        ret = this.info.color;
      return ret;
    },
    colorUser(){
      let ret = 'var(--text)';
      if(this.info.colorUser)
        ret = this.info.colorUser;
      return ret;
    },
    colorQuote(){
      let ret = 'var(--text)';
      if(this.info.colorQuote)
        ret = this.info.colorQuote;
      return ret;
    },
    fontSizeText(){
      let ret = '1 rem';
      if(this.info.fontSizeText)
        ret = this.info.fontSizeText+'px';
      return ret;
    },
    fontSizeUser(){
      let ret = '1 rem';
      if(this.info.fontSizeUser)
        ret = this.info.fontSizeUser+'px';
      return ret;
    },
    textSize(){
      let ret = '1 rem;';
      if(this.info.color)
        ret = this.info.color;
      return ret;
    },
  },
  props: {
    info: {
      default() {
        return {
          description: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit loremipsu.",
          authorName: "Customer Name"
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.quote__container {
  max-width: 100%;

  .quote {
    border: 1px solid v-bind(boderColor);
    background-color: v-bind(bgColor);
    border-radius: 15px;
    padding: 2% 7%;

    &__icon{
      color: var(--icon);
    }

    &__description {
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin: 0;
      color: v-bind(color);
      font-size: v-bind(fontSizeText);
    }

    &__author-name {
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: v-bind(colorUser);
      font-size: v-bind(fontSizeUser);
      margin-bottom: 0;
    }
  }
}

.material-symbols-outlined {
  color: v-bind(colorQuote);
  font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}
</style>