<template>
  <div class="hr" ></div>
</template>

<script>
export default {
  name: "Hr2",
  props: {
    color: {
      default() {
        return 'var(--main_color)';
      }
    }
  }
}
</script>

<style scoped lang="scss">

.hr {
  width: 100%;
  height: 2px;

  clear: both;

  background-color: v-bind(color);

  //opacity: .3;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

</style>