<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "InfoV3",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            name: "Layouts",
                            count: "180"
                        },
                        {
                            name: "Components",
                            count: "589"
                        },
                        {
                            name: "UI Elements",
                            count: "31"
                        },
                        {
                            name: "Categories",
                            count: "39"
                        }
                    ]
                }
            }
        }
    }
})
</script>

<template>

    <ul class="list">
        <li class="list__item item" v-for="item in info.items">
            <p class="item__name">{{ to(item, "name") }}</p>
            <h1 class="item__number">{{ item.count }}</h1>
        </li>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
    column-gap: 24px;
    row-gap: 16px;

    width: 100%;

    &__item {
        margin-left: 0;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    &__name, &__number {
        font-style: normal;
        line-height: normal;
        margin-top: 0;

        color: var(--title);
    }

    &__name {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;
    }

    &__number {
        font-family: var(--title_font);
        font-size: 3.78rem;
        font-weight: 700;
        line-height: 74.8px;
        letter-spacing: -0.245px;
        margin-bottom: 0;
    }
}

.content--l, .content--sm {

}

.content--xs {

}

</style>