<template>
    <ul class="link-list">
        <li class="link-list__item link-item" v-for="item in info.items">
            <i class="link-item__icon material-symbols-outlined" v-if="item.icon">{{ item.icon }}</i>
            <i class="link-item__image" v-if="item.image">
                <img :src="item.image" :alt="item.name"/>
            </i>
            <span class="link-item__info link-info">
                <span class="link-info__name">
                {{ item.name }}
                </span>
                <span class="link-info__desc">
                {{ item.desc }}
                </span>
            </span>
        </li>
    </ul>
</template>

<script>
import LinkAbstract from "@/views/Base/Link/Abstract.vue";

export default {
    name: "LinkListBig",
    extends: LinkAbstract,
    data: () => ({
        info: {
            items: [{
                url: "https://strefaportali.pl",
                icon: "content_copy",
                name: "Infrastruktura dla usług użyteczności publicznej",
                desc: 'Poczytaj o tej osi priorytetowej – czyli na czym nam zależy'
            }, {
                url: "https://strefaportali.pl",
                icon: "share",
                name: "Zielona energia",
                desc: "Inny możliwy opis do linka wrzuconego przez admina innego niż te powyżej",
            },]
        }
    }),
}
</script>

<style scoped lang="scss">
.link-list {

  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;

  &__item {
    padding: 20px 0;
    position: relative;

    &:after {

      position: absolute;

      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;

      background-color: var(--main);
      opacity: .2;
      content: '';

    }
  }
}

.link-item {
  //line-height: 2rem;
  display: flex;
  margin-right: 2rem;

  &__image {
    margin-right: .5rem;
  }


  &__icon {

    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;

    border-radius: 50%;

    background-color: white;
      color: var(--main);
  }

  &__info {
    padding-left: 30px;
  }

}

.link-info{
    display: flex;
    flex-direction: column;
    &__name{

        font-weight: 600;
        font-size: 1.15rem;
        line-height: 1.5rem;
        margin-bottom: 0.8rem;
        color: var(--main);
    }
    &__desc{

        font-size: 1rem;
        line-height: 1.25rem;
        opacity: 0.8;
    }
}

</style>