<script>
import TemplatePage from "@/template/Page.vue";
import Pagination from "@/Library/Components/Pagination.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import ArticleBlock1 from "@/views/Article/Block1.vue";
import axios from "@/axios";
import AbstractList from '@/views/Abstract/List.vue'
import Alert from "@/Library/Components/Alert.vue";
import News2 from "@/views/Article/News2.vue";
import News3 from "@/views/Article/News3.vue";
import News4 from "@/views/Article/News4.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";

export default {
    name: "ArticleList",
    extends: AbstractList,
    components: {News4, News3, News2, Alert, ArticleBlock1, MenuDropdown, Pagination, TemplatePage},
    mixins: [ComponentFormStateMachine],
    data: () => ({
        items: [],
        count: 0,
        page: 1,
        page_count: '10',

        sort: 'date ASC',
        category_sort: 'number ASC',

        page_count_types: [
            // {name: '2', value: '2'},
            {name: '10', value: '10'},
            {name: '20', value: '20'},
            {name: '50', value: '50'}
        ],

        get_url: 'article/get',
        get_params: {
            id_top_NULL: true,
            version: 'no',
            is_show: true,
            scope: 'title,slug,slugs,thumb,date,langs,date,lead,category_name,urls'
        },

        categorys: [],
        category_selected: null,
        category_get_url: 'article/categoryget',
        category_get_params: {
            status: 'live',
            show: 'yes',
            scope: 'id,name',
        },
    }),

    computed: {

        typeContent() {
            let elementConfig = this.config;
            return elementConfig.type;
        },

        statusOn() {

            let appInitial = this.$root.initial;

            let statusValue = appInitial['article' + this.typeContent + '_status'];

            return statusValue && statusValue === 'yes';

        },

        sortTypes() {
            let ret = []

            ret = [
                {name: this.t("sort_new"), value: 'date DESC'},
                {name: this.t("sort_old"), value: 'date ASC'},
                {name: this.t("sort_alphabet"), value: 'title ASC'}
            ]

            return ret
        },

        itemCls() {

            let info = this.info;

            let type = info.type;

            let ret = {
                'col-12 col-sm-6 col-lg-4 col-xl-3': type === 'type_1',
                'col-12 col-sm-6 col-lg-4': type === 'type_2',
                'col-12 col-sm-6 col-lg-3': type === 'type_3',
                'col-12 col-lg-6': type === 'type_4',
                'mb-3': true,
            }

            // console.log(ret, type)

            return ret;
        }
    },

    methods: {
        async refresh() {
            this.stateUpdate("sendInfo")

            let params = {...this.get_params, ...this.pars, ...this.config};
            params['page_count'] = this.page_count;
            params['page'] = this.page;
            params['sort'] = this.sort;
            if (this.statusOn) {
                params['status'] = 'public';
            }

            let response = await axios
                .get(this.get_url, {params: params})
                .then(r => r.data);

            this.items = response.data;
            this.count = response.count;

            this.stateUpdate("sendSuccess")
        },

        async categoryLoad() {

            let url = this.category_get_url;

            let params = this.category_get_params;
            params['sort'] = this.category_sort;
            params['page_count'] = this.page_count;

            let result = await axios
                .get(url, {params: params}).then(r => r).catch(e => e.result)

            if (result && result.status === 200) {
                this.categorys = [];
                result.data.data.forEach(category => {
                    this.categorys.push({
                        value: category.id,
                        name: category.name,
                    })
                })
                this.categorys.push({
                    value: null,
                    name: "Wszystkie",
                })

                console.log("this.categorys", this.categorys)
            }

        },

        categoryClick(item) {

            this.category_selected = item;

            this.pars['id_category'] = item;

            this.updatePars(this.pars)
        }
    },

    mounted() {
        this.categoryLoad();
        this.refresh()
    },

    watch: {

        '$route.params.path': {
            handler() {

                setTimeout(() => {
                    console.log('Odświerzam listę', this.config);
                    this.refresh()
                }, 1000)

            },
            deep: true,
        }
    }
}
</script>

<template>

    <div>
        <div class="filters">
            <div class="filters__left">
                <MenuDropdown :name="t('category')" v-if="categorys.length > 1" :config="{float: 'left'}" :items="categorys" :selected="pars['id_category']" @click="categoryClick"/>
            </div>
            <div class="filters__right">
                <MenuDropdown :name="t('sort')" :items="sortTypes" :selected="sort" @click="updateSort"/>
                <MenuDropdown :name="t('page_count')" :items="page_count_types" :selected="page_count" @click="updatePageCount"/>
            </div>
        </div>

        <div class="row" :class="{'list--type-4': info.type === 'type_4', 'loading': stateLoading()}">
            <div :class="itemCls" v-for="item in items">
                <ArticleBlock1 v-if="info.type === 'type_1'" :obj="item" v-bind="item"/>
                <news2 v-if="info.type === 'type_2'" :obj="item" v-bind="item"/>
                <news3 v-if="info.type === 'type_3'" :obj="item" v-bind="item"/>
                <news4 v-if="info.type === 'type_4'" :obj="item" v-bind="item"/>
            </div>
        </div>


        <div class="row" v-if="count === 0 && !stateLoading()">
            <div class="col-12">
                <br>
                <Alert :text="t('none')" label="" type="info"/>
            </div>
        </div>
        <div class="row" v-if="count > page_count">
            <div class="col-12" style="text-align: center">
                <Pagination :page="page" :page_count="page_count" :count="count"
                            @updatePage="updatePage"/>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: 12px;

    &__left {

    }

    &__right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 29px;

    &__item {
        margin-left: 0;
        width: 100%;
        max-width: 412px;

        &:first-child:last-child {
            max-width: 50%;
        }
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        &__item {

            &:first-child:last-child {
                max-width: unset;
            }
        }
    }

    &--type-4 {
        grid-template-columns: repeat(auto-fit, minmax(483px, 1fr));

        & .list {

            &__item {
                max-width: 632px;
            }
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        }
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .list {

        &__item {

            &:first-child:last-child {
                max-width: unset;
            }
        }

        &--type-4 {
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        }
    }
}
</style>