<script>
import Element from "@/components/Element.vue";
import MenuInline from "@/views/Base/Menu/Inline.vue";

export default {
  name: "MenuInlineBig",
  extends: Element,
  components: {MenuInline}
}
</script>

<template>
  <MenuInline
      color="black"
      :size="'1.2rem'"
      :space="'20px'"
      :weight="500"
      :place="'right'"
      :info="info"
  />
</template>

<style scoped lang="scss">

</style>