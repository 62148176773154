<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "BlockFaQV2",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    tabs: [
                        {
                            number: "1",
                            title: "Feature One",
                            selected: true
                        },
                        {
                            number: "2",
                            title: "Feature Two"
                        },
                        {
                            number: "3",
                            title: "Feature Three"
                        },
                        {
                            number: "4",
                            title: "Feature Four"
                        },
                        {
                            number: "5",
                            title: "Feature Five"
                        }
                    ],
                    items: [
                        {
                            idx: "1",
                            question: "Is Wireland for Web the best wireframe template for my design project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "1",
                            question: "Does Wireland for Web have more than 300 different UI components?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "2",
                            question: "Can I Create e-commerce using this wireframe kit?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "3",
                            question: "Will this wireframe library save me more than 30 hour of work per week?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "3",
                            question: "Does this template includes video documentation?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "4",
                            question: "How will Wireland for Web help me become more efficient?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            idx: "5",
                            question: "Is Wireland for Web the best wireframe template for my design project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        }
                    ]
                }
            }
        }
    },
    methods: {
        select(tab) {
            this.info.tabs.map(tab => tab.selected = false)

            tab.selected = true
        }
    },
    computed: {
        selectedTabNumber() {
            return this.info.tabs.find(tab => tab.selected)?.number
        },

        actualItems() {
            return this.info.items.filter(item => item.idx === this.selectedTabNumber + 1)
        }
    }

})
</script>

<template>

    <div class="wrapper">
        <div class="wrapper__tabs">
            <ul class="tabs">
                <li
                    class="tabs__item tab"
                    :class="{'tab--selected': tab.selected}"
                    v-for="tab in info.tabs"
                    @click="select(tab)">
                    {{ to(tab, "title") }}
                </li>
            </ul>
        </div>

        <div class="wrapper__list">
            <ul class="list">
                <li class="list__item item" v-for="item in actualItems">
                    <h1 class="item__title">{{ to(item, "question") }}</h1>
                    <p class="item__description" v-html="parseText(item, 'option')"></p>
                </li>
            </ul>
        </div>
    </div>

</template>

<style scoped lang="scss">

.wrapper {

    &__tabs {
        margin-bottom: -2px;
    }

    &__list {
        border-top: 2px solid var(--border_light);
        padding-top: 16px;
    }
}

.tabs, .list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;

    &__item {
        font-family: var(--title_front_font);
        font-style: normal;
        line-height: normal;
    }
}

.tab {
    font-size: 0.67rem;
    font-weight: 500;
    letter-spacing: 0.857px;
    text-transform: uppercase;

    padding: 25px 47px 24px 47px;
    min-width: fit-content;
    color: var(--title);
    cursor: pointer;

    &--selected {
        font-weight: 700;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: var(--border);
        }
    }
}

.list {
    display: block;

    &__item {
        margin-left: 0;
    }
}

.item {
    padding: 24px 0;
    border-bottom: 2px solid var(--border);

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        color: var(--title);
        margin-bottom: 8px;

        @media screen and (max-width: 1440px) {
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        color: var(--text);
    }
}

.content--l, .content--sm {

}

.content--xs {

}

</style>