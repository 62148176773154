<template>
    <ul class="link-list">
        <li class="link-list__item link-item" v-for="item in info.items">
            <i class="link-item__icon material-symbols-outlined" v-if="item.icon">{{item.icon}}</i>
            <span class="link-item__name">
                {{ item.name}}
            </span>
        </li>
    </ul>
</template>

<script>
import LinkAbstract from "@/views/Base/Link/Abstract.vue";

export default {
    name: "LinkList",
    extends: LinkAbstract,
    data: () => ({
        info: {
            items: [{
                url: "https://strefaportali.pl",
                icon: "content_copy",
                name: "Pokaż więcej",
            },{
                url: "https://strefaportali.pl",
                icon: "share",
                name: "Udostępnij",
            },]
        }
    }),
}
</script>

<style scoped lang="scss">
.link-list{

    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: row;

    &__item{

    }
}

.link-item{
    color: var(--main);
    //line-height: 2rem;
    display: flex;
    margin-right: 2rem;
    &__icon{
        margin-right: .5rem;
    }
    &__name{
        text-transform: uppercase;
        text-decoration: underline;
        line-height: 2rem;
    }

}

</style>