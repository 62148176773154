<template>

  <ul class="pagination">
    <li class="pagination__left" :class="{ 'pagination__item-disabled': !page_before }" v-on:click="pageSub()">

      <img src="@/assets/img/pagination-dart.svg" alt="">
    </li>

    <li class="pagination__item" v-if="page > 2" v-on:click="pageSet(1)">
      1
    </li>
    <li class="pagination__item" v-if="page > 3 " v-on:click="pageSet(2)">
      2
    </li>
    <li class="pagination__item" v-if="page > 4">
      ...
    </li>
    <li class="pagination__item" v-if="page > 1" v-on:click="pageSet(page-1)">
      {{ parseInt(page) - 1 }}
    </li>
    <li class="pagination__item pagination__item-selected" v-on:click="pageSet(page)">
      {{ page }}
    </li>
    <li class="pagination__item" v-if="page_max - page > 0" v-on:click="pageSet(page+1)">
      {{ parseInt(page) + 1 }}
    </li>
    <li class="pagination__item" v-if="page_max - page > 3">
      ...
    </li>
    <li class="pagination__item" v-if="page_max - page > 2" v-on:click="pageSet(page_max-1)">
      {{ page_max - 1 }}
    </li>
    <li class="pagination__item" v-if="page_max - page > 1" v-on:click="pageSet(page_max)">
      {{ page_max }}
    </li>

    <li class="pagination__right" :class="{ 'pagination__item-disabled': !page_after }" v-on:click="pageInc()">

      <img src="@/assets/img/pagination-dart.svg" alt="">
    </li>


  </ul>

</template>

<script>
export default {
  name: "Pagination",
  emits: ['updatePage'],
  props: {
    'count': {
      type: Number
    },
    'page': {
      type: Number
    },
    'page_count': {
      type: Number,
      default: 20
    },
  },
  data() {
    return {
      // page_max: null,
      // page_before: false,
      // page_after: false,
    }
  },
  computed: {

    page_max: function () {
      return Math.ceil(this.count / this.page_count);

    },
    page_before: function () {
      return this.page > 1;

    },
    page_after: function () {
      return this.page < this.page_max;

    },
  },
  methods: {
    pageSet: function (page) {


      this.$emit('updatePage', page);


    },
    pageInc: function () {
      let page = this.page;
      if (page < this.page_max)
        page++;
      this.$emit('updatePage', page);
    },
    pageSub: function () {

      let page = this.page;
      if (page > 1)
        page--;

      this.$emit('updatePage', page);

    },
    refresh: function () {

      // this.page_max = Math.ceil(this.count / this.page_count);
      // this.page_before = this.page > 1;
      // this.page_after = this.page < this.page_max;

    }
  },
  watch: {
    page: function (val) {

      console.log(val)

      this.refresh();
    },
    count: function (val) {

      // this.page_count = val;
      this.refresh();
    },
  }
}
</script>

<style scoped lang="scss">

.pagination {

  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: inline-block;
  //float: right;

  &__left,
  &__item,
  &__right {

    display: inline-block;
    cursor: pointer;
    float: left;
    margin-right: 6px;
  }
  &__right {
    margin-right: 0;
  }

  &__left img {
    transform: rotate(180deg);
  }

  &__item-disabled {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    cursor: default;
  }

  &__item {

    color: #3F434A;

    font-size: 15px;
    line-height: 22px;

    text-align: center;

    border-radius: 6px;
    min-width: 34px;
    height: 34px;
    line-height: 34px;


    &-selected {
      color: #fff;
      background-color: var(--main_color);
    }
    [data-layout-mode=dark],
    .dark-layout &{
      color: #ccc;
    }

  }
}

</style>