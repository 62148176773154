<script>
import Element from "@/components/Element.vue";
import ShowTags from "@/Library/Show/Tags.vue";

export default {
  name: "PortalTeamTags",
  components: {ShowTags},
  extends: Element,
}
</script>

<template>
  <ShowTags :data="obj.tags"/>
</template>

<style scoped lang="scss">

</style>