<template>

    <div class="input-objs">

        <input type="text" class="input-objs__text" @change="itemsRefresh" v-model="text"
               placeholder="Wpisz czego szukasz">
        <ul class="input-objs__list objs-list">
            <li class="objs-list__item list-item" v-for="item in itemsShow">
                <div class="list-item__check check">
                    <input
                            type="checkbox"
                            class="check__input"
                            :value="item.value"
                            :checked="itemIsChecked(item.value)"
                            @change="itemsUpdateChecked(item.value)"/>
                </div>
                <div class="list-item__thumb thumb" v-if="this.value_image">
                    <div class="thumb__image" :style="{ backgroundImage: `url(${item.image})` }"></div>
                </div>
                <div class="list-item__name">
                    {{ item.label }}
                </div>
            </li>
        </ul>

    </div>

</template>

<script>
import InputBase from "./InputBase";
import Input from "@/Library/Forms/Input.vue";
import axios from "@/axios";

export default {
    name: "InputObjs",
    components: {Input},
    props: {
        id: {
            default() {
                return null;
            }
        },
        name: {
            default() {
                return "";
            }
        },
        value: {
            default() {
                return [];
            }
        },
        value_show: {
            default() {
                return []
            }
        },
        value_image: {
            default() {
                return false;
            }
        },
        value_version: {
            default() {
                return 'id_obj';
            }
        },
        url: {
            default() {
                return "";
            }
        },
        url_parameters: {
            default() {
                return {}
            }
        },
        url_parameter_name: {
            default: 'has_text'
        },
    },
    data: () => ({
        text: "",
        items: [],
        items_count: 0,
        items_limit: 100,
        value_local: [],
        value_type: 'array',

    }),

    computed: {

        itemsShow() {

            let show = this.items;
            if (this.items_count < this.items_limit && this.text !== "") {
                show = show.filter(element => element.label.indexOf(this.text) >= 0)
            }

            return show;

        }

    },

    methods: {

        valueLoad(value) {

            let val = [];
            let val_type = 'string';

            if (Array.isArray(value)) {
                val = [...value];
                val_type = 'array';
            }

            if (typeof value === 'string' || value instanceof String) {
                val = value.split(",")
                val_type = 'string';
            }

            this.value_type = val_type

            return val;

        },

        itemIsChecked(value) {

            return this.value_local.includes(value);

        },

        itemsUpdateChecked(value) {

            console.log(value)

            let is_checked = this.itemIsChecked(value);

            if (is_checked) {
                console.log('Odjęto element: ' + value);
                this.value_local = this.value_local.filter(element => element !== value);
            }

            if (!is_checked) {
                console.log('Dodane element: ' + value);
                this.value_local.push(value);
            }

            this.$emit('updateValue', this.name, this.value_local);

        },

        async itemsLoad() {
            let params = Object.assign({}, this.url_parameters);
            params['ret_type'] = 'autocomplete';
            params['test'] = 'test';

            if (this.text !== "") {
                params[this.url_parameter_name] = this.text;
            }

            this.loading = true;
            let data =
                await axios
                    .get(
                        this.url,
                        {'params': params}
                    )
                    .then(response => {
                        return response.data ? response.data : [];
                    })
                    .catch(function (error) {
                        return error.response;
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            this.items = data.data;
            if (this.text === "") {
                this.items_count = data.count;
            }

        },

        itemsRefresh() {

            let self = this;

            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(async () => {

                if (self.text && self.items_count > self.items_limit) {
                    self.items = [];

                    await self.itemsLoad();
                }

            }, 500);

        },
    },

    mounted() {

        this.value_local = this.valueLoad(this.value);

        this.itemsLoad();

    },

    watch: {
        value: {
            handler(newValue) {

                this.value_local = this.valueLoad(newValue);
            },
            deep: true,
        }
    }
}
</script>

<style scoped lang="scss">

.input-objs {
  display: flex;
  flex-direction: column;

  background-color: #fafafa;
  border: 1px solid var(--sp-border-color);

  &__text {

    border: none;

    box-shadow: none;

    outline: none;

    padding: 10px;

    height: 40px;

    background-color: #f5a804;
    color: #fff;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: white;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: white;
    }

  }

  &__list {

    height: calc(100% - 40px);
    overflow-y: auto;
  }
}

.objs-list {
  list-style: none;

  margin: 0;
  padding: 0;

  //overflow: scroll;

  &__item {

    &:nth-child(odd) {
      background-color: rgba(70, 93, 139, 0.05);
    }

  }
}

.list-item {

  display: flex;
  flex-direction: row;

  padding: 10px 0;


  &__check {
    width: 30px;
    text-align: center;
  }

  &__thumb {
    width: 30px;
  }

  &__name {
    width: calc(100% - 80px);
  }
}

.thumb {

  &__image {
    width: 20px;
    height: 20px;

    border-radius: 50%;

    display: block;

    background-size: cover;
    background-repeat: no-repeat;
  }
}

.check {
  &__input {
    margin-top: 2px;
  }
}

</style>