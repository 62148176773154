<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Thumb1",
    props: {

        image: {
            default() {
                return 'https://picsum.photos/200/300?random=2'
            }
        },
        format: {
            default() {
                return '21'
            }
        },
        type: {
            default() {
                return 'cover'
            }
        },
        radius: {
            default() {
                return 'no'
            }
        },
        info: {
            default() {
                return {}
            }
        }
    },
    computed: {
        formatClass() {

            let ret = '';

            if (this.format === '41')
                ret = {'thumb--format-41': true};

            if (this.format === '31')
                ret = {'thumb--format-31': true};

            if (this.format === '21')
                ret = {'thumb--format-21': true};

            if (this.format === '11')
                ret = {'thumb--format-11': true};

            if (this.format === '32')
                ret = {'thumb--format-32': true};

            if (this.format === '43')
                ret = {'thumb--format-43': true};

            return ret;

        },
        typeClass() {

            let ret = '';

            if (this.type === 'cover')
                ret = {'thumb--cover': true};
            if (this.type === 'contain')
                ret = {'thumb--contain': true};

            return ret;

        },
        radiusClass() {

            let ret = '';

            if (this.radius === 'yes')
                ret = {'thumb--radius': true};
            if (this.radius === 'big')
                ret = {'thumb--radius-big': true};

            return ret;

        },

        style() {
            let ret = {}

            ret.objectFit = this.info.fit_content

            return ret
        }
    }
})
</script>

<template>

    <img alt="Obrazek" :src="image" :style="style"/>

</template>

<style scoped lang="scss">

img {
    height: 500px;
    width: 100%;
}


</style>