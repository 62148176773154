<script>

import Element from "@/components/Element.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Parallax, Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

export default {
    name: "Slider",
    components: {
        Swiper,
        SwiperSlide,
    },
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    info: {
                        type: "type_1",
                        items: [
                            {
                                image: {
                                    url: "https://picsum.photos/768/397/?blur=5"
                                },
                                title: "Recent Projects",
                                description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                                linkName: "Learn More",
                                linkUrl: "/"
                            },
                            {
                                image: {
                                    url: "https://picsum.photos/769/398/?blur=5"
                                },
                                title: "Newest Projects",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore cumque dolore quas. Qui doloribus, quam illum  nisi sed exercitationem architecto?",
                                linkName: "Learn More",
                                linkUrl: "/"
                            },
                            {
                                image: {
                                    url: "https://picsum.photos/770/399/?blur=5"
                                },
                                title: "Old Projects",
                                description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. A consectetur eligendi vitae dolorum tenetur? Cupiditate debitis ipsa sunt totam iusto nulla!",
                                linkName: "Learn More",
                                linkUrl: "/"
                            }
                        ]
                    }
                }
            }
        },
    },
    data: () => ({
        modules: [Parallax, Pagination, Navigation, Autoplay],

        swiper: null
    }),
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper
        },

        onSlideChange() {
            console.log("slide change")
        }
    }
}
</script>

<template>

    <div class="slider no-width-100-percent">
        <swiper
            :slides-per-view="1"
            :parallax="true"
            :pagination="info.type !== 'type_2' ? {clickable: true} : false"
            :modules="modules"
            :speed="1000"
            :auto-height="true"
            :autoplay="info.type !== 'type_2' ? false : {delay: 1000000}"
            @swiper="onSwiper"
            @slideChange="onSlideChange">
            <swiper-slide v-for="item in info.items">
                <div class="item" :class="{'item--type-2': info.type === 'type_2'}">
                    <img :src="item.image?.url" alt="image" class="item__image">

                    <div class="item__content content" data-swiper-parallax="-800" v-if="to(item, 'title' || parseText(item, 'description'))">
                        <h1 class="content__title" data-swiper-parallax="-800">{{ to(item, "title") }}</h1>
                        <p class="content__description" data-swiper-parallax="-600" v-html="parseText(item, 'description')"></p>
                        <div v-if="info.type === 'type_2' && to(item, 'linkName') && item.site_link" @click="linkClick(item.site_link)" class="item__link" data-swiper-parallax="-400">{{ to(item, "linkName") }}</div>
                    </div>

                    <div v-if="info.type !== 'type_2' && to(item, 'linkName') && item.site_link" @click="linkClick(item.site_link)" class="item__link" data-swiper-parallax="-400">{{ to(item, "linkName") }}</div>

                    <template v-if="info.type !== 'type_2'">
                        <button type="button" class="item__prev" @click="swiper.slidePrev()" data-swiper-parallax="-600">
                            <span class="material-symbols-rounded">arrow_back</span>
                        </button>
                        <button type="button" class="item__next" @click="swiper.slideNext()" data-swiper-parallax="-600">
                            <span class="material-symbols-rounded">arrow_forward</span>
                        </button>
                    </template>
                </div>
            </swiper-slide>
        </swiper>
    </div>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: var(--link);
}

.slider {
    background: #000;
}

.item {
    min-height: 397px;
    padding: 79px 247px;

    position: relative;

    &__image {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {

    }

    &__link {
        font-style: normal;
        line-height: normal;
        text-align: left;
        margin-top: 32px;
        font-family: var(--title_font);
        border-radius: 4px;
        border: 1px solid var(--block_dark_bg);
        background: var(--block_dark_bg);

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 32px 15px 31px!important;
        max-width: fit-content;

        cursor: pointer;

        font-size: 0.78rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--block_dark_text);
    }

    &__prev, &__next {
        border: none;
        background: transparent;

        position: absolute;
        top: calc(50% - (40px / 2));

        min-width: 40px;
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:active {

            & > .material-symbols-rounded {
                transform: translateX(0)
            }
        }

        & > .material-symbols-rounded {
            transition: all 0.25s ease 0s;
            color: var(--block_dark_text);
        }
    }

    &__prev {
        left: 150px;

        &:hover {

            & > .material-symbols-rounded {
                transform: translateX(-5px);
            }
        }
    }

    &__next {
        right: 150px;

        &:hover {

            & > .material-symbols-rounded {
                transform: translateX(5px);
            }
        }
    }

    &--type-2 {
        padding: 0;
        min-height: 555px;

        display: flex;
        justify-content: end;

        & .content {
            max-width: 40%;
            padding: 90px 60px;
            background: rgba(0, 0, 0, 0.7);
            z-index: 2;
            border-radius: 0;

            &__title {
                color: var(--block_dark_text);
                line-height: 52px;
                margin-bottom: 64px;
                border-bottom: 4px solid var(--block_dark_border);
            }
        }
    }

    @media screen and (max-width: 1440px) {
        padding: 80px 117px 78px 117px;

        &__description {
            max-width: 348px;
        }

        &__prev {
            left: 24px;
        }

        &__next {
            right: 24px;
        }

        &--type-2 {
            padding: 0;
            min-height: 555px;

            display: flex;
            justify-content: end;

            & .content {
                max-width: 40%;
                padding: 90px 60px;

                &__title {
                    line-height: 52px;
                    margin-bottom: 64px;
                    border-bottom: 4px solid var(--block_dark_border);
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        min-height: 771px;
        padding: 80px 16px 64px 16px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__title, &__description {
            text-align: center;
        }

        &__title {
            margin-bottom: 34px;
        }

        &__description {
            max-width: unset;
        }

        &__prev, &__next {
            top: unset;
            bottom: 64px;
        }

        &__prev {
            left: 16px;
        }

        &__next {
            right: 16px;
        }

        &--type-2 {
            padding: 30px 0;
            min-height: 771px;

            display: flex;
            align-items: center;
            justify-content: start;

            & .content {
                max-width: unset;
                padding: 60px 30px;

                &__title {
                    line-height: 52px;
                    margin-bottom: 64px;
                    border-bottom: 4px solid var(--block_dark_border);
                }
            }
        }
    }
}

.content {
    padding: 16px;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    max-width: fit-content;

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        text-align: left;
        margin-top: 0;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 2.11rem;
        font-weight: 700;
        margin-bottom: 17px;
        color: var(--block_dark_title);
    }

    &__description {
        font-family: var(--text_font);
        max-width: 458px;
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--block_dark_text);
    }
}

.content--l, .content--sm {

    & .item {
        padding: 80px 117px 78px 117px;

        &__description {
            max-width: 348px;
        }

        &__prev {
            left: 24px;
        }

        &__next {
            right: 24px;
        }

        &--type-2 {
            padding: 0;
            min-height: 555px;

            display: flex;
            justify-content: end;

            & .content {
                max-width: 40%;
                padding: 90px 60px;

                &__title {
                    line-height: 52px;
                    margin-bottom: 64px;
                    border-bottom: 4px solid var(--block_dark_border);
                }
            }
        }
    }
}

.content--xs {

    & .item {
        min-height: 771px;
        padding: 80px 16px 64px 16px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__title, &__description {
            text-align: center;
        }

        &__title {
            margin-bottom: 34px;
        }

        &__description {
            max-width: unset;
        }

        &__prev, &__next {
            top: unset;
            bottom: 64px;
        }

        &__prev {
            left: 16px;
        }

        &__next {
            right: 16px;
        }

        &--type-2 {
            padding: 30px 0;
            min-height: 771px;

            display: flex;
            align-items: center;
            justify-content: start;

            & .content {
                max-width: unset;
                padding: 60px 30px;

                &__title {
                    line-height: 52px;
                    margin-bottom: 64px;
                    border-bottom: 4px solid var(--block_dark_border);
                }
            }
        }
    }
}

</style>

<style lang="scss">

.swiper-pagination {
    bottom: 35px!important;

    @media screen and (max-width: 767px) {
        bottom: 76.5px!important;
        width: calc(100% - (16px + 40px) * 2)!important;
        left: calc((16px + 40px))!important;
    }
}

.content--xs {

    & .swiper-pagination {
        bottom: 76.5px!important;
        width: calc(100% - (16px + 40px) * 2)!important;
        left: calc((16px + 40px))!important;
    }
}

</style>