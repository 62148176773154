<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import Element from "@/components/Element.vue";
import BlockNews1 from "@/views/Base/Block/BlockNews1.vue";
import BlockNews2 from "@/views/Base/Block/BlockNews2.vue";

export default defineComponent({
  name: "BlockNews",
  extends: Element,
  components: {
    BlockNews2,
    BlockNews1,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    info: {
      default() {
        return {}
      }
    }
  }
})
</script>

<template>
  <div class="block__news">
    <block-news1 v-if="info.type === 'type_1'" :info="info" />
    <block-news2 v-if="info.type === 'type_2'" :info="info" />
  </div>
</template>

<style scoped lang="scss">
.block__news{
  max-width: 100%;
}
</style>