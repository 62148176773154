<template>
    <div class="upload">

        <ShowGallery
                :gallery="files"
                :can_delete="true"
                :can_zooming="true"
                :can_edit_info="versionExtended"
                :move="true"
                @imageDelete="remove"
                @imageEdit="editInfo"
        />

        <file-upload
                style="height: 0; float:left;"
                :post-action="baseUrl+'base/uploadfile'"
                :multiple="true"
                :directory="false"
                :drop="true"
                :drop-directory="true"
                @input-file="inputFile"
                @input-filter="inputFilter"
                v-model="files"
                :id="this.id"
                name="files"
                :accept="accept"
                ref="upload">
        </file-upload>

        <div class="example-drag">
            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                <h3>Drop files to upload</h3>
            </div>
        </div>

        <button class="btn btn-primary" @click="open()">
            Wrzuć plik
        </button>

        <template v-if="modules.includes('file')">

            <button class="btn btn-info" @click="this.$refs.fileModal.open()">Wybierz plik z bazy</button>

        </template>

        <TemplateModal ref="editInfo" v-if="file_selected">
            <template #head>Edycja informacji</template>
            <template #body>
                <Row
                        type="text"
                        label="Nazwa zdjęcia"
                        name="name"
                        :value="file_selected.name"
                        @updateValue="updateFileSelectedInfo"
                        :multilang="multilang"

                />


                <Row type="textarea" label="Opis zdjęcia" name="text" :value="file_selected.text"
                     @updateValue="updateFileSelectedInfo"
                     :multilang="multilang"/>

            </template>
        </TemplateModal>
    </div>
</template>

<script>

import FileUpload from 'vue-upload-component'
import ShowFiles from "../Show/Files";
import ShowGallery from "@/Library/Show/Gallery";
import axios from "@/axios";
import TemplateModal from "@/Library/Template/Modal.vue";
import Row from "@/Library/Forms/Row.vue";
import InputText from "@/Library/Forms/InputText.vue";
import InputTextarea from "@/Library/Forms/InputTextarea.vue";
import {mapState} from "vuex";

export default {
    components: {
        InputTextarea,
        InputText,
        Row,
        TemplateModal,
        ShowGallery,
        ShowFiles,
        FileUpload,
    },
    emits: ['updateValue', 'updateFiles'],
    props: {
        id: {},
        name: {
            default() {
                return 'files'
            }
        },
        value: {},
        accept: {
            default() {
                return 'image/*'
            }
        },
        version: {
            default() {
                return 'simple'
            }
        },
        multilang: {
            default() {
                return false;
            }
        },
    },
    data() {
        return {
            files: [],
            file_selected: {},
        }
    },
    computed: {
        ...mapState('app', ['modules']),
        versionSimple() {
            return this.version === 'simple'
        },
        versionExtended() {
            return this.version === 'extended'
        },
        baseUrl() {
            return axios.defaults.baseURL;
        },
    },
    methods: {
        updateFileSelectedInfo(name, value) {

            this.file_selected[name] = value;

        },
        val(val = null) {

            if (val !== null)
                this.filesLocalSet(val);

            if (val === null)
                return this.filesLocalGetValue();

        },

        remove(file) {

            let ret = this.$refs.upload.remove(file);

            this.files = this.files.filter(element => element.filename !== file.filename)

            this.updateValue();

        },

        editInfo(file) {

            this.file_selected = file;

            this.$refs.editInfo.open();

        },

        uploadClipboard(e) {

            let dataTransfer = e.clipboardData
            if (!dataTransfer) {
                return
            }
            this.$refs.upload.addDataTransfer(dataTransfer)
        },

        open() {
            this.$refs.upload.$el.querySelector('input').click()
        },

        clear() {
            this.files = [];
        },

        updateFileBank(data) {

            this.files.push(data);

            this.updateValue();

        },


        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Before adding a file
                // 添加文件前
                // Filter system files or hide files
                // 过滤系统文件 和隐藏文件
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
                // Filter php html js file
                // 过滤 php html js 文件
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }

            }

            if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                // 创建 blob 字段
                newFile.blob = ''
                let URL = (window.URL || window.webkitURL)
                if (URL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                // 缩略图
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }
            // image size
            // image 尺寸
            if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
                newFile.error = 'image parsing'
                let img = new Image();
                img.onload = () => {
                    this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
                }
                img.οnerrοr = (e) => {
                    this.$refs.upload.update(newFile, {error: 'parsing image size'})
                }
                img.src = newFile.blob
            }
        },
        // add, update, remove File Event
        inputFile(newFile, oldFile) {


            if (newFile && !oldFile) {
                // Add file
                this.$refs.upload.active = true;
            }

            if (newFile && oldFile) {
                // Update file

                // Start upload
                if (newFile.active !== oldFile.active) {
                    console.log('Start upload', newFile.active, newFile)

                    newFile.upload_date = new Date();
                }

                // Upload progress
                if (newFile.progress !== oldFile.progress) {
                    console.log('progress', newFile.progress, newFile)
                }

                // Upload error
                if (newFile.error !== oldFile.error) {
                    console.log('error', newFile.error, newFile)
                }

                // Uploaded successfully
                if (newFile.success !== oldFile.success) {

                    console.log(newFile)

                    console.log('success', newFile.success, newFile)
                    // console.log('xhr', JSON.parse(newFile.xhr.response));

                    let data = newFile.response;

                    newFile.filename = data.filename;
                    newFile.url = data.url;

                }

                // Automatic upload
                if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                    if (!this.$refs.upload.active) {
                        this.$refs.upload.active = true;
                    }
                }
            }

            if (!newFile && oldFile) {
                // Remove file

                // Automatically delete files on the server
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/file/delete?id=' + oldFile.response.id,
                    // });
                }
            }

            if (this.$refs.upload.uploaded && newFile && oldFile) {
                console.log('All files have been uploaded');
                this.updateValue();
            }
        },
        updateValue() {

            let value = this.filesLocalGetValue();

            this.$emit('updateFiles', this.files);
            this.$emit('updateValue', this.name, value, this.files);

        },

        filesLocalUpdate() {
            this.filesLocalSet(this.value);
        },

        filesLocalGetValue() {

            let ret = null;

            if (this.versionSimple) {
                ret = [];
                this.files.map(function (value_new) {
                    ret.push(value_new.filename);
                })
            }

            if (this.versionExtended) {
                ret = [...this.files];
            }

            return ret;

        },

        filesLocalSet(value) {

            let fileseparator = '|';

            this.files = [];

            if (value === undefined) {
                value = this.value;
            }

            if (this.versionSimple) {


                if (typeof value === 'string' || value instanceof String) {

                    let filenames = value.split(fileseparator);

                    filenames.forEach(element => {
                        this.files.push({filename: element})
                    });

                }

            }

            if (this.versionExtended) {

                if (Array.isArray(value)) {

                    this.files = [...value];

                }

            }

        }

    },
    mounted() {
        this.filesLocalUpdate();
    },
    watch: {
        value(value_new, value_old) {
            if (JSON.stringify(value_new) !== JSON.stringify(value_old)) {
                this.filesLocalSet(value_new);
            }
        },
        // files: {
        //   handler(value_new, value_old) {
        //
        //     if(JSON.stringify(value_new) !== JSON.stringify(value_old)) {
        //       this.updateValue();
        //     }
        //
        //   },
        //   deep: true,
        // }
    }
}
</script>

<style scoped lang="scss">
.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}


.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.example-drag .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}


</style>