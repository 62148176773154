<template>
<!--    <pre>{{info}}</pre>-->
    <div v-if="loaded">
		<div class="name">{{ obj?.name }}</div>
		<PieChart :obj="convertToChart" v-if="obj.chart_type === 'pie'"/>
		<BarChart :obj="convertToChart" v-if="obj.chart_type === 'bar'"/>
		<LineChart :obj="convertToChart" v-if="obj.chart_type === 'line'"/>
		<AreaChart :obj="convertToChart" v-if="obj.chart_type === 'area'"/>
	</div>
</template>

<script>
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";
import SystemConfigModule from "@/Library/System/Config/Module.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import ControllError from "@/Library/Controll/Error.vue";
import TemplatePage from "@/Library/Template/Page.vue";
import TemplateCard from "@/Library/Template/Card.vue";
import Alert from "@/Library/Components/Alert.vue";
import BarChart from "@/views/Poll/Chart/BarChart.vue";
import PieChart from "@/views/Poll/Chart/PieChart.vue";
import LineChart from "@/views/Poll/Chart/LineChart.vue";
import AreaChart from "@/views/Poll/Chart/AreaChart.vue";
import Show from "@/Library/Show/Show.vue";
import AbstractDynamic from "@/views/Base/DynamicChart/Part/AbstractDynamic.vue";

/**
 * Pokazywanie wykresu
 */
export default {
	name: "DynamicChartShow",
	props: {source: {},info: {}},
	emits: ['submitForm'],

	components: {
		Show,
		AreaChart,
		LineChart,
		PieChart,
		BarChart,
		Alert, TemplateCard, TemplatePage
	},
	mixins: [ComponentFormStateMachine, ControllForm, ControllError, SystemConfigModule, AbstractDynamic],

	data: () => ({
		obj: {rows: []},
        loaded: false
	}),

	methods: {

		/**
		 * Zamienia dane z formularza do postaci
		 * przyjmowanej przez wykres kołowy.
		 * @param rows zamieniane wiersze
		 * @param converted obiekt z zamienionymi wierszami
		 */
		convertToPieChart(rows, converted) {
			for (let i = 0; i < this.splitLabels.length; i++)
				converted.options.push(this.splitLabels[i]);

			converted.sum = Object.values(rows).reduce((sum, r) => sum + parseInt(r), 0);
			rows.forEach(r => converted.answers.push(parseFloat(r[0])));
		},

		/**
		 * Zamienia dane z formularza do postaci
		 * przyjmowanej przez wykres słupkowy.
		 * Działa również dla wykresów liniowych i warstwowych.
		 * @param rows zamieniane wiersze
		 * @param converted obiekt z zamienionymi wierszami
		 */
		convertToBarChart(rows, converted) {

			let labels = this.splitLabels;
			let data_series = this.splitDataSeries;

			for (let i = 0; i < this.splitDataSeries.length; i++)
				converted.options.push(this.splitDataSeries[i]);

			/**
			 * Przypisanie, dla jakiej etykiety są do odpowiedzi
			 * oraz utworzenie tablicy na dane
			 */
			this.splitLabels.forEach(o => converted.answers.push({name: o, data: []}))

			/**
			 * Przejście po wszystkich etykietach
			 */
			for (let i = 0; i < labels.length; i++) {
				let answer = converted.answers[i];

				/**
				 * Przejście po serii danych i wepchnięcie
				 * danych do odpowiedzi
				 */
				for (let j = 0; j < data_series.length; j++)
					answer.data.push(isNaN(parseFloat(rows[i][j])) ? null : parseFloat(rows[i][j]));
			}

			/**
			 * Liczy sumę wszystkich odpowiedzi
			 */
			converted.answers.forEach(answer =>
					converted.sum += answer.data.reduce((sum, el) => sum + (el !== null ? parseFloat(el) : 0), 0));
		},

		async refresh() {
			console.log(333)
			this.obj = await this.load(this.source.widget_data.chart)
		}
	},

	async mounted() {
        this.obj = {rows: []}
		await this.load(this.info.chart)
        setTimeout(() => this.loaded = true, 1200)
	},

	computed: {

		/**
		 * Ogólna zamiana danych z formularza do wykresu
		 * @returns {{options: *[], answers: *[], sum: number}}
		 */
		convertToChart() {
			/**
			 * Obiekt zawierające przekształcone dane
			 * @type {{options: *[], answers: *[], sum: number}}
			 */
			let converted = {options: [], answers: [], sum: 0};

			/**
			 * Utworzenie opcji i wywołanie funkcji obsługującej
			 * zamianę do konkretnego typu wykresu
			 */
			switch (this.obj.chart_type) {
				case 'pie':
					this.convertToPieChart(this.obj.rows, converted);
					break;

				case 'bar':
				case 'line':
				case 'area':
					this.convertToBarChart(this.obj.rows, converted);
					break;
			}
			return converted;
		},
	},

	// watch: {
	// 	source: {
	// 		async handler(val) {
	// 			console.log(val)
	// 			this.obj = await this.load(val.widget_data.chart)
	// 			console.log(this.obj)
	// 			//this.obj = JSON.parse(JSON.stringify(val));
	// 		}, deep: true
	// 	}
	// }
}
</script>

<style scoped lang="scss">
.float-right {
	float: right;
}

.name {
	font-weight: 600;
	font-size: 1.25rem;
	margin-left: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
}
</style>