<script>


import Element from "@/components/Element.vue";

export default {
    name: "DateCountDown",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                }
            }
        }
    },
    data: () => ({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,

        intervalId: null
    }),
    methods: {
        calculateTime() {
            const currentDate = new Date();
            const futureDate = new Date(this.info.date.replace(' ', 'T'));
            const timeDifference = futureDate - currentDate;

            this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        }
    },
    mounted() {
        this.calculateTime();
        this.intervalId = setInterval(this.calculateTime, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
}
</script>

<template>

    <div class="block">
        <div class="block__item item">
            <p class="item__value">{{ days }}</p>
            <p class="item__title">Days</p>
        </div>
        <div class="block__item item">
            <p class="item__value">{{ hours }}</p>
            <p class="item__title">Hours</p>
        </div>
        <div class="block__item item">
            <p class="item__value">{{ minutes }}</p>
            <p class="item__title">Minutes</p>
        </div>
        <div class="block__item item">
            <p class="item__value">{{ seconds }}</p>
            <p class="item__title">Seconds</p>
        </div>
    </div>

</template>

<style scoped lang="scss">

p {
    margin: 0;
    line-height: 54px;
}

.block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;

    &__item {
        font-family: var(--title_font);
        color: var(--block_dark_text);
        font-size: 2rem;
        font-weight: bold;

    }
}

.item {
    min-width: 200px;
    width: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 6px;

    padding: 20px;

    background: var(--block_dark_bg);
    border: 2px solid var(--border);

    &__title, &__value {

    }

    &__value {

    }

    &__title {

    }
}

.content--l, .content--sm {

}

.content--xs {

}

</style>