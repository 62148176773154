<template>
    <ul class="list-check">
        <template v-for="item in this.info.items">
            <li class="list-check__item" v-if="to(item, 'text')">
                {{ to(item, 'text') }}
            </li>
        </template>
    </ul>
</template>

<script>
import AbstractList from "@/views/Abstract/List.vue";

export default {
    name: "ListIconCheck",
    extends: AbstractList,
    props: {
        info: {
            default() {
                return {};
            }
        }
    },
    data: () => ({
        // info: {
        //   items: [{text: "Jeden"}, {text: "Dwa"}],
        // }
    })
}
</script>

<style scoped lang="scss">

.list-check {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    list-style: none;

    &__item {
        position: relative;

        left: 0;
        top: 0;

        padding-left: 32px;

        min-height: 25px;
        line-height: 1.8rem;
        margin-bottom: .5rem;

        &:after,
        &:before {
            position: absolute;

            content: '';

            left: 0;
            top: 0;

        }

        &:after {
            width: 12px;
            height: 6px;

            border: 1px solid var(--main_color);

            border-right: 0;
            border-top: 0;
            top: 7px;

            left: 6px;

            transform: rotate(310deg);
        }

        &:before {


            width: 25px;
            height: 25px;
            border-radius: 50%;

            background-color: var(--main_color);
            opacity: .2;
        }
    }
}

</style>