<script>
import Element from "@/components/Element.vue";
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";

export default {
  name: "PortalTeamImage",
  components: {Thumb1},
  extends: Element,
    computed: {
        imgStyle() {
            let ret = {}

            ret.objectFit = this.info.fit_content ? this.info.fit_content : "cover"

            return ret
        }
    }
}
</script>

<template>
  <Thumb1 :image="obj.image_url" format="11" radius="big" :style="imgStyle"/>
</template>

<style scoped lang="scss">

</style>