<script>
import Element from "@/components/Element.vue";
import axios from "@/axios";
import {mapState} from "vuex";

export default {
  name: "MenuAbstract",
  extends: Element,
  data: () => ({
    get_url: 'sitemenu/positionget',
    get_params: {
      sort: 'number ASC',
      scope: 'url,name,langs,icon,id_top_position'
    },

    items_local: [],
  }),
  computed: {
    ...mapState('app', ['lang']),
    itemsAll() {
      let ret = [...this.items_local, ...this.items];
      console.log(ret)
      ret.map(item => {
        item.url = this.linkLocal(item.url_base) ? this.prefix + item.url_base : item.url_base;
      });

      return ret;
    },
    prefix() {
      return this.$root.routerPrefix;
    },
  },
  methods: {
    async load() {

      let url = this.get_url;

      if (!this.info.id_site_menu) {
        this.items_local = [];
        return null;
      }

      let params = {...this.get_params};

      params['domain_lang'] = this.lang
      params['id_menu'] = this.info.id_site_menu;

      console.log(params)

      this.items_local = await axios.get(url, {params: params}).then(r => r.data.data);
      this.items_local.map(item => item.url_base = item.url);
    },
    linkLocal(link) {
      return link && link.substr(0, 1) === '/';
    },
    linkOut(link) {
      return link && link.substr(0, 4) === 'http';
    },
    emit(link) {
      return !this.linkLocal(link) && !this.linkOut(link);
    },
  },
  mounted() {

    if (this.info.id_site_menu)
      this.load();
  }
}
</script>

<style scoped>

</style>