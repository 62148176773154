<template>

    <ul class="stat-list">
        <li v-for="info_one in this.info" class="stat-list__item stat-item">
            <i class="stat-item__icon material-symbols-outlined">{{ info_one.icon }}</i>
            <span class="stat-item__name">{{ info_one.name }}</span>
            <span class="stat-item__text">{{ info_one.text }}</span>
        </li>
    </ul>

</template>

<script>
import StatAbstract from "@/views/Base/Stat/Abstract.vue";

export default {
    name: "StatIconList",
    extends: StatAbstract,
    props: {
        // info: [],
    },
    data: () => ({
        info: [{
            name: '32 mld zł',
            icon: 'person',
            text: 'na szkolenia',
        }, {
            name: '4 mld zł',
            icon: 'home',
            text: 'na aktywizację zawodową',
        }, {
            name: '74 mld zł',
            icon: 'home',
            text: 'na poprawę sytuacji w domu',
        }, {
            name: '23 mld zł',
            icon: 'home',
            text: 'na aktywizację zawodową',
        }],
    }),
}
</script>

<style scoped lang="scss">
.stat-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  &__item {
    margin: 0;
    padding: 0;
    flex: 1 1 0;
  }
}

.stat-item {

  text-decoration: none;
  text-align: center;

  user-select: none;

  cursor: pointer;

  display: flex;
  flex-direction: column;

  margin-right: 12px;

  &__icon,
  &__name,
  &__text {

  }

  &__icon {
    color: var(--main);
    margin-bottom: 20px;
    font-size: 2.11rem;
    margin-top: 14px;
  }

  &__name {
    color: var(--text);
    margin-bottom: 14px;

    font-weight: 600;
    font-size: 1.17rem;
    line-height: 28px;
  }

  &__text {
    color: var(--text);
    opacity: 0.6;

    font-weight: 400;
    font-size: 0.89rem;
    line-height: 21px;
  }


}

</style>