<template>
  <div>
    <div class="list-item__rotate " :class="{rotate: open}"
         @click="open=!open">▶
    </div>
    <a class="btn btn-link" @click="open=!open">{{ header }}</a>
    <slot v-if="open"></slot>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    header: {
      default() {
        return '';
      }
    },
    open: {
      default() {
        return false;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.rotate {
  transform: rotate(90deg);
}

.list-item {

  &__rotate {
    position: absolute;
    margin-top: 9px;

    cursor: pointer;
    transition: .3s;
    color: var(--sp-link-color);
  }
}
</style>