<template>
  <span :class="this.class" :title="date">
    <img src="@/assets/img/time.svg" :alt="date" v-if="icon_show"/>
    {{ time_show }}
  </span>
</template>

<script>

export default {
  name: "ShowTime",
  props: {
    date: null,
    icon: {
      default() {
        return false;
      }
    },
    class: {
      default() {
        return 'time';
      }
    },
    show_second: {
      default() {
        return false;
      }
    },
    show_null: {
      default() {
        return true;
      }
    },
    null_text: {
      default() {
        return "Brak";
      }
    }
  },
  computed: {

    icon_show() {
      let ret = true;
      if (!this.date)
        ret = false;

      if (!this.icon)
        ret = false;

      return ret;

    },
    time_show() {

      let ret = this.date;

      if (ret && ret[2] === ':' && ret[5] === ':' && ret[8] === '.')
        ret = ret.split('.')[0];

      if (ret && ret[4] === '-' && ret[7] === '-' && ret.length > 10) {
        ret = ret.substring(11, 19)
      }

      if (ret && !this.show_second) {
        ret = ret.substring(0, 5)

      }

      return ret;
    }

  }

}
</script>

<style scoped lang="scss">

.date {

}

</style>