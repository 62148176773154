<script>
import Alert from "@/Library/Components/Alert.vue";

export default {
  name: "AppNoLangs",
  components: {Alert},
  computed: {
    location() {
      return document.baseURI
    },
    doc() {
      return document
    },
  },
  mounted() {
    console.log(this.doc);
    console.log(window);
  }
}
</script>

<template>

  <div class="error">
    <div class="error__content content">
      <img class="content__image" src="@/assets/img/logo.png" alt="Logo Uniwersytetu"/>
      <div class="content__name">
        <Alert type="error">Wystąpił błąd. Nie masz włączonego żadnego języka w serwisie.</Alert>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

.error {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: 1050;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

</style>