<script>

import axios from "@/axios";
import AbstractList from "@/views/Abstract/List.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";
import ControllError from "@/Library/Controll/Error.vue";
import ArticleEventBlock from "@/views/Article/EventBlock.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import Alert from "@/Library/Components/Alert.vue";
import Calendar from "@/Library/Components/Calendar.vue";

export default {
    name: "ArticleEventShort",
    components: {Calendar, Alert, MenuDropdown, ArticleEventBlock},
    extends: AbstractList,
    mixins: [ComponentFormStateMachine, ControllError],
    data: () => ({
        items: [],

        pars: {},

        sort: 'date ASC',
        sort_types: [
            {name: 'Od najnowszych', value: 'date DESC'},
            {name: 'Od najstarszych', value: 'date ASC'},
            {name: 'Alfabetycznie', value: 'title ASC'}
        ],

        get_url: 'article/get',
        get_params: {
            event: "yes",
            version: 'no',
            scope: 'title,event_date_start,event_date_end,slug,slugs,thumb,langs,lead,urls'
        }
    }),
    methods: {
        async refresh() {
            this.stateUpdate("sendInfo")

            let params = {...this.get_params, ...this.config};
            params['sort'] = this.sort;

            if (this.info.page_count)
                params['page_count'] = this.info.page_count

            let response = await axios
                .get(this.get_url, {params: params})
                .then(r => r.data);
            this.items = response.data;
            this.count = response.count;

            this.stateUpdate("sendSuccess")
        },
    },
    watch: {
        page: {
            handler() {
                this.refresh();
            }
        }
    },
    async mounted() {
        await this.refresh();
    },
    beforeRouteUpdate(to, from, next) {
        if (to.path === this.$route.path) {
            this.loadUri(to.query);
        }
        next();
    },
}
</script>

<template>

    <div class="list">
        <div class="list__head head">
            <div class="head__sort">
                <MenuDropdown :name="t('sort')" :items="sort_types" :selected="sort" @click="updateSort"/>
            </div>
        </div>

        <div class="list__body body" :class="{'loading': stateLoading()}">
            <div class="body__content content">
                <div class="content__objs objs">
                    <ArticleEventBlock v-for="item in items" :item="item"/>
                </div>
                <div class="content__alert">
                    <Alert :text="t('none')" label="" type="info" v-if="!stateLoading() && items.length === 0"/>
                </div>
            </div>
        </div>
    </div>


</template>

<style scoped lang="scss">

.list {
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    width: 100%;

    &__head {
        margin-bottom: 16px;
    }

    &__body {

    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: end;

    &__sort {

    }
}

.body {

    &__content {
        width: 100%;
    }
}

.content {

    &__objs {

    }

    &__alert {

    }
}

.objs {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}

</style>