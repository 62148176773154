<script>
import {defineComponent} from 'vue'
import Image3V2 from "@/views/Base/Image/Image3V2.vue";
import Image3 from "@/views/Base/Image/Image3.vue";
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "ImageAll",
    components: {Image3, Image3V2},
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    type: "type_2",
                    image: {
                        url: "https://picsum.photos/653/367/?blur=10"
                    },
                    header: "Lorem ipsum dolor sit amet consectetur",
                    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
                }
            }
        }
    }
})
</script>

<template>

    <div class="item" :class="{'item--type-2': info.type === 'type_2'}">
        <img :src="info.image.url" v-if="info.type === 'type_1'" class="item__image" alt="image"/>

        <div class="item__text text">
            <h1 class="text__title">{{ to(info, "header") }}</h1>
            <p class="text__description" v-html="parseText(info, 'description')"></p>
        </div>
    </div>

</template>

<style scoped lang="scss">

.item {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;

    &__image {
        max-width: 653px;
        width: 100%;
        height: 367px;

        object-fit: cover;

        margin-bottom: 24px;
    }

    &__text {
        max-width: 750px;
    }

    &--type-2 {
        position: relative;
        padding-top: 40px;

        &::before {
            position: absolute;
            content: "";
            width: 162px;
            height: 8px;
            top: 0;
            left: calc(50% - (162px / 2));
            background: var(--title);
        }
    }

    @media screen and (max-width: 1440px) {
        max-width: 523px;

        &__image {
            max-width: 523px;
        }

        &__text {
            max-width: 534px;
        }
    }

    @media screen and (max-width: 767px) {
        max-width: unset;

        &__image {
            max-width: unset;
            height: 162px;
            margin-bottom: 32px;
        }

        &__text {

        }

        &--type-2 {

            &::before {
                width: 166px;
                left: calc(50% - (166px / 2));
            }
        }
    }
}

.text {

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        text-align: center;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.56rem;
        font-weight: 700;
        letter-spacing: -0.101px;
        margin-top: 0;
        margin-bottom: 8px;
        color: var(--title);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 1.33rem;
        font-weight: 400;
        line-height: 36px;
        color: var(--text);
    }

    @media screen and (max-width: 767px) {

        &__title {
            font-size: 1.22rem;
            margin-bottom: 16px;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

.content--l, .content--sm {

    & .item {

        &__image {
            max-width: 523px;
        }

        &__text {
            max-width: 534px;
        }
    }

    & .text {

        &__title {

        }

        &__description {

        }
    }

}

.content--xs {

    & .item {

        &__image {
            max-width: unset;
            height: 162px;
            margin-bottom: 32px;
        }

        &__text {

        }

        &--type-2 {

            &::before {
                width: 166px;
                left: calc(50% - (166px / 2));
            }
        }
    }

    & .text {

        &__title {
            font-size: 1.22rem;
            margin-bottom: 16px;
        }

        &__description {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }

}

</style>