<script>

export default {
    name: "ArticleNewsBlock",
    props: {
        item: {
            default() {
                return {}
            }
        },
        sub: {
            default() {
                return false
            }
        }
    }
}

</script>

<template>

    <div class="item" :class="{'item--sub': sub}">
        <router-link to="/" class="item__image image">
            <img :src="item.thumb" alt="">

            <p class="image__category">{{ item.category_name }}</p>
        </router-link>

        <div class="item__content content">
            <router-link to="/" class="content__title td-no">
                <h1>{{ item.title }}</h1>
            </router-link>
            <p v-if="!sub" class="content__description three-dots three-dots--5" v-html="item.text"></p>
        </div>
    </div>

</template>

<style scoped lang="scss">

.item {
    box-shadow: 0px 3px 40px rgba(14, 37, 49, 0.078);
    //border-radius: 4px;

    &__image {

    }

    &__content {

    }

    &:hover {

        .image img {
            transform: scale(1.05);
        }
    }

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-width: 1000px) {

    }

    @media screen and (max-width: 444px) {

    }

    &--sub {

        & .image {
            height: 222px;

            &__category {
                line-height: 30px;
                font-size: 24px;
                padding: 7px 22px;
            }
        }

        & .content {
            padding: 25px 20px;

            &__title {

                & > h1 {
                    font-size: 28px;
                    line-height: 35px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.image {
    height: 416px;
    overflow: hidden;
    display: block;

    position: relative;

    &__category {
        font-family: var(--text_font);
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        color: var(--block_dark_text);
        background: var(--block_dark_bg);
        padding: 8px 24px;
        //border-radius: 4px;
        margin: 0;

        position: absolute;
        top: 20px;
        left: 20px;
    }

    & img {
        width: 100%;
        height: inherit;
        object-fit: cover;

        transition: all 0.25s ease 0s;
    }
}

.content {
    padding: 40px 35px 35px 35px;

    &__title {

        & > h1 {
            font-family: var(--title_font);
            font-size: 36px;
            font-weight: 700;
            line-height: 53px;
            color: var(--title);
            margin: 0 0 15px 0;
        }

        &:hover {

            & > h1 {
                color: rgb(95, 45, 237);
            }
        }
    }

    &__description {
        font-family: var(--text_font);
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        color: var(--text);
        margin-bottom: 0;
    }
}

</style>