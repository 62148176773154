<template>

    <input class="row-nice__input" type="datetime-local" v-model="value_local" @keyup="updateValue" @change="updateValue"/>

</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputDateTime",
  extends: InputBase,
  props: ['name','value'],
  data: () => ({
    value_local: null,
  }),
  methods: {
    val(){
      return this.value;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value);
    }
  },
  mounted() {
    this.value_local = this.value;
  },

  watch: {
    value(new_value) {
      this.value_local = new_value
    }
  },
}
</script>

<style scoped>

</style>