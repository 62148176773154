<script>

import Element from "@/components/Element.vue";

export default {
  name: "Breadcrumb",
  extends: Element,

  computed: {
    items() {
      return [];
    }
  },

}

</script>
<template>
  <pre>
    {{info}}
  </pre>
  <ul class="breadcrumb" v-if="(items.length > 1)">
    <li class="breadcrumb__item" v-for="item in this.items">
      <router-view class="breadcrumb__link">{{ to(item,'name') }}</router-view>
    </li>
  </ul>
</template>


<style scoped lang="scss">

.breadcrumb {
    padding: 0;
    margin: 30px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 6px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(100, 100, 100);
    font-size: 1rem;

    &-title {

    }

    &-arrow {
    }

    &:not(:last-child)::after {
      content: "/";
      margin: 0 10px;
    }



    &--active {
      color: rgb(73, 78, 155);

      & a {
        cursor: text;
      }

      &::after {
        display: none;
      }
    }
  }
}

</style>