<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "Calendar",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          header: "Nachodzące wydarzenia",
          events: [{
            name: "Is Wireland for Web the best wireframe template for my design project?",
            date: [{
              day: "17",
              month: "Wrzesień"
            }]
          }, {
            name: "Event 2",
            date: [{
              day: "12",
              month: "Listopad"
            }]
          }, {
            name: "Event 3",
            date: [{
              day: "20",
              month: "Grudzień"
            }]
          }, {
            name: "Is Wireland for Web the best wireframe template for my design project?",
            date: [{
              day: "17",
              month: "Wrzesień"
            }]
          }, {
            name: "Is Wireland for Web the best wireframe template for my design project?",
            date: [{
              day: "17",
              month: "Wrzesień"
            }]
          }]
        }
      }
    }
  }
})
</script>

<template>
  <div class="calendar">
    <div class="calendar__header header">
      <h2 class="header__title">{{to(this.info, 'header')}}</h2>

      <div class="header__link link">
        <div class="link__container">
          <router-link class="page-back" v-if="info.link" :to="info.link">
            <p class="link__text">{{to(info, 'linkName')}}</p>
          </router-link>
        </div>
      </div>
    </div>

    <div class="calendar__events events">
      <div class="events__item item" v-for="event in info.items" :key="event.name">
        <div class="item__date">
          <h2 class="item-date__month">{{to(event, 'month')}}</h2>
          <h2 class="item-date__day">{{event.day}}</h2>
        </div>

        <div class="item__event-name">
          <h2 class="item__event-text">{{to(event, 'name')}}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.calendar{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    color: var(--title);
    padding-left: 2%;

    .link{
      flex-basis: 10%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-right: 2%;

      &__container{
        padding: 3% 10%;
        background-color: var(--block_light_bg);
      }

      &__text{
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin: 0;
        color: var(--link);
      }
    }
  }

  .events{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .item{
      flex-basis: 25%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      margin-bottom: 1%;

      &__date{
        flex-basis: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--block_dark_bg);
        align-items: center;
        align-content: center;
        padding: 3%;

        .item-date__month,
        .item-date__day{
          margin: 0;
        }

        .item-date__month{
          font-size: 0.89rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: var(--block_text_color)
        }

        .item-date__day{
          font-size: 2.78rem;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.1800002008676529px;
          text-align: left;
          color: var(--block_text_color)
        }
      }

      &__event-name{
        flex-basis: 60%;

        .item__event-text{
          font-size: 0.89rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: var(--text_color)
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .events {
    .item {
      flex-basis: 48% !important;
    }
  }
}

@media screen and (max-width: 861px) {
  .events{
    justify-content: space-between;

    .item{
      flex-basis: 100%!important;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;

      &__date{
        margin: 0 auto;
      }

      &__event-name{
        margin: 0 auto;

        .item__event-text{
          text-align: center!important;
        }
      }
    }
  }
}
</style>