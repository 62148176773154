<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";
import Contact1 from "@/views/Base/Contact/Contact1.vue";
import Contact2 from "@/views/Base/Contact/Contact2.vue";

export default defineComponent({
  name: "Contact",
  components: {Contact2, Contact1},
  extends: Element,
  props: {
    info: {
      default() {
        return {
          header: "Get in Touch",
          description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat.",
          number: "545-450-3059",
          email: "info@wireland.com",
          address: "919 Sophie Road"
        }
      }
    }
  }
})
</script>

<template>
  <contact1 :info="info" v-if="info.type === 'type_1'"/>
  <contact2 :info="info" v-if="info.type === 'type_2'"/>
</template>

<style scoped lang="scss"></style>