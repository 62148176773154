<script>
import {defineComponent} from 'vue'
import Hr1 from "@/views/Base/Hr/Hr1.vue";
import MenuInline from "@/views/Base/Menu/Inline.vue";
import SocialList from "@/views/Base/Social/List.vue";

export default defineComponent({
  name: "LayoutFooter",
  components: {SocialList, MenuInline, Hr1},
  data: () => ({
    items_left: [
      {name: 'Deklaracja dostępności', url: '/text'},
      {name: 'Polityka prywatności', url: '/text'},
      {name: 'Odwołanie zgody RODO', url: '/text'},
      {name: 'Zgłoś błąd', url: '/text'},
    ],
    items_right: [
      {name: 'Zobacz inne wydziały', url: '/text'},
      {name: 'Rok akademicki 2022 - 2023', url: '/text'},
    ],
  })
})
</script>

<template>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <img src="../assets/img/logo-us.png" style="width: 250px;">
        </div>
        <div class="col-12 col-lg-6" style="text-align: right">
          <SocialList style="float:right; margin: 10px 0;"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Hr1 color="white"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <MenuInline color="white" :items="items_left"/>
        </div>
        <div class="col-12 col-lg-6">
          <MenuInline color="white" :items="items_right" style="float:right;" size="1rem"/>
        </div>
      </div>
    </div>
  </footer>


</template>

<style scoped lang="scss">

footer {

  background-color: var(--main_color);
  padding: 25px 0 41px;

  color: white;
}

</style>