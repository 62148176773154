<script>

import ControlAnimate from "@/Library/Controll/Animate.vue"

export default {
    name: "Block3Image",
    props: {
        info: {
            default() {
                return {
                    title: "Title",
                    description: "Description",
                    image1: {
                        url: "https://via.placeholder.com/300",
                    },
                    image2: {
                        url: "https://via.placeholder.com/300",
                    },
                    image3: {
                        url: "https://via.placeholder.com/300",
                    },
                    link: "/",
                    link_name: "Link Name"
                }
            }
        }
    },
    mixins: [ControlAnimate]
}

</script>

<template>

    <div class="item">
        <div class="item__images images custom-hover-block">
            <div>
                <div class="image-container anim-1 _anim-item">
                    <img :src="info.image1?.url" alt="">
                </div>
            </div>
            <div>
                <div class="image-container anim-2 _anim-item">
                    <img :src="info.image2?.url" alt="">
                </div>
                <div class="image-container anim-3 _anim-item">
                    <img :src="info.image3?.url" alt="">
                </div>
            </div>
        </div>

        <div class="item__content content">
            <h1 class="content__title anim-4 _anim-item">{{ info.title }}</h1>
            <p class="content__description anim-5 _anim-item" v-html="info.description"></p>
            <div class="content__links links anim-7 _anim-item">
                <router-link :to="info.link" class="link td-no">{{ info.link_name }}</router-link>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">

.item {
    display: flex;
    align-items: center;
    column-gap: 150px;

    margin: 0 auto;
    width: fit-content;

    &__images {

    }

    &__content {
        max-width: 520px;
    }

    @media screen and (max-width: 1440px) {
        column-gap: 100px;

        &__content {
            max-width: 445px;
        }

        & .images {

            & > div:nth-child(1) {

                & > .image-container:nth-child(1) > img {
                    max-width: 235px;
                    //min-width: 235px;
                    height: 315px;
                }
            }

            & > div:nth-child(2) {

                & > .image-container:nth-child(2) > img {
                    max-width: 235px;
                    //min-width: 235px;
                    height: 230px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {

        &__content {
            max-width: 400px;
        }

        & .images {

            & > div:nth-child(1) {

                & > .image-container:nth-child(1) > img {
                    max-width: 190px;
                    //min-width: 190px;
                    height: 250px;
                }
            }

            & > div:nth-child(2) {

                & > .image-container:nth-child(1) > img, & > .image-container:nth-child(2) > img {
                    max-width: 190px;
                    //min-width: 190px;
                    height: 190px;
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;

        &__content {
            max-width: 720px;
        }

        & .links {
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;

        & .images {
            flex-direction: column;
            row-gap: 30px;
            width: 100%;

            & div {
                width: 100%;
            }

            & img {
                max-width: 100%!important;
            }

            & .image-container {
                width: unset;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.images {
    display: flex;
    align-items: center;
    column-gap: 35px;

    transition: transform 0.1s ease;

    & > div:nth-child(1) {

        & > .image-container:nth-child(1) > img {
            max-width: 300px;
            //min-width: 300px;
            height: 400px;
        }
    }

    & > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        & > .image-container:nth-child(1) > img {
            max-width: 200px;
            //min-width: 200px;
            height: 200px;
        }

        & > .image-container:nth-child(2) > img {
            max-width: 255px;
            //min-width: 255px;
            height: 250px;
        }
    }

    & img {
        width: 100%;
        object-fit: cover;
        //border-radius: 20px;
    }
}

.image-container {
    width: fit-content;
    position: relative;
    overflow: hidden;
    //border-radius: 20px;
    padding: 1px 1px 0 0;

    display: flex;

    &::after {
        // do odkomentowania
        display: none;

        position: absolute;
        content: "";
        top: 0;
        right: 0;
        background: #fff;
        width: 40%;
        height: 20%;
        transform: rotate(40deg) translateY(-70%) translateX(10%);
    }
}

.content {

    &__title {
        font-family: var(--title_font);
        font-size: 30px;
        font-weight: 700;
        line-height: 48px;
        color: var(--title);
        margin-top: 0;
        margin-bottom: 30px;
    }

    &__description {
        font-family: var(--text_font);
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        color: var(--text);
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 30px;
        }
    }

    &__links {

    }
}

.links {

}

.link {
    //border-radius: 8px;
    border: 2px solid var(--block_dark_bg);
    background: var(--block_dark_bg);
    color: var(--block_dark_title);

    display: flex;
    align-items: center;
    width: fit-content;

    font-family: var(--title_font);
    padding: 12px 55px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    height: 60px;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;

    transition: all 0.25s ease 0s;

    &:hover {
        color: var(--title);
        background: #fff;
    }
}

.content--xl {

    & .item {
        column-gap: 100px;

        &__content {
            max-width: 445px;
        }

        & .images {

            & > div:nth-child(1) {

                & > .image-container:nth-child(1) > img {
                    max-width: 235px;
                    //min-width: 235px;
                    height: 315px;
                }
            }

            & > div:nth-child(2) {

                & > .image-container:nth-child(2) > img {
                    max-width: 235px;
                    //min-width: 235px;
                    height: 230px;
                }
            }
        }
    }
}

.content--l {

    & .item {

        &__content {
            max-width: 400px;
        }

        & .images {

            & > div:nth-child(1) {

                & > .image-container:nth-child(1) > img {
                    max-width: 190px;
                    //min-width: 190px;
                    height: 250px;
                }
            }

            & > div:nth-child(2) {

                & > .image-container:nth-child(1) > img, & > .image-container:nth-child(2) > img {
                    max-width: 190px;
                    //min-width: 190px;
                    height: 190px;
                }
            }
        }
    }
}

.content--sm {

    & .item {
        flex-direction: column;
        row-gap: 30px;

        &__content {
            max-width: 720px;
        }

        & .links {
            display: flex;
            justify-content: center;
        }
    }
}

.content--xs {

    & .item {
        flex-direction: column;
        width: 100%;
        row-gap: 20px;

        & .images {
            flex-direction: column;
            row-gap: 30px;
            width: 100%;

            & div {
                width: 100%;
            }

            & img {
                max-width: 100%!important;
            }

            & .image-container {
                width: unset;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.anim {

    &-1 {
        transition: all 1s ease 0s;
        transform: scale(0.5);

        &._active {
            transform: scale(1);
        }
    }

    &-2 {
        transition: all 1s ease 0.2s;
        transform: scale(0.5);

        &._active {
            transform: scale(1);
        }
    }

    &-3 {
        transition: all 1s ease 0.4s;
        transform: scale(0.5);

        &._active {
            transform: scale(1);
        }
    }

    &-4 {
        transition: all 1s ease 0.2s;
        transform: translateX(100px);

        &._active {
            transform: translateX(0);
        }
    }

    &-5 {
        transition: all 1s ease 0.4s;
        transform: translateX(100px);

        &._active {
            transform: translateX(0);
        }
    }

    &-6 {
        transition: all 1s ease 0.6s;
        transform: translateX(100px);

        &._active {
            transform: translateX(0);
        }
    }

    &-7 {
        transition: all 1s ease 0.8s;
        transform: translateY(50px);

        &._active {
            transform: translateY(0);
        }
    }
}

</style>