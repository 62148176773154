<template/>

<script>
import InputBase from "./InputBase";

export default {
    name: "InputOptionsAbstract",
    extends: InputBase,
    props: {
        options_null: {
            default() {
                return false;
            }
        },
        options_null_label: {
            default() {
                return "Brak";
            }
        },
    },
    data: () => ({}),
    methods: {
        /**
         *
         * @param options
         * @returns {null}
         * null
         * string
         * array-key-obj      a[5] = {m:k}
         * array-nokey-obj    [{k:a,m:b}]
         * array-key-val      a[5] = k
         * array-nokey-val    [k]
         * obj-key-obj        {l:{o:g}}
         * obj-key-val        {l:p}
         */
        detectType(options) {
            let type = null;
            if (typeof options === 'string') {
                type = 'string';
            }

            if (Array.isArray(options)) {
                type = 'array';

                let values = Object.values(options);
                let keys = Object.keys(options);

                if (keys[0] !== '0')
                    type += '-key';
                if (keys[0] === '0')
                    type += '-no_key';

                if (this.isObject(values[0]))
                    type += '-obj';

                if (!this.isObject(values[0]))
                    type += '-val';
            }

            if (this.isObject(options)) {
                type = 'obj';

                let values = Object.values(options);
                let keys = Object.keys(options);

                if (keys[0] !== 0)
                    type += '-key';
                if (keys[0] === 0)
                    type += '-no_key';

                if (this.isObject(values[0])) {
                    type += '-obj';
                    if (values[0].name)
                        type += '-name'
                }

                if (!this.isObject(values[0]))
                    type += '-val';

            }

            return type;
        },

        makeOptions(options) {
            let type = this.detectType(options)

            console.log(type, options)

            let options_template = {
                'name': '',
                'value': '',
                'disabled': false,
            };
            let selectOptions = [];
            switch (type) {
                case 'null':
                    selectOptions = [];
                    break;

                case 'string':

                    if (options === "01") {

                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = 'Tak';
                        option_row['value'] = '1';
                        selectOptions.push(option_row);

                        option_row = Object.assign({}, options_template);
                        option_row['name'] = 'Nie';
                        option_row['value'] = '0';
                        selectOptions.push(option_row);

                    } else if (options === "yn") {

                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = 'Tak';
                        option_row['value'] = 'yes';
                        selectOptions.push(option_row);

                        option_row = Object.assign({}, options_template);
                        option_row['name'] = 'Nie';
                        option_row['value'] = 'no';
                        selectOptions.push(option_row);

                    } else {

                        let splitted = options.split(',');
                        for (let option of splitted) {
                            let option_row = Object.assign({}, options_template);
                            option_row['name'] = option;
                            option_row['value'] = option;

                            selectOptions.push(option_row);
                        }

                    }

                    break;

                case 'array-key-obj':
                    for (let [key, option] of Object.entries(options)) {

                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = Object.values(option)[0];
                        option_row['value'] = key;

                        selectOptions.push(option_row);

                    }
                    break;

                case 'array-no_key-obj':

                    for (let option of options) {
                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = option.name;
                        option_row['value'] = option.value;
                        option_row['disabled'] = option.disabled !== undefined ? option.disabled : false;

                        selectOptions.push(option_row);
                    }
                    break;

                case 'array-key-val':
                    for (let [key, option] of Object.entries(options)) {
                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = option;
                        option_row['value'] = key;
                        selectOptions.push( option_row );
                    }
                    break;

                case 'array-no_key-val':
                    for (let option of Object.values(options)) {
                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = option;
                        option_row['value'] = option;
                        selectOptions.push( option_row );
                    }
                    break;

                case 'obj-key-obj':
                    for (let option of Object.values(options)) {
                        for (let [key, nested_option] of Object.entries(option)) {
                            let option_row = Object.assign({}, options_template);
                            option_row['name'] = nested_option;
                            option_row['value'] = key;
                            selectOptions.push( option_row );
                        }
                    }
                    break;

                case 'obj-key-obj-name':
                    selectOptions = options;
                    break;

                case 'obj-key-val':
                    for (let [key, option] of Object.entries(options)) {
                        let option_row = Object.assign({}, options_template);
                        option_row['name'] = option;
                        option_row['value'] = key;
                        selectOptions.push( option_row );
                    }
                    break;
            }

            if (this.options_null) {
                let option_row = Object.assign({}, options_template);
                option_row['name'] = this.options_null_label;
                option_row['value'] = null;
                selectOptions[null] = option_row;
            }

            return selectOptions;
        },

        isObject(testing_data) {
            return typeof testing_data === 'object' &&
                !Array.isArray(testing_data) &&
                testing_data !== null
        }
    }
}
</script>

<style scoped lang="scss">

</style>