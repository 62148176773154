<template>
	<div class="row" v-if="canShow">
		<div>
			<div class="name">{{ obj.name }}</div>
			<table class="table no-footer table-striped table-hover mb-2" id="customTable">
				<tr style="text-align: center" class="table__thead-tr">
					<th style="width: 100px"></th>
					<th v-for="(series,number) in splitDataSeries" style="font-weight: 600;"
							:style="`width: ${obj.widths[number]}px`">
						{{ series }}
					</th>
				</tr>
				<tr v-for="(row, row_idx) in obj.rows" class="obj-table-row">
					<td>
						{{ splitLabels[row_idx] }}
					</td>
					<td v-for="data in row" :style="`text-align: ${/^[0-9]*$/.test(data) ? 'right' : 'left'}`">
						{{ data }}
					</td>
				</tr>
			</table>
		</div>

	</div>
</template>

<script>
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";
import SystemConfigModule from "@/Library/System/Config/Module.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import ControllError from "@/Library/Controll/Error.vue";
import TemplatePage from "@/Library/Template/Page.vue";
import TemplateCard from "@/Library/Template/Card.vue";
import Alert from "@/Library/Components/Alert.vue";
import AbstractDynamic from "@/views/Base/DynamicChart/Part/AbstractDynamic.vue";

/**
 * Pokazywanie tabeli
 */
export default {
	name: "DynamicTableShow",
	props: {source: {}},
	components: {
		Alert, TemplateCard, TemplatePage
	},
	mixins: [ComponentFormStateMachine, ControllForm, ControllError, SystemConfigModule, AbstractDynamic],

	data: () => ({
		obj: {rows: [], widths: []},
	}),

	methods: {
		refresh() {
			console.log('LaLaLa')
			this.obj = this.load(this.source.widget_data.table)
		}
	},

	async mounted() {
		this.obj = this.load(this.source.widget_data.table)
	},

	watch: {
		source(val) {
			this.obj = JSON.parse(JSON.stringify(val));
		}
	}
}
</script>

<style scoped lang="scss">
.float-right {
	float: right;
}

.name {
	font-weight: 600;
	font-size: 1.25rem;
	margin-left: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
}

#customTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customTable td, #customTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customTable tr:nth-child(even){background-color: #f2f2f2;}

#customTable tr:hover {background-color: #ddd;}

#customTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--block_dark_bg);
    color: white;
}
</style>