<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "TeamMember3",
  extends: Element,
  props: {
    obj: {
      default() {
        return {}
      }
    }
  },
  computed: {
    image_url() {
      return this.obj.image_url;
    },
    stand() {
      return this.obj.stand;
    },
    name() {
      return this.obj.name;
    },
    description() {
      return this.obj.description;
    },
    prefix() {
      return this.$root.routerPrefix;
    },
  }
})
</script>

<template>

    <router-link :to="prefix+'/'+obj.slug" class="item">
        <div class="item__image" :style="{backgroundImage: `url(${image_url})`}"></div>

        <h1 class="item__title">{{ to(obj, "name") }}</h1>
        <p class="item__subtitle">{{ to(obj, "stand") }}</p>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.item {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 767px) {

    }

    &__image {
        min-width: 215px;
        width: 215px;
        height: 215px;
        border-radius: 50%;
        margin-bottom: 24px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__title, &__subtitle {
        font-style: normal;
        line-height: normal;
        text-align: center;
        margin-top: 0;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--link);
    }

    &__subtitle {
        font-family: var(--text_font);
        font-size: 0.78rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: var(--text);

    }
}

.content--l, .content--sm {

}

.content--xs {

}

</style>