<template>

  <input :class="this.class" :style="this.style" type="text" v-model="value_local" @change="updateValue" @keyup="updateValue" ref="input" :placeholder="placeholder"/>


</template>

<script>
import InputBase from "./InputBase";
import Input from "@/Library/Forms/Input.vue";

export default {
  name: "InputText",
  components: {Input},
  extends: InputBase,
  props: {
    'name':{},
    'placeholder':{},
    'value':{},
    'class':{
      default() {
        return 'row-nice__input';
      }
    },
    'style':{
      default() {
        return '';
      }
    },
  },
  data: () => ({
    value_local: null,
  }),
  methods: {
    val(){
      return this.value;
    },
    focus(){
      this.$refs['input'].focus()
    },
    updateValue: function (value) {
      this.$emit('updateValue', this.name, this.value_local);
    }
  },
  mounted() {
    this.value_local = this.value;
  },

  watch: {
    value(new_value) {
      this.value_local = new_value
    }
  },
}
</script>

<style scoped lang="scss">

input{
  padding: 12px;

  border: 1px solid #002a5e;

}

.placeholder-white{
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}

</style>