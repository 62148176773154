<template>
    <div class="label" :style="{'background-color': bgColor}">{{ info.name}}</div>
</template>

<script>
import LabelAbstract from "@/views/Base/Label/Abstract.vue";
export default {
    name: "LabelSolid",
    extends: LabelAbstract,
    data: () => ({
        info: {
            name: "Nabór trwa",
            bgColor: 'var(--main_color)'
        },
    }),

}
</script>

<style scoped lang="scss">

.label{
    text-transform: uppercase;
    padding: 6px 12px;

    border-radius: 6px;
    color: white;

    line-height: 1rem;
}

</style>