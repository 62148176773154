<script>

import ControlAnimate from "@/Library/Controll/Animate.vue"

export default {
    name: "BlocksIcon",
    props: {
        info: {
            default() {
                return {}
            }
        }
    },
    mixins: [ControlAnimate],
    methods: {
        mouseover(item, itemDOM) {
            let item_title = itemDOM.querySelector(".item__title")
            let item_description = itemDOM.querySelector(".item__description")

            item_title.style.color = item.color
            item_description.style.color = item.color
        },

        mouseleave(item, itemDOM) {
            let item_title = itemDOM.querySelector(".item__title")
            let item_description = itemDOM.querySelector(".item__description")

            item_title.style.color = "var(--title)"
            item_description.style.color = "var(--text)"
        }
    }
}

</script>

<template>

    <ul class="list anim-numbers-section">
        <li
            :ref="`item_${index}`"
            class="list__item item _anim-item"
            :class="'anim-' + (index + 1)"
            v-for="(item, index) in info.items"
            @mouseover="mouseover(item, $refs[`item_${index}`][0])"
            @mouseleave="mouseleave(item, $refs[`item_${index}`][0])">
                <router-link to="/" class="td-no">
                    <div class="item__icon icon">
                        <span class="material-symbols-rounded" :style="{color: item.color}">{{ item.icon }}</span>

                        <span class="icon__count" :style="{background: item.color}" data-anim-number>{{ item.count }}</span>
                    </div>

                    <h1 class="item__title">{{ item.title }}</h1>
                    <p class="item__description" v-html="item.description"></p>

                    <router-link to="/" class="item__link td-no">
                        <span class="material-symbols-rounded" :style="{color: item.color}">east</span>
                    </router-link>
                </router-link>
        </li>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 80px;

    &__item {

    }

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 1200px) {

    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 60px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 444px) {

    }
}

.item {

    & > a {
        padding: 80px 45px 75px 45px;
        border: 2px dashed #d9d9d9;
        //border-radius: 25px;

        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;

        &:hover {

            & .item {

                &__icon {
                    transform: scale(1.2);

                    & .material-symbols-rounded {
                        font-variation-settings: 'FILL' 1
                    }
                }

                &__link {
                    animation-name: bounce;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-timing-function: ease-in-out;
                }
            }
        }
    }

    &__icon {
        margin-bottom: 25px;
        transition: all 0.25s ease 0s;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 24px;
        font-weight: 800;
        line-height: 26.4px;
        color: var(--title);
        margin-top: 0;
        margin-bottom: 15px;
        text-align: center;
    }

    &__description {
        font-family: var(--text_font);
        font-size: 15px;
        font-weight: 400;
        line-height: 25.5px;
        color: var(--text);
        margin: 0;
        text-align: center;
    }

    &__link {
        position: absolute;
        left: calc(50% - 55px / 2);
        bottom:  calc(-55px / 2);

        min-width: 55px;
        width: 55px;
        height: 55px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);

        & .material-symbols-rounded {
            font-size: 20px;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10px);
        }
    }
}

.icon {
    position: relative;

    & .material-symbols-rounded {
        font-size: 100px;
    }

    &__count {
        position: absolute;
        top: -20px;
        right: -35px;
        margin: 0;

        padding: 15px 20px;
        border-radius: 25px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Mulish, sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 19.8px;
        color: #fff;
    }
}

.content--xl {

}

.content--l {

}

.content--sm {

    & .list {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 60px;
    }
}

.content--xs {

    & .list {
        grid-template-columns: repeat(1, 1fr);
    }
}

.anim {

    &-1, &-2, &-3 {
        transform: translateY(100px);

        &._active {
            transform: translateY(0);
        }
    }

    &-1 {
        transition: all 1s ease 0s;
    }

    &-2 {
        transition: all 1s ease 0.2s;
    }

    &-3 {
        transition: all 1s ease 0.4s;
    }
}

</style>