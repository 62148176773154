<template>
  <a class="show-more">
    {{ info.name }}
  </a>
</template>

<script>
import LinkAbstract from "@/views/Base/Link/Abstract.vue";

export default {
  name: "LinkShowMore",
  extends: LinkAbstract,
  props: {
    color: {
      default() {
        return "var(--main_color)";
      }
    },
    info: {
      default() {
        return {
          url: "https://strefaportali.pl",
          name: "Pokaż więcej",
        };
      }
    },
  },
}
</script>

<style scoped lang="scss">

.show-more {

  text-align: left;

  position: relative;

  font-weight: 700;
  font-size: 0.85rem;
  line-height: 0.85rem;
  /* identical to box height, or 100% */

  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;

  color: v-bind(color);

  cursor: pointer;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    position: absolute;
    content: '';
    right: -12px;
    height: 7px;
    width: 7px;

    border: 1.5px solid v-bind(color);
    transform: rotate(45deg);

    border-bottom: none;
    border-left: none;
  }
}

</style>