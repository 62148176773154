<script>

import axios from "@/axios";
import SiteMapList from "@/views/Base/Mixed/SiteMapList.vue";
import Element from "@/components/Element.vue";

export default {
  name: "SiteMap",
  components: {SiteMapList},
  extends: Element,

  data: () => ({
    get_url: 'page/get',
    get_params: {
      status: 'live',
      scope_extra: 'urls,slugs',
    },

    pages: [],
  }),

  props: {},

  computed: {},

  methods: {
    async load() {

      let result = await axios.get(this.get_url, {params: this.get_params})
          .then(r => r)
          .catch(e => e.result)

      if (result.status === 200) {
        this.pages = result.data.data;
      }

    }
  },

  mounted() {
    this.load()
  }

}
</script>


<template>
  <SiteMapList v-bind="$props" :items="this.pages"/>
</template>
