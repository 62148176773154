<template>

    <ul class="social-list">
        <li v-for="info_one in this.info" class="social-list__item social-item">
            <a :href="info_one.url" class="social-item__icon material-symbols-outlined">{{ info_one.icon }}</a>
        </li>
    </ul>

</template>

<script>

import SocialAbstract from "@/views/Base/Social/Abstract.vue";

export default {
    name: "SocialList",
    extends: SocialAbstract,
    props: {
        // info: [],
    },
    data: () => ({
        info: [{
            name: 'Facebook',
            icon: 'person',
            url: '/',
        },{
            name: 'Strona główna',
            icon: 'home',
            url: '/',
        }],
    }),
}
</script>

<style scoped lang="scss">
.social-list{
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    &__item{
        margin: 0;
        padding: 0;
    }
}

.social-item{

    text-decoration: none;
    text-align: center;

    user-select: none;

    cursor: pointer;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: var(--icon_bg);
    border: var(--border) 1px solid;

    transition: .3s all;

    &__icon{
        text-decoration: none;

        color: var(--icon);

        line-height: 1.2em;

    }

    &:hover{

        background-color: var(--icon);

    }
    &:hover &__icon{
        color: var(--icon_bg);

    }

}

</style>