<script>
import Element from "@/components/Element.vue";
import axios from "@/axios";
import News3 from "@/views/Article/News3.vue";
import ArticleBlock1 from "@/views/Article/Block1.vue";
import News2 from "@/views/Article/News2.vue";
import News4 from "@/views/Article/News4.vue";
import Alert from "@/Library/Components/Alert.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";
import TeamMemberSlider from "@/views/Portal/Team/TeamMemberSlider.vue";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";

export default {
    name: "ArticleNews",
    components: {
        Carousel, Slide, Navigation, Pagination, TeamMemberSlider, Alert, News4, News2, ArticleBlock1, News3},
    extends: Element,
    mixins: [ComponentFormStateMachine],
    data: () => ({
        get_url: 'article/get',
        get_params: {
            id_top_NULL: true,
            version: 'no',
            is_show: true,
            scope: ['title', 'slug', 'slugs', 'thumb', 'date', 'langs', 'lead', 'urls', 'category_name']
        },
        items: [],
        count: 0,

        slidesToShow: 3.5,
    }),
    methods: {

        load() {
            this.stateUpdate("sendInfo")

            let url = this.get_url;

            let params = {...this.get_params};

            if (this.config.type)
                params['type'] = this.config.type;

              params['page_count'] = 4;
            if (this.info.page_count)
                params['page_count'] = this.info.page_count

            if (this.statusOn)
              params['status'] = 'public';

             axios.get(url, {params: params}).then(r => {
               this.items = r.data.data
               this.stateUpdate("sendSuccess")
            });


        },

        carouselSlidesSize() {
            window.innerWidth > 991 ? this.slidesToShow = 3.5 : (window.innerWidth < 991 && window.innerWidth > 760 ? this.slidesToShow = 2 : this.slidesToShow = 1);
        }
    },

    computed: {

      typeContent() {
        let elementConfig = this.config;
        return elementConfig.type;
      },

        itemCls() {

            let info = this.info;

            let type = info.type;

            let ret = {
                'col-12 col-sm-6 col-lg-4 col-xl-3': type === 'type_1',
                'col-12 col-sm-6 col-lg-4': type === 'type_2',
                'col-12 col-sm-6 col-lg-3': type === 'type_3',
                'col-12 col-lg-6': type === 'type_4',
                'mb-3': true,
            }

            // console.log(ret, type)

            return ret;
        },

        statusOn() {

          let appInitial = this.$root.initial;

          let statusValue = appInitial['article' + this.typeContent + '_status'];

          return statusValue && statusValue === 'yes';

        }
    },

    mounted() {
        this.load();
        this.carouselSlidesSize();
    }
}
</script>

<template>
    <div class="row" :class="{'list--type-3': info.type === 'type_3', 'loading': stateLoading()}">
        <div :class="itemCls" v-for="item in items">
            <ArticleBlock1 v-if="info.type === 'type_1'" :obj="item" v-bind="item"/>
            <news2 v-if="info.type === 'type_2'" :obj="item" v-bind="item"/>
            <news3 v-if="info.type === 'type_3'" :obj="item" v-bind="item"/>
<!--            <news4 v-if="info.type === 'type_4'" :obj="item" v-bind="item"/>-->
        </div>
    </div>

    <div class="row" v-if="!stateLoading() && items.length === 0">
        <div class="col-12">
            <br>
            <Alert :text="t('none')" label="" type="info"/>
        </div>
    </div>

    <div class="messages__container" v-if="info.type === 'type_4'">
        <div class="messages__top-part top-part">
            <div class="top-part__arrows-container arrows-container">
                <div class="arrows-container__left" @click="this.$refs.carousel.prev()">
                    <span class="material-symbols-outlined">arrow_back</span>
                </div>
                <div class="arrows-container__right" @click="this.$refs.carousel.next()">
                    <span class="material-symbols-outlined">arrow_forward</span>
                </div>
            </div>
        </div>

        <carousel class="slider-v2-container__carousel carousel" ref="carousel" :items-to-show="1">
            <slide class="carousel__slide" v-for="item in this.items">
                <news4 v-if="info.type === 'type_4'" :obj="item" v-bind="item"/>
            </slide>
        </carousel>

        <!--            <div class="row">-->
        <!--                <div class="col-12" style="text-align: center">-->
        <!--                    <Pagination :page="page" :page_count="page_count" :count="count"-->
        <!--                                @updatePage="updatePage"/>-->
        <!--                </div>-->
        <!--            </div>-->
    </div>
</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(555px, 1fr));
    gap: 30px;

    width: 100%;

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    &__item {
        margin-left: 0;
        width: 100%;

        &:first-child:last-child {
            max-width: 50%;
        }
    }

    &--type-3 {
        grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
    }
}

.content--l, .content--sm {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    }
}

.content--xs {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 0;
    }
}

.messages__container {
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .top-part {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-content: center;
        align-items: center;
        margin-bottom: 3%;

        .arrows-container {
            flex-basis: 7%;
            min-width: 5.8125rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            &__left,
            &__right {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                border: 0.5rem solid var(--block_light_border);
                color: var(--secondary);
                cursor: pointer;
                border-radius: 0.125rem;
            }
        }
    }

    .carousel {

        &__slide {

        }
    }
}

</style>

<style lang="scss">

.carousel {

    &__slide {
        width: 100%!important;
    }
}

</style>