<script>
import RenderStructureBase from "@/views/RenderStructureBase.vue";
import RenderStructure from "@/views/RenderStructure.vue";

export default {
  name: "RenderStructureContent",
  components: {RenderStructure},
  extends: RenderStructureBase,
  computed: {}

}
</script>

<template>
  <template v-if="sectionKidsHas">
    <RenderStructure
        v-for="kid in sectionKids"
        :parent_direction="section.direction"
        :sections="sections"
        :elements="elements"
        :section="kid"
        :obj="obj"/>
  </template>

  <template v-if="!sectionKidsHas">
    <template v-if="parent_direction === 'row'">
      <div class="col-12" :class="elemenetCls">

          <RenderElement
              :item="item"
              :obj="obj"
              v-for="item in this.localElements"
          />

      </div>
    </template>

    <template v-if="parent_direction === 'column'">

        <RenderElement
            :item="item"
            :obj="obj"
            :class="elemenetCls"
            v-for="item in this.localElements"
        />


    </template>

  </template>


</template>

<style lang="scss">

.content {
  display: flex;

  //&--horizontal:not(&:has(.no-width-100-percent)) {
  &--horizontal {
    flex-direction: row !important;

    /**
     * COLUMNS
     * [-][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--top {
      align-items: start !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [-][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--middle {
      align-items: center !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [-][ ][ ]
     */

    &.content--left.content--bottom {
      align-items: end !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * COLUMNS
     * [ ][-][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--top {
      align-items: start !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][-][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--middle {
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][-][ ]
     */

    &.content--center.content--bottom {
      align-items: end !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * COLUMNS
     * [ ][ ][-]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--right.content--top {
      align-items: start !important;
      justify-content: end !important;
      text-align: right;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][-]
     * [ ][ ][ ]
     */

    &.content--right.content--middle {
      align-items: center !important;
      justify-content: end !important;
      text-align: right;
    }

    /**
     * COLUMNS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][-]
     */

    &.content--right.content--bottom {
      align-items: end !important;
      justify-content: end !important;
      text-align: right;
    }
  }

  &--vertical:not(&:has(.no-width-100-percent)) {
    flex-direction: column !important;

    & > .element, & > .content--vertical {
      width: 100%;
    }


    /**
     * ROWS
     * [-][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--top {
      align-items: start !important;
      justify-content: start !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [-][ ][ ]
     * [ ][ ][ ]
     */

    &.content--left.content--middle {
      align-items: start !important;
      justify-content: center !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [-][ ][ ]
     */

    &.content--left.content--bottom {
      align-items: start !important;
      justify-content: end !important;
      text-align: left;
    }

    /**
     * ROWS
     * [ ][-][ ]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--top {
      align-items: center !important;
      justify-content: start !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][-][ ]
     * [ ][ ][ ]
     */

    &.content--center.content--middle {
      align-items: center !important;
      justify-content: center !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][-][ ]
     */

    &.content--center.content--bottom {
      align-items: center !important;
      justify-content: end !important;
      text-align: center;
    }

    /**
     * ROWS
     * [ ][ ][-]
     * [ ][ ][ ]
     * [ ][ ][ ]
     */

    &.content--right.content--top {
      align-items: end !important;
      justify-content: start !important;
      text-align: right;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][-]
     * [ ][ ][ ]
     */

    &.content--right.content--middle {
      align-items: end !important;
      justify-content: center !important;
      text-align: right;
    }

    /**
     * ROWS
     * [ ][ ][ ]
     * [ ][ ][ ]
     * [ ][ ][-]
     */

    &.content--right.content--bottom {
      align-items: end !important;
      justify-content: end !important;
      text-align: right;
    }
  }
}


.full {
  //width: 100vw!important;
  //position: relative;
  //left: 50%;
  //right: 50%;
  //margin-left: -50vw;
  //margin-right: -50vw;

}

</style>