<script>
import Element from "@/components/Element.vue";
import Breadcrumb from "@/views/Base/Router/Breadcrumb.vue";
import {mapActions, mapState} from "vuex";
import Row from "@/Library/Forms/Row.vue";
import Alert from "@/Library/Components/Alert.vue";
import axios from "@/axios";
import base from "@/Library/Extends/Base.vue";

export default {
  name: "Router",
  components: {Alert, Row, Breadcrumb},
  extends: Element,
  data: () => ({
    obj: {},
  }),
  computed: {
    ...mapState({
      app_view: state => state.app.view,
      pass_tab: state => state.app.pass_tab,
    }),

  },
}
</script>

<template>

      <div class="row">
        <div class="col-12">
          <Breadcrumb v-bind="$props"/>
        </div>
      </div>

      <div class="router-section" id="main" tabindex="-1">
        <router-view/>
      </div>


</template>
