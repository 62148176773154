<script>
import Element from "@/components/Element.vue";

export default {
  name: "ArticleEventLink",
  extends: Element,
}
</script>

<template>
  <a :href="obj.event_link_url" >
    {{ obj.event_link_url }}
  </a>
</template>