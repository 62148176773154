<script>
import CalendarAbstract from "@/Library/Components/Calendar/Abstract.vue";
import CalendarDay from "@/Library/Components/Calendar/Day.vue";

export default {
  name: 'calendarWeek',
  emits: ['selectDay'],
  extends: CalendarAbstract,
  components: {
    CalendarDay
  },
  data: () => ({
    firstDayOfRange: new Date()
  }),
  computed: {
    getWeeks() {
      let week = [];
      let firstDay = new Date(this.year, this.month, this.day);
      firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1);
      this.firstDayOfRange = firstDay

      let lastDay = new Date(firstDay);
      lastDay.setDate(lastDay.getDate() + 6);


      if (this.dateMain === this.date) {
        this.$emit('selectRange', {start: firstDay, end: lastDay})
      }
      while (firstDay <= lastDay) {
        let day = {
          date: firstDay.getDate(),
          fullDate: new Date(firstDay),
        }
        week.push(day);
        firstDay.setDate(firstDay.getDate() + 1);
      }
      return week;
    },
  },
  methods: {
    nextWeek() {
      let firstDayOfRange = new Date(this.year, this.month, this.day);
      firstDayOfRange.setDate(firstDayOfRange.getDate() + 7);
      this.dateMain = firstDayOfRange;

      let firstDay = new Date(this.dateMain);
      let lastDay = new Date(this.dateMain);
      firstDay.setDate(firstDay.getDate() - 1);
      lastDay.setDate(lastDay.getDate() + 5);
      this.$emit('selectRange', {start: firstDay, end: lastDay})

    },

    prevWeek() {
      let firstDayOfRange = new Date(this.year, this.month, this.day);
      firstDayOfRange.setDate(firstDayOfRange.getDate() - 7);
      this.dateMain = firstDayOfRange;

      let firstDay = new Date(this.dateMain);
      let lastDay = new Date(this.dateMain);
      firstDay.setDate(firstDay.getDate() - 1);
      lastDay.setDate(lastDay.getDate() + 5);
      this.$emit('selectRange', {start: firstDay, end: lastDay})


    }
  },
}
</script>

<template>
  <div class="week">
    <div class="week__btn week__btn--left" @click="prevWeek"></div>
    <CalendarDay :calendarType="'week'" :firstDayOfRange="this.firstDayOfRange" :day="day" v-for="day in getWeeks"
                 @selectDay="selectDay" :events="eventInDay(day)"/>
    <div class="week__btn week__btn--right" @click="nextWeek"></div>
  </div>
</template>

<style scoped lang="scss">
.week {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &__btn {

    position: relative;
    margin: auto 7px auto 7px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    cursor: pointer;

    &--right {
      border-left: 5px solid #000000;
    }

    &--left {
      border-right: 5px solid #000000;
    }

    &::before {
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-top: -25px;
      margin-left: -15px;

    }
  }
}


</style>