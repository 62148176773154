<script>
import Element from "@/components/Element.vue";
import ShowSvg from "@/components/ShowSvg.vue";
import Map from "@/components/Map/Map.vue";

export default {
    name: "Contact1",
    components: {
        Map,
        ShowSvg
    },
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    subTitle: "Contact",
                    header: "Get in Touch",
                    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat.",
                    items: [
                        {
                            icon: "call",
                            title: "545-450-3059"
                        },
                        {
                            icon: "mail",
                            title: "info@wireland.com"
                        },
                        {
                            icon: "location_on",
                            title: "919 Sophie Road"
                        }
                    ]
                }
            }
        }
    },
    data: () => ({
        markers: [],
        showMap: false,
    }),
    methods: {
        isLink(text) {
            return text && text.length > 0
        },

        updateLocation(lat, lng) {
            this.markers = []

            let marker = {
                id: 0,
                id_category: null,
                name: "Marker",
                lat: lat,
                lng: lng
            }

            this.markers.push(marker)

            this.updateCenter();
        },

        updateCenter() {
            let lat = this.info.latitude;
            let lng = this.info.longitude;

            let lat_transform = 0;
            let lng_transform = 0;
            switch (this.screenWidthStr) {
                case 'xs':
                    lat_transform = .0015
                    break;
                case 'sm':
                    lat_transform = .002
                    break;
                case 'l':
                    lng_transform = -.003
                    break;
                case 'xl':
                    lng_transform = -.003
                    break;
            }

            if (this.$refs.map)
                this.$refs.map.setCenter(lat + lat_transform, lng + lng_transform)
        },

        refreshMap() {
            this.showMap = false;
            setTimeout(()=> this.showMap = true,10)
            setTimeout(()=> this.updateLocation(this.info.latitude, this.info.longitude),10)
        }
    },
    computed: {
        hasMapData(){
            return this.info.latitude && this.info.longitude
        }
    },
    watch: {
        'screenWidthStr': {
            handler(newValue, oldValue) {
                this.refreshMap()
            },
            deep: true
        }
    },
    mounted() {
        // this.updateLocation(this.info.latitude, this.info.longitude)
        this.refreshMap()
    }
}
</script>

<template>

    <div class="contact width-100-percent">
        <div class="contact__info info">
            <h1 class="info__subtitle">{{ to(info, "subTitle") }}</h1>
            <h1 class="info__title">{{ to(info, "header") }}</h1>
            <p class="info__description" v-html="parseText(info, 'description')"></p>

            <ul class="list">
                <li class="list__item" v-for="item in info.items">
                    <span class="material-symbols-rounded">{{ item.icon }}</span>
                    <p>{{ to(item, "title") }}</p>
                </li>
            </ul>

            <ul class="socials">
                <li class="socials__item" v-if="info.facebook_has && isLink(info.facebook)">
                    <a :href="info.facebook" title="Facebook link" target="_blank">
                        <ShowSvg icon="socials-facebook"/>
                    </a>
                </li>

                <li class="socials__item" v-if="info.linkedin_has && isLink(info.linkedin)">
                    <a :href="info.linkedin" title="Linkedin link" target="_blank">
                        <ShowSvg icon="socials-linkedin"/>
                    </a>
                </li>

                <li class="socials__item" v-if="info.tiktok_has && isLink(info.tiktok)">
                    <a :href="info.tiktok" title="Tiktok link" target="_blank">
                        <ShowSvg icon="socials-tiktok"/>
                    </a>
                </li>

                <li class="socials__item" v-if="info.twitter_has && isLink(info.twitter)">
                    <a :href="info.twitter" title="X link" target="_blank">
                        <ShowSvg icon="socials-x"/>
                    </a>
                </li>

                <li class="socials__item" v-if="info.instagram_has && isLink(info.instagram)">
                    <a :href="info.instagram" title="Instagram link" target="_blank">
                        <ShowSvg icon="socials-instagram"/>
                    </a>
                </li>
            </ul>
        </div>

        <div class="contact__map">
            <Map
                ref="map"
                v-if="showMap && hasMapData"
                :id="String(Math.random())"
                :center="{
                            lat: this.info.latitude ? this.info.latitude : 50.259807,
                            lng: this.info.longitude ? this.info.longitude:  19.013125
                           }"
                :markers="markers"/>
        </div>
    </div>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: unset;
}

.contact {
    position: relative;
    height: 100%;
    padding: 80px 0;

    display: flex;
    justify-content: center;

    &__info {
        max-width: 457px;
        width: 100%;
        position: relative;
        z-index: 2;
        margin-right: 457px;
    }

    &__map {
        width: 100%;
        min-height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @media screen and (max-width: 1440px) {

        &__info {

        }

        &__map {

        }
    }

    @media screen and (max-width: 992px) {
        padding: 80px 0 359px 0;

        &__info {
            margin-right: 0;
        }

        &__map {

        }
    }

    @media screen and (max-width: 767px) {
        padding: 0 0 191px 0;
        margin-top: 192px;

        display: flex;
        align-items: center;
        justify-content: center;

        &__info {
            margin: 0;
            min-width: unset;
            width: calc(100% - (16px * 2));
            top: -192px;
        }

        &__map {

        }
    }
}

.info {
    padding: 55px 40px;
    border-radius: 10px;
    border: 1px solid var(--border);
    background: var(--bg);

    @media screen and (max-width: 767px) {
        padding: 63px 15px;

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__subtitle {
            font-size: 1.22rem;
        }

        &__title {
            font-size: 2.11rem;
            line-height: normal;
            margin-bottom: 31px;
        }

        &__description {
            margin-bottom: 48px;
        }
    }

    &__subtitle, &__title, &__description {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
    }

    &__subtitle, &__title {
        font-family: var(--title_font);
        font-weight: 700;
        color: var(--title);
    }

    &__subtitle {
        font-size: 1.56rem;
        margin-bottom: 16px;
    }

    &__title {
        font-size: 2.78rem;
        line-height: 60px;
        margin-bottom: 16px;
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 51px;
        color: var(--text);
    }
}

.list, .socials {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 38px;

    @media screen and (max-width: 767px) {
        align-items: center;
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 16px;
        margin-left: 0;

        & > .material-symbols-rounded {
            color: var(--icon);
        }

        & > p {
            font-family: var(--text_font);
            font-size: 0.89rem;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: var(--text);
            margin-top: 0;
        }
    }
}

.socials {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &__item {

        & > a {
            display: block;
        }
    }

    @media screen and (max-width: 1440px) {
        justify-content: center;
        max-width: fit-content;
    }

    @media screen and (max-width: 767px) {
        justify-content: center;
        max-width: fit-content;
        margin: 0 auto;
    }
}

.content--l {

    & .contact {

        &__info {

        }

        &__map {

        }
    }

    & .socials {
        justify-content: center;
        max-width: fit-content;
    }
}

.content--sm {

    & .contact {
        padding: 80px 0 359px 0;

        &__info {
            margin-right: 0
        }

        &__map {

        }
    }

    & .socials {
        justify-content: center;
        max-width: fit-content;
    }
}

.content--xs {

    & .contact {
        padding: 0 0 191px 0;
        margin-top: 192px;

        display: flex;
        align-items: center;
        justify-content: center;

        &__info {
            margin: 0;
            min-width: unset;
            width: calc(100% - (16px * 2));
            top: -192px;
        }

        &__map {

        }
    }

    & .info {
        padding: 63px 15px;

        &__subtitle, &__title, &__description {
            text-align: center;
        }

        &__subtitle {
            font-size: 1.22rem;
        }

        &__title {
            font-size: 2.11rem;
            line-height: normal;
            margin-bottom: 31px;
        }

        &__description {
            margin-bottom: 48px;
        }
    }

    & .list {
        align-items: center;
    }

    & .socials {
        justify-content: center;
        max-width: fit-content;
        margin: 0 auto;
    }
}

</style>