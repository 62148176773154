<template>

  <div class="mb-0" :class="'alert alert--'+typeBts" role="alert">
    <div class="alert-body"><strong v-if="show_label">{{ typeLabel }}:</strong> <span v-html="text"></span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      default() {
        return 'info';
      }
    },
    text: {
      default() {
        return '';
      }
    },
    label: {
      default() {
        return null;
      }
    },
    show_label: {
      default() {
        return false;
      }
    }
  },
  computed: {
    typeBts() {

      let ret = this.type;

      // if (this.type === 'error')
      //   ret = 'danger'

      return ret;

    },
    typeLabel() {
      let ret = null;
      if (this.type === 'info')
        ret = 'Info';
      if (this.type === 'success')
        ret = 'Udało się';
      if (this.type === 'warning')
        ret = 'Ostrzeżenie';
      if (this.type === 'error')
        ret = 'Wystąpił błąd';

      return ret;

    }
  }
}
</script>

<style scoped lang="scss">

.alert {
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #eaeaea;

  &--info {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;

  }

  &--error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }


}

</style>