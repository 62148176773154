<script>
export default {
  name: "UserDescription",
  props: {
    obj: {
      default() {
        return {}
      }
    }
  }
}
</script>

<template>
<p>Description</p>
</template>

<style scoped lang="scss">

</style>