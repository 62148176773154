<template>
  <div class="label">{{ to(info, 'text') }}</div>
</template>

<script>
import LabelAbstract from "@/views/Base/Label/Abstract.vue";

export default {
  name: "LabelSoft",
  extends: LabelAbstract,
  props: {},
  computed: {
    text() {
      return this.info.text
    }
  },
  data: () => ({
    // info: {
    //   name: "Nabór trwa",
    //   bgColor: 'var(--main_color)'
    // },
  }),

}
</script>

<style scoped lang="scss">

.label {
  background-color: #F2F5FA;
  color: var(--main);

  font-weight: 500;

  border-radius: 6px;
  padding: 6px 8px;

  text-transform: uppercase;

  display: inline;
}

</style>