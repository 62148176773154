import {createRouter, createWebHistory} from 'vue-router'
import Blank from '@/pages/Blank.vue'
import HomeView from '@/pages/Home.vue'

const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: HomeView
    // },
    {
        path: '/blank',
        name: 'blank',
        component: Blank
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
        }
    },
    {
        path: '/article',
        name: 'ArticleList',
        component: function () {
            return import('../pages/ArticleList.vue')
        }
    },
    {
        path: '/article/show',
        name: 'ArticleShow',
        component: function () {
            return import('../pages/ArticleShow.vue')
        },
    },
    {
        path: '/raport',
        name: 'RaportList',
        component: function () {
            return import('../pages/RaportList.vue')
        }
    },
    {
        path: '/user',
        name: 'UserList',
        component: function () {
            return import('../pages/UserList.vue')
        }
    },
    {
      path: '/user/show',
      name: 'UserShow',
      component: function () {
        return import('../pages/UserShow.vue')
      }
    },
    {
        path: '/galerie',
        name: 'GalleryList',
        component: function () {
            return import('../pages/GalleryList.vue')
        }
    },
    {
        path: '/galerie/pokaz',
        name: 'GalleryShow',
        component: function () {
            return import('../pages/GalleryShow.vue')
        }
    },
    {
        path: '/error',
        name: 'Error',
        component: function () {
            return import('../pages/Error.vue')
        }
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: function () {
            return import('../pages/FAQ.vue')
        }
    },
    {
        path: '/text',
        name: 'Text',
        component: function () {
            return import('../pages/Text.vue')
        }
    },
    {
        path: "/:catchAll(.*)",
        component: function () {
            return import('../views/RenderView.vue')
        }
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
    routes
})

router.afterEach((to, from) => {
        const box = document.body
        box.tabIndex = -1
        box.focus();

})


export default router
