<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "TeamMember2",
    extends: Element,
    props: {
        obj: {
            default() {
                return {}
            }
        }
    },
    computed: {
        image_url() {
            return this.obj.image_url;
        },
        stand() {
            return this.obj.stand;
        },
        name() {
            return this.obj.name;
        },
        description() {
            return this.obj.description;
        },

        prefix() {
            return this.$root.routerPrefix;
        }
    },
})
</script>

<template>

    <div class="item">
        <div class="item__image" :style="{backgroundImage: `url(${image_url})`}"></div>

        <div class="item__content content">
            <h1 class="content__title">{{ to(obj, "name") }}</h1>
            <p class="content__subtitle">{{ to(obj, "stand") }}</p>
            <p class="content__description">{{ to(obj, "description") }}</p>

            <router-link :to="prefix+'/'+obj.slug" class="content__button button">
                <span class="button__text">Szczegóły</span>
                <span class="button__icon material-symbols-rounded">arrow_forward</span>
            </router-link>
        </div>
    </div>

</template>

<style scoped lang="scss">

.item {
    display: flex;
    column-gap: 40px;
    padding: 21px 16px 32px 0;

    &__image {
        min-width: 179px;
        width: 179px;
        height: 179px;
        border-radius: 50%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__content {

    }

    @media screen and (max-width: 1440px) {
        flex-direction: column;
        row-gap: 29px;
        align-items: center;
        padding: 56px 24px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        row-gap: 29px;
        align-items: center;
        padding: 56px 24px;
    }
}

.content {

    &__title, &__subtitle, &__description {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    &__subtitle, &__description {
        font-family: var(--text_font);
    }

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--link);
    }

    &__subtitle {
        font-size: 0.78rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: var(--text);

    }

    &__description {
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
        color: var(--text);
    }

    @media screen and (max-width: 1440px) {

        &__title, &__subtitle, &__description {
            text-align: center;
        }

        &__subtitle {
            margin-bottom: 24px;
        }

        &__description {
            margin-bottom: 32px;
        }

        &__button {
            margin: 0 auto!important;
        }
    }

    @media screen and (max-width: 767px) {

        &__title, &__subtitle, &__description {
            text-align: center;
        }

        &__subtitle {
            margin-bottom: 24px;
        }

        &__description {
            margin-bottom: 32px;
        }

        &__button {
            margin: 0 auto!important;
        }
    }
}

.button {
    margin: 0;
    border-radius: 4px;
    border: 1px solid var(--block_dark_bg);
    background: var(--block_dark_bg);

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 10px 32px 11px 31px!important;
    max-width: fit-content;
    cursor: pointer;
    text-decoration: none;

    &__icon, &__text {
        color: var(--block_dark_text);
    }

    &__icon {
        margin: -4px 0;
    }

    &__text {
        font-family: var(--text_font);
        font-style: normal;
        line-height: normal;
        font-size: 0.78rem;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.content--l, .content--sm {

    & .item {
        flex-direction: column;
        row-gap: 29px;
        align-items: center;
        padding: 56px 24px;
    }

    & .content {

        &__title, &__subtitle, &__description {
            text-align: center;
        }

        &__subtitle {
            margin-bottom: 24px;
        }

        &__description {
            margin-bottom: 32px;
        }

        &__button {
            margin: 0 auto!important;
        }
    }
}

.content--xs {

    & .item {
        flex-direction: column;
        row-gap: 29px;
        align-items: center;
        padding: 56px 24px;
    }

    & .content {

        &__title, &__subtitle, &__description {
            text-align: center;
        }

        &__subtitle {
            margin-bottom: 24px;
        }

        &__description {
            margin-bottom: 32px;
        }

        &__button {
            margin: 0 auto!important;
        }
    }
}

</style>