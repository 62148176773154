<script>
import Element from "@/components/Element.vue";

export default {
    name: "PageModelBlockListWithLinks",
    extends: Element,
    computed() {
    },
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            question: "Is Wireland for Web the best wireframe template for my project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does Wireland for Web have more than 300 components?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Can I Create e-commerce using this wireframe kit?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Will this wireframe library save me more than 30 hour of work?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does this template includes video documentation?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "How will Wireland for Web help me become more efficient?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Is Wireland for Web the best wireframe template for my project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Is Wireland for Web the best wireframe template for my project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does Wireland for Web have more than 300 components?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Can I Create e-commerce using this wireframe kit?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Will this wireframe library save me more than 30 hour of work?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Does this template includes video documentation?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "How will Wireland for Web help me become more efficient?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        },
                        {
                            question: "Is Wireland for Web the best wireframe template for my project?",
                            option: "Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed. Awesome template, it has all the layouts and components I need for every web design project, lorem ipsum dolor sit. Lorem ipsum dolor sit amet, consectetur adipisicing elitsed."
                        }
                    ]
                }
            }
        }
    },
    methods: {
        expand(event, item) {
            item.expanded = !item.expanded

            let item_html = event.target

            if (!item_html.classList.contains("item"))
                item_html = item_html.closest(".item")

            let item_body = item_html.querySelector(".item__body")
            let body_description = item_body.querySelector(".body__description")

            if (item.expanded) {
                item_body.style.height = body_description.offsetHeight + "px"
            }

            if (!item.expanded) {
                item_body.style.height = 0
            }
        }
    }
}
</script>

<template>

    <div class="wrapper">
        <ul class="list" v-for="i in 2">
            <template v-for="item in info.items.filter((el, index) => (i === 1) ? (index % 2 === 0) : (index % 2 !== 0))">
                <li
                    v-if="to(item, 'question')"
                    class="list__item item"
                    :class="{'item--expanded': item.expanded}"
                    @click="expand($event, item)">
                    <div class="item__head head">
                        <h1 class="head__title">{{ to(item, "question") }}</h1>
                        <div class="head__arrow">
                            <span class="material-symbols-rounded">expand_more</span>
                        </div>
                    </div>
                    <div class="item__body body">
                        <p class="body__description" v-html="parseText(item, 'option')"></p>
                    </div>
                </li>
            </template>
        </ul>
    </div>

</template>

<style scoped lang="scss">

.wrapper {
    display: flex;
    column-gap: 30px;

    @media screen and (max-width: 1440px) {
        flex-direction: column;
    }

    @media screen and (max-width: 767px) {

    }
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    &__item {
        margin-left: 0;
    }

    @media screen and (max-width: 1440px) {

        &:not(&:last-child) {

            & .item:last-child {
                border-bottom: none;
            }
        }
    }
}

.item {
    border-top: 1px solid var(--border);
    cursor: pointer;

    &:last-child {
        border-bottom: 1px solid var(--border);
    }

    &__head {

    }

    &__body {

    }

    &--expanded {

        & .head__arrow {

            & > .material-symbols-rounded {
                transform: rotate(180deg);
            }
        }
    }
}

.head__title, .body__description {
    font-style: normal;
    line-height: normal;
    margin-top: 0;
    text-align: left;
}

.head {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    padding: 24px;

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        color: var(--title);
        margin-bottom: 0;
    }

    &__arrow {
        display: flex;
        height: fit-content;

        & > .material-symbols-rounded {
            color: var(--icon);
        }
    }
}

.body {
    height: 0;
    overflow: hidden;

    transition: all 0.25s linear;
    background: var(--block_light_bg);

    &__description {
        font-family: var(--text_font);
        padding: 24px;
        border-top: 1px solid var(--border);
        color: var(--text);

        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;

        @media screen and (max-width: 767px) {
            border-top: none;
        }
    }
}

.content--l, .content--sm {

    & .wrapper {
        flex-direction: column;
    }

    & .list {

        &:not(&:last-child) {

            & .item:last-child {
                border-bottom: none;
            }
        }
    }
}

.content--xs {

    & .wrapper {
        flex-direction: column;
    }

    & .list {

        & .body__description {
            border-top: none;
        }

        &:not(&:last-child) {

            & .item:last-child {
                border-bottom: none;
            }
        }
    }
}

</style>