<template>

    <div class="messages__container">
        <div class="messages__top-part top-part">
            <div class="top-part__arrows-container arrows-container">
                <div class="arrows-container__left" @click="this.$refs.carousel.prev()">
                    <span class="material-symbols-outlined">keyboard_arrow_left</span>
                </div>

                <div class="arrows-container__right" @click="this.$refs.carousel.next()">
                    <span class="material-symbols-outlined">keyboard_arrow_right</span>
                </div>
            </div>
        </div>

        <carousel class="slider-v2-container__carousel carousel" ref="carousel" :items-to-show="1">
            <slide class="carousel__slide" v-for="item in this.info.benefits">
                <div class="item" @click="linkClick(item.link)">
                    <div class="item__badges badges">
                        <p class="badges__category" v-if="item.label">{{ to(item, "label") }}</p>
                        <ShowDate class="badges__date" :date="item.date" :show_year_always="true"/>
                    </div>
                    <h1 class="item__title three-dots three-dots--3">{{ to(item, 'title') }}</h1>
                    <p class="item__description three-dots three-dots--4" v-html="to(item, 'description')"></p>
                    <div class="item__link" v-if="item.link" :data-link="item.link" @click="linkClick(item.link)">
                        <p>{{ to(item, 'link_name') }}</p>
                        <span class="material-symbols-rounded">arrow_forward</span>
                    </div>
                </div>
            </slide>
        </carousel>
    </div>

<!--    <ul class="list">-->
<!--        <li class="list__item" v-for="item in info.benefits">-->
<!--            <div class="item">-->
<!--                <div class="item__badges badges">-->
<!--                    <p class="badges__category" v-if="item.label">{{ to(item, "label") }}</p>-->
<!--                    <ShowDate class="badges__date" :date="item.date" :show_year_always="true"/>-->
<!--                </div>-->
<!--                <h1 class="item__title three-dots three-dots&#45;&#45;3">{{ to(item, 'title') }}</h1>-->
<!--                <p class="item__description three-dots three-dots&#45;&#45;4" v-html="to(item, 'description')"></p>-->
<!--                <div class="item__link" v-if="item.link" :data-link="item.link" @click="linkClick(item.link)">-->
<!--                    <p>{{ t("read_more") }}</p>-->
<!--                    <span class="material-symbols-rounded">arrow_forward</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </li>-->
<!--    </ul>-->

</template>

<script>
import ShowDate from "@/Library/Show/Date.vue";
import Element from "@/components/Element.vue";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";


export default {
    name: "Alert2",
    components: {Carousel, Slide, Pagination, Navigation,ShowDate},
    extends: Element,
    computed() {

    },
    props: {
        info: {
            default() {
                return {}
            }
        }
    },
}
</script>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__item {

    }
}

a {
    text-decoration: none;
}

.item {
    border-radius: 9px;
    border: 1px solid var(--border);
    height: 100%;
    width: 100%;

    margin: 0!important;

    display: flex;
    flex-direction: column;
    padding: 34px 99px 47px 29px;

    cursor: pointer;

    & .badges__category, & .badges__date, &__title, &__description, &__link > p {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    & .badges__category, & .badges__date, &__description, &__link > p {
        font-family: var(--text_font)
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.56rem;
        font-weight: 700;
        margin-bottom: 24px;
        color: var(--link);
        text-align: start;
    }

    &__description {
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 33px;
        color: var(--text);
        text-align: start;
    }

    &__link {
        margin-top: auto;

        display: flex;
        align-items: center;
        column-gap: 12px;

        & > p, & > .material-symbols-rounded {
            color: var(--text);
        }

        & > p {
            font-size: 0.78rem;
            font-weight: 500;
            text-decoration-line: underline;
            margin-bottom: 0;
        }

        & > .material-symbols-rounded {

        }
    }

    @media screen and (max-width: 767px) {
        padding-right: 29px;
    }
}

.badges {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 16px;

    &__category {
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.7px;
        padding: 6px 9px;
        border-radius: 2px;
        color: var(--block_dark_text);
        background: var(--block_dark_bg);
        width: fit-content;
        margin-bottom: 0;
    }

    &__date {
        font-size: 0.67rem;
        font-weight: 400;
        line-height: 20px;
        color: var(--text);
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .item {
        padding-right: 29px;
    }
}

.messages__container {
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .top-part {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-content: center;
        align-items: center;
        margin-bottom: 3%;

        .arrows-container {
            flex-basis: 7%;
            min-width: 5.8125rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            &__left,
            &__right {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                border: 0.5rem solid var(--block_light_border);
                color: var(--secondary);
                cursor: pointer;
                border-radius: 0.125rem;
            }
        }
    }

    .carousel {


        &__slide {

        }
    }
}


</style>

<style lang="scss">

.carousel {

    &__viewport {
        width: 100%!important;
    }

    &__slide {
        width: 100%!important;
    }
}

</style>