<script>
import CalendarMonth from "@/Library/Components/Calendar/Month.vue";
import CalendarWeek from "@/Library/Components/Calendar//Week.vue";
export default {
  name: 'calendar',
  components: {
    CalendarMonth,
    CalendarWeek
  },
  emits: ['selectDay', 'selectRange'],
  props: {
    calendarType: {
      required: true,
      type: String,
    },
    date: {
      default: new Date(),
      type: Date,
    },
    events: {
      default: [],
      type: Array,
    }
  },
  methods: {
    selectDay(day) {
      this.$emit('selectDay', day)
    },
    selectRange(range) {
      this.$emit('selectRange', range)
    },
  }
}
</script>

<template>
  <CalendarMonth v-if="calendarType==='month'" :date="this.date" :events="this.events" @selectDay="selectDay"
                 @selectRange="selectRange" calendar-type="month"/>
  <CalendarWeek v-if="calendarType==='week'" :date="this.date" :events="this.events" @selectDay="selectDay"
                @selectRange="selectRange" calendar-type="week"/>
</template>

<style scoped lang="scss">

</style>