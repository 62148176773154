<template>

    <router-link class="article-block" :to="slugs[lang] ?? './'">

        <div class="article-block__bg" :style="{backgroundImage: 'url('+thumb+')'}"></div>
        <div class="article-block__info article-block-info">
      <span class="article-block-info__date">
        <ShowDate :date="date"/>
      </span>
            <div class="article-block-info__name">{{ to(obj, 'title') }}</div>
            <div class="article-block-info__desc">{{ to(obj, 'text') }}</div>
        </div>
    </router-link>

</template>

<script>

import ArticleAbstract from '@/views/Article/Abstract.vue';
import ShowDate from "@/Library/Show/Date.vue";
import {mapState} from "vuex";

export default {
    name: "ArticleBlock1",
    components: {ShowDate},
    extends: ArticleAbstract,
    props: {
        date: {
            default() {
                return '01.01.2023'
            }
        },
        slug: {
            default() {
                return '/article/show'
            }
        },
        slugs: {
            default() {
                return []
            }
        },
        title: {
            default() {
                return 'Kolejny maluch w stajni ośrodka akademickiego. Na świat przyszedł kasztanowaty ogierek'
            }
        },
        text: {
            default() {
                return 'Na wiele rzeczy nie mamy bezpośrednio wpływu, jak na aurę za oknem. Mżący śnieg z deszczem jest wyzwaniem'
            }
        },
        thumb: {
            default() {
                return 'https://picsum.photos/200/300?random=2'
            }
        },
    },
    computed: {
        ...mapState('app', {appConfig: "config"}),
        lang() {
            return localStorage.getItem('lang')
        }
    }
}
</script>

<style scoped lang="scss">

.article-block {

    position: relative;
    text-align: left;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;

    overflow: hidden;

    display: block;
    color: inherit;

    &__bg,
    &__info {
        position: absolute;
    }

    &__bg {
        //border-radius: 12px;
        top: 0%;
        height: 100%;
        left: 0;
        right: 0;
        background-color: var(--block_light_bg);
        margin-bottom: 100px;

        background-image: url('https://picsum.photos/200/300?random=2');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__info {
        background-color: var(--block_light_bg);
        width: 90%;
        bottom: 0;
        left: 0;

        transition: all 0.25s ease 0s;

        padding: 18px 12px 12px;

        border-radius: 0 12px 0 0;

        //transition: all .3s;
        min-height: 143px;
        height: fit-content;
        //overflow: hidden;

        &:after {

            position: absolute;
            width: 0;
            height: 20px;

            content: '';

            bottom: 0;

            top: -35px;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid var(--block_light_bg);;
            clear: both;


        }

    }

    &:hover &__info {
        width: 100%;
        height: 100%;
        border-radius: 0;

        background-color: var(--block_dark_bg);
        color: var(--block_dark_text);
    }

    &:hover &__info:after {
        display: none;
    }

}

.article-block-info {
    display: flex;
    flex-direction: column;

    &__date {
        margin-bottom: 12px;
    }

    &__name {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: var(--main);
        //height: 62px;

        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
        //overflow: hidden;
        //text-overflow: ellipsis;
    }

    &__desc {
        display: none;
    }

    &:hover &__name {
        height: auto;
        -webkit-line-clamp: 12;
    }

}


</style>