<template>

</template>

<script>
import Element from "@/components/Element.vue";

export default {
    name: "LabelAbstract",
    extends: Element,
    computed: {
        bgColor() {
            return this.info.bgColor ?? 'var(--main_color)';
        },
    },
}
</script>

<style scoped>

</style>