<script>
import CalendarAbstract from "@/Library/Components/Calendar/Abstract.vue";
export default {
  name: 'calendarDay',
  emits: ['selectedDay'],
  extends: CalendarAbstract,
  props: {
    day: {
      required: true
    },
    firstDayOfRange: {
      required: true
    },
  },
  computed: {
    isToday() {
      return (this.day.fullDate.toDateString() === new Date().toDateString())
    },
    inThisMonth() {
      return this.day.fullDate.getMonth() === this.firstDayOfRange.getMonth() || this.calendarType === 'week'
    },
    isSatOrSan() {
      return this.day.fullDate.getDay() === 0 || this.day.fullDate.getDay() === 6
    },
    DayDetailsClass() {
      return {
        'day--isToday': this.isToday,
        'day--inThisMonth': this.inThisMonth,
        'day--notInThisMonth': !this.inThisMonth,
        'day--isSatOrSan': this.isSatOrSan
      }
    }
  },
}
</script>

<template>
  <div class="day" @click="selectDay(day)"
       :class="DayDetailsClass, this.calendarType === 'week' ? 'day--week' : 'day--month'">
    {{ day.date }}
    <div v-if="calendarType==='week'">{{ dayName(day.fullDate.getDay()) }}</div>
    <div v-if="events.length>0" class="listDayEvent">
      <div v-if="events.length<=3" class="listDayEvent--maxThreeEvents" v-for="event in events"
           :style="{color: event.color}">·
      </div>
      <div v-if="events.length>3" class="listDayEvent--moreThenThreeEvents">···</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.day {
  font-family: Poppins;
  cursor: pointer;
  color: var(--heading, #3F434A);
  border-radius: 10px 10px;
  justify-content: center;
  align-items: center;
  position: relative;

  &--inThisMonth {
    color: var(--body-light, #8A9099);
  }

  &--notInThisMonth {
    opacity: .3;
  }

  &--isSatOrSan {
    color: var(--body-light, #f68282);
  }

  &--isToday {
    background: var(--body-light, #d9e4ff);
  }

  &:hover {
    color: #000000;
    background: #aab2fd;
  }

  &--month {
    font-size: 15px;
    position: relative;
    height: 50px;
    display: flex;

    .listDayEvent {
      display: flex;
      position: absolute;
      margin-top: 35px;
    }
  }

  &--week {
    text-align: center;
    width: 57px;
    height: 60px;
    display: flex;
    flex-direction: column;

    .listDayEvent {
      display: flex;
      position: absolute;
      margin-top: 50px;

    }
  }
}

.listDayEvent {
  font-size: 20px;
  filter: opacity(60%);

  &:hover {
    filter: opacity(100%);
  }

  &--maxThreeEvents {
    margin-left: 2px;
    margin-right: 2px;
  }
}

</style>