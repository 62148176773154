<script>
import Element from "@/components/Element.vue";

export default {
  name: "TeamMemberSlider",
  extends: Element,
  props: {
    obj: {
      default() {
        return {}
      }
    }
  },
  computed: {
    image_url() {
      return this.obj.image_url;
    },
    name() {
      return this.obj.name;
    },
    description() {
      return this.obj.description;
    },
    prefix() {
      return this.$root.routerPrefix;
    },
  }
}
</script>

<template>

    <router-link :to="prefix+'/'+obj.slug" class="item">
        <div class="item__image" :style="{backgroundImage: `url(${image_url})`}"></div>

        <h1 class="item__title">{{ to(obj, "name") }}</h1>
        <p class="item__description three-dots three-dots--3">{{ to(obj, "description") }}</p>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.item {
    width: 360px;
    height: 100%;
    padding: 24px 24px 40px 24px;
    border-radius: 10px;
    border: 1px solid var(--border);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 767px) {

    }

    &__image {
        height: 214px;
        margin-bottom: 24px;
        border-radius: 10px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        color: var(--link);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--text);

    }
}

.content--l, .content--sm {

}

.content--xs {

}

</style>