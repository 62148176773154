<template>
  <span class="show-option">{{ value }}</span>
</template>

<script>
import InputOptionsAbstract from "@/Library/Forms/InputOptionsAbstract";

export default {
  name: "ShowOption",
  mixins: [InputOptionsAbstract],
  props: {
    data: {
      default() {
        return null;
      }
    },
    options: {
      default() {
        return {};
      }
    }
  },

  computed: {


    value() {

      let ret = this.options.find(element => element.value === this.data);

      return ret ? ret.name : null;

    }

  }
}
</script>

<style scoped lang="scss">

.show-color {


}

</style>