<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "Image2",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          header: "Lorem ipsum dolor sit amet, consectetur adipisicing",
          description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
          imageLink: "https://picsum.photos/200/300",
          link: "",
          linkName: "Learn more"
        }
      }
    },
  }
})
</script>

<template>
  <div class="slider__container">
    <div class="slider-container__content content">
      <h2 class="content__header">{{to(this.info, 'header')}}</h2>
      <p class="content__description" v-html="parseText(info, 'description')"></p>
      <div class="content__link link">
        <a class="link__name" :href="info.link">{{to(this.info, 'linkName')}}</a>
        <span class="material-symbols-outlined link__icon">arrow_right_alt</span>
      </div>
    </div>

    <img class="slider-container__image image" :src="info.imageLink.url" alt="Main slider image" />
  </div>
</template>

<style scoped lang="scss">
.slider__container{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-self: stretch;
  align-content: stretch;

  .content{
    min-height: 100%;
    flex-basis: 48%;
    padding: 1.5rem 3rem;

    &__header{
      font-size: 1.56rem;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -0.10080009698867798px;
      text-align: left;
      color: var(--title);
    }

    &__description{
      font-size: 1.33rem;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0;
      text-align: left;
      color: var(--text);
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .link{
      width: 120px;
      color: var(--link);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-around;

      &__name{
        font-size: 0.78rem;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: -0.05040004849433899px;
        text-align: center;
        color: var(--link);
      }

      &__icon{
        color: var(--icon);
      }
    }
  }

  .image{
    object-fit: fill;
    flex-basis: 50%;
    height: 440.7px;
  }
}

@media only screen and (max-width: 1199px) {
  .content{
    &__header{
      margin: 0;
    }

    &__description{
      display: -webkit-box;
      -webkit-line-clamp: 3!important;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 3% 0;
    }
  }

  .image{
    object-fit: fill;
    flex-basis: 50%;
    height: 200px!important;
  }
}

@media only screen and (max-width: 991px) {
  .slider__container {
    flex-direction: column;

    .content {
      &__header {
        margin: 0;
        text-align: center;
      }

      &__description {
        display: -webkit-box;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 3% 0;
      }
    }
  }

  .image{
    width: 100%;
    max-height: 350px;
    object-fit: cover;
  }
}
</style>