<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "BlogPosts",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          image: "https://picsum.photos/200/300",
          firstCategory: "Category 1",
          secondCategory: "Category 2",
          title: "Article Name",
          description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut",
          link: "",
          linkName: "Read Post"
        }
      }
    },
  }
})
</script>

<template>
  <!--<div class="blog-posts__main-container">-->
  <div class="blog-posts__container">
    <div class="blog-posts-container__item item">
      <img class="item__image" v-if="info.image" :src="info.image.url" alt="Main blog post image" />

      <div class="item__content content">
        <div class="content__category">
          <p class="content-category__info">{{to(this.info, 'firstCategory')}}</p>
          <p class="content-category__info">{{to(this.info, 'secondCategory')}}</p>
        </div>

        <div class="content__text text">
          <h2 class="text__title">{{to(this.info, 'title')}}</h2>
          <p class="text__description">{{to(this.info, 'description')}}</p>

          <div class="text__link link">
            <a class="link__href" :href="info.link">{{to(this.info, 'linkName')}}</a>
            <span class="material-symbols-outlined link__icon">arrow_right_alt</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="blog-posts__see-all-posts see-all-posts">-->
  <!--    <p class="see-all-posts__text">See all posts</p>-->
  <!--  </div>-->
  <!--</div>-->
</template>

<style scoped lang="scss">
//.blog-posts__main-container {
//  width: 1140px;
//  max-width: 100%;

.blog-posts__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

  .item {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    border: 1px solid var(--block_light_border);

    &__image {
      max-height: 247px;
      flex-basis: 48%;
      object-fit: fill;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    &__content {
      flex-basis: 50%;

      .content {
        &__category {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          color: var(--text);

          .content-category__info {
            font-size: 0.67rem;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 7%;

        &__title {
          font-size: 1.22rem;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: -0.07920006662607193px;
          text-align: left;
          margin: 0 0 2% 0;
          color: var(--title);
        }

        &__description {
          font-size: 0.89rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          margin: 0;
          color: var(--text);
        }

        .link {
          width: 110px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-content: center;
          align-items: center;
          padding: 5% 0;

          a {
            color: var(--link);
            font-size: 0.78rem;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: -0.05040004849433899px;
            text-align: center;
          }

          &__icon {
            color: var(--icon);
          }
        }
      }
    }
  }
}

//.see-all-posts{
//  width: 173px;
//  margin: 4% auto 1% auto;
//  border-radius: 10px;
//  border: 1px solid var(--block_light_border);
//
//  &__text{
//    font-size: 0.78rem;
//    font-weight: 700;
//    line-height: 17px;
//    letter-spacing: 1px;
//    text-align: center;
//    text-transform: uppercase;
//    color: var(--text);
//  }
//}
//}
</style>