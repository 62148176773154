<script>
import axios from "@/axios";
import SiteMenuStyleDesktop from "@/views/Base/Menu/SiteMenuStyleDesktop.vue";
import SiteMenuStyleMobileWrapper from "@/views/Base/Menu/SiteMenuStyleMobileWrapper.vue";
import Element from "@/components/Element.vue";

export default {
    name: "MenuInline",
    components: {
        SiteMenuStyleMobileWrapper,
        SiteMenuStyleDesktop
    },
    props: {
        info: {
            default() {
                return {}
            }
        },
        preview: {
            default() {
                return {}
            }
        },
        simulate: {
            default() {
                return {}
            }
        }
    },
    extends: Element,
    emits: ["updateSimulate"],
    data: () => ({
        get_url: "sitemenu/positionget",
        get_params: {
            scope: "id,id_top_position,name,url,langs,number,slug",
            sort: 'number ASC'
        },
        positions: [],
        id_site_menu: null,

        mobile_menu_show: false
    }),
    methods: {
        async loadPositions() {
            if (!this.info.id_site_menu) {
                this.positions = []
                return null
            }

            let params = {
                ...this.get_params
            }

            params["id_menu"] = this.info.id_site_menu

            this.positions = await axios.get(this.get_url, {params: params}).then(r => r.data.data)

            this.id_site_menu = this.info.id_site_menu

            console.log("positions", this.positions)
        },

        updateSimulate(slug, url){
            this.$emit("updateSimulate", slug, url)
        },

        async refresh() {
            await this.loadPositions()
        },

        menuMobileWrapperHandler(func) {

            let scrollbarWidth = window.innerWidth - document.querySelector("body").offsetWidth + "px"

            if (func === "open") {
                // document.querySelector(".header").style.transition = "none"
                // document.querySelector(".header").style.paddingRight = scrollbarWidth
                // document.querySelector("body").style.paddingRight = scrollbarWidth
                // document.body.style.overflow = "hidden"
                this.mobile_menu_show = true
            }
            if (func === "close") {
                // document.querySelector(".header").style.paddingRight = "0px"
                // document.querySelector("body").style.paddingRight = "0px"
                setTimeout(() => {
                    // document.querySelector(".header").style.transition = "all 0.25s ease 0s"
                }, 250)
                // document.body.style.overflow = "auto"
                this.mobile_menu_show = false
            }
        }
    },
    mounted() {
        this.loadPositions()
    },
    watch: {
        "$props.info": {
            handler() {
                if (this.id_site_menu !== this.info.id_site_menu) {
                    this.positions = []

                    // console.log('Ładowanie pozycji menu: ' + this.info.id_site_menu)
                    this.loadPositions();
                }
            },
            deep: true
        }
    }

}
</script>

<template>

    <div class="style-menu">
        <div class="style-menu__desktop">
            <SiteMenuStyleDesktop
                :items="positions"
                :style="info.type"
                :simulate="simulate"
                @updateSimulate="updateSimulate"/>
        </div>

        <div class="style-menu__burger" v-if="info.mobile === 'yes'">
            <div class="burger" @click="menuMobileWrapperHandler('open')">
                <span class="material-symbols-rounded">menu</span>
            </div>
        </div>

        <div class="style-menu__mobile">
            <SiteMenuStyleMobileWrapper
                :show="mobile_menu_show"
                :menu_items="positions"
                @emitMenuMobileWrapper="menuMobileWrapperHandler"
                @updateSimulate="updateSimulate"/>
        </div>
    </div>

</template>

<style scoped lang="scss">

.style-menu {

    &__desktop {

    }

    &__burger {
        display: none;
    }

    &__mobile {
        display: none;
    }

    @media screen and (max-width: 767px) {

        &__desktop {
            display: none;
        }

        &__burger {
            display: block;
        }

        &__mobile {
            display: block;
        }
    }
}

.burger {
    min-width: 48px;
    width: 48px;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--border);
    transition: all 0.25s ease 0s;
    cursor: pointer;

    &:hover {
        transform: scale(0.95);
    }

    &:active {
        transform: scale(1);
    }

    & > .material-symbols-rounded {

    }
}

.content--xs {

    & .style-menu {

        &__desktop {
            display: none;
        }

        &__burger {
            display: block;
        }

        &__mobile {
            display: block;
        }
    }
}

</style>