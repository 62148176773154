<script>

import Element from "@/components/Element.vue";
import axios from "@/axios"
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue"
import Alert from "@/Library/Components/Alert.vue"
import ArticleNewsBlock from "@/views/Article/NewsBlock.vue";

import ControlAnimate from "@/Library/Controll/Animate.vue"


export default {
    name: "ArticleNews2List",
    components: {
        ArticleNewsBlock,
        Alert
    },
    extends: Element,
    mixins: [ComponentFormStateMachine, ControlAnimate],
    props: {
        type: {default: ""},
        preview: {default: ""}
    },
    data: () => ({
        get_url: "article/get",
        get_params: {
            scope: "title,text,slug,slugs,thumb,date,langs,lead,category_name,urls"
        },

        items: [],
        count: 0
    }),
    methods: {
        async load() {
            this.stateUpdate("sendInfo")

            let url = this.get_url

            let params = {...this.get_params}

            params["scope_extra"] = "slugs,thumb,lead"
            params["version"] = 'no'
            params["is_show"] = true
            params["id_top_NULL"] = true

            if (this.config.type)
                params["type"] = this.config.type

            if (this.info.page_count)
                params["page_count"] = this.info.page_count

            if (this.statusOn)
                params["status"] = "public"

            this.items = await axios.get(url, {params: params}).then(r => r.data.data)

            this.stateUpdate("sendSuccess")
        }
    },
    computed: {
        elementType() {
            let elementConfig = this.config
            return elementConfig.type
        },

        statusOn() {
            let appInitial = this.$root.initial
            let statusValue = appInitial["article" + this.elementType + "_status"]
            return statusValue && statusValue === "yes"
        },
        itemCls() {
            let info = this.info

            let ret = {
                "col-12 col-sm-6 col-lg-4 col-xl-3": info.type === "type_1",
                "col-12 col-sm-6 col-lg-4": info.type === "type_2",
                "col-12 col-sm-6 col-lg-3": info.type === "type_3",
                "col-12 col-lg-6": info.type === "type_4"
            }

            return ret
        }
    },
    mounted() {
        this.load()
    }
}
</script>

<template>


    <div class="news">
        <div class="news__left">
            <ArticleNewsBlock
                class="anim-1 _anim-item"
                :simulate="simulate"
                :item="items[0]"
                @click="type==='render'?$emit('updateSimulate','slug',items[0].slug):''"/>
        </div>

        <div class="news__right">
            <ArticleNewsBlock
                class="anim-2 _anim-item"
                :sub="true"
                :simulate="simulate"
                :item="items[1]"
                @click="type==='render'?$emit('updateSimulate','slug',items[1].slug):''"/>

            <ArticleNewsBlock
                class="anim-3 _anim-item"
                :sub="true"
                :simulate="simulate"
                :item="items[2]"
                @click="type==='render'?$emit('updateSimulate','slug',items[2].slug):''"/>
        </div>
    </div>

    <div class="row" v-if="!stateLoading() && items.length === 0">
        <div class="col-12">
            <br>
            <Alert :text="t('none')" label="" type="info"/>
        </div>
    </div>

</template>

<style scoped lang="scss">

.news {
    margin: 0 auto;

    display: flex;
    column-gap: 30px;

    &__left {

    }

    &__right {
        min-width: 370px;
        max-width: 370px;

        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 1200px) {

        &__right {
            min-width: 290px;
            max-width: 290px;
        }
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        row-gap: 30px;

        &__right {
            min-width: unset;
            max-width: unset;
        }
    }

    @media screen and (max-width: 444px) {

    }
}

.content--xl {

}

.content--l {

    & .news {

        &__right {
            min-width: 290px;
            max-width: 290px;
        }
    }
}

.content--sm, .content--xs {

    & .news {
        flex-direction: column;
        row-gap: 30px;

        &__right {
            min-width: unset;
            max-width: unset;
        }
    }
}

.content--xs {

}

.anim {

    &-1 {
        transform: translateX(-100px);

        &._active {
            transform: translateX(0);
        }
    }

    &-2 {
        transition: all 1s ease 0.2s;
        transform: translateX(100px);

        &._active {
            transform: translateX(0);
        }
    }

    &-3 {
        transition: all 1s ease 0.2s;
        transform: translateX(100px);

        &._active {
            transform: translateX(0);
        }
    }
}

</style>