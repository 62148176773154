<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "BlockBenefits",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    benefits: [
                        {
                            icon: "photo_camera",
                            title: "Benefit One",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut",
                            linkButton: "/",
                            buttonName: "Learn more"
                        },
                        {
                            icon: "mail",
                            title: "Benefit Two",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut",
                            linkButton: "/",
                            buttonName: "Learn more"
                        },
                        {
                            icon: "lock",
                            title: "Benefit Three",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut",
                            linkButton: "/",
                            buttonName: "Learn more"
                        },
                        {
                            icon: "location_on",
                            title: "Benefit Four",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididuntut",
                            linkButton: "/",
                            buttonName: "Learn more"
                        }
                    ]
                }
            }
        }
    }

})
</script>

<template>

    <ul class="list">
        <template v-for="item in info.benefits">
            <li class="list__item item" v-if="to(item, 'title') || parseText(item, 'description')">
                <div class="item__icon">
                    <span class="material-symbols-rounded material-symbols-rounded--fill">{{ item.icon }}</span>
                </div>

                <h1 class="item__title">{{ to(item, "title") }}</h1>

                <p class="item__description" v-html="parseText(item, 'description')"></p>

                <div @click="linkClick(item.linkButton)" class="item__link">
                    <p>{{ item.buttonName }}</p>
                    <span class="material-symbols-rounded">arrow_forward</span>
                </div>
            </li>
        </template>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(458px, 1fr));
    gap: 30px;
    width: 100%;
    margin: 0 auto;

    &__item {
        margin-left: 0;
    }

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
        gap: 24px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 24px;
    }
}

.item {
    padding: 48px 24px 56px 24px;
    border-radius: 10px;
    border: 1px solid var(--border);

    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        min-width: 68px;
        width: 68px;
        height: 68px;
        border-radius: 50px;
        margin-bottom: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--block_light_bg);

        & > .material-symbols-rounded {
            color: var(--icon);
        }
    }

    &__title, &__description, &__link > p {
        font-style: normal;
        line-height: normal;
        text-align: center;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.22rem;
        font-weight: 700;
        margin-bottom: 16px;
        color: var(--title);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 32px;
        color: var(--text);
    }

    &__link {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: var(--link);

        & > p {
            font-family: var(--text_font);
            margin-bottom: 0;
            text-decoration: underline;
            margin-top: 0;
        }

        & > .material-symbols-rounded {

        }
    }
}

.content--l, .content--sm {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
        gap: 24px;

        &__item {

        }
    }

    & .item {

        &__icon {

            & > .material-symbols-rounded {

            }
        }

        &__title, &__description, &__link > p {

        }

        &__title {

        }

        &__description {

        }

        &__link {

            & > p {

            }

            & > .material-symbols-rounded {

            }
        }
    }
}

.content--xs {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 24px;

        &__item {

        }
    }

    & .item {

        &__icon {

            & > .material-symbols-rounded {

            }
        }

        &__title, &__description, &__link > p {

        }

        &__title {

        }

        &__description {

        }

        &__link {

            & > p {

            }

            & > .material-symbols-rounded {

            }
        }
    }
}

.material-symbols-rounded {

    &--fill {
        font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24
    }
}

</style>