<template>
  <DIV class="row">
    <DIV class="col-12 col-md-6 col-lg-4 col-xl-4" v-for="item in items">
      <div class="menu-item">
        <span class="menu-item__icon material-symbols-outlined">{{ item.icon }}</span>
        <span class="menu-item__name">{{ item.name }}</span>
      </div>
    </DIV>
  </DIV>
</template>

<script>
export default {
  name: "MenuBlock",
  data: () => ({

    items: [{
      name: 'Informatyka lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'person',
      url: 'https://facebook.com',
    }, {
      name: 'Matematyka lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'home',
      url: 'https://twitter.com',
    }, {
      name: 'Chemia lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'home',
      url: 'https://twitter.com',
    }, {
      name: 'Fizyka lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'home',
      url: 'https://twitter.com',
    }, {
      name: 'Informatyka lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'person',
      url: 'https://facebook.com',
    }, {
      name: 'Matematyka lorem ipsum dolor sit amet lorem ipsum dolor sit amet',
      icon: 'home',
      url: 'https://twitter.com',
    }]

  })
}
</script>

<style scoped lang="scss">

.menu-block {

  //display: grid;
  //grid-template-columns: repeat(3, 33%);
  //grid-column-gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  //gap: 6px;


  &__item {
  }
}

.menu-item{
  padding: 35px 25px;
  background: #FFFFFF;
  backdrop-filter: blur(3px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  cursor: pointer;

  color: var(--main);
  font-size: 1.15rem;
  line-height: 1.5rem;

  margin-bottom: 1rem;

  display: flex;
  &__icon{
    font-size: 2.67rem;
    font-weight: 300;
    margin-right: 1rem;
    margin-top: -3px;
  }
  &__icon{}
}

</style>