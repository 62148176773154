<script>

import ControllLang from '@/Library/Controll/Lang.vue'
import Base from "@/Library/Extends/Base.vue";
import {mapState} from "vuex";

export default {
    name: "Element",
    extends: Base,
    mixins: [ControllLang],
    props: {

        ...mapState('app', {appConfig: "config"}),

        view: {
            default() {
                return {}
            }
        },
        config: {
            default() {
                return {}
            }
        },
        info: {
            default() {
                return {}
            }
        },
        obj: {
            default() {
                return {}
            }
        },
        item: {
            default() {
                return {}
            }
        },
        items: {
            default() {
                return []
            }
        },
    },
    data: () => ({
        styles: {},
    }),

    computed: {


        domainLang() {
            return this.appConfig?.lang;
        },


        styleGenerete(style_default = {}, style_obj = {}) {

            let props = {}

            let ret = {};

            Object.assign(props, style_default, style_obj);

            let keys = Object.keys(props);

            keys.forEach(key => {

                let val = props[key];

                if (key === 'font-size')
                    val += 'px'
                if (key === 'border-radius')
                    val += 'px'
                if (key === 'border-width')
                    val += 'px'
                if (key.includes('margin'))
                    val += 'px'

                ret[key] = val;

            })

            let css = props["css"]?.replaceAll(/\s/g, '').replace(/(\r\n|\n|\r)/gm, "")

            let css_arr = css?.split(";")
            css_arr = css_arr?.filter(el => el !== "")

            if (css_arr && Array.isArray(css_arr)) {
                css_arr.map(el => {
                    let el_arr = el.split(":")
                    ret[el_arr[0]] = el_arr[1]
                })
            }

            delete ret["css"]

            console.log("ret", ret)

            return ret;

        },
        widgetStyle() {

            let style_default = {...this.item['widget_style']};
            let style_preview = {...this.item['widget_style_' + this.screenWidthStr]};

            let fields = style_default ? Object.keys(style_default) : [];

            let ret = {};
            fields.forEach(field => {

                ret[field] = {...style_default[field], ...style_preview[field]};

            })

            return ret;
        }
    },

    methods: {
        parseText(obj, column) {
            let ret = ""

            ret = this.to(obj, column)

            if (ret)
                ret = ret.replaceAll("\n", "<br/>")

            return ret
        },

        linkClick(link) {

            let code = link.code;

            if (code === 'link') {
                window.open(link.url, '_blank');
                return null;
            }

            if (code === 'none') {
                return null;
            }

            let lang = this.lang;

            let slugs = link.slugs;

            let slug = slugs[lang];

            let url = this.$root.routerPrefix + '/' + slug;

            this.$router.push(url);
            // this.$emit('updateSimulate', 'slug', slug)

        },

        stylesUpdate() {

            let widget_style = this.widgetStyle;

            let fields = Object.keys(widget_style);
            let ret = {};
            fields.forEach(field => {

                ret[field] = {};
                let items = widget_style[field];

                let keys = Object.keys(items);

                keys.forEach(key => {

                    let val = items[key];

                    if (key === 'font-size')
                        val += 'px'
                    if (key === 'border-radius')
                        val += 'px'
                    if (key === 'border-width')
                        val += 'px'
                    if (key.includes('margin'))
                        val += 'px'

                    ret[field][key] = val;

                })




            })



            this.styles = ret;

            // console.log(this.item, this.styles)


        }

    },

    mounted() {

        this.stylesUpdate();

    },

    watch: {

        // widget_style: {
        //   handler(newVal) {
        //     this.stylesUpdate();
        //   },
        //   deep: true,
        //   immediate: true
        // }
    }
}
</script>

<style scoped lang="scss">

.menu-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;

    &__item {
        width: 10%;
        margin: 0;
    }
}

.menu-item {

    display: flex;

    flex-direction: column;

    color: var(--icon);

    &__icon {
        font-size: 32px;
        padding: 15px 0;
    }

    &__name {
        font-size: 12px;
        line-height: 1.5em;
        font-weight: 200;
    }

}

</style>