<script>

import ShowDateTime from "@/Library/Show/DateTime.vue";
import Element from "@/components/Element.vue";

export default {
    name: "ArticleEventMetric",
    extends: Element,
    components: {
        ShowDateTime,
    },
    props: {
        info: {
            default() {
                return {}
            }
        }
    }
}
</script>

<template>

    <ul class="metric" v-if="obj">
        <li class="metric__item item">
            <p class="item__title">
                <span class="material-symbols-rounded">calendar_month</span>
                <span>{{ t("event_start") }}</span>
            </p>
            <p class="item__body">
                <ShowDateTime :show_time="true" :date="obj.event_date_start"/>
            </p>
        </li>

        <li class="metric__item item">
            <p class="item__title">
                <span class="material-symbols-rounded">calendar_month</span>
                <span>{{ t("event_end") }}</span>
            </p>
            <p class="item__body">
                <ShowDateTime :show_time="true" :date="obj.event_date_end"/>
            </p>
        </li>

        <li class="metric__item item" v-if="obj.event_link_url">
            <p class="item__title">
                <span class="material-symbols-rounded">link</span>
                <span>{{ t("event_link_online") }}</span>
            </p>
            <p class="item__body">
                <a :href="obj.event_link_url" target="_blank">{{ obj.event_link_url }}</a>
            </p>
        </li>
    </ul>

</template>

<style lang="scss">

.item__body {

    & span {
        color: var(--block_dark_text);
        font-style: italic;
    }
}

</style>

<style scoped lang="scss">

p {
    margin: 0;
}

a {
    text-decoration: none;
}

.metric {
    list-style: none;
    margin: 0;

    display: flex;
    flex-direction: column;
    row-gap: 24px;

    background: var(--block_dark_bg);
    padding: 24px;

    width: 100%;

    &__item {

    }
}

.item {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    &__title, &__body {

        & span {
            color: var(--block_dark_text);
        }
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: 1.2rem;

        & .material-symbols-rounded {
            font-size: 1.2rem;
        }

        & span:last-child {
            font-family: var(--title_font);
        }
    }

    &__body {

        & span {
            font-family: var(--text_font);
        }

        & a {
            color: var(--block_dark_text);
            word-break: break-all;
        }
    }
}

</style>