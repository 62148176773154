<script>
import axios from "@/axios";
import {mapState} from "vuex";

export default {

    name: "SystemConfigAbstractConfig",

    data: () => ({

        /**
         * Nazwa systemowa modułu
         */
        module: null,

        /**
         * Adres do ustawianie konfiguracji
         */
        config_set_url: '/baseconfig/set',

        /**
         * Adres do pobierania konfiguracji
         */
        config_get_url: '/base/init',

        /**
         * Zapisuje konfiguracje
         */
        config_data: [],

        /**
         * Wartości inicjujące
         */
        init_data: [],

        obj_source: 'data',

        obj_name: 'obj',
    }),

    computed: {
        ...mapState('app', ['initial', 'config'])
    },

    methods: {

        /**
         * Zwraca obiekt, którego dotyczy konfiguracja
         */
        objConfig() {

            let ret = null;

            switch (this.obj_source) {
                case "data":
                    ret = this.$data[this.obj_name]
                    break;
                case "props":
                    ret = this.$props[this.obj_name]
                    break;
            }

            return ret;

        },

        /**
         * Zwraca wartość obiektu jeśli jest
         */
        objConfigValue(code) {

            let ret = this.configValueGet(code);

            let obj = this.objConfig();

            if (ret !== 'no' && obj && obj[code]) {
                ret = obj[code];
            }

            return ret;

        },

        /**
         * Sprawdza czy wartość obiektu jest taka jak powinnna
         */
        objConfigValueIs(code, value) {

            return this.objConfigValue(code) === value;

        },

        /**
         * Sprawdza czy wartość jest równa yes(tak)
         */
        objConfigValueIsYes(code) {
            return this.objConfigValueIs(code, "yes");
        },

        /**
         * Wartość prefix
         */
        configValuePrefix() {

            return this.module;

        },

        /**
         * Zwraca wartość systemową konfiguracji
         */
        configValueCode(code) {

            return this.configValuePrefix() + '_' + code
        },

        /**
         * Zwraca wartość ustawienia
         */
        configValueGet(code, def = 'no') {
            return this.config_data[this.configValueCode(code)] ?? def;

        },

        /**
         * Sprawdza czy wartość konfiguracji jest taka jak powinnna
         */
        configValueIs(code, value) {

            return this.configValueGet(code) === value;

        },

        /**
         * Sprawdza czy wartość jest równa yes(tak)
         */
        configValueIsYes(code) {
            return this.configValueIs(code, "yes");
        },


        /**
         * Zwraca wartość ustawienia
         */
        configBaseValueGet(code, def = 'no') {

            return this.config_data[code] ?? def;

        },

        /**
         * Sprawdza czy wartość konfiguracji jest taka jak powinnna
         */
        configBaseValueIs(code, value) {

            return this.configBaseValueGet(code) === value;

        },

        /**
         * Sprawdza czy wartość jest równa yes(tak)
         */
        configBaseValueIsYes(code) {
            return this.configBaseValueIs(code, "yes");
        },

        /**
         * Zwraca wartość inicjującą
         */
        initValueGet(code, def = 'no') {

            return this.init_data[this.configValueCode(code)] ?? def;

        },

        /**
         * Sprawdza czy wartość inicjująca jest taka jak powinnna
         */
        initValueIs(code, value) {

            return this.initValueGet(code) === value;

        },

        /**
         * Sprawdza czy wartość jest równa yes(tak)
         */
        initValueIsYes(code) {
            return this.initValueIs(code, "yes");
        },


        /**
         * Ustawia wartość
         */
        configValueSet(code, value) {

            let code_full = this.configValueCode(code);

            this.config_data[code_full] = value;

            this.send(code_full, value);

        },

        /**
         * Wczytuje init i config
         * @returns {Promise<void>}
         */
        async configLoad() {

            /**
             * Odświerza ustawienia
             */
            await this.configInitRefresh();


            for (let [key, value] of Object.entries(this.initial)) {
                if (key.includes(this.configValuePrefix()))
                    this.init_data[key] = value
            }

            for (let [key, value] of Object.entries(this.config)) {
                if (key.includes(this.configValuePrefix()))
                    this.config_data[key] = value
            }
        },

        /**
         * Wysyła ustawienia
         */
        configSend(code, value) {

            /**
             * Ustawienie do wysłania
             */
            let obj = {
                code: this.configValueCode(code),
                value: value
            }

            /**
             * Wysłanie żądania
             */
            axios
                .post(this.set_url, obj)
                .catch(error => this.errorCatch(error))

        },

        configInitRefresh() {
            let segments = this.$route.path.split('/');
            this.module = segments[1];
        },

    },

    created() {
        this.configLoad();
    }
}
</script>