<template>
  <div class="gallery-v2__container">
    <div class="gallery-v2__gallery gallery-v2">
      <img class="gallery-v2__image" v-if="info.image" :src="info.image.url" alt="Item image" />
      <h2 class="gallery-v2__name">{{to(this.info, 'name')}}</h2>
      <p class="gallery-v2__description">{{to(this.info, 'description')}}</p>
    </div>
  </div>
</template>

<script>
import Element from "@/components/Element.vue";

export default {
  name: "PageModelBlockGalleryV2",
  extends: Element,
  computed() {},
  props: {
    info: {
      default() {
        return {
          // items: [{
          name: "Event 1",
          description: "Description for the first event",
          image: "https://media.istockphoto.com/id/1140421822/photo/3d-rendering-of-blue-lighten-x-shape-next-by-concrete-pillars-and-grunge-floor-with-puddles.jpg?s=612x612&w=0&k=20&c=X3fgI1KvWByWie4JPJBbHlblXq0trvI02snOLWSI9jk="
          // }, {
          //   name: "Event 2",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1621084733721-3d215f4d4be9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8&w=1000&q=80"
          // },{
          //   name: "Event 3",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1646419814219-6460b96ebaf5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&w=1000&q=80"
          // }, {
          //   name: "Event 4",
          //   description: "Description for the first event",
          //   image: "https://i.pinimg.com/1200x/9e/ca/47/9eca4737b193cab2b2123a294e4aa1e5.jpg"
          // }, {
          //   name: "Event 5",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1579202645762-5de0cd37c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 6",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1635810409963-4b0f91e2f58b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 7",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1559095012-12c3f86f6d0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDExfHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 8",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1513600746470-f5f12174e545?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 9",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1579202645762-5de0cd37c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 10",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1579202645762-5de0cd37c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }, {
          //   name: "Event 11",
          //   description: "Description for the first event",
          //   image: "https://images.unsplash.com/photo-1579202645762-5de0cd37c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80"
          // }]
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gallery-v2__container{
  width: 1140px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .gallery-v2{
    max-width: 33%;
    min-width: 21%;
    flex: 1 1 auto;
    background-color: var(--block_light_bg);

    &__name{
      font-size: 1.67rem;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.1800002008676529px;
      text-align: left;
      margin: 0 0 0 3%;
      color: var(--block_text);
    }

    &__description{
      font-size: 0.89rem;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      margin: 0 0 3% 3%;
      color: var(--block_text);
    }

    &__image{
      max-height: 250px;
      object-fit: cover;
      vertical-align: middle;
      width: 100%;
    }
  }
}
</style>