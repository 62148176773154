<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
    name: "News2",
    extends: Element,
    computed: {
        image() {
            return this.obj.thumb;
        },
        title() {
            return this.obj.title;
        },
        lead() {
            return this.obj.lead;
        },
        category_name() {
            return this.obj.category_name && this.obj.category_name !== "" ? this.obj.category_name : null;
        },
        lang() {
            return localStorage.getItem('lang')
        }
    },
    props: {
        obj: {
            default() {
                return {}
            }
        },
        slugs: {
            default() {
                return []
            }
        }
    }
})
</script>

<template>

    <router-link :to="slugs[lang] ?? './'" class="item">
        <div class="item__image" :style="{backgroundImage: `url(${image})`}"></div>

        <div class="item__content content">
            <p class="content__category" v-if="category_name">{{ to(obj, "category_name") }}</p>
            <router-link class="content__link" :to="slugs[lang] ?? './'">
                <h1 class="content__title three-dots three-dots--2">{{ to(obj, 'title') }}</h1>
            </router-link>
            <p class="content__description three-dots three-dots--3">{{ to(obj, 'lead') }}</p>
            <router-link class="content__link" :to="slugs[lang] ?? './'">
                <p>{{ t("read_more") }}</p>
                <span class="material-symbols-rounded">arrow_forward</span>
            </router-link>
        </div>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.item {
    display: block;
    border-radius: 9px;
    border: 1px solid var(--border);
    overflow: hidden;
    height: 100%;

    &__image {
        height: 247px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__content {

    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 31px 23px;
    height: calc(100% - 247px);

    &__category, &__title, &__description, &__link > p {
        font-style: normal;
        line-height: normal;
        margin-top: 0;
        text-align: left;
    }

    &__category, &__description, &__link > p {
        font-family: var(--text_font);
    }

    &__category {
        font-size: 0.56rem;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.7px;
        padding: 6px 9px;
        border-radius: 2px;
        color: var(--block_dark_text);
        background: var(--block_dark_bg);
        width: fit-content;
        margin-bottom: 16px;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.22rem;
        font-weight: 700;
        margin-bottom: 8px;
        color: var(--link);
    }

    &__description {
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
        color: var(--text);
    }

    &__link {
        margin-top: auto;

        display: flex;
        align-items: center;
        column-gap: 12px;
        text-decoration: none;

        & > p, & > .material-symbols-rounded {
            color: var(--text);
        }

        & > p {
            font-size: 0.78rem;
            font-weight: 500;
            text-decoration-line: underline;
            margin-bottom: 0;
        }

        & > .material-symbols-rounded {

        }
    }
}

</style>