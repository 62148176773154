<template>
    <div class="example-drag">
        <div class="upload">
            <ShowImage
                    :class="show_class"
                    :image="imageUrl"
            />

            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                <h3>Drop files to upload</h3>
            </div>

            <file-upload
                    :post-action="baseUrl+'base/uploadfile'"
                    :multiple="false"
                    :drop="true"
                    :drop-directory="false"
                    @input-file="inputFile"
                    @input-filter="inputFilter"
                    v-model="value_data"
                    :id="this.id"
                    name="files"
                    :accept="accept"
                    ref="upload">
            </file-upload>

            <button class="btn btn-primary" @click="open()">
                Wrzuć obrazek
                <span v-if="progress" class="opacity-50">( <ShowPercent :percent="this.progress"/> )</span>
            </button>

            <button class="btn btn-link right" v-if="value" @click="clear()">
                Usuń obrazek
            </button>

        </div>

    </div>
</template>
<script>
import FileUpload from 'vue-upload-component'
import ShowImage from "../Show/Image";
import ShowPercent from "@/Library/Show/Percent";
import InputBase from "@/Library/Forms/InputBase";
import axios from "@/axios";
import {mapState} from "vuex";

export default {
    extends: InputBase,
    components: {
        ShowPercent,
        ShowImage,
        FileUpload,
    },
    props: {
        name: {},
        value: {},
        value_show: {},
        id: {},
        class: {},
        show_class: {},
        accept: {
            default() {
                return 'image/*'
            }
        }
    },
    data() {
        return {
            progress: 0,
            value_data: null,
            value_show_data: null,
            value_show_local: null,
        }
    },
    methods: {
        val(val = null, val_show = null) {

            if (val !== null) {
                this.value = val;
            }
            if (val_show !== null) {
                this.value_show = val_show;
            }

            if (val === null) {
                return this.value;
            }

        },

        open() {
            this.$refs.upload.$el.querySelector('input').click()
        },

        clear() {
            // this.value = null;
            // this.value_show = null;
            this.value_show_local = null;


            this.$emit('updateValue', this.name, null, null)
        },

        updateFile(data) {


            this.$emit('updateImage', this.name, data.filename, data.url)

            this.$emit('updateValue', this.name, data.filename, data.url)

            this.value_show_local = data.url

            this.progress = null;
        },

        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Before adding a file
                // 添加文件前
                // Filter system files or hide files
                // 过滤系统文件 和隐藏文件
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
                // Filter php html js file
                // 过滤 php html js 文件
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }

            }

            if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                // 创建 blob 字段
                newFile.blob = ''
                let URL = (window.URL || window.webkitURL)
                if (URL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                // 缩略图
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }
            // image size
            // image 尺寸
            if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
                newFile.error = 'image parsing'
                let img = new Image();
                img.onload = () => {
                    this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
                }
                img.οnerrοr = (e) => {
                    this.$refs.upload.update(newFile, {error: 'parsing image size'})
                }
                img.src = newFile.blob
            }
        },
        // add, update, remove File Event
        inputFile(newFile, oldFile) {

            if (newFile && !oldFile) {
                // Add file
                this.$refs.upload.active = true;
            }

            if (newFile && oldFile) {
                // Update file

                // Start upload
                if (newFile.active !== oldFile.active) {
                    console.log('Start upload', newFile.active, newFile)

                    newFile.upload_date = new Date();
                }

                // Upload progress
                if (newFile.progress !== oldFile.progress) {
                    console.log('progress', newFile.progress, newFile)

                    this.progress = newFile.progress;

                }

                // Upload error
                if (newFile.error !== oldFile.error) {
                    console.log('error', newFile.error, newFile)
                }

                // Uploaded successfully
                if (newFile.success !== oldFile.success) {
                    console.log('success', newFile.success, newFile)
                    console.log('xhr', JSON.parse(newFile.xhr.response));

                    let data = JSON.parse(newFile.xhr.response);

                    this.updateFile(data);

                }

                // Automatic upload
                if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                    if (!this.$refs.upload.active) {
                        this.$refs.upload.active = true;
                    }
                }
            }

            if (!newFile && oldFile) {
                // Remove file

                // Automatically delete files on the server
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/file/delete?id=' + oldFile.response.id,
                    // });
                }
            }
        },


    },

    computed: {

        ...mapState('app',['modules']),

        baseUrl() {
            return axios.defaults.baseURL;
        },


        imageUrl() {

            let ret = null;

            if (!ret && this.value_show)
                ret = this.value_show;

            if (!ret && this.value_show_local)
                ret = this.value_show_local;

            return ret;

        }

    },
    mounted() {

        this.value_data = this.value;
        this.value_show_data = this.value_show;

    },

    watch: {
        // value(newValue,oldValue){
        //
        //   this.value_data = newValue;
        // },
        // value_show(newValue,oldValue){
        //
        //   this.value_show_data = newValue;
        // }
    }

}
</script>

<style scoped lang="scss">
.example-drag .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;

  & h3 {

    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }

}

.example-drag .drop-active h3 {
}


</style>