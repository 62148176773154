<script>
export default {
  name: "ControllStorage",
  data: () => ({
    storage_prefix: "storage",
  }),
  methods: {

    storageSet: function (name, value) {
      let name_full = this.storage_prefix + '_' + name;

      localStorage.setItem(name_full, value);
    },

    storageGet: function (name, def) {
      let name_full = this.storage_prefix + '_' + name;

      let value = localStorage.getItem(name_full);

      value = value === null ? def : value;

      return value;
    },

  },
  computed:{

  },
}
</script>
