<script>
import Element from "@/components/Element.vue";
import Thumb1 from "@/views/Base/Thumb/Thumb1.vue";

export default {
    name: "ArticleThumb",
    components: {Thumb1},
    extends: Element,
}
</script>

<template>
    <div class="thumb-bg" :style="{backgroundImage: `url(${obj.thumb})`}">
        <Thumb1 :image="obj.thumb" format="31" radius="yes" :info="info"/>
    </div>
</template>

<style lang="scss" scoped>

.thumb-bg {
    display: flex;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    & img {
        backdrop-filter: blur(20px);

    }
}

</style>