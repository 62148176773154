<script>
import Element from "@/components/Element.vue";

export default {
    name: "Info",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor",
                    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim."
                }
            }
        },
    }
}
</script>

<template>

    <div class="text">
        <p class="text__left">{{ to(info, "title") }}</p>
        <p class="text__right" v-html="parseText(info, 'description')"></p>
    </div>

</template>

<style scoped lang="scss">

.text {
    display: flex;
    column-gap: 29px;

    &__left, &__right {
        font-style: normal;
        line-height: normal;
        width: 50%;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__left {
        font-family: var(--title_font);
        font-size: 1.56rem;
        font-weight: 700;
        text-align: right;
        color: var(--title);
    }

    &__right {
        font-family: var(--text_font);
        font-size: 1.33rem;
        font-weight: 400;
        line-height: 36px;
        color: var(--text);
    }

    @media screen and (max-width: 1440px) {
        flex-direction: column;
        row-gap: 8px;

        &__left, &__right {
            width: 100%;
            text-align: center;
        }
    }

    @media screen and (max-width: 767px) {

        &__left {
            font-size: 1.22rem;
        }

        &__right {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

.content--l, .content--sm {

    & .text {
        flex-direction: column;
        row-gap: 8px;

        &__left, &__right {
            width: 100%;
            text-align: center;
        }
    }
}

.content--xs {

    & .text {
        flex-direction: column;
        row-gap: 8px;

        &__left, &__right {
            width: 100%;
            text-align: center;
        }

        &__left {
            font-size: 1.22rem;
        }

        &__right {
            font-size: 0.89rem;
            line-height: 24px;
        }
    }
}

</style>
