<script>
import TemplatePage from "@/template/Page.vue";
import MenuDropdown from "@/views/Base/Menu/Dropdown.vue";
import ArticleBlock1 from "@/views/Article/Block1.vue";
import axios from "@/axios";
import AbstractList from '@/views/Abstract/List.vue'
import UserBlock1 from "@/views/User/Block1.vue";
import PortalTeamBlock1 from "@/views/Portal/Team/Block1.vue";
import Alert from "@/Library/Components/Alert.vue";
import TeamMember from "@/views/Portal/Team/TeamMember.vue";
import TeamMember2 from "@/views/Portal/Team/TeamMember2.vue";
import TeamMember3 from "@/views/Portal/Team/TeamMember3.vue";
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import TeamMemberSlider from "@/views/Portal/Team/TeamMemberSlider.vue";
import PortalTeamBlock5 from "@/views/Portal/Team/Block5.vue";

export default {
    name: "PortalTeamList",
    extends: AbstractList,
    components: {
        PortalTeamBlock5,
        TeamMemberSlider,
        Carousel, Slide, Navigation, Pagination,
        TeamMember3, TeamMember2,
        TeamMember, Alert, PortalTeamBlock1, UserBlock1, ArticleBlock1, MenuDropdown, TemplatePage
    },
    data: () => ({
        items: [],
        count: 0,
        page: 1,
        slidesToShow: 3,
        page_count: '10',

        sort: 'date ASC',
        sort_types: [
            {name: 'Od najnowszych', value: 'date DESC'},
            {name: 'Od najstarszych', value: 'date ASC'},
            {name: 'Alfabetycznie', value: 'title ASC'}
        ],

        page_count_types: [
            {name: '10', value: '10'},
            {name: '20', value: '20'},
            {name: '50', value: '50'}
        ],

        get_url: 'portalteam/get',
        get_params: {
            scope: 'name,slug,image_url,description,stand,langs,socials',
            sort: "number ASC"
        },
    }),
    created() {
        // window.addEventListener("resize", this.carouselSlidesSize);
    },
    destroyed() {
        // window.removeEventListener("resize", this.carouselSlidesSize);
    },
    methods: {
        async refresh() {
            let params = {...this.get_params};
            let result = await axios
                .get(this.get_url, {params: params})
                .then(r => r.data);
            this.items = result.data;
            this.count = result.count;
        },

        next() {
            console.log("next()")
            this.$refs.carousel.next()
        },

        prev() {
            console.log("prev()")
            this.$refs.carousel.prev()
        }
    },
    computed: {
        carouselSlidesSize() {
            let ret = 3

            if (this.screenWidthStr === "l")
                ret = 2

            if (this.screenWidthStr === "sm")
                ret = 1.5

            if (this.screenWidthStr === "xs")
                ret = 1

            return ret
        },
    },
    mounted() {
        this.refresh();
        // this.carouselSlidesSize();
    }
}
</script>

<template>
    <div class="row" v-if="count === 0">
        <div class="col-12">
            <br>
            <Alert :text="t('none')" label="" type="info"/>
        </div>
    </div>
    <template v-if="count > 0">
        <ul class="list width-100-percent" v-if="info.type !== 'type_4'" :class="{'list--type-3': info.type === 'type_3'}">
            <li class="list__item" :class="info.type" v-for="(item, index) in this.items">
                <PortalTeamBlock1 v-if="info.type === 'type_1'" v-bind="item" :obj="item"/>
                <team-member2 v-if="info.type === 'type_2'" v-bind="item" :obj="item"/>
                <team-member3 v-if="info.type === 'type_3'" v-bind="item" :obj="item"/>
                <PortalTeamBlock5 v-if="info.type === 'type_5'" v-bind="item" :item="item" :index="index"/>
            </li>
        </ul>

        <div class="messages__container" v-if="info.type === 'type_4' && count > 0">
            <div class="messages__top-part top-part">
                <div class="top-part__arrows-container arrows-container">
                    <div class="arrows-container__left" @click="prev">
                        <span class="material-symbols-outlined">arrow_back</span>
                    </div>
                    <div class="arrows-container__right" @click="next">
                        <span class="material-symbols-outlined">arrow_forward</span>
                    </div>
                </div>
            </div>

            <pre>{{carouselSlidesSize}}</pre>
            <carousel class="slider-v2-container__carousel carousel" ref="carousel" :items-to-show="carouselSlidesSize">
                <slide class="carousel__slide" style="width: auto!important;" v-for="item in this.items">
                    <TeamMemberSlider :obj="item" @click="type==='render'?$emit('updateSimulate','slug',item.slug):''"/>
                </slide>
            </carousel>

<!--            <div class="row">-->
<!--                <div class="col-12" style="text-align: center">-->
<!--                    <Pagination :page="page" :page_count="page_count" :count="count"-->
<!--                                @updatePage="updatePage"/>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </template>
</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    gap: 30px;

    width: 100%;

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    &__item {
        margin-left: 0;
        width: 100%;

        &:first-child:last-child {
            max-width: 50%;
        }
    }

    &--type-3 {
        grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
    }
}

.content--l, .content--sm {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(348px, 1fr));
    }
}

.content--xs {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 0;
    }
}

.messages__container {
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;

    .top-part {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-content: center;
        align-items: center;
        margin-bottom: 3%;

        .arrows-container {
            flex-basis: 7%;
            min-width: 5.8125rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            &__left,
            &__right {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                border: 0.5rem solid var(--block_light_border);
                color: var(--secondary);
                cursor: pointer;
                border-radius: 0.125rem;
            }
        }
    }

    .carousel {

        &__slide {

        }
    }
}

</style>

<style lang="scss">

.carousel__viewport {
    width: 100%!important;
}

.carousel__slide {

    & > .item {
        margin: 0 15px;
    }

    &:first-child {

        & > .item {
            margin-left: 0;
        }
    }

    &:last-child {

        & > .item {
            margin-right: 0;
        }
    }
}

</style>