<script>
export default {
  name: "UserTags",
  props: {
    obj: {
      default() {
        return {}
      }
    }
  }
}
</script>

<template>
  <p>Tags</p>
</template>

<style scoped lang="scss">

</style>