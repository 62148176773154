<script>

import Element from "@/components/Element.vue";

export default {
  name: "SiteMapList",
  extends: Element,

  props: {
    items: {
      default() {
        return [];
      }
    },

    id_top: {
      default() {
        return null
      }
    },

  },

  computed: {
    localItems() {
      return this.items.filter(item => item.id_top === this.id_top)
    }
  },

  methods: {
    linkUrl(link) {

      let lang = this.lang;

      let urls = link.urls;

      let slugs = link.slugs;

      return slugs[lang]

    },

    itemHasKids(item) {

      return this.items.filter(item_kid => item_kid.id_top === item.id).length > 0;

    }

  }


}
</script>

<template>

  <ul>
    <li v-for="item in this.localItems">
      <router-link :to="linkUrl(item)" v-html="item.name"/>

      <SiteMapList
          v-if="itemHasKids(item)"
          v-bind="$props"
          :id_top="item.id"
      />
    </li>
  </ul>


</template>

<style lang="scss">

.space {
  width: 100%;
  clear: both;
}

</style>