<template>

  <div class="gallery">
    <Carousel id="gallery" class="my-gallery gallery__slider" :items-to-show="1" :wrap-around="false"
              v-model="currentSlide">


      <template #slides>
        <Slide v-for="(slide, index) in items" :key="index">
          <div class="my-gallery__item">
            <img :src="slide.image" alt="image" @click="$refs.templatePageFull.show()">
          </div>
        </Slide>
      </template>
    </Carousel>
    <div class="gallery__thumbs">

      <Carousel id="thumbnails" class="my-thumbnails " :items-to-show="itemsToShow" :wrap-around="false"
                v-model="currentSlide" ref="carousel">
        <template #addons>
          <Navigation/>
        </template>
        <template #slides>
          <Slide v-for="(slide, index) in items" :key="index">
            <div class="my-thumbnails__item" @click="slideTo(index)"
                 :style="{backgroundImage: 'url(' + slide.image + ')'}"></div>
          </Slide>
        </template>
      </Carousel>
    </div>

  </div>

  <TemplateModalFull v-if="items" ref="templatePageFull">
    <template #default>

      <Carousel id="gallery" class="my-gallery my-gallery--full" :items-to-show="1" :wrap-around="false"
                v-model="currentSlide">
        <template #addons>
          <Navigation/>
        </template>

        <template #slides>
          <Slide v-for="(slide, index) in items" :key="index">
            <div class="my-gallery__item my-gallery__item--default">
              <img :src="slide.image" alt="image" @click.stop>
            </div>
          </Slide>
        </template>
      </Carousel>

    </template>
  </TemplateModalFull>

</template>

<script>
import {Carousel, Slide, Navigation, Pagination} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import TemplateModalFull from "@/components/TemplateModalFull.vue";

export default {
  name: "Gallery1",
  components: {
    TemplateModalFull,
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  props: {
    items: {
      default() {
        return null
      }
    }
  },
  data: () => ({
    currentSlide: 0,
    items_length: null,
  }),
  methods: {
    /**
     * Przełączanie slajdów
     * @param val numer (index) slajda (zdjęcie)
     */
    slideTo(val) {
      this.currentSlide = val
    },

    handleKeydown(e) {
      let self = this

      switch (e.keyCode) {
        case 27:
          self.$refs.templatePageFull.hide()
          break;
        case 37:
          if (self.currentSlide > 0)
            self.slideTo(self.currentSlide - 1)
          break;
        case 39:
          if (self.currentSlide < self.items.length - 1)
            self.slideTo(self.currentSlide + 1)
          break;
      }
    }
  },
  computed: {
    itemsToShow() {
      let ret = 1

      switch (this.items_length) {
        case 2:
          ret = 2
          break
        case 3:
          ret = 3
          break
        default:
          ret = 4
      }

      return ret
    }
  },
  mounted() {
    this.items_length = this.items.length

    let self = this
    window.addEventListener('resize', function () {

      if (window.innerWidth > 991)
        self.items_length = 4

      if (window.innerWidth <= 991)
        self.items_length = 3

      if (window.innerWidth <= 767)
        self.items_length = 2


    }, true);
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  }
}
</script>

<style lang="scss">

.gallery {

  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;

  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--main);
    opacity: 0.05;
    content: '';
  }

  &__slider {
    background-color: #1B1B1B;

  }

  &__thumbs {

    width: calc(100% - 140px);
    margin: 0 auto;
    padding: 20px;

  }

}


.my-gallery {
  margin-bottom: 20px;

  @media screen and (max-width: 444px) {
    margin-bottom: 0;
  }

  &__item {
    display: flex;

    & img {
      width: 100%;
      max-height: 500px;
      cursor: pointer;

      @media screen and (max-width: 991px) {

      }

      @media screen and (max-width: 891px) {
        max-height: 450px;
      }

      @media screen and (max-width: 791px) {
        max-height: 400px;
      }

      @media screen and (max-width: 691px) {
        max-height: 350px;
      }

      @media screen and (max-width: 591px) {
        max-height: 300px;
      }

      @media screen and (max-width: 491px) {
        max-height: 250px;
      }

      @media screen and (max-width: 391px) {
        max-height: 200px;
      }

      @media screen and (max-width: 291px) {
        max-height: 150px;
      }
    }

    &--default {

      & img {
        cursor: default;
      }
    }
  }

  &--full {
    margin: auto;

    & .my-gallery__item img {
      max-height: calc(100vh - 60px);
    }
  }

  & .carousel__next, & .carousel__next {

  }

  & .carousel__track {
    margin: 0;
  }
}

.my-thumbnails {

  @media screen and (max-width: 444px) {
    display: none;
  }

  &__item {
    width: 100%;
    //height: 81px;
    height: 0;
    padding-bottom: 67%;
    border-radius: 6px;
    margin-right: 10px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .carousel__slide:last-child .my-thumbnails__item {
    margin-right: 0;
  }

  & .carousel__prev {
    left: -70px
  }

  & .carousel__next {
    right: -70px
  }
}

</style>