<template>

    <div class="line">
        <div class="line__head line-head">
            <div class="line-head__thumb"></div>
            <div class="line-head__text">{{ info.category_name }}</div>
            <div class="line-head__date">12.01.2022</div>
        </div>
        <div class="line_body line-body">

            <div class="line-body__left line-left">

                <ul class="line-left__metric metric">
                    <li class="metric__item">
                        <LabelSolid/>
                    </li>
                    <li class="metric__item">
                        Klasa 1-3
                    </li>
                    <li class="metric__item item item--right">
                        <LabelSolid/>
                    </li>
                </ul>
                <div class="line-left__name">
                    1.1 Założenie nowej działaności gospodarczej przez osoby nieaktywne zawodow, Polska Wschodnia
                </div>
                <div class="line-left__text">
                    Koszty związane z realizacja projektu obejmują kategorie zakupu sprzętu, usług doradczych i
                    eksperckich związanych z rozwijaniem modelu biznesowego, wynagrodzenia pracy personelu projektu
                </div>
                <div class="line-left__links">
                    <LinkList/>
                </div>
            </div>
            <div class="line-body__right line-right">
                <span class="line-right__label">Czas trwania</span>
                <b class="line-right__value">15 min</b>
            </div>

        </div>
    </div>

</template>

<script>
import LineAbstract from "@/views/Base/Link/Abstract.vue";
import LabelSolid from "@/views/Base/Label/Solid.vue";
import LinkList from "@/views/Base/Link/List.vue";

export default {
    name: "LineBig1",
    components: {LinkList, LabelSolid},
    extends: LineAbstract,
    data: () => ({
        info: {
            category_image: '',
            category_name: 'Polska Agencja Rozwoju Przedsiębiorczości ogłasza nabór w ramach poddziałania 1.1.2 POPW Rozwój startupów w Polsce Wschodniej',
            create_date: '',
            name: '',
            text: '',
            education_step: '',
            time: '',
        }
    })
}
</script>

<style scoped lang="scss">
.line {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 2rem;

  &__head {
  }

  &__body {

  }
}

.line-left {
  &__metric {
    margin-bottom: 12px !important;
  }

  &__name {

    margin-bottom: .85rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--main);
  }

  &__text {

    margin-bottom: .85rem;
    font-weight: 300;
    font-size: 1rem;
    line-height: 2rem;
    opacity: 0.7;
  }
}

.line-head {

  background: #FAFCFF;
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: space-between;

  padding: 17px 20px;

  &__thumb {
    width: 42px;
    height: 42px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
    background-image: url('https://us.edu.pl/wp-content/uploads/Nieprzypisane/us.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__text {
    padding-left: 1rem;
    color: var(--main);
    opacity: .85;
    max-width: 600px;

    font-weight: 500;
    line-height: 1.5rem;
  }

  &__date {
    margin-left: auto;
  }
}

.line-body {

  padding: 17px 20px;


  display: flex;
  flex-direction: row;

  &__left {
    width: 80%;
    padding-right: 1.5rem;
  }

  &__right {
    width: 20%;
    padding-left: 2rem;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: var(--main);
      opacity: .2;
      content: '';
    }
  }

}

.line-right {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: 300;
    font-size: .85rem;
    line-height: 1.5rem;

    letter-spacing: 0.02em;
    text-transform: uppercase;
    opacity: 0.7;
  }

  &__value {

    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.2rem;
    /* identical to box height */


    /* black */

    color: #1B1B1B;

    opacity: 0.8;
  }
}

.metric {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  justify-content: space-between;

  &__item {
    margin: 0 12px 0 0;
    padding: 0;
    line-height: 1.8rem;
  }
}

.item {
  &--right {
    margin-left: auto;
  }
}
</style>