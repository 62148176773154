<template>
  <TemplatePage
      title="Pusta strona"
      :breadcrumb="[
          {title: 'Strona główna', link: '/', icon: 'home'},
          {title: 'Pusta strona', link: '/blank'},
        ]"
  >

    <br><br><br><br><br><br><br><br><br><br>
    <br><br><br><br><br><br><br><br><br><br>
    <br><br><br><br><br><br><br><br><br><br>
  </TemplatePage>
</template>

<script>
// @ is an alias to /src
import TemplatePage from "@/template/Page.vue";
import Page from "@/Extends/Page.vue";

export default {
  name: 'PagesBlank',

  extends: Page,
  components: {
    TemplatePage,
  }
}
</script>

<style>

</style>