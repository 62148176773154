<template>

  <div class="slider">

    <img class="slider__image" :src="info.image.url" :style="styles['image']"/>

    <div class="slider__info slider-info">
      <div class="slider-info__title" :style="styles['title']">
        {{ to(info,'title') }}
      </div>

      <a @click="linkClick(info.link)" :style="styles['link_name']">
        {{ to(info,'link_name') }}
      </a>

    </div>
  </div>
</template>

<script>
import SliderAbstract from "@/views/Base/Slider/Abstract.vue";
import LinkShowMore from "@/views/Base/Link/ShowMore.vue";

export default {
  name: "Slider1",
  components: {LinkShowMore},
  extends: SliderAbstract,
  props: {
    info: {
      default() {
        return {};
      }
    },
  },
  data: () => ({
    image: 'slider.jpg',
    info: {
      title: 'Uniwesytet Śląski w Katowicach',
      link: 'Czytaj',
    },
  })
}
</script>

<style scoped lang="scss">


.slider {
  position: relative;

  &:before {
    position: absolute;

    content: '';

    width: calc(100% - 30px);
    height: 30px;

    border-radius: 15px;

    background-color: var(--decoration);

    top: -15px;
    left: 15px;
    right: 15px;

    z-index: 1;
  }

  &__image {
    position: relative;
    z-index: 2;

    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;
    height: 350px;

    background-size: cover;

    border-radius: 12px;
    margin-bottom: 42px;

  }

  &__info {
    position: absolute;
    z-index: 3;
    width: 450px;
    height: 230px;
    bottom: -42px;

    max-width: 60%;
  }
}

.slider-info {

  background-color: white;
  backdrop-filter: blur(3px);
  border-radius: 0 12px 12px 12px;
  padding: 24px;

  text-align: left;

  &__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  &__link {
  }

  @media screen and (max-width: 972px) {

    &__title {
      font-size: 1.6rem;
    }

  }

}

</style>