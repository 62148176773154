<script>
import {defineComponent} from 'vue'
import Element from "@/components/Element.vue";

export default defineComponent({
  name: "BlockStep",
  extends: Element,
  props: {
    info: {
      default() {
        return {
          header: "How It Works",
          description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
          firstButton: "LEARN MORE",
          secondButton: "WATCH DEM",
          secondButtonIcon: "play_circle",
          steps: [{
            icon: "1",
            title: "Step One",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo"
          }, {
            icon: "2",
            title: "Step Two",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo"
          }, {
            icon: "3",
            title: "Step Three",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elitseddo"
          }]
        }
      }
    },
  }
})
</script>

<template>
  <div>
    <div class="description__container">
      <div class="description-container__content content">
        <div class="content__info info">
          <h1 class="info__header">{{to(this.info, 'header')}}</h1>
          <p class="info__description">{{to(this.info, 'description')}}</p>
        </div>

        <div class="content__buttons buttons">
          <div class="buttons__first-button">
            <p class="first-button__description">{{to(this.info, 'firstButton')}}</p>
          </div>

          <div class="buttons__second-button">
            <span class="material-symbols-outlined second-button__icon">{{to(this.info, 'secondButtonIcon')}}</span>
            <p class="second-button__description">{{to(this.info, 'secondButton')}}</p>
          </div>
        </div>
      </div>

      <div class="description-container__steps steps">
        <div class="steps__item item" v-for="step in info.items" :key="step.title">
          <div class="item__icon">
            <span class="material-symbols-outlined">{{step.icon}}</span>
          </div>

          <div class="item__content">
            <h2 class="item-content__header">{{to(step, 'name')}}</h2>
            <p class="item-content__description">{{to(step, 'text')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.description__container{
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  .content{
    flex-basis: 48%;
    max-width: 458px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 5% 0 0;

    .info{
      flex-basis: 50%;

      &__header{
        font-size: 2.78rem;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.1800002008676529px;
        text-align: left;
        margin: 0;
        color: (--title);
      }

      &__description{
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--text);
        margin: 0;
        padding: 1.5rem 0 4.25rem 0;
      }
    }

    .buttons{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;

      &__first-button{
        flex-basis: 48%;
        background-color: var(--block_light_bg);
        border-radius: 5px;
        color: var(--block_text);
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        .first-button__description{
          font-size: 0.78rem;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 1px;
          margin: 0;
          padding: 0.94rem 2.07rem 1rem 2.05rem;
        }
      }

      &__second-button{
        flex-basis: 48%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-around;
        border-radius: 5px;
        color: var(--link);
        margin: 0;
        padding: 0.75rem 2.61rem 0.75rem 2.29rem;

        &__icon{
          color: var(--icon);
        }

        .second-button__description{
          font-size: 0.78rem;
          font-weight: 700;
          line-height: 1;
          letter-spacing: 1px;
          margin: 0;
        }
      }
    }
  }

  .steps{
    max-width: 361px;
    flex-basis: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      margin: 1.5rem 0 0 0;

      &:first-child{
        margin: 0;
      }

      &__icon{
        flex-basis: 30%;
        max-width: 4.25rem;
        height: 4.25rem;
        border-radius: 50%;
        background-color: var(--block_light_bg);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
      }

      &__content{
        flex-basis: 70%;

        .item-content__header{
          font-size: 1.22rem;
          font-weight: 700;
          line-height: 27px;
          letter-spacing: -0.07920006662607193px;
          text-align: left;
          padding: 0 0 0.5rem 0;
          margin: 0;
          color: var(--title);
        }

        .item-content__description{
          font-size: 0.78rem;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: var(--text);
          margin: 0;
          padding: 0 0 1rem 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .description__container{
    align-items: center;
    flex-direction: column;

    .content{
      text-align: center;

      .info{
        text-align: center;

        &__header{
          text-align: center;
        }
      }
    }

    .steps{
      flex-direction: row;
      min-width: 100%;

      .item{
        flex-direction: column;

        .item-content__header{
          text-align: center;
        }

        .item-content__description{
          text-align: center;
          margin: 1% 3%;
        }
      }
    }
  }
}

@media only screen and (max-width: 839px) {
  .steps{
    flex-direction: column!important;
  }
}

.material-symbols-outlined {
  font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}
</style>