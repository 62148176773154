<template>
  <ShowGallery
      :can_zooming="true"
      :gallery="info.images"
      :object_fit="info.fit_content"
      :gallery_style="info && info.style ? info.style : 'block'"
  />
</template>

<script>
import Element from "@/components/Element.vue";
import ShowGallery from "@/Library/Show/Gallery.vue";

export default {
  name: "PageModelBlockGallery",
  components: {ShowGallery},
  extends: Element,
  computed() {},
  props: {

  }
}
</script>

<style scoped lang="scss">

</style>