<script>

import TitleAbstract from "@/views/Base/Title/Abstract.vue";

export default {
    name: "Title",
    extends: TitleAbstract,
    props: {
        info: {
            default() {
                return {};
            }
        }
    },
    computed: {

        title() {
            return this.info ? this.to(this.info, 'title') : null;
        },
        size() {
            return this.info && this.info.size ? parseInt(this.info.size) : 1;
        },
        tag() {
            return 'h' + this.size;
        },
        html() {
            return '<' + this.tag + '>' + this.title + '</' + this.tag + '>';
        },

        titleStyle() {
            let ret = {}

            ret.color = this.info.color

            return ret
        }
    }
}
</script>

<template>
    <h1 v-if="size === 1" :style="titleStyle" v-html="title"></h1>
    <h2 v-if="size === 2" :style="titleStyle" v-html="title"></h2>
    <h3 v-if="size === 3" :style="titleStyle" v-html="title"></h3>
    <h4 v-if="size === 4" :style="titleStyle" v-html="title"></h4>
    <h5 v-if="size === 5" :style="titleStyle" v-html="title"></h5>
    <h6 v-if="size === 6" :style="titleStyle" v-html="title"></h6>
</template>

<style scoped lang="scss">

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

</style>