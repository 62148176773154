<script>
import axios from "@/axios";
import {mapState} from "vuex";

/**
 * Abstrakt dla tworzenia tabel/wykresów
 */
export default {
    name: "AbstractDynamic",
    emits: ['updateValue'],
    data: () => ({
        is_edited: false,
        add_url: '/basedata/add',
        set_url: '/basedata/set',
        find_url: '/basedata/find',

        timeout_handler: null,
        timeout_ms: 1000, can_show: false
    }),
    methods: {

        /**
         * Aktualizuje wartości etykiet
         * @param value nowa wartość
         */
        updateValueLabels(value) {
            console.log(this.obj)
            let old_length = this.splitLabels?.length ?? 0;
            this.obj.labels = value;

            /**
             * Jeśli nowa długość etykiet jest dłuższa od starej
             * do dodaj wiersz
             */
            if (this.splitLabels.length > old_length)
                this.obj.rows.push(new Array(this.splitDataSeries?.length ?? 0).fill(''))

            /**
             * Jeśli krótsza to usuń wiersz
             */
            if (this.splitLabels.length < old_length)
                this.obj.rows.pop()
            this.updateObj()
            //this.$emit('updateValue', 'labels', this.obj.labels)
        },

        /**
         * Aktualizuje wartość w określonym wierszu i kolumnie
         * @param value wpisywana wartość
         * @param row numer wiersza
         * @param column numer kolumny
         */
        updateValueInRow(value, row, column) {
            this.obj.rows[row][column] = value;
            this.updateObj()
            //this.$emit('updateValue', 'rows', this.obj.rows)
        },

        /**
         * Zapisuje wykres/tabelę
         * @param obj
         * @returns {Promise<void>}
         */
        async add(obj) {
            let data_row = {obj: obj}
            let data = {name: obj.name, data: data_row}
            await axios.post(this.add_url, data).then(r => r).catch(e => console.log(e));
        },

        /**
         * Edytuje wykres/tabelę
         * @param obj
         * @returns {Promise<void>}
         */
        async edit(obj) {
            let data_row = {obj: obj}
            let data = {name: obj.name, data: data_row}
            await axios.post(this.set_url, data, {params: {id: this.id}}).then(r => r).catch(e => console.log(e));
        },

        /**
         * Wczytuje wykres/tabelę
         * @param id
         * @returns {Promise<void>}
         */
        async load(id) {
            /**
             * Aktualizacja stanu
             */
            this.stateUpdate('sendInfo');
            await axios
                .get(this.find_url, {params: {id: id, id_domain: this.domain_selected_id}})
                .then(response => {

                    /**
                     * Przypisanie danych
                     */
                    this.obj = response.data.data.data.obj;

                    /**
                     * Aktualizacja stanu
                     */
                    this.stateUpdate('sendSuccess');

                })
                .catch(error => {

                    /**
                     * Obsługa błędów
                     */
                    this.errorCatch(error);
                })
        },
        updateObj() {

            clearTimeout(this.timeout_handler);

            this.timeout_handler = setTimeout(() => {
                this.$emit('updateValue', this.obj);
            }, this.timeout_ms)

        },
    },
    mounted() {
        this.obj = {rows: [], widths: []}
    },
    unmounted() {
        this.$emit('updateValue', this.obj);
        clearTimeout(this.timeout_handler)
    },
    computed: {
        ...mapState('app', ['domain_selected_id']),
        /**
         * Zamienia etykiety rozdzielane ',' na tablicę
         * @returns {*}
         */
        splitLabels() {
            return this.obj.labels?.split(',');
        },

        /**
         * Zamienia serie danych rozdzielane ',' na tablicę
         * @returns {*}
         */
        splitDataSeries() {
            return this.obj.data_series?.split(',');
        },

        canShow() {
            let obj = this.obj
            if (obj === undefined || obj === null || obj.rows === undefined || obj.rows.length === 0)
                return false;
            return obj.rows.length > 0
        }
    },
    watch: {
        obj: {
            handler(newVal) {
                if (newVal !== undefined && newVal.data !== undefined) {
                    this.obj = newVal.data.obj;
                } else {
                    this.obj = newVal;
                }

                //this.can_show = this.obj.rows?.length > 0
            }
        },
        // deep: true, immediate: true
    }
}
</script>