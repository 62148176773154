<template>

    <ul class="menu" :class="{'menu--main': type === 'main', 'menu--sub': type === 'sub'}">
        <template v-if="items" v-for="item in currentItems">
            <li
                v-if="to(item, 'name')"
                class="menu__item"
                :class="{'menu__item--open': item.open}">

                <div class="menu__item-content" :style="{paddingLeft: deep * 15 + 'px'}">
                    <router-link :to="$root.routerPrefix + item.url" @click="emitMenuMobile(item, 'close')">{{ to(item, "name") }}</router-link>
                    <span v-if="isHasKids(item)" @click="emitMenuMobile(item, 'toggle')" class="material-symbols-outlined">{{ !item.open ? 'add' : 'remove' }}</span>
                </div>

                <SiteMenuStyleMobile
                    v-if="item.open"
                    :items="items"
                    :id_top_position="item.id"
                    type="sub"
                    @emitMenuMobile="emitMenuMobile"
                    :deep="deep + 1"
                    @updateSimulate="updateSimulate"/>
            </li>
        </template>
    </ul>

</template>

<script>

import Element from "@/components/Element.vue";

export default {
    name: "SiteMenuStyleMobile",
    props: {
        items: {
            default() {
                return null
            }
        },
        type: {
            default() {
                return "main"
            }
        },
        id_top_position: {
            default() {
                return null
            }
        },
        deep: {
            default() {
                return 1
            }
        }
    },
    extends: Element,
    emits: ["emitMenuMobile", "updateSimulate"],
    methods: {
        /**
         * Emitowanie kliknięć
         */
        emitMenuMobile(item, func) {
            this.$emit("emitMenuMobile", item, func)

            if (func === "close")
                this.myLinkClick(item.url)
        },

        /**
         * Sprawdza czy element menu zawiera podelementy
         * @param item element menu
         */
        isHasKids(item) {
            let ret = false
            let items = []

            items = this.items.filter(obj => obj.id_top_position === item.id)

            items.map(obj => {
                if (this.to(obj, "name"))
                    ret = true
            })

            return ret
        },

        myLinkClick(link) {
            if (link.code)
                this.linkClick(link)

            if (!link.code)
                this.updateSimulate('slug', link)
        },

        updateSimulate(slug, url) {
            this.$emit("updateSimulate", slug, url)
        }
    },
    computed: {
        /**
         * Zwraca aktualne elementy menu
         */
        currentItems() {
            return this.items.filter(item => item.id_top_position === this.id_top_position)
        }
    }
}
</script>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;

    &--sub {

        & .menu__item {

            & .menu__item-content {

                & > a, & .material-symbols-outlined {
                    color: var(--title_sub);
                }
            }
        }
    }

    &__item {
        user-select: none;

        &-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 15px;
            border-bottom: 1px solid var(--border_light);

            & > a, & .material-symbols-outlined {
                color: var(--link);
                cursor: pointer;
                padding: 15px;
                transition: all 0.25s ease 0s;
            }

            & > a {
                font-family: var(--title_font);
                font-size: 0.67rem;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.857px;
                text-transform: uppercase;
                margin: 0;
                text-align: left;
            }

            & > .material-symbols-outlined {
                font-size: 1.25rem;
            }
        }

        &--open {

        }
    }
}

</style>