<script>
import Element from "@/components/Element.vue";

export default {
  name: "ArticleTitle",
  extends: Element,
}
</script>

<template>
  <h1>{{ to(obj, 'title')}}</h1>

</template>

<style scoped lang="scss">

</style>