<template>
  <div class="hello">
    <div class="hello__title">{{ to(info, 'title') }}</div>
    <div class="hello__hand">👋</div>
    <div class="hello__text" v-html="parseText(info, 'text')"></div>
  </div>
</template>

<script>
import HelloAbstract from "@/views/Base/Hello/Abstract.vue";

export default {
  name: "Hello1",
  extends: HelloAbstract,
  computed() {

  },
}
</script>

<style scoped lang="scss">

.hello {
  text-align: center;
  margin: 0 auto;


  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    /* identical to box height, or 133% */


    /* black */
    color: var(--text);
    margin-bottom: 25px;
  }

  &__hand {
    margin-bottom: 25px;
    font-size: 2.5rem;
    line-height: 3rem;
  }

  &__text {

    font-size: 1.15rem;
    line-height: 1.5rem;
    opacity: .8;
    margin-bottom: 1.2rem;
  }

  &__sub-text {
    color: var(--main);
    text-decoration: underline;
  }
}

</style>