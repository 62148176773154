<script>
export default {
  name: "AppLoading"
}
</script>

<template>

  <div class="wait"></div>

</template>

<style scoped lang="scss">

.wait {
  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: 1050;

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

</style>