<template>
        <ul class="tabs">
            <li class="tabs__item tabs-item"
                :class="{'tabs-item--active': active_idx === idx}"
                v-for="(item, idx) in info.items"
                @click="select(item, idx)"

            >{{ item.name }}
            </li>
        </ul>
</template>

<script>
export default {
    name: "Tabs1",
    emits: ['select'],
    data: () => ({
        active_idx: 0,
        info: {
            items: [{
                name: 'Wszystkie',
            }, {
                name: 'Osoby prywatne',
            }, {
                name: 'Firmy',
            }, {
                name: 'Organizacje pozarządowe',
            }, {
                name: 'Samorządy',
            }]
        }
    }),
    methods: {
        select(item, idx) {

            this.active_idx = idx;
            this.$emit('select', item, idx)
        }
    }

}
</script>

<style scoped lang="scss">


.tabs {
  margin: 0;
  padding: 4px;
  list-style: none;
  display: flex;
  position: relative;

  clear: both;

  & > * {
    z-index: 2;
    position: relative;
  }

  &:before {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    content: '';
    border: 1px solid var(--main);
    border-radius: 10px;
    opacity: .3;

  }

  &__item {

  }
}

.tabs-item {
  margin: 0 6px 0 0;
  padding: 10px 12px 8px;

  text-transform: uppercase;
  border-radius: 6px;

  letter-spacing: 0.04em;

  font-size: 0.85rem;
  line-height: 0.85rem;

  cursor: pointer;
  user-select: none;

  color: var(--main);

  &--active {
    color: white;
    background-color: var(--main);
  }
}

</style>