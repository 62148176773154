<template>

    <div :id="`map${id}`" class="map">

        <div class="search" :class="{'search--show': show_search}">
            <div class="input-search rows__item rows__item--icon-left">
                <Row type="text" name="search_text" id="search_text" ref="search_text" :value="search_text"
                     placeholder="Podaj adres"
                     label="Adres"
                     :disabled="search_location_loading" @updateValue="updateValue"
                     @keydown.enter="setLocation()"
                     @click.stop.prevent
                />
                <span class="material-symbols-rounded" @click="setLocation()">search</span>
            </div>
        </div>

    </div>

</template>

<script>
import {tileLayerOffline, savetiles} from "leaflet.offline";
import {Control, Map} from "./leaflet-src";
import "leaflet.markercluster";
import {urlTemplate} from "./const";
import storageLayer from "./index/storageLayer";
import "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import axios from "axios";
import {useToast} from "vue-toastification";
import ControlTemp from "@/components/Map/Temp.vue";
import Row from "@/Library/Forms/Row.vue";
import ControllForm from "@/Library/Controll/Form.vue";

export default {
    name: "Map",
    components: {
        Row
    },
    props: {
        id: {
            default() {
                return ""
            }
        },
        markers: {
            default() {
                return null
            }
        },
        markers_categories: {
            default() {
                return null
            }
        },
        routes: {
            default() {
                return null
            }
        },
        routes_categories: {
            default() {
                return null
            }
        },
        marker_icon_type: {
            default() {
                return "divIcon"
            }
        },
        show_me: {
            default() {
                return false
            }
        },
        show_me_marker_icon_type: {
            default() {
                return "divIcon"
            }
        },
        show_me_marker_icon: {
            default() {
                return null
            }
        },
        show_me_marker_color: {
            default() {
                return null
            }
        },
        show_user_click: {
            default() {
                return false
            }
        },
        show_user_click_marker_icon_type: {
            default() {
                return "divIcon"
            }
        },
        show_user_click_marker_icon: {
            default() {
                return null
            }
        },
        show_user_click_marker_color: {
            default() {
                return null
            }
        },
        center: {
            default() {
                return "auto"
            }
        },
        show_search: {
            default() {
                return false
            }
        },
        set_current_location: {
            default() {
                return false
            }
        },
        download: {
            default() {
                return false
            }
        },
        default_search_city_name: {
            default() {
                return null
            }
        }
    },
    mixins: [ControllForm, ControlTemp],
    emits: ["markerClick", "routeClick", "mapClick", "emitLatLngAddress"],
    data: () => ({
        /**
         * Obiekt mapy
         */
        leafletMap: null,

        /**
         * Objekt warstwy bazowej mapy
         */
        baseLayer: null,

        /**
         * Warstwa bazowa dla wszystkich warstw markerów (grupuje markery)
         */
        markerClusterGroup: null,

        /**
         * Obiekt, który zawiera poszczegolne warstwy dla markerów każdej kategorii lun innych markerów
         */
        markersLayers: {},

        /**
         * Obiekt, który zawiera poszczegolne warstwy dla kadej z tras
         */
        routesLayers: {},

        /**
         * Ustawia czy jest potrzeba pokazywać na mapie div'a z przełącznikiem warstw
         */
        layer_switcher_show: false,

        /**
         * Służy do sprawdzania czy użytkownik kliknął na trasę
         */
        route_click: false,

        /**
         * Służy do zapisywania poprzedniego stany "kategorii markerów" (przed każdym kolejnym watchem, który nasłucuje na zmiany w kategoriach markerów)
         */
        prev_markers_categories: null,

        /**
         * Służy do zapisywania poprzedniego stany "tras" (przed każdym kolejnym watchem, który nasłucuje na zmiany w trasach)
         */
        prev_routes: null,

        /**
         * Przechowuje podany przez użytkownika adres do wyszukiwania
         */
        search_text: null,

        /**
         * Służy do ustawiania atrybutu "disabled" do inputu w trakcie wszukiwania
         */
        search_location_loading: false,

        added_layers: [],

        top_left_click: false,
        bottom_right_click: false,
    }),
    methods: {
        /**
         * Dodawanie przycisków, które są dostępne ma mapie
         * (powiększenie, zmniejszenie, pobieranie kafelków mapy, usuwanie kafelków mapy)
         */
        mapButtonsAdd() {
            /**
             * Zmiana przycisków powięszenia i pomniejszenia
             */
            document.querySelector(".leaflet-control-zoom-in").innerHTML = "<span class='material-symbols-rounded'>add</span>"
            document.querySelector(".leaflet-control-zoom-out").innerHTML = "<span class='material-symbols-rounded'>remove</span>"

            /**
             * Dodawanie przycisków do zapisywania kafelków w przeglądanym obszarze
             */
            const saveControl = savetiles(this.baseLayer, {
                /**
                 * Opcjonalne poziomy powiększenia do zapisania
                 * Dodaje na podstawie objektów w mapie "minZoom: 6" i "maxZoom: 18"
                 */
                zoomlevels: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
                alwaysDownload: false,
                confirm(layer, successCallback) {
                    if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
                        successCallback();
                    }
                },
                confirmRemoval(layer, successCallback) {
                    if (window.confirm('Remove all the tiles?')) {
                        successCallback();
                    }
                },
                saveText: "<span class='material-symbols-rounded'>download</span>",
                rmText: "<span class='material-symbols-rounded'>delete</span>",
            });

            if (this.download)
                saveControl.addTo(this.leafletMap);
        },

        /**
         * Wystylizowanie przycisków, które są dostępne ma mapie
         * (powiększenie, zmniejszenie, pobieranie kafelków mapy, usuwanie kafelków mapy)
         */
        mapButtonsStyle() {
            /**
             * Centrowanie obrazków (.material-symbols-rounded) w przyciskach
             */
            let buttonsClasses = ["a.leaflet-control-zoom-in", "a.leaflet-control-zoom-out", "a.savetiles", "a.rmtiles"]
            buttonsClasses.map(buttonClass => {
                let btn = document.querySelector(buttonClass)

                if (btn) {
                    btn.style.display = "flex"
                    btn.style.alignItems = "center"
                    btn.style.justifyContent = "center"
                    btn.querySelector("span.material-symbols-rounded").style.fontSize = "20px"
                }
            })
        },

        /**
         * Zdarzenia podczas zapisywania kafelków mapy
         */
        mapSaveTiles() {
            let progress;
            let total;

            let map = document.getElementById(`map${this.id}`)
            let mapLoadingDiv = document.createElement("div")

            this.baseLayer.on('savestart', (e) => {
                progress = 0;
                total = e._tilesforSave.length;

                /**
                 * Postęp ładowania mapy
                 */
                mapLoadingDiv.classList.add("map-loading")
                map.parentNode.appendChild(mapLoadingDiv)
            });

            this.baseLayer.on('loadtileend', () => {
                progress += 1;
                mapLoadingDiv.style.background = `linear-gradient(to right, rgba(0, 128, 0, 0.8) ${(progress / total) * 100}%, rgba(0, 0, 0, 0.1) 0)`
                console.log("download", `${(progress / total) * 100} %`)

                if (progress === total) {
                    mapLoadingDiv.style.background = `linear-gradient(to right, rgba(0, 128, 0, 0.8) 0, rgba(0, 0, 0, 0.1) 0)`
                    mapLoadingDiv.remove()
                }
            });
        },

        /**
         * Uzyskanie środka mapy
         */
        getMapBounds() {
            let points_arr = this.markers.map(point => [point.lat, point.lng])
            return new L.LatLngBounds(points_arr)
        },

        /**
         * Tworzenie mapy
         */
        mapCreate() {
            /**
             * Jeśli :center="{ lat: ..., lng: ... }", to ustawiamy centrum mapy na podstawie przekazywanego propsa
             */
            if (this.center !== "auto") {
                this.leafletMap.setView(
                    this.center,
                    13
                );
            }

            // ustawiamy lokalizację użytkownika
            if (this.set_current_location)
                this.getCurrentLatLng()

            /**
             * Jeśli :center="auto", to ustawiamy centrum mapy na podstawie wyliczanych współrzędnych
             */
            if (this.center === "auto") {
                this.leafletMap.fitBounds(this.getMapBounds())
            }

            /**
             * Niezbędne przyciski, do zarządzania mapą
             */
            this.mapButtonsAdd()
            this.mapButtonsStyle()


            /**
             * Jeśli "this.layer_switcher_show" jest ustawione na true to dodaję do mapy div'a z przelącznikiem warstw
             */
            if (this.layer_switcher_show) {
                /**
                 * Sterowanie przełącznikiem warstw (tutaj mamy warstwę która przechowuje kafelki mapy)
                 */
                const layerswitcher = new Control.Layers(
                    {
                        'osm (offline)': this.baseLayer,
                    },
                    null,
                    {
                        collapsed: false
                    }
                ).addTo(this.leafletMap);

                /**
                 * Dodajemy checkbox'a, który pokazuje załadowane kafelki mapy
                 */
                storageLayer(this.baseLayer, layerswitcher);
            }

            /**
             * Funkcja, która pozwala pobierać kafelki mapy
             */
            this.mapSaveTiles()

            // this.minMaxLanLng()
        },

        minMaxLanLng() {
            let map_div = document.getElementById(`map${this.id}`)
            let map_div_rect = map_div.getBoundingClientRect()

            let top_left_click = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
                clientX: map_div_rect.left,
                clientY: map_div_rect.top
            })

            this.top_left_click = true
            map_div.dispatchEvent(top_left_click)
            this.top_left_click = false

            let bottom_right_click = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
                clientX: map_div_rect.right,
                clientY: map_div_rect.bottom
            })

            this.bottom_right_click = true
            map_div.dispatchEvent(bottom_right_click)
            this.bottom_right_click = false
        },

        /**
         * Rysowanie markerów na mapie
         * @param markers_categories kategorie markerów
         */
        markersDraw(markers_categories) {
            // tworzę tablicę markerów bez duplikatów
            let filter_markers = []
            this.markers.map(marker => {
                let found_marker = filter_markers.find(obj => obj.id === marker.id)

                if (!found_marker)
                    filter_markers.push(marker)
            })


            /**
             * Warstwa dla markerów bez kategorii
             */
            this.markersLayers["layer_null"] = []

            /**
             * Tworzę tablicę, do której dodam markery z włączoną kategorią
             */
            let current_markers = filter_markers.filter(marker => marker.id_category === null)

            if (markers_categories) {
                markers_categories.map(category => {
                    /**
                     * W warstwie markerów "markersLayers" tworzę pustą warstę dla każdej kategorii markerów
                     */
                    this.markersLayers["layer_" + category.id] = []

                    /**
                     * Do tablicy "current_markers" dodaję wszystkie markery z włączoną kategorią
                     */
                    // console.log("this.markers", this.markers)
                    this.markers.map(marker => {
                        if (marker.id_category === category.id && category.show)
                            current_markers.push(marker)
                    })
                })
            }

            // console.log("current markers", current_markers)

            /**
             * Dla każdego "włączonego" markera ustawiam potrzebne dane
             */
            current_markers.map(obj => {

                /**
                 * Obrazek dla markera (:marker_icon_type="divIcon")
                 */
                let marker_icon = ""

                /**
                 * Color markera (:marker_icon_type="divIcon")
                 */
                let marker_color = "royalblue"

                /**
                 * Kategoria markera (:marker_icon_type="divIcon")
                 */
                let marker_category = this.markers_categories ? this.markers_categories.find(category => category.id === obj.id_category) : null

                /**
                 * Jeśli jest kategoria markera, to kolor i obrazek dla markera ustawiam na podstawie kategorii (:marker_icon_type="divIcon")
                 */
                if (marker_category) {
                    marker_icon = marker_category.icon ? marker_category.icon : ""
                    marker_color = marker_category.color ? marker_category.color : "#000"
                }

                /**
                 * Jeśli marker zawiera swój kolor, to ustawiamy mu jego kolor
                 */
                if (obj.color)
                    marker_color = obj.color

                /**
                 * Tworzymy wygłąd markera na podstawie "obrazek.png" (:marker_icon_type="icon")
                 *
                 * Opcje dla obrazka markera (:marker_icon_type="icon")
                 */
                let iconOptions = {
                    iconUrl: marker_category ? marker_category.icon : null,
                    iconSize: [39, 50],
                    iconAnchor: [20, 53]
                }

                /**
                 * Obrazek dla markera (:marker_icon_type="icon")
                 */
                let customIcon = L.icon(iconOptions);

                /**
                 * Opcje dla markera
                 */
                let markerOptions = {
                    id: obj.id,
                    title: obj.name,
                    clickable: true,
                    draggable: false,
                    icon: this.marker_icon_type === "icon" ? customIcon : L.divIcon({
                        iconSize: [0, 0],
                        html: "<div class='custom-marker' style='background-color: " + marker_color + "'><span class='material-symbols-rounded'>" + marker_icon + "</span></div>"
                    })
                }

                /**
                 * Tworzymy marker dla mapy
                 */
                let marker = L.marker([obj.lat, obj.lng], markerOptions)

                /**
                 * Ustawiamy kliknięcie dla markera, tutaj po kliknięciu emituję kliknięty marker (obiekt)
                 */
                marker
                    .on("click", () => {
                        this.$emit("markerClick", obj)
                    })

                let markers_layer = this.markersLayers["layer_" + (obj.id_category ? obj.id_category : "null")]
                // console.log("this.markersLayers", this.markersLayers)
                // console.log("markers_layer", markers_layer)
                // setTimeout(() => {
                //     console.log("markers_layer setTimeout()", markers_layer)
                //     console.log("markers_layer[0] setTimeout()", markers_layer[0])
                // }, 2000)
                // console.log("markers_layer[0]", markers_layer[0])
                // console.log("typeof markers_layer", typeof markers_layer)
                let found_marker = markers_layer.find(obj_found => obj_found.options.id === obj.id)
                // console.log("obj", obj)
                // console.log("found_marker", found_marker)
                // console.log("\n\n\n\n\n\n\n")

                /**
                 * Do odpowiedniej warstwy markerów dodaję marker
                 */
                if (!found_marker) {
                    markers_layer.push(marker)
                }
            })

            /**
             * Do bazowej warstwy markerów "markerClusterGroup" dodaję poszczegolne warstwy każdej kategorii markerów, które są przechowywane w "markersLayers"
             */
            for (const property in this.markersLayers) {
                this.clusterLayerAdd(property);
            }
        },

        clusterLayerAdd(property) {

            if (!this.added_layers.includes(property)) {
                this.added_layers.push(property)
                this.markerClusterGroup.addLayers(this.markersLayers[property]);

            }

        },

        clusterLayerRemove(property) {


            if (this.added_layers.includes(property)) {
                this.markerClusterGroup.removeLayers(this.markersLayers[property])

                this.added_layers = this.added_layers.filter(layer => layer !== property)
            }

        },

        /**
         * Rysowanie tras na mapie
         * @param routes trasy
         */
        routesDraw(routes) {

            routes.map(async (route, index) => {
                /**
                 * Jeśli warstwa z trasą istnieje && wartość "show"==="true" i nie ma zmian w punktach - to dodajemy warstwę z trasą do mapy (włączamy)
                 */
                if (this.routesLayers["layer_" + route.id] && route.show && JSON.stringify(this.routesLayers["layer_" + route.id].options.prev_route.points) === JSON.stringify(route.points))
                    this.leafletMap.addLayer(this.routesLayers["layer_" + route.id])

                /**
                 * Jeśli warstwa z trasą istnieje && wartość "show"==="false" i nie ma zmian w punktach - to usuwamy warstwę z trasą z mapy (wyłączamy)
                 */
                if (this.routesLayers["layer_" + route.id] && !route.show && JSON.stringify(this.routesLayers["layer_" + route.id].options.prev_route.points) === JSON.stringify(route.points))
                    this.leafletMap.removeLayer(this.routesLayers["layer_" + route.id])

                /**
                 * Jeśli warstwy z trasą jeszcze nie ma lub aktualne punkty różnią się od punktów poprzednich - no to musimy dodać/zaktualizować taką warstwę z trasą
                 */
                if (!this.routesLayers["layer_" + route.id] || JSON.stringify(this.routesLayers["layer_" + route.id].options.prev_route.points) !== JSON.stringify(route.points)) {
                    /**
                     * Jeśli taka warstwa z trasą już istnieje - no to usuwamy warstwę
                     */
                    if (this.routesLayers["layer_" + route.id])
                        this.leafletMap.removeLayer(this.routesLayers["layer_" + route.id])

                    /**
                     * Jeśli aktualna liczba punktów w trasie jest "0", to usuwany trasę z propsów "routes"
                     */
                    if (route.points.length === 0)
                        this.routes.splice(index, 1)

                    /**
                     * Punkty do rysowania trasy
                     */
                    let points_to_draw = route.points

                    /**
                     * Jeśli nie mamy połączenia z internetem i route.type === "computed", to musimy pobrać wcześniej obliczone
                     * punkty trasy, które zostały zapisane do localStorage (temp["routes_computed"]) w postaci { id: route.id, points: [obliczone punkty] }
                     */
                    if (!this.$root.internetConnect && route.type === "computed") {
                        points_to_draw = this.getTempElement("routes_computed", []).find(obj => obj.id === route.id).points
                    }

                    /**
                     * Jeśli route.type === "computed" to musimy obliczyć niezbędne punkty do rysowania (+ jeśli mamy połączenie z Internetem)
                     */
                    if (this.$root.internetConnect && route.type === "computed" && route.points.length > 1) {
                        let str = route.points.map(point => point.lng + "," + point.lat).join(";")

                        let url = 'https://router.project-osrm.org/route/v1/driving/'

                        let params = {
                            overview: "full",
                            geometries: "geojson",
                            steps: true
                        }

                        let data = await axios.get(url + str, {params: params}).then(response => response.data)

                        points_to_draw = data.routes[0].geometry.coordinates.map(element => ({
                            lat: element[1],
                            lng: element[0]
                        }))

                        /**
                         * Aktualizujemy wyliczone punkty dla konkretnej trasy w localStorage
                         */
                        this.addToTempElement("routes_computed", [{id: route.id, points: points_to_draw}])
                    }

                    /**
                     * Do objektu "routesLayers" który zawiera wszystkie warstwy z trasami, dodajemy warstwę z narysowaną trasą za pomocą "L.polyline"
                     */
                    this.routesLayers["layer_" + route.id] = L.polyline(points_to_draw, {
                        color: route.color,
                        weight: route.weight,
                        prev_route: JSON.parse(JSON.stringify(route)),
                    }).on("click", (e) => {
                        /**
                         * Ustawiam zmienną "route_click" na "true", to oznacza że użytkownik kliknął na trasę
                         */
                        this.route_click = true

                        /**
                         * Za 250 ms ustawiam z powrotem zmienną "route_click" na "false"
                         */
                        setTimeout(() => {
                            this.route_click = false
                        }, 250)

                        this.$emit("routeClick", route)
                    })

                    /**
                     * Do mapy dodajemy warstwę z narysowaną trasą
                     */
                    this.leafletMap.addLayer(this.routesLayers["layer_" + route.id])

                    /**
                     * Aktualizujemy trasę w localStorage
                     */
                    this.addToTempElement("routes", [route])
                }
            })
        },

        /**
         * Pokazywanie użytkownika na mapie
         */
        showMe() {
            /**
             * Warstwa, która zawiera marker użytkownika
             */
            this.markersLayers["layer_show_me"] = []

            /**
             * Obrazek dla markera (:show_me_marker_icon_type="divIcon")
             */
            let marker_icon = this.show_me_marker_icon ? this.show_me_marker_icon : "person"

            /**
             * Color markera (:show_me_marker_icon_type="divIcon")
             */
            let marker_color = this.show_me_marker_color ? this.show_me_marker_color : "rgb(247, 11, 49)"

            /**
             * Tworzymy wygłąd markera na podstawie "obrazek.png" (:show_me_marker_icon_type="icon")
             *
             * Opcje dla obrazka markera (:show_me_marker_icon_type="icon")
             */
            let iconOptions = {
                iconUrl: this.show_me_marker_icon,
                iconSize: [39, 50],
                iconAnchor: [20, 53]
            }

            /**
             * Obrazek dla markera (:icon_type="icon")
             */
            let customIcon = L.icon(iconOptions);

            /**
             * Opcje dla markera
             */
            let markerOptions = {
                title: "User Marker",
                clickable: true,
                draggable: false,
                icon: this.show_me_marker_icon_type === "icon" ? customIcon : L.divIcon({
                    iconSize: [0, 0],
                    html: "<div class='custom-marker' style='background-color: " + marker_color + "'><span class='material-symbols-rounded'>" + marker_icon + "</span></div>"
                })
            }

            /**
             * Otrzymanie współrzędnych użytkownika
             */
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    /**
                     * Tworzymy marker dla mapy
                     */
                    let marker = L.marker([position.coords.latitude, position.coords.longitude], markerOptions)

                    /**
                     * Ustawiamy kliknięcie dla markera, tutaj po kliknięciu emituję współrzędne markera jako obiekt ({lat, lng})
                     */
                    marker
                        .on("click", () => {
                            this.$emit("markerClick", {lat: position.coords.latitude, lng: position.coords.longitude})
                        })

                    /**
                     * Do odpowiedniej warstwy markerów dodaję marker
                     */
                    this.markersLayers["layer_show_me"].push(marker)

                    /**
                     * Do bazowej warstwy markerów "markerClusterGroup" dodaję warstwę z markerem użytkownika
                     */
                    // this.markerClusterGroup.addLayers(this.markersLayers["layer_show_me"]);
                    this.clusterLayerAdd('layer_show_me')
                })

            } else {
                console.log("#error -> 'get_location'")
            }
        },

        /**
         * Dodawanie markera po kliknięciu w dowolnym miejscu na mapie
         * @param lat współrzędne klikniętego miejsca ma mapie
         * @param lng współrzędne klikniętego miejsca ma mapie
         */
        showUserClick(lat, lng) {
            /**
             * Jeśli warstwa markerów "layer_show_user_click" zawiera jakieś markery, no to usuwam tą warstwę z warstwy bazowej (markerClusterGroup)
             */
            if (this.markersLayers["layer_show_user_click"]) {
                // this.markerClusterGroup.removeLayers(this.markersLayers["layer_show_user_click"])
                this.clusterLayerRemove('layer_show_user_click');
            }

            /**
             * Warstwa, która zawiera marker(y) klikniętego miejsca na mapie
             */
            this.markersLayers["layer_show_user_click"] = []

            /**
             * Obrazek dla markera (:show_user_click_marker_icon_type="divIcon")
             */
            let marker_icon = this.show_user_click_marker_icon ? this.show_user_click_marker_icon : "my_location"

            /**
             * Color markera (:show_user_click_marker_icon_type="divIcon")
             */
            let marker_color = this.show_user_click_marker_color ? this.show_user_click_marker_color : "dimgrey"

            /**
             * Tworzymy wygłąd markera na podstawie "obrazek.png" (:show_user_click_marker_icon_type="icon")
             *
             * Opcje dla obrazka markera (:show_user_click_marker_icon_type="icon")
             */
            let iconOptions = {
                iconUrl: this.show_user_click_marker_icon,
                iconSize: [39, 50],
                iconAnchor: [20, 53]
            }

            /**
             * Obrazek dla markera (:show_user_click_marker_icon_type="icon")
             */
            let customIcon = L.icon(iconOptions);

            /**
             * Opcje dla markera
             */
            let markerOptions = {
                title: "User Click Marker",
                clickable: true,
                draggable: false,
                icon: this.show_user_click_marker_icon_type === "icon" ? customIcon : L.divIcon({
                    iconSize: [0, 0],
                    html: "<div class='custom-marker' style='background-color: " + marker_color + "'><span class='material-symbols-rounded'>" + marker_icon + "</span></div>"
                })
            }

            /**
             * Tworzymy marker dla mapy
             */
            let marker = L.marker([lat, lng], markerOptions);

            /**
             * Ustawiamy kliknięcie dla markera, tutaj po kliknięciu emituję współrzędne klikniętego miejsca na mapie
             */
            marker
                .on("click", () => {
                    this.$emit("markerClick", {lat: lat, lng: lng})

                    /**
                     * Również po kliknięciu na marker - usuwam go z mapy
                     */
                    // this.markerClusterGroup.removeLayers(this.markersLayers["layer_show_user_click"])
                    this.clusterLayerRemove('layer_show_user_click');

                })

            /**
             * Do odpowiedniej warstwy markerów dodaję marker
             */
            this.markersLayers["layer_show_user_click"].push(marker)

            /**
             * Do bazowej warstwy markerów "markerClusterGroup" dodaję warstwę z otrzymanym markerem
             */
            // this.markerClusterGroup.addLayers(this.markersLayers["layer_show_user_click"]);
            this.clusterLayerAdd('layer_show_user_click')

        },

        /**
         * Aktualizowanie wartości otrzymywanych z komponentu "Row"
         * @param name nazwa
         * @param value wartość
         */
        updateValue(name, value) {
            this.search_text = value
        },

        setCenter(lat, lng) {
            if (lat && lng && !Number.isNaN(lat) && !Number.isNaN(lng))
                this.leafletMap.setView({lat: lat, lng: lng}, 17)
        },

        /**
         * Ustawianie (wyszukiwanie) lokalizacji na podstawie podanego adresu od użytkownika
         */

        setLocation(search_text, config = {}) {

            const toast = useToast()

            this.search_location_loading = true

            let draw_marker = config.draw_marker ? config.draw_marker : false;

            search_text = search_text ? search_text : this.search_text

            let url = "https://nominatim.openstreetmap.org/search.php?q=" + search_text + "&polygon_geojson=1&format=jsonv2"

            axios
                .get(url)
                .then(response => {

                    setTimeout(() => {
                        let address = response.data[0]

                        if (address && address.lat && address.lon) {
                            this.setCenter(address.lat, address.lon)
                            // this.leafletMap.setView({lat: address.lat, lng: address.lon}, 17)

                            if (draw_marker)
                                this.showUserClick(address.lat, address.lon);

                        }

                        this.search_location_loading = false;
                    }, 1000)

                })
                .catch(error => {
                    this.search_location_loading = false

                    toast.error("Błąd przy pobieraniu lokalizacji", {
                        timeout: 2000
                    })
                })

        },

        /**
         * Pobieranie aktualnych współrzędnych użytkownika (przy załadowaniu mapy)
         */
        getCurrentLatLng() {

            const toast = useToast()

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.setLatLngLocation);
            } else {
                this.my_location_loading = false

                toast.error("Błąd przy pobieraniu aktualnych współrzędnych użytkownika", {
                    timeout: 2000
                })
            }
        },

        /**
         * Ustawienie lokalizacji mapy na podstawie współrzędnych
         * @param position zawiera współrzędne
         */
        setLatLngLocation(position) {

            let lat = position.coords.latitude
            let lng = position.coords.longitude

            if (lat && lng) {
                this.leafletMap.setView({lat: lat, lng: lng}, 17)
            }

            const toast = useToast()

            let headers = {
                "accept-language": "pl"
            }

            axios
                .get("https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=" + lat + "&lon=" + lng, {headers: headers})
                .then(response => {

                    let address = response.data.address
                    let coords = response.data

                    this.showUserClick(lat, lng)

                    this.$emit("emitLatLngAddress", {
                        ...address,
                        ...{lat: coords.lat, lng: coords.lon}
                    })

                })
                .catch(error => {
                    toast.error("Błąd przy pobieraniu danych o lokalizacji", {
                        timeout: 2000
                    })
                })

        },

    },
    watch: {
        "$props.markers": {
            handler(newValue, oldValue) {
                // console.log("props new markers")
                this.markersDraw(this.markers_categories)
            },
            deep: true
        },
        "$props.markers_categories": {
            handler(newValue, oldValue) {
                /**
                 * Do tablicy "changed_markers_categories" zapiszę tylko kategorie markerów, w których nastąpiła jakakolwiek zmiana
                 */
                let changed_markers_categories = []
                newValue.map(category => {
                    if (!this.prev_markers_categories.find(obj => JSON.stringify(obj) === JSON.stringify(category)))
                        changed_markers_categories.push(category)
                })

                this.prev_markers_categories = JSON.parse(JSON.stringify(newValue))

                changed_markers_categories.map(category => {
                    /**
                     * Jeśli kategoria markerów ma ustawione "show" na true, no to dodajemy warstwę z markerami do bazowej warstwy markerów (markerClusterGroup)
                     */
                    if (category.show) {
                        // this.markerClusterGroup.addLayers(this.markersLayers["layer_" + category.id])
                        this.clusterLayerAdd('layer_'+category.id)
                    }

                    /**
                     * Jeśli kategoria markerów ma ustawione "show" na false, no to usuwamy warstwę z markerami z bazowej warstwy markerów (markerClusterGroup)
                     */
                    if (!category.show) {
                        // this.markerClusterGroup.removeLayers(this.markersLayers["layer_" + category.id])
                        this.clusterLayerRemove('layer_'+category.id);

                    }
                })
            },
            deep: true
        },
        "$props.routes": {
            handler(newValue, oldValue) {
                /**
                 * Do tablicy "changed_routes" zapiszę tylko trasy, w których nastąpiła jakakolwiek zmiana
                 */
                let changed_routes = []
                newValue.map(route => {
                    if (!this.prev_routes.find(obj => JSON.stringify(obj) === JSON.stringify(route)))
                        changed_routes.push(route)
                })

                this.prev_routes = JSON.parse(JSON.stringify(newValue))

                this.routesDraw(changed_routes)
            },
            deep: true
        }
    },
    mounted() {
        /**
         * Tworzymy obiekt mapy
         */
        this.leafletMap = new Map((`map${this.id}`), {
            gestureHandling: true
        })
            .on("click", (e) => {
                /**
                 * Dodatkowe funkcje po kliknięciu w dowolnym miejscu na mapie, jeśli użytkownik nie klika na trasę
                 */
                if (!this.route_click) {
                    let click_type = "default"

                    if (this.top_left_click)
                        click_type = "top_left"

                    if (this.bottom_right_click)
                        click_type = "bottom_right"

                    /**
                     * Emituję kliknięcie w dowolnym miejscu na mapie
                     */
                    this.$emit("mapClick", e.latlng.lat, e.latlng.lng, click_type)

                    /**
                     * Jeśli "show_user_click" jest ustawione na true, to wywołuję metodę "showUserClick"
                     */
                    if (this.show_user_click)
                        this.showUserClick(e.latlng.lat, e.latlng.lng)
                }
            })

        /**
         * Tworzymy warstwę bazową mapy (zawira kafelki mapy)
         * Warstwa bazowa offline, użyje źródła offline, jeśli będzie dostępne
         */
        this.baseLayer = tileLayerOffline(urlTemplate, {
            attribution: 'Map data {attribution.OpenStreetMap}',
            subdomains: 'abc',
            minZoom: 6,
            maxZoom: 18,
        }).addTo(this.leafletMap);

        /**
         * Tworzymy warstwę bazową dla wszystkich warstw markerów (grupuje markery)
         */
        this.markerClusterGroup = L.markerClusterGroup().addTo(this.leafletMap)

        /**
         * Tworzymy mapę za pomocą metody "mapCreate"
         */
        this.mapCreate()

        /**
         * Jeśli props "show_me" ustawiony na true, to dodajemy do mapy markera z lokalizacją użytkownika
         */
        if (this.show_me)
            this.showMe()

        /**
         * Zapisuję pierwszy stan "kategorii markerów" (przed watchem, który nasłucuje na zmiany w kategoriach markerów)
         */
        this.prev_markers_categories = JSON.parse(JSON.stringify(this.markers_categories)) || []

        /**
         * Po utworzeniu mapy dodajemy do niej markery (jeśli są przekazywane przez propsy)
         */
        // if (this.markers)
        //     this.markersDraw(this.markers_categories)

        /**
         * Zapisuję pierwszy stan "tras" (przed watchem, który nasłucuje na zmiany w trasach)
         */
        this.prev_routes = JSON.parse(JSON.stringify(this.routes)) || []

        /**
         * Po utworzeniu mapy dodajemy do niej trasy (jeśli są przekazywane przez propsy)
         */
        if (this.routes)
            this.routesDraw(this.routes)

        /**
         * Jeśli przekazujemy domyślną nazwę miasta do wyszukiwania, to adrazu przypisujemy nazwę do tekstu do wyszukiwania
         */
        if (this.default_search_city_name)
            this.search_text = this.default_search_city_name + ", "
    }
}
</script>

<style scoped lang="scss">

.map {
    height: 100%;
    z-index: 1;

    position: relative;
}

.search {
    position: absolute;
    top: 10px;
    left: calc(50% - calc(248px / 2));
    z-index: 999;

    //opacity: 0;
    //visibility: hidden;
    transform: translateY(calc(-100% - 10px));
    transition: all 0.25s ease 0s;

    &--show {
        //opacity: 1;
        //visibility: visible;
        transform: translateY(0);
    }
}

</style>