<script>

import Element from "@/components/Element.vue";

export default {
    name: "SearchItem",
    props: {
        item: {
            default() {
                return null
            }
        },
    },
    extends: Element,
    emits: ["itemClick"],
    computed: {
        lang() {
            return localStorage.getItem('lang')
        }
    }
}

</script>

<template>
    
    <router-link :to="item.slugs[lang] ?? './'" class="item">
        <div class="item__image" :style="{backgroundImage: `url(${item.thumb})`}"></div>
        <div class="item__text">
            <h1 class="text__title">{{ item.title }}</h1>
            <p class="text__description" v-html="item.text"></p>
        </div>
    </router-link>

</template>

<style scoped lang="scss">

a {
    text-decoration: none;
    color: var(--link)
}

.item {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--border);

    cursor: pointer;
    display: flex;
    overflow: hidden;

    &:hover {

        & .item {

            &__image {

            }

            &__text {

            }
        }
    }

    &__image {
        min-width: 400px;
        width: 400px;
        height: 100%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        z-index: -1;
        transition: all 0.25s ease 0s;
    }

    &__text {
        width: calc(100% - 400px);
        background: rgba(0, 0, 0, 0.6);
        padding: 30px;
        transition: all 0.25s ease 0s;
    }
}

.text {

    &__title, &__description {
        text-align: left;
    }

    &__title {
        font-family: var(--title_font);
        color: var(--block_dark_title);
        margin-top: 0;
        margin-bottom: 12px;
    }

    &__description {
        font-family: var(--text_font);
        color: var(--block_dark_text);
        margin: 0;

        max-height: calc(300px);
        overflow: hidden;
    }
}

</style>