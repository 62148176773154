<script>
import MenuAbstract from "@/views/Base/Menu/Abstract.vue";

export default {
    name: "MenuDropdown",
    extends: MenuAbstract,
    emits: ['click'],
    props: {
        name: {
            default() {
                return null
            }
        },
        items: {
            default() {
                return []
            }
        },
        selected: {
            default() {
                return null
            }
        },
        config: {
            default() {
                return {}
            }
        }
    },
    data: () => ({
        is_hover: false
    }),
    methods: {
        linkClick(item) {
            this.$emit("click", item.value, item)
        },

        mouseover() {
            this.is_hover = true

            let dropdown = this.$refs.dropdown
            let items = dropdown.querySelectorAll(".item__name")

            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let item = items[i]

                    setTimeout(() => {
                        item.style["transform"] = "translateX(0px)"
                    }, i * 100)
                }
            }
        },

        mouseleave() {
            this.is_hover = false

            let dropdown = this.$refs.dropdown
            let items = dropdown.querySelectorAll(".item__name")

            setTimeout(() => {
                if (items.length > 0 && !this.is_hover) {
                    for (let i = 0; i < items.length; i++) {
                        let item = items[i]
                        item.style["transition"] = "unset"
                        item.style["transform"] = "translateX(-50px)"
                        item.style["transition"] = "all 0.5s ease 0s"
                    }
                }
            }, 250)
        }
    },
    computed: {
        nameShow() {
            if (this.name)
                return this.name;

            let itemObj = this.itemsAll.find(item => item.value === this.selected)

            return itemObj?.name
        },

        selectedShow() {
            let ret = null

            ret = this.items.find(item => (item.value === this.selected) || !item.value)

            return ret?.name
        }
    },
}
</script>

<template>

    <div class="dropdown no-width-100-percent" :class="{'dropdown--left': config.float === 'left', 'dropdown--no-padding': config.no_padding}" ref="dropdown" @mouseover="mouseover" @mouseleave="mouseleave">
        <div class="dropdown__header header">
            <p class="header__name">{{ nameShow }}</p>
            <p class="header__selected">{{ selectedShow }}</p>
            <span class="header__arrow material-symbols-rounded">arrow_drop_down</span>
        </div>

        <ul class="dropdown__list list">
            <template v-for="item in items">
                <li v-if="linkOut(item.value)" class="list__item item" :class="{'item--selected': item.value === this.selected}">
                    <a class="item__name">{{ item.name }}</a>
                </li>

                <li v-if="linkLocal(item.value)" class="list__item item" :class="{'item--selected': item.value === this.selected}">
                    <router-link :to="item.value" class="item__name">{{ item.name }}</router-link>
                </li>

                <li v-if="emit(item.value)" @click="linkClick(item)" class="list__item item" :class="{'item--selected': item.value === this.selected}">
                    <span class="item__name">{{ item.name }}</span>
                </li>
            </template>
        </ul>
    </div>

</template>

<style scoped lang="scss">

.dropdown {
    position: relative;
    width: fit-content;

    &__header {

    }

    &__list {

    }

    &:hover {

        & > .list {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            pointer-events: all;
        }

        & .header__arrow {
            transform: rotate(-90deg);
        }
    }

    &--left {

        & > .list {
            right: unset;
            left: 0;
        }
    }

    &--no-padding {

        & .header {
            padding: 0 9px 0 16px;
        }
    }
}

.header {
    display: flex;
    align-items: end;
    column-gap: 9px;

    padding: 20px 9px 20px 16px;

    cursor: pointer;

    &__name, &__selected {
        font-family: var(--title_font);
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        margin: 0;
        color: var(--link);
    }

    &__name {
        font-weight: 600;

    }

    &__selected {
        font-weight: 400;
        font-size: 8px;
        background: var(--block_light_bg);
        padding: 0 6px;
        border-radius: 6px;
    }

    &__arrow {
        color: var(--link);
    }

    &::before {
        display: none;
    }
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    transition: all 0.25s ease 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);

    border: 1px solid var(--border_light);
    overflow: hidden;
    pointer-events: none;

    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99999;

    &__item {

    }
}

.item {
    font-family: var(--title_font);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin: 0;
    padding: 20px 16px;
    background: var(--bg);
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    cursor: pointer;

    transition: all 0.25s ease 0s;

    &:hover {
        background: var(--block_light_bg);
    }

    &__name {
        display: block;
        transform: translateX(-50px);
        transition: all 0.5s ease 0s;
        font-weight: 400;
        text-align: left;
    }

    &--selected {

        & .item {

            &__name {
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }
}

</style>