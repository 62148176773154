<script>
import Element from "@/components/Element.vue";

export default {
    name: "BlockStep2",
    extends: Element,
    props: {
        info: {
            default() {
                return {
                    items: [
                        {
                            title: "Step One",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
                        },
                        {
                            title: "Step Two",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
                        },
                        {
                            title: "Step Three",
                            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
                        }
                    ]
                }
            }
        },
    }
}
</script>

<template>

    <ul class="list">
        <template v-for="item in info.items">
            <li class="list__item item" v-if="to(item, 'title') || parseText(item, 'description')">
                <h1 class="item__title">{{ to(item, "title") }}</h1>
                <p class="item__description" v-html="parseText(item, 'description')"></p>
            </li>
        </template>
    </ul>

</template>

<style scoped lang="scss">

.list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 30px;

    &__item {
        margin-left: 0;
    }

    @media screen and (max-width: 1440px) {

    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}

.item {
    border-radius: 10px;
    border: 1px solid var(--border);
    padding: 48px 24px;

    &__title, &__description {
        font-style: normal;
        line-height: normal;
        text-align: center;
        margin-top: 0;
    }

    &__title {
        font-family: var(--title_font);
        font-size: 1.22rem;
        font-weight: 700;
        margin-bottom: 8px;
        color: var(--title);
    }

    &__description {
        font-family: var(--text_font);
        font-size: 0.89rem;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--text);
    }
    @media screen and (max-width: 767px) {
        padding: 48px 24px 24px 24px;
    }
}

.content--l, .content--sm {

}

.content--xs {

    & .list {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    & .item {
        padding: 48px 24px 24px 24px;
    }
}

</style>