<template>

    <ul
        class="menu"
        :class="{'menu--main': type === 'main', 'menu--sub': type === 'sub', 'menu--2': style === 'type_2', 'menu--3': style === 'type_3'}">
        <template v-if="items" v-for="item in currentItems">
            <li class="menu__item" v-if="to(item, 'name')">
                <router-link
                    :to="$root.routerPrefix + item.url"
                    class="item"
                    :class="{'item--active': ($root.routerPrefix + item.url) === $route.path,
                             'item--no-arrow': !isHasKids(item),
                             'item--divide': config.divide}">
                    <p class="item__text" :style="{color: info.text_color}">{{ to(item, "name") }}</p>
                    <span v-if="isHasKids(item)" class="item__arrow material-symbols-rounded">arrow_drop_down</span>
                </router-link>

                <SiteMenuStyleDesktop
                    v-if="isHasKids(item)"
                    :items="items"
                    :id_top_position="item.id"
                    :config="config"
                    @updateSimulate="updateSimulate"
                    type="sub"/>

            </li>
        </template>
    </ul>

</template>

<script>

import Element from "@/components/Element.vue";

export default {
    name: "SiteMenuStyleDesktop",
    props: {
        items: {
            default() {
                return null
            }
        },
        type: {
            default() {
                return "main"
            }
        },
        id_top_position: {
            default() {
                return null
            }
        },
        style: {
            default() {
                return "type_1"
            }
        },
        simulate: {
            default() {
                return {}
            }
        },
        config: {
            default() {
                return {}
            }
        },
        info: {
            default() {
                return {}
            }
        }
    },
    extends: Element,
    emits: ["updateSimulate"],
    methods: {
        /**
         * Sprawdza czy element menu zawiera podelementy
         * @param item element menu
         */
        isHasKids(item) {
            let ret = false
            let items = []

            items = this.items.filter(obj => obj.id_top_position === item.id)

            items.map(obj => {
                if (this.to(obj, "name"))
                    ret = true
            })

            return ret
        },

        updateSimulate(slug, url) {
            this.$emit("updateSimulate", slug, url)
        }
    },
    computed: {
        /**
         * Zwraca aktualne elementy menu
         */
        currentItems() {
            return this.items.filter(item => item.id_top_position === this.id_top_position)
        }
    }
}
</script>

<style scoped lang="scss">

a {
    text-decoration: none;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
        column-gap: 15px;
    }

    &--sub {
        display: block;
        width: 250px;
        height: fit-content;
        position: absolute;
        top: 90%;
        left: 0;
        opacity: 0;
        transform: translateY(10px);
        visibility: hidden;
        background: var(--bg);
        border-top: 4px solid var(--border);
        box-shadow: 0 10px 15px rgba(25,25,25,.1);

        transition: all 0.25s ease 0s;
        z-index: 999;

        pointer-events: none;

        & > .menu__item {
            border-bottom: 1px solid var(--border_light);

            &:last-child {
                border-bottom: none;
            }

            & > .item {
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    top: calc(50% - (3px / 2));
                    left: 16px;
                    width: 0;
                    height: 3px;
                    border-radius: 2px;
                    background: var(--border);
                    transition: all 0.25s ease 0s;
                }

                &:hover {

                    & .item {

                        &__text {
                            transform: translateX(16px);
                        }
                    }

                    &::before {
                        width: 10px;
                    }
                }
            }
        }
    }

    &--2, &--3 {

        & .item--active {

            &::after {
                position: absolute;
                content: "";
                bottom: 2px;
                left: 0;
                width: 100%;
                height: 4px;
                background: var(--border);
            }
        }

        & .menu--sub {

            & .item--active {

                &::after {
                    display: none;
                }
            }
        }
    }

    &--3 {
        column-gap: unset;

        & .menu__item:last-child {

            & > .item {
                border-right: 1px solid var(--border_light);
            }
        }

        & .item {
            border: 1px solid var(--border_light);
            border-right: none;

            &::after {
                bottom: 0;
            }
        }

        & .menu--sub {

            & .item {
                border: none!important;
            }
        }
    }

    &:has(.item--divide) {
        column-gap: 0;

        & .menu__item:last-child {

            & > .item {

                &::after {
                    display: none;
                }
            }
        }
    }


    &__item {
        position: relative;
        margin-left: 0;
        min-width: fit-content;

        &:hover {

            & .item {

                &__arrow {
                    transform: rotate(-90deg);
                }
            }

            & > .menu--sub {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }
}

.item {
    display: flex;
    align-items: end;
    column-gap: 9px;

    padding: 25px 9px 24px 16px;
    transition: all 0.25s ease 0s;
    cursor: pointer;

    &__text, &__arrow {
        color: var(--link);
    }

    &__text {
        font-family: var(--title_font);
        font-size: 0.89rem;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.857px;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0;

        transition: all 0.25s ease 0s;
    }

    &__arrow {
        display: flex;
        margin: -2px 0;
        transition: all 0.25s ease 0s;
    }

    &--no-arrow {
        padding: 25px 16px 24px 16px;
    }

    &--divide {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            right: calc(0px - (2px / 2));
            top: calc(25px + (8px / 2));
            height: calc(100% - 25px - 24px - 8px);
            width: 2px;
            background: var(--border);
        }
    }
}

</style>